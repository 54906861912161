import isHotkey from "is-hotkey";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { createEditor } from "slate";
import { withHistory } from "slate-history";
import { Editable, Slate, withReact } from "slate-react";

import { Box, FormControl } from "@chakra-ui/react";
import { extractTextFromSlateValue, withInlines } from "../Message/MessageInput/ulility";
import {
  HOTKEYS,
  SlateElement,
  SlateLeaf,
  handleEnter,
  toggleMark,
} from "./SlateEditorUtil";
import { SlateToolbar } from "./SlateToolbar";

export const SlateInputField = ({
  initValue,
  required,
  fullWidth,
  placeholder,
  onChange,
  textContainerStyle,
  setValue, name,
  disabled
}) => {
  const [slateValue, setSlateValue] = useState(initValue);
  const [plainText, setPlainText] = useState("");
  const editor = useMemo(() => withInlines(withHistory(withReact(createEditor()))), []);
  const renderElement = useCallback((props) => <SlateElement {...props} />, []);
  const renderLeaf = useCallback((props) => <SlateLeaf {...props} />, []);

  useEffect(() => {
    setValue(name, plainText ? plainText : extractTextFromSlateValue(initValue));
  }, [plainText]);

  return (
    <FormControl isRequired={required} width={fullWidth ? "100%" : "auto"}>
      <Box boxShadow="md" borderWidth="0px" borderRadius="md" className="slate-editor slate-editor-wrapper">
        <Slate
          editor={editor}
          initialValue={slateValue}
          onChange={(value) => {
            if (value.length === 0) {
              value = initValue;
            }
            // setSlateValue(JSON.stringify(value));
            setSlateValue(value);
            onChange(JSON.stringify(value));
            setPlainText(extractTextFromSlateValue(value));
          }}
        >
          {!disabled &&
            <SlateToolbar />
          }
          <Editable
            readOnly={disabled}
            renderLeaf={renderLeaf}
            placeholder={placeholder}
            renderElement={renderElement}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault();
                const { nativeEvent } = event;
                handleEnter(editor, nativeEvent);
              }
              for (const hotkey in HOTKEYS) {
                if (isHotkey(hotkey, event)) {
                  event.preventDefault();
                  const mark = HOTKEYS[hotkey];
                  toggleMark(editor, mark);
                }
              }
            }}
            style={{ ...textContainerStyle }}
          />
        </Slate>
      </Box>
    </FormControl >
  );
};
