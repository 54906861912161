import { Box, Flex, Grid, GridItem, Link, Text } from "@chakra-ui/react";
import { get, isEqual, orderBy } from 'lodash';
import moment from "moment";
import { memo, useContext, useEffect, useMemo, useState } from "react";
import { publicRoute } from "../../../../../../config/api.config";
import { AppContext } from "../../../../../../contexts/AppContext";

export default function Card() {
    const [{ user }] = useContext(AppContext);
    const [feeds, setFeed] = useState([]);

    const rssLinks = useMemo(() => {
        const skills = get(user, 'currentProfession.skills', []);
        const rssLinks = [];

        if (skills) {
            skills.forEach(skill => {
                rssLinks.push(...skill?.rss?.filter(rss => rss) || []);
            });
        }

        return rssLinks;
    }, [user]);

    useEffect(() => {
        getRSSFeeds();
    }, [rssLinks]);

    const fetchFeed = async (url) => {
        try {
            const response = await publicRoute.get(`https://api.rss2json.com/v1/api.json?rss_url=${encodeURIComponent(url)}`);
            return response.data?.items || [];
        } catch (err) {
            console.log(' ERROR ', err);
        }
    };

    const getRSSFeeds = async () => {
        const promises = rssLinks.map(link => fetchFeed(link));

        Promise.all(promises).then(response => {
            const feed = response?.filter(r => r)?.reduce?.((responses, response) => ([...responses, ...response]), []);
            setFeed(feed);
        });
    };

    const sortedFeedsByDateTime = useMemo(() => {
        return orderBy(feeds, 'pubDate', 'desc');
    }, [feeds]);
    return (
        <Flex gap="35px" flexDirection={{ md: "column", lg: "unset" }}>
            {sortedFeedsByDateTime.length > 0 && <Box maxW={1081} >
                <Text pt="35px" pb="10px" fontSize="fs.18" fontWeight="fw.500" textAlign="start">Latest IT News</Text>
                <Grid height="auto" background="white" padding="25px" borderRadius="br.10" gap="25px" overflow={'hidden'} templateColumns={{ md: "repeat(3, 1fr)", }} >
                    {sortedFeedsByDateTime.map((feed) =>
                        <FeedItem feed={feed} key={feed.link} />
                    )}
                </Grid>
            </Box>}
        </Flex>
    );
}

const FeedItem = memo(({ feed }) => {
    return (
        <GridItem border="1px solid #E5E5E5" borderRadius="br.10">
            <Link href={feed.link} isExternal _hover={{ textDecor: 'none' }}>
                <Box bg={`url(${feed.thumbnail || '/placeholder.png'})`} width="100%" h={133} bgSize={'cover'} bgPos={'center'} borderRadius={'5px 5px 0 0'} />
                <Box padding="10px" textAlign="start">
                    <Flex gap="7px" justify={'space-between'}>
                        <Text fontSize="fs.12" fontWeight="fw.500" lineHeight="22px" pb="4px" color="#A0A6B1">{feed?.author || 'Unknown'}</Text>
                        <Text fontSize="fs.12" fontWeight="fw.500" lineHeight="22px" pb="4px" color="#A0A6B1">{moment(feed?.pubDate).format('LL')}</Text>
                    </Flex>
                    <Text fontSize="fs.16" fontWeight="fw.500" >{feed?.title}</Text>
                </Box>
            </Link>
        </GridItem>
    );
}, (pp, np) => isEqual(pp, np));