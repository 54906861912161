import { Box, Flex, Tooltip, useBoolean } from "@chakra-ui/react";
import { IconBook, IconBuildingStore, IconBulb, IconColumns3, IconFilePencil, IconFrame, IconHome2, IconMessage, IconUserCog } from "@tabler/icons-react";
import { isEmpty } from "lodash";
import { useContext } from "react";
import { Link, useLocation, useMatch } from 'react-router-dom';
import { AppContext } from "../../contexts/AppContext";
import { isAdmin, isSysAdmin } from "../../utils/users.utils";
import ChatContent from "../Message/MessageSiderBar";
import "../style.css";
import { ChatContext } from "../../contexts/ChatContexts";

export default function SideNavigation() {
	const [showMessageSideBar, setShow] = useBoolean(false);
	const location = useLocation();
	const match = useMatch('/message/*');
	const [stateApp] = useContext(AppContext);
	const [chatState] = useContext(ChatContext);
	const { channels, directs, unreadCounts } = chatState || {};
	const { user } = stateApp;


	const totalUnreadCounts = () => {
		const totalDirectsUnreadCount = directs.reduce((total, direct) => {
			const unreadCount = unreadCounts?.directs?.find(directCount => directCount.channelId === direct?._id)?.unreadCount || 0;
			return total + unreadCount;
		}, 0);

		const totalChannelsUnreadCount = channels.reduce((total, channel) => {
			const unreadCount = unreadCounts?.channels?.find(channelCount => channelCount.channelId === channel?._id)?.unreadCount || 0;
			return total + unreadCount;
		}, 0);

		return totalDirectsUnreadCount + totalChannelsUnreadCount;
	};

	const totalUnreadCount = totalUnreadCounts();

	return (

		<>
			<Flex direction={{ base: 'row', md: "column" }} justify={{ base: 'center', md: 'unset' }} alignItems="center" borderTop="1px solid #E5E5E5" gap={{ base: 10, md: 7 }} wrap={'nowrap'} overflowX={{ base: 'auto', md: 'hidden' }} px={{ base: 4, md: 0 }} py={{ base: 3 }} color="gray.500" h="full" boxShadow={'0 0 10px 0px rgba(0, 0, 0, 0.1)'}>
				<Link to="/" >
					<Flex justifyContent="center" px={{ base: "10px", md: "18px", xl: "25px" }}>
						<Tooltip label='Home' placement="right" hasArrow>
							<Box className={`menu-item ${location?.pathname === '/' ? 'active-menu-item' : ''}`} h={35} w={35} borderRadius={'br.10'}>
								<IconHome2 size={25} />
							</Box>
						</Tooltip>
					</Flex>
				</Link>
				<Link to="/channels" >
					<Flex justifyContent="center" px={{ base: "10px", md: "18px", xl: "25px" }}>
						<Tooltip label='Channels' placement="right" hasArrow>
							<Box className={`menu-item ${location?.pathname === '/channels' ? 'active-menu-item' : ''}`} h={35} w={35} borderRadius={'br.10'}>
								<IconFrame size={25} />
							</Box>
						</Tooltip>
					</Flex>
				</Link>
				{
					!isAdmin(user) && <Link to="/message" >
						<Flex justifyContent="center" px={{ base: "10px", md: "18px", xl: "25px" }}
							onMouseEnter={setShow.on}
							onMouseLeave={setShow.off}
						>
							<Tooltip label='Chat' placement={'right'} hasArrow>
								<Box className={`menu-item ${!isEmpty(match) ? 'active-menu-item' : ''}`} h={35} w={35} borderRadius={'br.10'}
								>
									<IconMessage size={25} />
								</Box>
							</Tooltip>
							{((!isEmpty(match) && showMessageSideBar) || match?.pathname === '/message') &&
								<Box
									className="ContentBarCss"
									py="25px"
									px="15px"
									width={{ base: '100dvw', md: "380px" }}
									height={{ base: "calc(100dvh - 160px)", md: 'calc(100dvh - 80px)' }}
									overflowX={'auto'}
									boxShadow={'8px 2px 7px 0px rgba(0, 0, 0, 0.10)'}
									borderRight="1px solid gray.500"
									background="white"
									zIndex={10}
									display={{ base: `${(showMessageSideBar || match.pathname === '/message') ? 'unset' : 'none'}`, xl: "none" }}
									position="absolute"
									top={{ base: '80px' }}
									left={{ base: 0, md: "80px" }}>
									<ChatContent />
								</Box>}
						</Flex>
					</Link>}
				{
					!isAdmin(user) &&
					<Link to="/project" >
						<Flex justifyContent="center" px={{ base: "10px", md: "18px", xl: "25px" }}>
							<Tooltip label='Projects' placement="right" hasArrow>
								<Box className={`menu-item ${location.pathname === '/project' ? 'active-menu-item' : ''}`} h={35} w={35} borderRadius={'br.10'}>
									<IconFilePencil size={25} />
								</Box>
							</Tooltip>
						</Flex>
					</Link>
				}
				{
					!isAdmin(user) &&
					<Link to="/boards" >
						<Flex justifyContent="center" px={{ base: "10px", md: "18px", xl: "25px" }}>
							<Tooltip label='Boards' placement="right" hasArrow>
								<Box className={`menu-item ${location.pathname === '/boards' ? 'active-menu-item' : ''}`} h={35} w={35} borderRadius={'br.10'}>
									<IconColumns3 size={25} />
								</Box>
							</Tooltip>
						</Flex>
					</Link>
				}
				<Link to="/library" >
					<Flex justifyContent="center" px={{ base: "10px", md: "18px", xl: "25px" }}>
						<Tooltip label='Library' placement="right" hasArrow>
							<Box className={`menu-item ${location.pathname === '/library' ? 'active-menu-item' : ''}`} h={35} w={35} borderRadius={'br.10'}>
								<IconBook size={25} />
							</Box>
						</Tooltip>
					</Flex>
				</Link>
				{
					isSysAdmin(user) &&
					<Link to="/product-list" >
						<Flex justifyContent="center" px={{ base: "10px", md: "18px", xl: "25px" }}>
							<Tooltip label='Products' placement="right" hasArrow>
								<Box className={`menu-item ${location.pathname === '/product-list' ? 'active-menu-item' : ''}`} h={35} w={35} borderRadius={'br.10'}>
									<IconBuildingStore size={25} />
								</Box>
							</Tooltip>
						</Flex>
					</Link>
				}
				{
					isSysAdmin(user) &&
					<Link to="/manage-users" >
						<Flex justifyContent="center" px={{ base: "10px", md: "18px", xl: "25px" }}>
							<Tooltip label='Manage Users' placement="right" hasArrow>
								<Box className={`menu-item ${location.pathname === '/manage-users' ? 'active-menu-item' : ''}`} h={35} w={35} borderRadius={'br.10'}>
									<IconUserCog size={25} />
								</Box>
							</Tooltip>
						</Flex>
					</Link>
				}
				{
					isSysAdmin(user) &&
					<Link to="/skills" >
						<Flex justifyContent="center" px={{ base: "10px", md: "18px", xl: "25px" }}>
							<Tooltip label='Skills' placement="right" hasArrow>
								<Box className={`menu-item ${location.pathname === '/skills' ? 'active-menu-item' : ''}`} h={35} w={35} borderRadius={'br.10'}>
									<IconBulb size={25} />
								</Box>
							</Tooltip>
						</Flex>
					</Link>
				}
			</Flex >
		</>
	);
}
