import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import channelReducer from './channelSlice';
import tokenReducer from './tokenSlice';
import SignupStepsReducer from './SignupStepsSlice';

const tokenPersistConfig = {
  key: 'token',
  storage,
};

const channelPersistConfig = {
  key: 'channel',
  storage,
};

const persistedTokenReducer = persistReducer(tokenPersistConfig, tokenReducer);
const persistedChannelReducer = persistReducer(channelPersistConfig, channelReducer);

const rootReducer = combineReducers({
  channel: persistedChannelReducer,
  token: persistedTokenReducer,
  SignupSteps: SignupStepsReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

const persistor = persistStore(store);

export { store, persistor };
