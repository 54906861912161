import axios from "axios";
import { protectedRoute, publicRoute } from "../config/api.config";

export function getCountriesAPI() {
    return publicRoute.get('/static/countries');
}

export function getStatesAPI({ queryKey }) {
    return publicRoute.get('/static/state-of-countries?countryCode=' + queryKey[1]);
}

export function getCitiesAPI({ queryKey }) {
    return publicRoute.get('/static/cities-of-states?countryCode=' + queryKey[1] + "&stateCode=" + queryKey[2]);
}

export function getCitiesAPI2({ queryKey }) {
    const countryCode = queryKey[1];
    return publicRoute.get(`/static/cities-of-countries?countryCode=${countryCode}`);
}

export function getPreSignedURL(payload) {
    return protectedRoute.post('/files/getPreSignedURL', payload);
}
export function getPreSignedThumbnailURL(payload, isPDF) {
    return protectedRoute.post(isPDF ? '/files/get-pdf-pre-signed-url' : '/files/get-image-pre-signed-url', payload);
}

export async function uploadToS3(url, file) {
    try {
        const response = await axios.put(url, file, {
            headers: {
                'Content-Type': file.type,
                'Cache-Control': 'no-cache',
            }
        });
        return response;
    } catch (error) {
        console.error('Error uploading file to S3:', error);
        throw error;
    }
}
