export const StatusOptions = [
  {
    label: "To Do",
    value: "Todo",
  },
  {
    label: "In Progress",
    value: "InProgress",
  },
  {
    label: "Review",
    value: "Review",
  },
  {
    label: "Done",
    value: "Done",
  },
];


export const PriorityOptions = [
  {
    label: "Low",
    value: "Low",
  },
  {
    label: "Medium",
    value: "Medium",
  },
  {
    label: "High",
    value: "High",
  },
  {
    label: "Highest",
    value: "Highest",
  },

];
