import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { last } from 'lodash';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { GET_EBOOK } from '../../../apis/constants.api';
import { updatePublishEBook } from '../../../apis/ebook.api';
import ThumbImageUpload from '../../Dropzone/ThumbImageUpload';
import RelevantSkill from './RelevantSkill';
import { bookSchema } from './form.schema';

const EBookEditDialog = ({ book, isEditOpen, onEditClose, onEditOpen }) => {
  const methods = useForm({
    defaultValues: {
      thumbnailUrl: book.thumbnailUrl,
      title: book.title,
      pdfUrl: book.pdfUrl,
      relevantSkills: book.relevantSkills.map((item) => ({ value: item._id, label: item.name })),
    },
    resolver: yupResolver(bookSchema)
  });
  const { formState: { errors }, control, register } = methods;

  const queryClient = useQueryClient();

  const { mutate: updatePublishEBooks, isLoading: isUpdating } = useMutation(updatePublishEBook, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_EBOOK] });
      methods.reset();
      onEditClose();
      toast({
        title: 'Book updated successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    },
  });

  const onSubmit = async (values) => {
    const payload = {
      thumbnailUrl: values.thumbnailUrl,
      title: values.title,
      pdfUrl: values.pdfUrl,
      relevantSkills: values.relevantSkills.map((item) => item.value),
    };

    const bookId = book._id;
    updatePublishEBooks({ bookId, ...payload });
  };

  const onError = (error) => {
    console.log(' >>>>> onError ', error);
  };

  return (
    <Modal closeOnOverlayClick={false} onClose={onEditClose} isOpen={isEditOpen} isCentered>
      <ModalOverlay />
      <form onSubmit={methods.handleSubmit(onSubmit, onError)}>
        <ModalContent maxWidth="599px" borderRadius="10px">
          <ModalHeader fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16", xl: "fs.18" }} fontWeight="fw.600">Update Book</ModalHeader>
          <ModalCloseButton border="1px solid #868B944D" />
          <ModalBody pt="4px" pb="30px">
            <Box>
              <Box mt="30px">
                <Text fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} fontWeight="fw.500">Title</Text>
                <Controller
                  name="title"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) =>
                    <Input {...field} placeholder="Enter Ebook name" height="50px" _hover={{ borderColor: "#713EDD" }} background="#F7FAFC" mt="8px" _focusVisible={{ borderColor: "#713EDD" }} />
                  }
                />
              </Box>
              <Box mt="30px">
                <Text fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} fontWeight="fw.500">Thumbnail Image</Text>
                <Controller
                  name="thumbnailUrl"
                  control={control}
                  render={({ field }) =>
                    <>
                      <ThumbImageUpload
                        {...field}
                        innerMessage='Upload Thumbnail Image'
                        acceptedFiles={{ 'image/*': ['jpg', 'jpeg', 'png', '.webp'] }}
                        maxFiles={1}
                        defaultValue={process.env.REACT_APP_THUMBNAIL_URL + book.thumbnailUrl}
                        onRemove={() => field.onChange('')}
                        onUpload={(uploadObject) => {
                          field.onChange(last(uploadObject?.url?.split?.('/')));
                        }}
                      />
                      {errors.thumbnailUrl?.message && <Text color="red.500" fontSize={'fs.14'}>{errors.thumbnailUrl.message}</Text>}
                    </>
                  }
                />
              </Box>
              <Box mt="30px">
                <Text fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} fontWeight="fw.500">PDF File</Text>
                <Controller
                  name="pdfUrl"
                  control={control}
                  render={({ field }) =>
                    <>
                      <ThumbImageUpload
                        {...field}
                        innerMessage='Upload PDF File'
                        acceptedFiles={{ 'application/pdf': ['.pdf'] }}
                        maxFiles={1}
                        isPDF={true}
                        maxSize={1024 * 1024 * 500}
                        defaultValue={book.pdfUrl}
                        onRemove={() => field.onChange('')}
                        onUpload={(uploadObject) => {
                          field.onChange(last(uploadObject?.url?.split?.('/')));
                        }}
                      />
                      {errors.pdfUrl?.message && <Text color="red.500" fontSize={'fs.14'}>{errors.pdfUrl.message}</Text>}
                    </>
                  }
                />
              </Box>
              <Box mt="30px">
                <Text fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} fontWeight="fw.500">Relevant Skill</Text>
                <Controller
                  height="50px"
                  name="relevantSkills"
                  control={control}
                  render={({ field }) => (
                    <RelevantSkill skillVal={book.relevantSkills} register={register} field={field} />
                  )}
                />
              </Box>
            </Box>
          </ModalBody>
          <ModalFooter gap="20px" background="#F5F4F6" borderRadius="0px 0px 10px 10px">
            <Button background="#EAE9EC" onClick={onEditClose} >Cancel</Button>
            <Button
              type="submit"
              px={{ md: "15px", lg: "30px" }}
              py="8px"
              background="linear-gradient(134.59deg, #713EDD -1.98%, #A17DEE 111.17%)"
              display="flex"
              columnGap="5px"
              color="white"
              _hover={{ background: "linear-gradient(134.59deg, #A17DEE -1.98%, #713EDD 111.17%)" }}
              isLoading={isUpdating}
              loadingText={'Updating'}
              spinnerPlacement={'end'}
              ml={2}>
              {'Update'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};

export default EBookEditDialog;