import {
  Box, Button, Flex, Input, Modal, ModalBody,
  ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text,
  useToast
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import AsyncSelect from "react-select/async";
import { GET_ALL_USERS, GET_MY_KEY, GET_PROJECTS } from "../../../apis/constants.api";
import { getUserByProject, updateProject } from "../../../apis/project.api";
import { getKeyAvailability, getPlatformUserDataAPI } from "../../../apis/users.api";
import useDebounce from "../../../hooks/useDebounce.hook";
import CustomDropzone from "../../Dropzone/FileDropZone";
import { schema } from "./form";

const ProjectEditDialog = ({ openEditModal, project, onCloseEditModal }) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const [inputSearch, setInputSearch] = useState('');
  const debouncedSearch = useDebounce(inputSearch);

  const { control, handleSubmit, formState: { errors }, setError } = useForm({
    defaultValues: {
      name: project.name,
      key: project.key,
      icon: [project.icon],
      team: project.team.flatMap(team => team._id),
    },
    resolver: yupResolver(schema)

  });


  const { data: getUser, refetch: refetchUser } = useQuery({
    queryKey: [GET_PROJECTS, {
      projectId: project._id,
    }],
    queryFn: getUserByProject,
    enabled: project && true
  });


  const { mutate } = useMutation(updateProject, {
    onSuccess: data => {
      queryClient.invalidateQueries({ queryKey: [GET_PROJECTS] });
      onCloseEditModal();
    },
    onError: (error) => {
      let errorMessage;

      if (error?.response?.data?.message) {
        errorMessage = error.response.data.message;

        toast({
          title: "Error",
          description: errorMessage,
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
      }

    }
  });
  const { refetch } = useQuery({
    queryKey: [GET_ALL_USERS, { search: debouncedSearch }],
    queryFn: getPlatformUserDataAPI,
    enabled: false
  });

  const loadUserOption = useCallback(debounce(async (e, callback) => {
    const result = await refetch();
    callback((result?.data?.data?.data || []).filter((e) => e.role === "Student" && e.status === "ACCEPTED").map(op => ({ label: op.email, value: op._id })));
  }, 500), []);

  const onSubmit = async (data) => {
    const { name, key, icon } = data;
    if (!name || !key || !icon) {
      return;
    };
    const payload = {
      name: data.name,
      key: data.key,
      icon: data.icon,
      team: data.team,
    };
    const projectId = project._id;
    await mutate({ projectId, ...payload });

  };
  const [key, setKey] = useState("");
  const debouncedKey = useDebounce(key);

  const { data: keyAvilable } = useQuery({
    queryKey: [GET_MY_KEY, {
      key: debouncedKey,
    }],
    queryFn: getKeyAvailability,
  });

  const handleKeyCheck = (e) => {
    var searchText = e.target.value;
    setKey(searchText);
  };

  useEffect(() => {
    if (keyAvilable?.data?.available !== true && keyAvilable?.data?.available !== undefined) {
      setError('key', { type: 'custom', message: 'This key already exists. Please add something different.' });
    }
  }, [keyAvilable]);

  return (<>
    <Modal isOpen={openEditModal} onClose={onCloseEditModal} isCentered size="xl">
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit project</ModalHeader>
          <ModalCloseButton border="1px solid #868B944D" />
          <ModalBody>
            <Box>
              <Box >
                <Text fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} fontWeight="fw.500">Project name </Text>
                <Controller
                  name="name"
                  control={control}

                  rules={{ required: true }}
                  render={({ field, fieldState }) =>
                    <>  <Input {...field} placeholder="Project name "
                      errorBorderColor="red.500"
                      isInvalid={errors?.name?.message || fieldState?.['name']?.message}
                      height="50px" _hover={{ borderColor: "#713EDD" }}
                      background="#F7FAFC" mt="8px" _focusVisible={{ borderColor: "#713EDD" }} />

                      {(errors?.name?.message || fieldState?.['name']?.message)
                        && <Text size={'small'} fontSize={'sm'} mt={1} color="red.500">{errors?.name?.message || fieldState?.['name']?.message}</Text>}
                    </>

                  }

                />
              </Box>
              <Box mt="20px">
                <Text fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} fontWeight="fw.500">Key</Text>
                <Controller
                  name="key"
                  control={control}

                  rules={{ required: true }}
                  render={({ field, fieldState }) =>
                    <>
                      <Input errorBorderColor="red.500"
                        isInvalid={errors?.key?.message || fieldState?.['key']?.message}  {...field} placeholder="Project key " height="50px" _hover={{ borderColor: "#713EDD" }} background="#F7FAFC" mt="8px" _focusVisible={{ borderColor: "#713EDD" }} onChange={(val) => {
                          field.onChange(val); handleKeyCheck(val);
                        }} />

                      {(errors?.key?.message || fieldState?.['key']?.message)
                        && <Text size={'small'} fontSize={'sm'} mt={1} color="red.500">{errors?.key?.message || fieldState?.['key']?.message}</Text>}
                    </>
                  }
                />
              </Box>
              <Box mt="20px">
                <Text fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} mb="8px" fontWeight="fw.500">Icon</Text>
                <Controller
                  name="icon"
                  control={control}
                  render={({ field, fieldState }) =>
                    <CustomDropzone
                      {...field}
                      innerMessage='Upload Project Icon'
                      acceptedFiles={{ 'image/*': ['jpg', 'jpeg', 'png', '.webp'] }}
                      maxFiles={5}
                      defaultImages={[project.icon]}
                      onFileChange={(files) => field.onChange(files[0])}
                      error={errors?.icon?.message || fieldState?.['icon']?.message}
                    />
                  }
                />
              </Box>
              <Box my="20px">
                <Box mt="20px" mb="27px">
                  <Text fontSize="fs.16" fontWeight="fw.500" mb="8px">Add Member</Text>

                  <Controller
                    name="team"
                    control={control}
                    render={({ field, fieldState }) =>
                      <>
                        <AsyncSelect
                          isMulti
                          cacheOptions
                          defaultOptions
                          loadOptions={loadUserOption}
                          onChange={(value) => field.onChange(value.map(val => val.value))}
                          onInputChange={setInputSearch}
                          defaultValue={getUser && getUser?.data?.data?.team?.map(member => ({ label: member.email, value: member._id }))}
                        />
                        {(errors?.team?.message || fieldState?.['team']?.message)
                          && <Text size={'small'} fontSize={'sm'} mt={1} color="red.500">{errors?.team?.message || fieldState?.['team']?.message}</Text>}
                      </>

                    }
                  />
                </Box>
              </Box>
            </Box>
          </ModalBody>
          <ModalFooter background="#F5F4F6" borderRadius=" 0px 0px 10px 10px">
            <Flex gap="10px">
              <Button background="#EAE9EC" onClick={onCloseEditModal}>Cancel</Button>
              <Button type="submit" px={{ md: "15px", lg: "30px" }} py="8px" background="linear-gradient(134.59deg, #713EDD -1.98%, #A17DEE 111.17%)" _hover={{ background: "linear-gradient(134.59deg, #A17DEE -1.98% ,#713EDD 111.17%)" }} color="white">Update</Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>



  </>);
};

export default ProjectEditDialog;