import { Box, Button, ButtonGroup, Flex, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, NumberInput, NumberInputField, Spinner, Text, Textarea, useDisclosure } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { forwardRef, memo, useImperativeHandle, useState, } from "react";
import { GET_PRODUCTS } from "../../../apis/constants.api";
import { deleteProduct } from "../../../apis/products.api";
import { IconTrash } from "@tabler/icons-react";
import { toast } from "react-toastify";
import reject from "../../../../src/assets/icon/reject.png";

const ProductDeleteDialog = forwardRef(function ProductDeleteDialog({ product }, ref) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);

  useImperativeHandle(ref, () => {
    return {
      openDialog() {
        onOpen();
      }
    };
  }, [onOpen]);

  const queryClient = useQueryClient();


  const { mutate: deleteProducts } = useMutation(deleteProduct, {
    onSuccess: data => {
      queryClient.invalidateQueries({ queryKey: [GET_PRODUCTS] });
      onClose();
    },

  });

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      await deleteProducts(product._id);
      toast({
        title: 'E-Book deleted successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      // onDelete();
      onClose();
    } catch (error) {
      console.error('Error:', error);
      toast({
        title: 'An error occurred',
        description: 'Failed to delete E-Book',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal closeOnOverlayClick={false} onClose={onClose} size="lg" isOpen={isOpen} isCentered >

      <ModalOverlay />
      <ModalContent>

        <ModalHeader>
          <Image alt='reject' src={reject} />
        </ModalHeader>
        <ModalCloseButton border="1px solid #868B944D" />
        <ModalBody>
          <Text fontSize="fs.18" fontWeight="fw.600">{`Delete "${product.title}"`}</Text>
          <Text fontSize="fs.14" fontWeight="fw.400" color="#475467" mt="5px" >Are you sure you want to delete this product?</Text>

          <ButtonGroup variant='outline' spacing='5' width="100%" my="18px">
            <Button {...actionButtonChakraProps} width={"100%"} onClick={onClose}>Cancel</Button>
            <Button {...actionButtonChakraProps} width={"100%"} onClick={handleDelete} background={'red.500'} color="white" _hover={{ background: "red.600" }}>
              Delete
              {isLoading && <Spinner size="sm" ml={2} />}
            </Button>
          </ButtonGroup>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
});

const actionButtonChakraProps = {
  fontWeight: "fw.600",
  width: "100%",
  borderRadius: "8px",
  height: "44px",
  fontSize: "fs.16",
};

export default memo(ProductDeleteDialog);