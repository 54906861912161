import { Box, Flex } from "@chakra-ui/react";
import HelloSection from "./HelloSection";
import Projects from "./Projects";
import PlanSchedule from "./PlanSchedule";
import Card from "./News/Card";
import { useContext, useEffect } from "react";
import { AppContext } from "../../../../contexts/AppContext";
import { ChatContext } from "../../../../contexts/ChatContexts";

export default function RightContent() {
    const [AppState] = useContext(AppContext);
    const [chatState, __, { refetchUnreadCount }] = useContext(ChatContext);
    const { user } = AppState;

    useEffect(() => {
        if (user?._id) {
            refetchUnreadCount();
        }

    }, [user?._id]);
    return (
        <Box padding="35px" width="100%" className="scrollBar" overflowY="scroll" background="#F7F7F7">
            <HelloSection />
            <Flex
                direction={{ base: "column-reverse", lg: "row" }}
                gap={{ base: 4, md: 8 }}
                alignItems={{ base: "center", lg: "flex-start" }}
            >
                <Box width={{ base: "100%", lg: "70%" }}>
                    <PlanSchedule />
                    {user?.currentProfession?.skills && <Card />}
                </Box>
                <Box width={{ base: "100%", lg: "40%" }}>
                    <Projects />
                </Box>
            </Flex>
        </Box>
    );
}
