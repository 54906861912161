import {
    Avatar,
    AvatarGroup,
    Box,
    Flex,
    Image,
    Slider,
    SliderFilledTrack,
    SliderTrack,
    Text,
    Spinner,
} from "@chakra-ui/react";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TaskCompleted from "../../../../../../assets/icon/TaskCompleted.svg";
import { useQuery } from "@tanstack/react-query";
import { getMyTasks } from "../../../../../../apis/task.api";
import { GET_MY_TASKS } from "../../../../../../apis/constants.api";

export default function CompletedTasks() {
    const backgroundColors = ["#E5E5FE", "#E9F5FE", "#FFDDCF"];
    const iconbackgroundColors = ["#D7D5FC", "#CDE7FE", "#F8C4B3"];
    const sliderBgColor = ["#533DC2", "#488BBD", "#F37C49"];

    const { data: tasksData, isLoading } = useQuery(
        [GET_MY_TASKS],
        getMyTasks
    );

    const completedTasks = tasksData?.data?.data?.completedTasks;

    return (
        <Box>
            <Text pt="35px" pb="10px" fontSize="fs.18" fontWeight="fw.500" textAlign="start">
                Completed Tasks
            </Text>
            <Box overflow="scroll">
                {isLoading ? (
                    <Box
                        padding="25px"
                        borderRadius="br.10"
                        background="white"
                        height="210px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Spinner />
                    </Box>
                ) : completedTasks && completedTasks.length > 0 ? (
                    completedTasks.map((task, index) => {
                        let description = "";
                        try {
                            const parsedDescription = JSON.parse(task.description);
                            description = parsedDescription
                                .map((item) =>
                                    item.children.map((child) => child.text).join(" ")
                                )
                                .join(" ");
                        } catch (error) {
                            console.error("Failed to parse description:", error);
                        }

                        return (
                            <Box
                                key={index}
                                padding="20px"
                                borderRadius="br.10"
                                background={backgroundColors[index % backgroundColors.length]}
                                height="210px"
                                mb={4}
                            >
                                <Flex justifyContent="space-between" alignItems="center" textAlign="start">
                                    <Flex alignItems="center" gap="12px">
                                        <Flex
                                            background={iconbackgroundColors[index % iconbackgroundColors.length]}
                                            borderRadius="br.50"
                                            alignItems="center"
                                            justifyContent="center"
                                            width="40px"
                                            height="40px"
                                            padding="6px"
                                        >
                                            <Image src={TaskCompleted} alt="Task Completed Icon" />
                                        </Flex>
                                        <Text fontSize="fs.18" fontWeight="fw.600">
                                            {task.title}
                                        </Text>
                                    </Flex>
                                    <FontAwesomeIcon icon={faEllipsis} />
                                </Flex>
                                <Box mt="20px" textAlign="start">
                                    <Text>
                                        {description.length > 0 ? `${description.slice(0, 40)}${description?.length > 40 ? "..." : ""}` : "No description added for this task"}
                                    </Text>
                                    <Slider
                                        aria-label="slider-ex-2"
                                        colorScheme="blue"
                                        borderRadius="br.10"
                                        defaultValue={100}
                                        isDisabled
                                    >
                                        <SliderTrack height="8px" borderRadius="br.10">
                                            <SliderFilledTrack bg={sliderBgColor[index % sliderBgColor.length]} />
                                        </SliderTrack>
                                    </Slider>
                                </Box>
                                <Box mt="10px">
                                    <Flex justifyContent="space-between">
                                        <AvatarGroup size="md" max={2}>
                                            <Avatar
                                                name={task?.assignee?.name}
                                                src={task?.assignee?.profilePicture}
                                            />
                                            <Avatar
                                                name={task?.reporter?.name}
                                                src={task?.reporter?.profilePicture}
                                            />
                                        </AvatarGroup>
                                    </Flex>
                                </Box>
                            </Box>
                        );
                    })
                ) : (
                    <Box
                        height="210px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        backgroundColor="white"
                        borderRadius="br.10"
                    >
                        <Text fontSize="lg">No tasks are completed</Text>
                    </Box>
                )}
            </Box>
        </Box>
    );
}
