import { Avatar, Box, Flex, Text } from "@chakra-ui/react";
import { faMessage } from "@fortawesome/free-regular-svg-icons";
import { faListCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { AppContext } from "../../../../../contexts/AppContext";
import { useNavigate } from "react-router-dom";
import { ChatContext } from "../../../../../contexts/ChatContexts";
import { GET_MY_TASKS } from "../../../../../apis/constants.api";
import { getMyTasks } from "../../../../../apis/task.api";
import { useQuery } from "@tanstack/react-query";


export default function HelloSection() {
    const [appState] = useContext(AppContext);
    const [chatState] = useContext(ChatContext);
    const { channels, directs, unreadCounts } = chatState || {};
    const navigate = useNavigate();
    const handleClickOnMessages = () => {
        if (appState?.user?.role !== 'SysAdmin') {
            navigate('/message');
        }
    };
    const handleClickOnBoard = () => {
        if (appState?.user?.role !== 'SysAdmin') {
            navigate('/boards');
        }
    };

    const totalUnreadCounts = () => {
        const totalDirectsUnreadCount = directs.reduce((total, direct) => {
            const unreadCount = unreadCounts?.directs?.find(directCount => directCount.channelId === direct?._id)?.unreadCount || 0;
            return total + unreadCount;
        }, 0);

        const totalChannelsUnreadCount = channels.reduce((total, channel) => {
            const unreadCount = unreadCounts?.channels?.find(channelCount => channelCount.channelId === channel?._id)?.unreadCount || 0;
            return total + unreadCount;
        }, 0);

        return totalDirectsUnreadCount + totalChannelsUnreadCount;
    };

    const totalUnreadCount = totalUnreadCounts();

    const { data: tasksData } = useQuery(
        [GET_MY_TASKS],
        getMyTasks
    );

    const totalTask = tasksData?.data?.data?.inProgressTasks?.length;

    return (
        <Box>
            <Flex
                direction={{ base: "column", md: "row", }}
                justifyContent="space-between"
                alignItems={{ base: "flex-start", md: "center" }}
                padding="25px"
                borderRadius="br.10"
                background="linear-gradient(136.49deg, #713EDD -27.36%, #A17DEE 98.8%)"
                gap={{ base: "15px", md: "0px" }}
            >
                <Flex alignItems="center" gap={{ base: "10px", md: "15px" }}>
                    <Box>
                        <Avatar key={appState?.user?._id} width={{ base: "50px", md: "65px" }} height={{ base: "50px", md: "65px" }} name={appState?.user?.name} src={appState?.user?.profilePicture} />
                    </Box>
                    <Box textAlign="start">
                        <Text
                            mb="4px"
                            fontSize={{ base: "fs.18", md: "fs.20", xl: "fs.22" }}
                            fontWeight="fw.600"
                            color="white"
                        >
                            Welcome! {appState?.user?.name}
                        </Text>
                        <Text
                            fontSize={{ base: "fs.12", md: "fs.14", xl: "fs.16" }}
                            fontWeight="fw.400"
                            color="white"
                        >
                            {appState?.myQuotes || "I hope you enjoy your day today."}
                        </Text>
                    </Box>
                </Flex>
                <Flex
                    direction={{ base: "row", sm: "row" }}
                    gap={{ base: "10px", sm: "20px" }}
                    alignItems="center"
                >
                    <Box padding="12px" background="white" borderRadius="br.5" width={{ base: "100%", sm: "auto" }} onClick={handleClickOnBoard} className="cursor-pointer">
                        <Flex
                            alignItems="center"
                            justifyContent={{ base: "space-between", sm: "center" }}
                            gap={{ base: "10px", md: "30px", lg: "50px", xl: "82px" }}
                        >
                            <Flex
                                alignItems="center"
                                justifyContent="center"
                                borderRadius="br.4"
                                width="35px"
                                height="35px"
                                padding="7px"
                                background="rgba(113, 62, 221, 0.1)"
                            >
                                <FontAwesomeIcon icon={faListCheck} size='lg' color="#713EDD" />
                            </Flex>
                            <Text
                                fontWeight="fw.700"
                                fontSize={{ base: "fs.18", md: "fs.20" }}
                            >
                                {totalTask || 0}
                            </Text>
                        </Flex>
                        <Box textAlign="end">
                            <Text
                                fontSize={{ base: "fs.12", md: "fs.14" }}
                                fontWeight="fw.400"
                                color="#868B94"
                            >
                                Tasks
                            </Text>
                        </Box>
                    </Box>
                    <Box padding="12px" background="white" borderRadius="br.5" width={{ base: "100%", sm: "auto" }} onClick={handleClickOnMessages} className="cursor-pointer">
                        <Flex
                            alignItems="center"
                            justifyContent={{ base: "space-between", sm: "center" }}
                            gap={{ base: "10px", md: "30px", lg: "50px", xl: "82px" }}
                        >
                            <Flex
                                width="35px"
                                alignItems="center"
                                justifyContent="center"
                                height="35px"
                                borderRadius="br.4"
                                padding="7px"
                                background="rgba(113, 62, 221, 0.1)"
                            >
                                <FontAwesomeIcon icon={faMessage} color="#713EDD" size='lg' />
                            </Flex>
                            <Text
                                fontWeight="fw.700"
                                fontSize={{ base: "fs.18", md: "fs.20" }}
                            >
                                {totalUnreadCount || 0}
                            </Text>
                        </Flex>
                        <Box textAlign="end">
                            <Text
                                fontSize={{ base: "fs.12", md: "fs.14" }}
                                fontWeight="fw.400"
                                color="#868B94"
                            >
                                Messages
                            </Text>
                        </Box>
                    </Box>
                </Flex>
            </Flex>

        </Box>
    );
}