import { Box, Button, Flex, Input, InputGroup, InputLeftElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, NumberInput, NumberInputField, Spinner, Text, Textarea, useDisclosure } from "@chakra-ui/react";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { GET_PRODUCTS } from "../../../apis/constants.api";
import { addNewProduct } from "../../../apis/products.api";
import CustomDropzone from '../../Dropzone/FileDropZone';
import ProductListView from "./ProductTable";
import { schema } from "./form";
import { useState } from "react";

export default function ProductListItems() {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const queryClient = useQueryClient();
	const [searchQuery, setSearchQuery] = useState("");

	const handleSearch = (e) => {
		var searchText = e.target.value.toLowerCase();
		setSearchQuery(searchText);
	};

	const { control, handleSubmit, reset, formState: { errors } } = useForm({
		defaultValues: {
			title: '',
			description: '',
			price: 0,
			stock: 0,
			ratings: 0,
			images: [],
		},
		resolver: yupResolver(schema)
	});

	const { mutate, isLoading } = useMutation(addNewProduct, {
		onSuccess: data => {
			queryClient.invalidateQueries({ queryKey: [GET_PRODUCTS] });
			reset();
			onClose();
		},
	});

	const onSubmit = (values) => {
		const payload = {
			...values,
		};
		mutate(payload);
	};

	return (
		<Box padding={{ base: "15px", lg: "25px", xl: "35px" }}>
			<Box background="white" borderRadius="br.10" padding="25px" height="calc(100vh - 150px)">

				<Flex width="100%" alignItems="center" justifyContent="space-between" gap={{ md: "10px", lg: "0" }}>
					<InputGroup width="auto">
						<InputLeftElement pointerEvents='none'>
							<FontAwesomeIcon icon={faMagnifyingGlass} style={{ color: "#A0A6B1" }} />
						</InputLeftElement>
						<Input onChange={handleSearch} focusBorderColor='#713EDD' variant='filled' width={{ lg: "auto", xl: "300px" }} type='tel' background='#EBEDF5' placeholder='Search' />
					</InputGroup>
					<Box pr="20px">
						<Button onClick={onOpen} px={{ md: "15px", lg: "30px" }} py="8px" border="1px solid #713EDD" fontSize="fs.16" fontWeight="fw.500" background="linear-gradient(134.59deg, #713EDD -1.98%, #A17DEE 111.17%)" _hover={{ background: "linear-gradient(134.59deg, #A17DEE -1.98% ,#713EDD 111.17%)" }} color="#FFFFFF">+ Add Product</Button>

						<Modal closeOnOverlayClick={false} onClose={onClose} isOpen={isOpen} isCentered >
							<form onSubmit={handleSubmit(onSubmit)}>
								<ModalOverlay />
								<ModalContent maxWidth="599px" borderRadius="10px">
									<ModalHeader fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16", xl: "fs.18" }} fontWeight="fw.600">Add New Product</ModalHeader>
									<ModalCloseButton border="1px solid #868B944D" />
									<ModalBody pt="4px" pb="30px">
										<Controller
											name="images"
											control={control}
											render={({ field, fieldState }) =>
												<>
													<CustomDropzone
														multiple
														innerMessage='Upload Product Images'
														acceptedFiles={{ 'image/*': ['jpg', 'jpeg', 'png', 'webp'] }}
														maxFiles={5}
														onFileChange={(files) => {
															field.onChange(files);
														}}
														error={errors?.images?.message || fieldState?.['image']?.message}
													/>
												</>
											}
										/>

										<Box>
											<Box mt="30px">
												<Text fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} fontWeight="fw.500">Product Name</Text>
												<Controller
													name="title"
													control={control}
													rules={{ required: true }}
													render={({ field, fieldState }) =>
														<>
															<Input
																{...field}
																errorBorderColor="red.500"
																isInvalid={errors?.title?.message || fieldState?.['title']?.message}
																placeholder="Enter product title"
																height="50px" _hover={{ borderColor: "#713EDD" }} background="#F7FAFC" mt="8px" _focusVisible={{ borderColor: "#713EDD" }} />
															{(errors?.title?.message || fieldState?.['title']?.message) && <Text size={'small'} fontSize={'sm'} mt={1} color="red.500">{errors?.title?.message || fieldState?.['title']?.message}</Text>}
														</>
													}
												/>
											</Box>
											<Box mt="20px">
												<Text fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} fontWeight="fw.500">Description</Text>
												<Controller
													name="description"
													control={control}
													rules={{ required: true }}
													render={({ field, fieldState }) =>
														<>
															<Textarea {...field} placeholder="Enter Description" height="150px" _hover={{ borderColor: "#713EDD" }} background="#F7FAFC" mt="8px" _focusVisible={{ borderColor: "#713EDD" }}></Textarea>
															{(errors?.description?.message || fieldState?.['description']?.message) && <Text size={'small'} fontSize={'sm'} mt={1} color="red.500">{errors?.description?.message || fieldState?.['description']?.message}</Text>}
														</>
													}
												/>
											</Box>
											<Flex justifyContent="space-between" gap="20px" mt="20px">
												<Box width="100%">
													<Text fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} fontWeight="fw.500">Price</Text>
													<Controller
														name="price"
														control={control}
														rules={{ required: true }}
														render={({ field, fieldState }) =>
															<>
																<NumberInput
																	{...field}
																	onChange={(strValue, numberValue) => field.onChange(numberValue || strValue)}
																	max={10000000}
																	min={0}
																	precision={2}
																	clampValueOnBlur={false}
																	errorBorderColor="red.500"
																	isInvalid={fieldState?.error?.['price']?.message} >
																	<NumberInputField placeholder='Price' />
																</NumberInput>
																{(errors?.price?.message || fieldState?.['price']?.message) && <Text size={'small'} fontSize={'sm'} mt={1} color="red.500">{errors?.price?.message || fieldState?.['price']?.message}</Text>}
															</>
														}
													/>
												</Box>
												<Box width="100%">
													<Text fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} fontWeight="fw.500">Quantity</Text>
													<Controller
														name="stock"
														control={control}
														rules={{ required: true }}
														render={({ field, fieldState }) =>
															<>
																<NumberInput
																	{...field}
																	onChange={(strValue, numberValue) => field.onChange(numberValue || strValue)}
																	max={10000000}
																	min={0}
																	precision={2}
																	clampValueOnBlur={false}
																	errorBorderColor="red.500"
																	isInvalid={fieldState?.error?.['stock']?.message} >
																	<NumberInputField placeholder='Stock' />
																</NumberInput>
																{(errors?.stock?.message || fieldState?.['stock']?.message) && <Text size={'small'} fontSize={'sm'} mt={1} color="red.500">{errors?.stock?.message || fieldState?.['stock']?.message}</Text>}
															</>
														}
													/>
												</Box>
											</Flex>
											<Box mt="20px" width="48%">
												<Text fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} fontWeight="fw.500">Ratings</Text>
												<Controller
													name="ratings"
													control={control}
													rules={{ required: true }}
													render={({ field, fieldState }) =>
														<>
															<NumberInput
																{...field}
																onChange={(strValue, numberValue) => field.onChange(numberValue || strValue)}
																max={5}
																min={0}
																precision={2}
																clampValueOnBlur={false}
																errorBorderColor="red.500"
																isInvalid={fieldState?.error?.['ratings']?.message} >
																<NumberInputField placeholder='Ratings' />
															</NumberInput>
															{(errors?.ratings?.message || fieldState?.['ratings']?.message) && <Text size={'small'} fontSize={'sm'} mt={1} color="red.500">{errors?.ratings?.message || fieldState?.['ratings']?.message}</Text>}
														</>
													}
												/>
											</Box>
										</Box>
									</ModalBody>
									<ModalFooter gap="20px" background="#F5F4F6" borderRadius="0px 0px 10px 10px">
										<Button background="#EAE9EC" onClick={onClose} >Cancel</Button>
										<Button
											type="submit"
											px={{ md: "15px", lg: "30px" }}
											py="8px"
											background="linear-gradient(134.59deg, #713EDD -1.98%, #A17DEE 111.17%)"
											display="flex"
											columnGap="5px"
											color="white"
											_hover={{ background: "linear-gradient(134.59deg, #A17DEE -1.98%, #713EDD 111.17%)" }}
										>
											Add {isLoading && <Spinner size="sm" ml={2} />}
										</Button>
									</ModalFooter>
								</ModalContent>
							</form>
						</Modal>
					</Box>
				</Flex>
				<Box mt="25px" >
					<ProductListView searchQuery={searchQuery} />
				</Box>
			</Box>
		</Box>
	);
}