import { protectedRoute, publicRoute } from '../config/api.config';

export async function loginInAPI(payload) {
  return publicRoute.post('/auth/login', payload);
}

export async function signupAPI(payload) {
  return publicRoute.post('/auth/create-account', payload);
}

export async function resetPassword(payload) {
  return publicRoute.post('/auth/reset-password', payload);
}

export async function setPassword(payload) {
  return publicRoute.post('/auth/set-password', payload);
}

export async function updatePassword(payload) {
  return protectedRoute.post('/auth/update-password', payload);
}

export async function sendOTP(payload) {
  return publicRoute.post('/auth/send-otp', payload);
}

export async function verifyEmail(payload) {
  return publicRoute.post('/auth/verify-email', payload);
}