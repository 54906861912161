import {
  Box, Button, Input, Modal, ModalBody,
  ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, Flex, useToast,
  Spinner
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useContext, useEffect } from "react";
import { updatePassword } from "../../apis/auth.api";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../contexts/AppContext";
import PasswordField from "../PasswordField/PasswordField";

const ChangePassDialog = ({ isOpen, onClose }) => {
  const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();
  const toast = useToast();
  const [stateApp, setStateApp] = useContext(AppContext);
  const navigate = useNavigate();

  const defaultState = {
    user: null,
    auth: null,
  };

  const newPassword = watch("newPassword");

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  const handleLogOut = () => {
    localStorage.removeItem('@apiToken');
    setStateApp(defaultState);
    navigate("/signin");
  };
  const { mutate, isLoading } = useMutation(updatePassword, {
    onSuccess: () => {
      toast({
        title: "Success",
        description: "Password has been updated.",
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
      onClose();
      handleLogOut();
    },
    onError: (error) => {
      let errorMessage = "Something went wrong!";

      if (error?.response?.data?.message) {
        errorMessage = error.response.data.message;
      }

      toast({
        title: "Error",
        description: errorMessage,
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    }
  });

  const onSubmit = data => {
    const payload = {
      oldPassword: data.oldPassword,
      newPassword: data.newPassword,
    };
    mutate(payload);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="md">
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Change Password</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <Text mb="8px">Old Password</Text>
              <PasswordField mb={errors.newPassword ? 2 : 4} register={register("oldPassword", { required: "Old password is required" })}
                placeholder="Enter old password" />
              {errors.oldPassword && <Text color="red.500">{errors.oldPassword.message}</Text>}

              <Text mb="8px">New Password</Text>
              <PasswordField mb={errors.newPassword ? 2 : 4} placeholder="Enter new password" register={register("newPassword", { required: "New password is required" })} />
              {errors.newPassword && <Text color="red.500">{errors.newPassword.message}</Text>}
              <Text mb="8px">Re-enter New Password</Text>
              <PasswordField mb={errors.newPassword ? 2 : 4} register={register("confirmPassword", {
                required: "Please re-enter the new password",
                validate: value =>
                  value === newPassword || "Passwords do not match"
              })}
                placeholder="Re-enter new password" />
              {errors.confirmPassword && <Text color="red.500">{errors.confirmPassword.message}</Text>}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Flex gap="10px">
              <Button onClick={onClose} background="#EAE9EC">Close</Button>
              <Button type="submit" background="linear-gradient(134.59deg, #713EDD -1.98%, #A17DEE 111.17%)" color="white">
                Update {isLoading && <Spinner />}
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};

export default ChangePassDialog;
