import { Avatar, Box, Flex, Spinner, Wrap, WrapItem } from "@chakra-ui/react";
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { Controller } from "react-hook-form";
import { getCommentsById } from '../../../apis/comments.api';
import { GET_COMMENTS } from '../../../apis/constants.api';
import { SlateInputField } from "../../RichTextBox/SlateInputField";
import UpdateCommentActions from './UpdateCommentActions';

const CommentList = ({ id, stateApp, controlComment, initialValue, setValueComment }) => {

  const PAGE_SIZE = process.env.REACT_APP_PAGE_SIZE;
  const [page, setPage] = useState(1);
  const [isEditId, setIsEditId] = useState("");

  const { data: comments, isFetching, isLoading } = useQuery({
    queryKey: [GET_COMMENTS, { page, skip: PAGE_SIZE * (page - 1), limit: PAGE_SIZE, taskId: id }],
    queryFn: getCommentsById,
    enabled: !!id,
    // keepPreviousData: true,
  });

  const commentsList = comments?.data?.data || [];

  const extractData = (item) => {
    const urlRegex = /<url>(.*?)<url>/;
    const urlMatch = item?.plainText?.match(urlRegex);
    const url = urlMatch ? urlMatch[1] : '';
    const text = item?.plainText?.replace(urlRegex, '').trim();
    return { text, url };
  };


  return (
    <>
      {isFetching && !isLoading && <Box textAlign={'center'} fontSize={"14px"} fontWeight={600}>LOADING...</Box>}
      {isLoading && (
        <Box padding="25px"
          borderRadius="br.10"
          background="white"
          height="270px"
          overflowY="auto"
          className="flex justify-center items-center"
        >
          <Spinner />
        </Box>
      )}
      {commentsList?.length > 0 && commentsList?.map((e) => {
        return (
          <Box key={e._id} mt="15px" width={"100%"}>
            <Flex alignItems="center" gap="10px" mt="10px">
              <Wrap alignSelf={'self-start'}>
                <WrapItem>
                  <Avatar src={stateApp.user?.profilePicture} name={stateApp.user?.name} size={'sm'} />
                </WrapItem>
              </Wrap>
              <Controller
                name="text"
                control={controlComment}
                render={({ field }) =>
                  <SlateInputField
                    disabled={isEditId !== e._id}
                    placeholder="Add a comment..."
                    initValue={e.text ? JSON.parse(e.text) : initialValue}
                    textContainerStyle={{ minHeight: 35, margin: 10, padding: "0px 13px", width: "535px" }}
                    onChange={field.onChange}
                    setValue={setValueComment}
                    name="plainText"
                  />
                }
              />
            </Flex>
            {(e.author === stateApp.user?._id) && <UpdateCommentActions e={e} isEditId={isEditId} setIsEditId={setIsEditId} isFetching={isFetching} />}
          </Box>
        );
      })}
    </>
  );
};


export default CommentList;