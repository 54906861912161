import { CloseIcon } from "@chakra-ui/icons";
import { Box, Button, Input, Text } from "@chakra-ui/react";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";

const InputArray = ({ control, defaultValue, name }) => {
  const { setValue } = useFormContext();

  const [items, setItems] = useState(!defaultValue?.length ? [{ key: nanoid(), value: "" }] : defaultValue?.map((item) => ({
    key: nanoid(),
    value: item
  })));


  const add = () => {
    let cloned = [...items];
    cloned.push({ key: nanoid(), value: "" });
    setItems(cloned);
  };


  useEffect(() => {
    setValue(
      name,
      items?.filter(x => x.value.toString().trim().length > 0).length === 0 ? [] : items?.flatMap(x => x.value)
    );

  }, [items, name]);

  const updateItem = (key, newValue) => {

    const cloned = items.map((item) => {
      if (item.key === key) {
        return { ...item, value: newValue };
      }
      return item;
    });

    setItems(cloned);

  };

  const remove = (key) => {

    let cloned = [...items];
    cloned = cloned.filter(x => x.key !== key);
    setItems(cloned);

  };

  const fields = items?.map((item, index) => (
    < >
      <Controller
        name="rss"
        control={control}
        render={({ field }) => (
          <Box style={{ display: 'flex', justifyContent: 'center', alignItems: "center" }}>
            <Input
              key={items[index].value}
              onBlur={(e) => updateItem(items[index].key, e.target.value)}
              defaultValue={items[index].value}
              height="50px"
              _hover={{ borderColor: "#713EDD" }}
              background="#F7FAFC"
              mt="8px"
              mr="10px"
              _focusVisible={{ borderColor: "#713EDD" }}
            />
            <Button cursor="pointer" onClick={() => remove(items[index].key)} style={{ backgroundColor: 'transparent', border: "1px solid #868B944D", marginTop: '7px', padding: '23px 17px' }}>
              <CloseIcon
                fontSize={'12px'}
              />
            </Button>
          </Box>
        )}
      />

    </>
  ));


  return (<>

    <Text fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} fontWeight="fw.500">{name.toUpperCase()}</Text>
    {fields}
    <Button size='xs' mt="10px" onClick={(e) => { e.preventDefault(); add(); }}>
      Add More
    </Button>

  </>);
};

export default InputArray;