import React, { useContext, useState } from "react";
import { Box, Flex, Text, Select, Button, Spinner, useDisclosure } from "@chakra-ui/react";
import { faCalendar, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import "../PlanSchedule/calendarHome.css";
import { AppContext } from "../../../../../contexts/AppContext";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { GET_MY_TASKS, GET_TASKS } from "../../../../../apis/constants.api";
import { getMyTasks, updateTask } from "../../../../../apis/task.api";
import EditTaskDialog from "../../../../BoardListview/EditTaskDialog";

export default function PlanSchedule() {
    const [AppState] = useContext(AppContext);
    const queryClient = useQueryClient();
    const { user } = AppState;
    const [selectedTask, setSelectedTask] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());

    const calendarRef = React.createRef();

    const handleMonthChange = (event) => {
        const monthIndex = parseInt(event.target.value);
        setSelectedMonth(monthIndex);

        const calendarApi = calendarRef.current.getApi();
        calendarApi.gotoDate(new Date(new Date().getFullYear(), monthIndex, 1));
    };

    const { data: tasksData, isLoading: isTasksLoading } = useQuery(
        [GET_MY_TASKS],
        getMyTasks
    );

    const { mutate, isLoading } = useMutation(updateTask, {
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [GET_TASKS] });
        },
        onError: (error) => {
            console.error("Error updating task:", error);
        },
    });

    const handleViewDetails = (task) => {
        setSelectedTask(task);
        onOpen();
    };

    const InProgressTasks = tasksData?.data?.data?.inProgressTasks;
    return (
        <Flex gap="35px" flexDirection={{ md: "column", lg: "unset" }}>
            <Box width="100%">
                {user?.role !== "SysAdmin" && (
                    <Box width="100%">
                        <Text
                            pt="35px"
                            pb="10px"
                            fontSize="fs.18"
                            fontWeight="fw.500"
                            textAlign="start"
                        >
                            Projects
                        </Text>
                        <Box
                            padding="25px"
                            borderRadius="br.10"
                            background="white"
                            height="270px"
                            overflowY="auto"
                            display={
                                InProgressTasks?.length === 0 ? "flex" : "block"
                            }
                            alignItems={
                                InProgressTasks?.length === 0
                                    ? "center"
                                    : "unset"
                            }
                            justifyContent={
                                InProgressTasks?.length === 0
                                    ? "center"
                                    : "unset"
                            }
                        >
                            {isTasksLoading ? (
                                <Box padding="25px"
                                    borderRadius="br.10"
                                    background="white"
                                    height="270px"
                                    overflowY="auto"
                                    className="flex justify-center items-center"
                                >
                                    <Spinner />
                                </Box>
                            ) : InProgressTasks &&
                                InProgressTasks?.length > 0 ? (
                                InProgressTasks?.map((task, index) => {
                                    const formattedDate = new Date(task.dueDate).toLocaleDateString(
                                        "en-US",
                                        {
                                            year: "numeric",
                                            month: "long",
                                            day: "numeric",
                                        }
                                    );

                                    let description = "";
                                    try {
                                        const parsedDescription = JSON.parse(task.description);
                                        description = parsedDescription
                                            .map((item) =>
                                                item.children.map((child) => child.text).join(" ")
                                            )
                                            .join(" ");
                                    } catch (error) {
                                        console.error("Failed to parse description:", error);
                                    }

                                    return (
                                        <Flex
                                            background="#F6F2FD"
                                            padding="25px"
                                            borderRadius="br.10"
                                            gap="25px"
                                            key={index}
                                            className="gap-y-2 mb-3"
                                        >
                                            <Flex
                                                textAlign="start"
                                                flexDirection="column"
                                                justifyContent="space-between"
                                            >
                                                <Box>
                                                    <Box
                                                        width="100px"
                                                        py="5px"
                                                        px="10px"
                                                        background="rgba(255, 128, 87, 0.2)"
                                                        borderRadius="br.5"
                                                    >
                                                        <Text
                                                            fontSize="fs.12"
                                                            fontWeight="fw.500"
                                                            color="#FF8057"
                                                        >
                                                            {task.status}
                                                        </Text>
                                                    </Box>
                                                    <Text
                                                        mt="12px"
                                                        fontSize={{ lg: "fs.20", xl: "fs.26" }}
                                                        fontWeight="fw.600"
                                                    >
                                                        {task.title}
                                                    </Text>
                                                    <Text>
                                                        {description.length > 0 ? `${description.slice(0, 40)}${description?.length > 40 ? "..." : ""}` : "No description added for this task"}
                                                    </Text>
                                                    <Flex mt="10px">
                                                        <Text
                                                            fontSize={{ base: "fs.14", xl: "fs.18" }}
                                                            fontWeight="fw.500"
                                                            color="#202B3C"
                                                        >
                                                            Due date:
                                                        </Text>
                                                        <Text
                                                            fontSize={{ base: "fs.14", xl: "fs.18" }}
                                                            fontWeight="fw.500"
                                                            color="#868B94"
                                                        >
                                                            {formattedDate}
                                                        </Text>
                                                    </Flex>
                                                </Box>
                                                <Box>
                                                    <Button
                                                        onClick={() => handleViewDetails(task)}
                                                        mt="6px"
                                                        py="8px"
                                                        px="22px"
                                                        _hover={{
                                                            background:
                                                                "linear-gradient(134.59deg, #A17DEE -1.98%, #713EDD 111.17%)",
                                                        }}
                                                        background="linear-gradient(134.59deg, #713EDD -1.98%, #A17DEE 111.17%)"
                                                        color="white"
                                                        fontSize="fs.16"
                                                        fontWeight="fw.500"
                                                    >
                                                        View Details
                                                    </Button>
                                                </Box>
                                            </Flex>
                                        </Flex>
                                    );
                                })
                            ) : (
                                <Text className="text-lg">No tasks assigned with high priority.</Text>
                            )}
                        </Box>
                    </Box>
                )}
                <Flex justifyContent="space-between" alignItems="center" pt="28px" pb="10px">
                    <Text fontSize="fs.18" fontWeight="fw.500" textAlign="start">
                        Plan Schedule
                    </Text>
                    <Flex
                        borderRadius="br.4"
                        alignItems="center"
                        justifyContent="space-between"
                        width="180px"
                        height="35px"
                        background="white"
                        padding="10px"
                    >
                        <Flex alignItems="center" gap="10px">
                            <FontAwesomeIcon icon={faCalendar} />
                            <Select
                                value={selectedMonth}
                                onChange={handleMonthChange}
                                fontSize="fs.14"
                                fontWeight="fw.500"
                                border="none"
                                icon={<FontAwesomeIcon icon={faChevronDown} />}
                            >
                                {[
                                    "January",
                                    "February",
                                    "March",
                                    "April",
                                    "May",
                                    "June",
                                    "July",
                                    "August",
                                    "September",
                                    "October",
                                    "November",
                                    "December",
                                ].map((month, index) => (
                                    <option key={index} value={index}>
                                        {month}
                                    </option>
                                ))}
                            </Select>
                        </Flex>
                    </Flex>
                </Flex>
                <Box
                    height="auto"
                    background="white"
                    padding="25px"
                    borderRadius="br.10"
                    gap="25px"
                >
                    <FullCalendar
                        plugins={[dayGridPlugin]}
                        initialView="dayGridMonth"
                        height="600px"
                        ref={calendarRef}
                    />
                </Box>
            </Box>
            {isOpen && <EditTaskDialog isOpen={isOpen} onClose={onClose} item={selectedTask} />}
        </Flex>
    );
}
