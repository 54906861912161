import { Box, Button, Flex, Spinner, Text, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { updateCareerDetailsAPI } from "../../../apis/index.api";
import { setCareerDetails } from "../../../store/SignupStepsSlice";
import { useDispatch, useSelector } from "react-redux";

const CareerDetails = ({ goToNext, goToPrevious }) => {
  const careerDetails = useSelector((state) => state?.SignupSteps?.careerDetails);
  const [seekingDegree, setSeekingDegree] = useState(careerDetails?.seekingDegree || "High School");
  const [isSubmitting] = useState(false);
  const dispatch = useDispatch();

  const handleSeeking = (option) => {
    setSeekingDegree(option);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let payload = {
      isAStudent: true,
    };
    payload = {
      ...payload,
      seekingDegree: seekingDegree,
    };
    goToNext();
    dispatch(setCareerDetails(payload));
    // mutate(payload);
  };

  return (
    <>
      <Box px={{ base: "0", md: "10px", xl: "20px" }}>
        <Text
          textAlign="center"
          fontSize={{
            base: "fs.25",
            md: "fs.30",
            lg: "fs.30",
            xl: "fs.38",
          }}
          fontWeight="fw.600"
        >
          Career Details
        </Text>
        <Text
          textAlign="center"
          fontSize={{
            base: "fs.12",
            md: "fs.14",
            lg: "fs.16",
            xl: "fs.18",
          }}
          fontWeight="fw.400"
          pt="10px"
          pb="20px"
          color="darkgray"
        ></Text>
        <hr />
      </Box>
      <Box mt={{ base: "20px" }}>
        <Box>
          <Text
            fontSize={{
              base: "fs.12",
              md: "fs.14",
              lg: "fs.16",
              xl: "fs.18",
            }}
            fontWeight="fw.500"
            px="10px"
          >
            Seeking degree
          </Text>
        </Box>
        <Flex
          justifyContent="center"
          mt="10px"
          gap={{ base: "5px", lg: "20px" }}
        >
          <Button
            type="button"
            onClick={() => handleSeeking("High School")}
            border={`1.5px solid ${seekingDegree === "High School"
              ? "#713EDD"
              : "rgba(210, 210, 210, 0.5)"
              }`}
            backgroundColor={
              seekingDegree === "High School" ? "#F6F2FD" : "white"
            }
            color={seekingDegree === "High School" ? "#713EDD" : "#000"}
            _hover={{
              background: "#F6F2FD",
              color: "#713EDD",
              border: "1.5px solid #713EDD",
            }}
            isDisabled={isSubmitting}
            width="50%"
            fontSize={{
              base: "fs.12",
              md: "fs.14",
              lg: "fs.16",
              xl: "fs.16",
            }}
            fontWeight="fw.400"
            borderRadius="10px"
            height={{ base: "40px", lg: "50px", "2xl": "55px" }}
          >
            High School
          </Button>
          <Button
            type="button"
            onClick={() => handleSeeking("Associate's Degree")}
            border={`1.5px solid ${seekingDegree === "Associate's Degree"
              ? "#713EDD"
              : "rgba(210, 210, 210, 0.5)"
              }`}
            backgroundColor={
              seekingDegree === "Associate's Degree" ? "#F6F2FD" : "white"
            }
            color={seekingDegree === "Associate's Degree" ? "#713EDD" : "#000"}
            _hover={{
              background: "#F6F2FD",
              color: "#713EDD",
              border: "1.5px solid #713EDD",
            }}
            isDisabled={isSubmitting}
            width="50%"
            fontSize={{
              base: "fs.12",
              md: "fs.14",
              lg: "fs.16",
              xl: "fs.16",
            }}
            fontWeight="fw.400"
            borderRadius="10px"
            height={{ base: "40px", lg: "50px", "2xl": "55px" }}
          >
            Associate's Degree
          </Button>
        </Flex>
        <Flex
          justifyContent="center"
          mt="25px"
          gap={{ base: "5px", lg: "20px" }}
        >
          <Button
            type="button"
            isDisabled={isSubmitting}
            onClick={() => handleSeeking("Bachelor's Degree")}
            border={`1.5px solid ${seekingDegree === "Bachelor's Degree"
              ? "#713EDD"
              : "rgba(210, 210, 210, 0.5)"
              }`}
            backgroundColor={
              seekingDegree === "Bachelor's Degree" ? "#F6F2FD" : "white"
            }
            color={seekingDegree === "Bachelor's Degree" ? "#713EDD" : "#000"}
            _hover={{
              background: "#F6F2FD",
              color: "#713EDD",
              border: "1.5px solid #713EDD",
            }}
            width="50%"
            fontSize={{
              base: "fs.12",
              md: "fs.14",
              lg: "fs.16",
              xl: "fs.16",
            }}
            fontWeight="fw.400"
            borderRadius="10px"
            height={{ base: "40px", lg: "50px", "2xl": "55px" }}
          >
            Bachelor's Degree
          </Button>
          <Button
            isDisabled={isSubmitting}
            type="button"
            onClick={() => handleSeeking("Master's Degree")}
            border={`1.5px solid ${seekingDegree === "Master's Degree"
              ? "#713EDD"
              : "rgba(210, 210, 210, 0.5)"
              }`}
            backgroundColor={
              seekingDegree === "Master's Degree" ? "#F6F2FD" : "white"
            }
            color={seekingDegree === "Master's Degree" ? "#713EDD" : "#000"}
            _hover={{
              background: "#F6F2FD",
              color: "#713EDD",
              border: "1.5px solid #713EDD",
            }}
            width="50%"
            fontSize={{
              base: "fs.12",
              md: "fs.14",
              lg: "fs.16",
              xl: "fs.16",
            }}
            fontWeight="fw.400"
            borderRadius="10px"
            height={{ base: "40px", lg: "50px", "2xl": "55px" }}
          >
            Master's Degree
          </Button>
        </Flex>
      </Box>
      <Flex
        mt={{ base: "30px", md: "50px", lg: "30px", xl: "30px" }}
        className="gap-3"
      >
        <Button
          fontSize={{
            base: "fs.12",
            md: "fs.14",
            lg: "fs.16",
            xl: "fs.18",
          }}
          fontWeight="fw.600"
          height={{ base: "40px", lg: "50px", "2xl": "55px" }}
          width="50%"
          borderRadius="10px"
          onClick={(e) => goToPrevious(e)}
          backgroundColor={"#F6F2FD"}
          border={`1.5px solid #713EDD`}
          isDisabled
        >
          Back
        </Button>
        <Button
          onClick={(e) => handleSubmit(e)}
          color="white"
          background="linear-gradient(150.62deg, #713EDD 5.97%, #A17DEE 64.37%)"
          _hover={{ animation: "changeBg 0.5s ease infinite" }}
          width="50%"
          height={{ base: "40px", lg: "50px", "2xl": "55px" }}
          fontSize={{
            base: "fs.12",
            md: "fs.14",
            lg: "fs.16",
            xl: "fs.18",
          }}
          fontWeight="fw.600"
          borderRadius="10px"
          type="submit"
          isDisabled={isSubmitting}
          gap={5}
        >
          Next
          {/* {isLoading && <Spinner />} */}
        </Button>
      </Flex>
    </>
  );
};
export default CareerDetails;
