
export const BORDER_RADIUS = {
    none: '0',
    4: '0.25rem',
    5: '0.313rem',
    6: '0.375rem',
    8: '0.5rem',
    9: '0.563rem',
    10: '0.625rem',
    20: '1.25rem',
    50: '3.125rem',
};