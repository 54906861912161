import { Box, Button, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { IconTrash } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { Controller } from "react-hook-form";
import ReactPaginate from "react-paginate";
import ReactSelect from "react-select";
import { GET_ALL_USERS } from "../../../apis/constants.api";
import { getPlatformUserDataAPI } from "../../../apis/users.api";
import useDebounce from "../../../hooks/useDebounce.hook";

const PAGE_SIZE = process.env.REACT_APP_PAGE_SIZE;

const UserFields = ({ channel, control, errors, channelMembers, handleRemove, isOpenChannel }) => {
  const [inputSearch, setInputSearch] = useState('');
  const [page, setPage] = useState(1);
  const debouncedSearch = useDebounce(inputSearch);

  const { data: platFormUsers, isLoading } = useQuery({
    queryKey: [GET_ALL_USERS, {
      skip: PAGE_SIZE * (page - 1),
      limit: PAGE_SIZE,
      search: debouncedSearch
    }],
    queryFn: getPlatformUserDataAPI,
  });

  const loadOption = (platFormUsers?.data?.data || []).map(op => ({ label: op.name, value: op._id }));
  const userData = platFormUsers?.data?.data;
  const totalPages = (channelMembers?.length || 0) / PAGE_SIZE;;

  const handlePageChange = useCallback((data) => {
    setPage(data.selected + 1);
  }, []);

  return (<>
    <Box mt="20px" mb="27px">
      <Text fontSize="fs.16" fontWeight="fw.500" mb="8px">Members</Text>
      {
        !isOpenChannel &&
        <Controller
          name="members"
          control={control}
          defaultValue={channel?.members?.map((e) => ({ label: e?.user?.email, value: e?.user?._id }))}
          render={({ field, fieldState }) =>
            <>
              <ReactSelect
                isMulti
                isLoading={isLoading}
                placeholder='Select Members...'
                isSearchable={true}
                options={loadOption}
                onChange={(value) => field.onChange(value.map(val => val))}
                onInputChange={setInputSearch}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderColor: errors?.members?.message ? 'red' : provided.borderColor,
                    '&:hover': {
                      borderColor: errors?.members?.message ? 'red' : provided['&:hover'].borderColor,
                    },
                  }),
                }}
              />
              {(errors?.members?.message || fieldState?.['members']?.message)
                && <Text size={'small'} fontSize={'sm'} mt={1} color="red.500">{errors?.members?.message || fieldState?.['members']?.message}</Text>}
            </>

          }
        />
      }
    </Box>

    <Box>
      <TableContainer
        border='1px solid rgba(234, 236, 240, 1)'
        borderRadius='0px 0px 10px 10px'
        height="calc(100vh - 440px)"
        overflowY='scroll'
        overflowX='scroll'
        css={`
              &::-webkit-scrollbar {
                  width: 0;
                  height: 0;
              }

              &::-webkit-scrollbar-thumb {
                  background-color: rgba(0, 0, 0, 0);
              }
          `}
      >
        <Box position='relative'>
          <Table variant='simple'>
            <Thead
              bgGradient='linear-gradient(0deg, #EAECF0, #EAECF0),linear-gradient(0deg, #F9FAFB, #F9FAFB)'
              textAlign='center'
              position='sticky'
              top='0px'
            // zIndex='1'
            >
              <Tr>
                <Th
                  fontSize={{
                    base: 'fs.14',
                    md: 'fs.14',
                    lg: 'fs.16',
                    xl: 'fs.18',
                  }}
                  fontWeight='fw.400'
                  color=' rgba(134, 139, 148, 1)'
                  // py='13px'
                  textTransform="capitalize">
                  Name</Th>
                <Th
                  fontSize={{
                    base: 'fs.14',
                    md: 'fs.14',
                    lg: 'fs.16',
                    xl: 'fs.18',
                  }}
                  fontWeight='fw.400'
                  color=' rgba(134, 139, 148, 1)'
                  // py='13px'
                  textTransform="capitalize">
                  Email</Th>
                <Th
                  fontSize={{
                    base: 'fs.14',
                    md: 'fs.14',
                    lg: 'fs.16',
                    xl: 'fs.18',
                  }}
                  fontWeight='fw.400'
                  color=' rgba(134, 139, 148, 1)'
                  // py='13px'
                  textTransform="capitalize">
                  Action
                </Th>
              </Tr>
            </Thead>

            <Tbody
              bg='transparent'>
              {!channelMembers?.length && <Tr><Th colSpan={3} textAlign={'center'}>No Members Found</Th></Tr>}
              {isLoading && <Tr><Th colSpan={8} textAlign={'center'}> LOADING...</Th></Tr>}
              {channelMembers?.map(({ user }) => {
                return (<>
                  <Tr py='14px' >
                    <Td
                      pr='20px'
                      py='14px'
                      color='rgba(134, 139, 148, 1)'
                      fontSize={{
                        base: 'fs.14',
                        md: 'fs.14',
                        lg: 'fs.16',
                        xl: 'fs.16',
                      }}
                      fontWeight='fw.500'>
                      {user?.name}</Td>
                    <Td
                      pr='20px'
                      py='14px'
                      color='rgba(134, 139, 148, 1)'
                      fontSize={{
                        base: 'fs.14',
                        md: 'fs.14',
                        lg: 'fs.16',
                        xl: 'fs.16',
                      }}
                      fontWeight='fw.500'>
                      {user?.email}</Td>
                    <Td
                      pr='20px'
                      py='14px'
                      color='rgba(134, 139, 148, 1)'
                      fontSize={{
                        base: 'fs.14',
                        md: 'fs.14',
                        lg: 'fs.16',
                        xl: 'fs.16',
                      }}
                      fontWeight='fw.500'>
                      <Button variant={'unstyled'} color="red" onClick={() => handleRemove(user?._id)}>
                        <IconTrash />
                      </Button>
                    </Td>
                  </Tr ></>);
              })}

              {!isLoading && userData?.length === 0 && <Tr><Td colSpan={8} textAlign={'center'}>No User found</Td></Tr>}
            </Tbody >
          </Table >
        </Box >
      </TableContainer >
    </Box>

    <Box position="relative" mt="20px">
      <ReactPaginate
        pageCount={totalPages}
        containerClassName={"paginationButtons"}
        disabledClassName={"paginationDisabled"}
        activeClassName={"paginationActive"}
        previousLabel={<span>&larr; Previous</span>}
        nextLabel={<span>Next &rarr;</span>}
        onPageChange={handlePageChange}
        forcePage={page - 1}
      />
    </Box>
  </>);
};
export default UserFields;