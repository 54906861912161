import { Box, Button, CircularProgress, Flex, Image, Spinner, Switch, Td, Text, Tr, useToast } from '@chakra-ui/react';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRef } from 'react';
import { GET_PRODUCTS } from '../../../apis/constants.api';
import { publishProduct, unPublishProduct } from '../../../apis/products.api';
import { convertISOToLocalDate } from '../../../utils/date.utils';
import ProductEditDialog from './ProductEditDialog';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import ProductDeleteDialog from './ProductDeleteDialog';

const LOW_STOCK = 10;
function ProductListItem(props) {
  const productEditDialogRef = useRef(null);
  const productDeleteDialogRef = useRef(null);
  const { product } = props;
  const queryClient = useQueryClient();
  const toast = useToast();

  const { images, stock, price, description, title, ratings, updatedAt, published, _id } = product;
  const isInStock = stock > 0;
  const isLowStock = stock <= LOW_STOCK;

  const { mutate: changeToPublish, isLoading } = useMutation(publishProduct, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_PRODUCTS] });
    },
    onError: (error) => {
      const errorMessage = error?.response?.data?.message;

      toast({
        title: "Error",
        description: errorMessage,
        status: "error",
        duration: 2000,
        isClosable: true,
        position: 'top-right'
      });
    },
  });

  const { mutate: changeToUnpublished, isLoading: isToggling } = useMutation(unPublishProduct, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_PRODUCTS] });
    },
    onError: (error) => {
      const errorMessage = error?.response?.data?.message;

      toast({
        title: "Error",
        description: errorMessage,
        status: "error",
        duration: 2000,
        isClosable: true,
        position: 'top-right'
      });
    },
  });

  const handlePublishedStatus = (e) => {
    e.stopPropagation();

    if (published) {
      changeToUnpublished(_id);
    } else {
      changeToPublish(_id);
    }
  };

  const isTogglingStatus = isLoading || isToggling;
  return (
    <>
      <Tr cursor="pointer" _hover={{ background: "#F9FAFB" }} >
        <Td pl="26px" py="16px" >
          <Box width="40px" height="40px" borderRadius="6px" background="#f6f6f6">
            <Image src={images[0]} alt='cardImg' objectFit={'cover'} height={'100%'} width={'100%'} borderRadius={10} />
          </Box>
        </Td>
        <Td pr={{ base: "auto", xl: "84px" }} title={`${title}\n${description}`}>
          <Text className='ellipsis' noOfLines={1} fontSize={{ base: "fs.12", md: "fs.14", xl: "fs.16" }} fontWeight="fw.500" whiteSpace={"pre-line"} height={"20px"} lineHeight={"20px"} textOverflow="ellipsis" width={{ base: "400px", md: "250px", lg: "400px", xl: "auto", '2xl': "665px" }} overflow="hidden" display="-webkit-box" >
            {title}
          </Text>
          <Text mt={2} className='ellipsis' noOfLines={2} fontSize={{ base: "fs.10", md: "fs.12", xl: "fs.12" }} fontWeight="fw.300" textOverflow="ellipsis" width={{ base: "400px", md: "250px", lg: "400px", xl: "auto", '2xl': "665px" }} overflow="hidden" display="-webkit-box" >
            {description}
          </Text>
        </Td>
        <Td fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} fontWeight="fw.500" isNumeric>{price}</Td>
        <Td >
          <Flex width="-webkit-fit-content" borderRadius="40px" alignItems="center" justifyContent="center" height={{ base: "30px", md: "40px" }} px="16px" gap="6px" >
            <Text fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} fontWeight="fw.500" color={(!isInStock || isLowStock) ? "#E1251A" : "#27AC2D"} >{stock}</Text>
          </Flex>
        </Td>
        <Td>
          <Text fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} fontWeight="fw.500">{ratings} / 5</Text>
        </Td>
        <Td>
          {
            isTogglingStatus ?
              <Spinner color='gray.500' />
              :
              <Switch id={'published-control-' + product._id} variant={'success'} isChecked={published} onChange={handlePublishedStatus} />
          }
        </Td>
        <Td isNumeric textAlign={"start !important"}>
          <Text fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} fontWeight="fw.500">{convertISOToLocalDate(updatedAt)}</Text>
        </Td>
        <Td onClick={() => productEditDialogRef?.current?.openDialog()} textAlign="center" pl={{ base: "auto" }} pr="15px" isNumeric >
          <Button
            pr='10px'
            bg='transparent'
            leftIcon={<FontAwesomeIcon color='#3182ce' size='md' icon={faEye} />}
            loadingText="Deleting..."
            spinner={<CircularProgress size="24px" color="purple.500" />}
            _hover={{ bg: '#d7e9fe' }}
          />
          {/* <FontAwesomeIcon color="#868B94" width="20px" icon={faEye} /> */}
        </Td>
        <Td onClick={() => productDeleteDialogRef?.current?.openDialog()} textAlign="center" pl={{ base: "auto" }} pr="36px" isNumeric >
          <Button
            pr='10px'
            bg='transparent'
            leftIcon={<FontAwesomeIcon color='red' size='md' icon={faTrashCan} />}
            loadingText="Deleting..."
            spinner={<CircularProgress size="24px" color="purple.500" />}
            _hover={{ bg: 'red.100' }}
          />
        </Td>
      </Tr>
      <ProductEditDialog product={product} ref={productEditDialogRef} />
      <ProductDeleteDialog product={product} ref={productDeleteDialogRef} />
    </>
  );
}

export default ProductListItem;