import { protectedRoute } from "../config/api.config";

export function getMessagesAPI({ queryKey, pageParam = 0 }) {
  const [, limit, channelId] = queryKey;
  return protectedRoute.get('/messages', { params: { limit, skip: pageParam * limit, channelId, sortOrder: 'desc' } });
}

export function getLastMessagesAPI() {
  return protectedRoute.get('messages/last-messages');
}

// export function sendMessagesAPI(payload) {
//   return protectedRoute.post('/messages/send', payload);
// }