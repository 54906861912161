import { Box, Flex, SkeletonCircle, SkeletonText } from "@chakra-ui/react";
import { nanoid } from "nanoid";
import { useState } from "react";

function KanabanLaneSkeleton({ noOfItems = 1 }) {
  const [items] = useState(new Array(noOfItems).fill('').map(i => nanoid()));

  return (
    <>
      {
        items?.map(item =>
          <Box key={item} padding='6' border='1px solid' borderColor="#E3E4E8" borderRadius="br.10">
            <SkeletonText mt='6' noOfLines={2} skeletonHeight='4' />
            <SkeletonText mt='4' noOfLines={1} skeletonHeight='2' />
            <SkeletonText mt='4' noOfLines={1} skeletonHeight='0.5' />
            <Flex justify="space-between" align="center" mt={2} mb={6}>
              <Flex justify="space-between" align="center" mt={2} >
                <SkeletonCircle size='8' />
                <SkeletonCircle size='8' ml={-15} />
              </Flex>
              <SkeletonText noOfLines={1} skeletonHeight='4' w={14} mt={2} />
            </Flex>
          </Box>
        )
      }
    </>
  );
}
function AvtarSkeleton({ noOfItems = 1 }) {
  const [items] = useState(new Array(noOfItems).fill('').map(i => nanoid()));

  return (
    <>
      {
        items?.map(item =>
          <Box key={item}>
            <Flex Flex justify="space-between" align="center" mt={2} >
              <SkeletonCircle size='8' />
              <SkeletonCircle size='8' ml={-15} />
              <SkeletonCircle size='8' ml={-15} />
            </Flex >
          </Box>
        )
      }
    </>
  );
}

export { AvtarSkeleton, KanabanLaneSkeleton };