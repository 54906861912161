import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { cloneDeep, orderBy, uniqBy } from 'lodash';
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { getMyChannels } from "../apis/channel.api";
import { GET_LAST_MESSAGES, GET_MESSAGES, GET_MY_CHANNELS, GET_MY_DIRECTS, GET_UNREAD_MESSAGE_COUNT } from "../apis/constants.api";
import { getMyDirectsAPI } from "../apis/directs.api";
import { getLastMessagesAPI, getMessagesAPI } from "../apis/message.api";
import { isUserAccepted } from "../utils/users.utils";
import { AppContext } from "./AppContext";
import { getUnreadMessagesCountAPI } from "../apis/notifications.api";
import { useQueryClient } from "@tanstack/react-query";
import { GET_CHANNEL } from "../apis/constants.api";

export const ChatContext = createContext(null);

const defaultState = {
  channels: [],
  directs: [],
  unreadCounts: [],
  messageHistory: {},
  lastMessages: [],
  channelTabDialogOpen: true,
  newestDirect: ""
};

const PAGE_SIZE = process.env.REACT_APP_PAGE_SIZE;
export default function ChatContextProvider({ children }) {
  const [chatState, setChatState] = useState(defaultState);
  const [stateApp] = useContext(AppContext);
  const [fetchUnreadCounts, setFetchUnreadCounts] = useState(false);
  const location = useLocation();
  const queryClient = useQueryClient();

  const { channelId, channelType } = useMemo(() => {
    const regexr = /^\/message\/(channel|direct)\/[a-z0-9]+$/;

    if (regexr.test(location.pathname)) {
      const split = location.pathname.split('/');

      return { channelId: split[3], channelType: split[2] };
    }

    return { channelId: '', channelType: '' };
  }, [location]);


  const { data: unreadCountData, refetch: refetchUnreadCount } = useQuery({
    queryKey: [GET_UNREAD_MESSAGE_COUNT],
    queryFn: getUnreadMessagesCountAPI,
    enabled: fetchUnreadCounts,
    onSuccess: (unreadCountData) => {
      if (unreadCountData?.data) {
        setChatState((prevState) => ({
          ...prevState,
          unreadCounts: unreadCountData?.data,
        }));
      }
    },
    onError: (error) => {
      console.error("Error fetching unread message counts:", error);
    }
  });

  const { data: lastMessages, refetch: refetchLastMessages } = useQuery({
    queryKey: [GET_LAST_MESSAGES],
    queryFn: getLastMessagesAPI,
    enabled: isUserAccepted(stateApp?.user),
    onSuccess: (lastMessages) => {
      if (lastMessages?.data) {
        setChatState((prevState) => ({
          ...prevState,
          lastMessages: lastMessages?.data,
        }));
      }
    }
  });

  const { refetch: refetchChannels, isLoading: isChannelLoading, isFetching: isChannelFetching } = useQuery({
    queryKey: [GET_MY_CHANNELS, { limit: 1000 }],
    queryFn: getMyChannels,
    enabled: isUserAccepted(stateApp?.user),
    onSuccess: (channelsData) => {
      if (channelsData?.data?.data?.channels) {
        setChatState((prevState) => ({ ...prevState, channels: channelsData?.data?.data?.channels }));
      }
    }
  });

  const { refetch: refetchDirects, isLoading: isDirectsLoading, isFetching: isDirectingFetching } = useQuery({
    queryKey: [GET_MY_DIRECTS, { limit: 1000 }],
    queryFn: getMyDirectsAPI,
    enabled: isUserAccepted(stateApp?.user),
    onSuccess: (directsData) => {
      if (directsData?.data?.data?.directs) {
        setChatState((prevState) => ({ ...prevState, directs: directsData?.data?.data?.directs }));
      }
    }
  });

  const { data: messagesData, isLoading, isFetching, fetchNextPage, fetchPreviousPage, refetch } = useInfiniteQuery({
    queryKey: [GET_MESSAGES, PAGE_SIZE, channelId],
    queryFn: getMessagesAPI,
    getNextPageParam: (lastPage, pages) => {
      if (((lastPage?.config?.params?.skip || 0) + PAGE_SIZE) > lastPage.data.data.totalCount)
        return;

      return (lastPage?.config?.params?.skip || 0) / (lastPage?.config?.params?.limit || 0) + 1;
    },
    enabled: isUserAccepted(stateApp?.user) && Boolean(channelId)
  });

  useEffect(() => {
    if (channelId) {
      const { messages = [], totalMessagesCount = 0 } = messagesData?.pages?.reduce?.(({ messages, totalMessagesCount }, page) => ({ messages: [...messages, ...page?.data?.data?.messages], totalMessagesCount: page?.data?.data?.totalCount }), { messages: [], totalMessagesCount: 0 }) || {};
      let newMessageHistory = cloneDeep(chatState.messageHistory);

      newMessageHistory = {
        ...newMessageHistory,
        [channelId]: { messages: orderBy(uniqBy(messages, '_id'), 'createdAt'), total: totalMessagesCount }
      };

      setChatState((prevState) => ({
        ...prevState,
        messageHistory: newMessageHistory,
      }));


    }
    if (isUserAccepted(stateApp?.user)) {
      setFetchUnreadCounts(true);
    }

  }, [messagesData, channelId]);

  const chatData = {
    ...chatState,
    loaders: {
      areMessagesLoading: isLoading,
      areMessagesFetching: isFetching,
      isChannelLoading,
      isChannelFetching,
      isDirectsLoading,
      isDirectingFetching
    }
  };

  const addMessage = (channelId, messages) => {
    setChatState((prevChatState) => {
      let newMessageHistory = cloneDeep(prevChatState.messageHistory);
      const newMessageList = orderBy(uniqBy([...(newMessageHistory[channelId]?.messages || []), ...messages], '_id'), 'createdAt');

      newMessageHistory[channelId] = { messages: newMessageList, total: 0 };

      return { ...prevChatState, messageHistory: newMessageHistory };
    });
  };

  const channelDialogToogle = () => {
    setChatState((prevChatState) => {
      return { ...prevChatState, channelTabDialogOpen: !prevChatState.channelTabDialogOpen };
    });
  };

  const updateAllChannels = () => {
    queryClient.invalidateQueries({ queryKey: [GET_CHANNEL] });
  };

  const endCall = (channelId, messageId, duration) => {
    setChatState((prevChatState) => {
      let newArray = cloneDeep(prevChatState.messageHistory);
      if (!newArray[channelId]?.messages) {
        return prevChatState;
      }
      const newMessageHistory = newArray[channelId].messages.map(m => {
        if (m._id === messageId) {
          return { ...m, callStatus: "Call ended", duration: duration };
        } else {
          return m;
        }
      });
      newArray[channelId].messages = [...newMessageHistory];
      return { ...prevChatState, messageHistory: newArray };
    });
  };

  return (
    <ChatContext.Provider value={[
      chatData,
      setChatState, {
        fetchNextPage,
        fetchPreviousPage,
        refetch,
        addMessage,
        refetchChannels,
        refetchUnreadCount,
        refetchLastMessages,
        refetchDirects,
        endCall,
        channelDialogToogle,
        updateAllChannels,
      }]}>
      {children}
    </ChatContext.Provider>
  );
}