import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
  PinInput,
  PinInputField,
  HStack,
  Flex,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { sendOTP, verifyEmail } from '../../../apis/auth.api';

const OtpModal = ({ isOpen, onClose, email, onSignupSuccess }) => {
  const [otp, setOtp] = useState('');
  const [isResending, setIsResending] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const toast = useToast();

  const { mutate: sendOtp } = useMutation(sendOTP, {
    onSuccess: () => {
      toast({
        title: 'OTP Sent',
        description: `An OTP has been sent to ${email}`,
        status: 'success',
        duration: 2000,
        isClosable: true,
        position: 'top-right',
      });
      setOtp('');
    },
    onError: (error) => {
      toast({
        title: 'Error',
        description: error?.response?.data?.message || 'Failed to send OTP',
        status: 'error',
        duration: 2000,
        isClosable: true,
        position: 'top-right',
      });
      setOtp('');
    },
    onSettled: () => setIsResending(false),
  });

  const { mutate: verifyOtp } = useMutation(verifyEmail, {
    onSuccess: (data) => {
      toast({
        title: 'Verification Successful',
        description: 'Your email has been verified',
        status: 'success',
        duration: 2000,
        isClosable: true,
        position: 'top-right',
      });
      onSignupSuccess(true);
      setOtp('');
      onClose();
    },
    onError: (error) => {
      toast({
        title: 'Verification Failed',
        description: error?.response?.data?.message || 'Invalid OTP',
        status: 'error',
        duration: 2000,
        isClosable: true,
        position: 'top-right',
      });
      setOtp('');
    },
    onSettled: () => setIsVerifying(false),
  });

  const handleResendOtp = () => {
    setIsResending(true);
    sendOtp({ email });
  };

  const handleVerifyOtp = () => {
    setIsVerifying(true);
    verifyOtp({ email, otp });
  };

  const handleClose = () => {
    setOtp('');
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="md" isCentered>
      <ModalOverlay />
      <ModalContent bg="white">
        <ModalHeader>Enter OTP</ModalHeader>
        <ModalCloseButton size="lg" color="gray.500" border="2px solid gray" />
        <ModalBody>
          <FormControl>
            <FormLabel>Please enter the OTP sent to your email address: {email}.</FormLabel>
            <Flex justify="center">
              <HStack>
                <PinInput
                  value={otp}
                  onChange={(value) => setOtp(value)}
                  focusBorderColor="#713EDD"
                  otp
                >
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                </PinInput>
              </HStack>
            </Flex>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="purple"
            mr={3}
            onClick={handleVerifyOtp}
            isLoading={isVerifying}
          >
            Verify
          </Button>
          <Button variant="ghost" onClick={handleResendOtp} isLoading={isResending}>
            Resend OTP
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default OtpModal;
