

export const FONT_SIZES = {
    8: '0.5rem',
    10: '0.625rem',
    12: '0.750rem',
    13: '0.813rem',
    14: '0.875rem',
    15: '0.938rem',
    16: '1rem',
    18: '1.125rem',
    20: '1.25rem',
    22: '1.375rem',
    24: '1.5rem',
    25: '1.563rem',
    26: '1.65rem',
    27: "1.688rem",
    28: '1.75rem',
    30: '1.875rem',
    32: '2rem',
    34: '2.125rem',
    35: '2.188rem',
    36: '2.25rem',
    38: '2.375rem',
    40: '2.5rem',
    43: '2.688rem',
    44: '2.75rem',
    42: '2.625rem',
    45: '2.813rem',
    50: '3.125rem',
    53: '3.313rem',
    55: '3.438rem',
    65: '4.063rem',
    68: '4.25rem',
    70: '4.375rem',
    85: '5.313rem'
};
export const FONT_WEIGHTS = {
    100: 100,
    200: 200,
    300: 300,
    400: 400,
    500: 500,
    600: 600,
    700: 700,
    800: 800,
    900: 900,
};