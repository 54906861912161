import { Box, Button, Flex, Input, InputGroup, InputLeftElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure } from "@chakra-ui/react";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { last } from "lodash";
import { useContext, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { GET_EBOOK } from "../../../apis/constants.api";
import { publishEBook } from "../../../apis/ebook.api";
import { AppContext } from "../../../contexts/AppContext";
import { isAdmin } from "../../../utils/users.utils";
import ThumbImageUpload from "../../Dropzone/ThumbImageUpload";
import EBooksTable from "./EBookTable";
import RelevantSkill from "./RelevantSkill";
import { bookSchema } from "./form.schema";

export default function EBookListItems() {
	const [eBookCreated, setEBookCreated] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");
	const [AppState] = useContext(AppContext);
	const { user } = AppState;
	const queryClient = useQueryClient();
	const [page, setPage] = useState(1);
	const isInitialLoad = useRef(true);

	const { register, control, handleSubmit, reset, formState: { errors } } = useForm({
		defaultValues: {
			title: '',
			thumbnailUrl: '',
			pdfUrl: '',
			relevantSkills: []
		},
		resolver: yupResolver(bookSchema)
	});
	const { isOpen, onOpen, onClose } = useDisclosure({
		onClose: () => reset()
	});

	const handleScrollToBottom = (() => {
		setPage(prev => prev + 1);
	});

	const { mutate, isLoading } = useMutation(publishEBook, {
		onSuccess: data => {
			queryClient.invalidateQueries({ queryKey: [GET_EBOOK] });
			reset();
			onClose();
		},
	});

	const onSubmit = (values) => {
		const payload = {
			...values,
			relevantSkills: values?.relevantSkills?.map((value) => value.value)
		};
		mutate(payload);
	};


	const handleSearch = (e) => {
		var searchText = e.target.value.toLowerCase();
		setSearchQuery(searchText);
	};

	useEffect(() => {
		if (isInitialLoad.current) {
			isInitialLoad.current = false;
		} else {
		}
	}, [page]);

	return (
		<Box
			padding={{ base: "15px", lg: "25px", xl: "35px" }}
			background={isAdmin(user) ? "#F7F7F7" : "none"}
		>
			<Box {...(isAdmin(user) ? {
				background: "white",
				borderRadius: "br.10",
				padding: "25px",
				height: "calc(100vh - 150px)",
			} : {

			})}
			>
				<Flex width="100%" alignItems="center" justifyContent="space-between" gap={{ md: "10px", lg: "0" }}>
					<InputGroup width="auto">
						<InputLeftElement pointerEvents='none'>
							<FontAwesomeIcon icon={faMagnifyingGlass} style={{ color: "#A0A6B1" }} />
						</InputLeftElement>
						<Input onChange={handleSearch} focusBorderColor='#713EDD' variant='filled' width={{ lg: "auto", xl: "300px" }} type='tel' background='#EBEDF5' placeholder='Search' />
					</InputGroup>
					<Box pr="20px">
						{isAdmin(user) ? (<Button onClick={() => {
							reset(); // Reset the form fields when the button is clicked
							onOpen();
						}} px={{ base: 2, md: "15px", lg: "30px" }} py="8px" border="1px solid #713EDD" fontSize={{ base: 12, sm: 14, md: 16 }} fontWeight="fw.500" background="linear-gradient(134.59deg, #713EDD -1.98%, #A17DEE 111.17%)" _hover={{ background: "linear-gradient(134.59deg, #A17DEE -1.98% ,#713EDD 111.17%)" }} color="#FFFFFF">+ Add E-Book</Button>
						) : null}
						<Modal closeOnOverlayClick={false} onClose={onClose} isOpen={isOpen} isCentered >
							<form onSubmit={handleSubmit(onSubmit)}>
								<ModalOverlay />
								<ModalContent maxWidth="599px" borderRadius="10px">
									<ModalHeader fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16", xl: "fs.18" }} fontWeight="fw.600">Add New EBook</ModalHeader>
									<ModalCloseButton border="1px solid #868B944D" />
									<ModalBody pt="4px" pb="30px">
										<Box>
											<Box mt="30px">
												<Text fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} fontWeight="fw.500">Title</Text>
												<Controller
													name="title"
													control={control}
													rules={{
														required: 'Title is required',
														minLength: { value: 3, message: 'Title must be at least 3 characters' },
														maxLength: { value: 50, message: 'Title cannot exceed 50 characters' }
													}}
													render={({ field }) => (
														<>
															<Input {...field} placeholder="Enter Title" height="50px" _hover={{ borderColor: "#713EDD" }} background="#F7FAFC" mt="8px" _focusVisible={{ borderColor: "#713EDD" }} />
															{errors.title && (
																<Text color="red.500" fontSize={'fs.14'}>{errors.title.message.charAt(0).toUpperCase() + errors.title.message.slice(1)}</Text>
															)}
														</>
													)}
												/>
											</Box>
											<Box mt="30px">
												<Text fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} fontWeight="fw.500">Thumbnail Image</Text>
												<Controller
													name="thumbnailUrl"
													control={control}
													render={({ field }) =>
														<ThumbImageUpload
															innerMessage='Upload Thumbnail Image'
															accept="image/jpeg, image/jpg, image/png, image/webp" // Specify accepted image file types
															maxFiles={1}
															error={errors?.thumbnailUrl?.message}
															onUpload={(uploadedFile) => {
																field.onChange(last(uploadedFile?.url?.split('/')));
															}}
														/>
													}
												/>
											</Box>
											<Box mt="30px">
												<Text fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} fontWeight="fw.500">PDF File</Text>
												<Controller
													name="pdfUrl"
													control={control}
													render={({ field }) =>
														<ThumbImageUpload
															innerMessage='Upload PDF File'
															acceptedFiles={{ 'application/pdf': ['.pdf'] }}
															maxFiles={1}
															isPDF={true}
															maxSize={1024 * 1024 * 500}
															error={errors?.pdfUrl?.message}
															onUpload={(uploadedFile) => {
																field.onChange(last(uploadedFile?.url?.split('/')));
															}}
														/>
													}
												/>
											</Box>
											<Box mt="30px">
												<Text fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} fontWeight="fw.500">Relevant Skill</Text>
												<Controller
													name="relevantSkills"
													control={control}
													render={({ field }) => (
														<RelevantSkill register={register} field={field} isLoading={isLoading} />
													)}
												/>
											</Box>
										</Box>
									</ModalBody>
									<ModalFooter gap="20px" background="#F5F4F6" borderRadius="0px 0px 10px 10px">
										<Button background="#EAE9EC" onClick={onClose}>Cancel</Button>
										<Button
											type="submit"
											px={{ md: "15px", lg: "30px" }}
											py="8px"
											background="linear-gradient(134.59deg, #713EDD -1.98%, #A17DEE 111.17%)"
											display="flex"
											columnGap="5px"
											color="white"
											_hover={{ background: "linear-gradient(134.59deg, #A17DEE -1.98%, #713EDD 111.17%)" }}
											loadingText='Adding'
											isLoading={isLoading}
											spinnerPlacement='end'
											ml={2}>
											Add
										</Button>
									</ModalFooter>
								</ModalContent>
							</form>
						</Modal>
					</Box>
				</Flex>
				<Box mt="25px">
					<EBooksTable
						searchQuery={searchQuery}
						eBookCreated={eBookCreated}
						eBooksLoaded={() => {
							setEBookCreated(false);
						}} />
				</Box>
			</Box>
		</Box >
	);
}