import { Button, Flex, Stack, Td, Text, Tr, useDisclosure } from '@chakra-ui/react';
import SkillsEditDialog from './SkillsEditDialog';
import { useForm } from 'react-hook-form';
import DeleteSkillModal from './DeleteSkillModal';
import { schema } from './form';
import { yupResolver } from '@hookform/resolvers/yup';

function SkillsListItem(props) {
  const { skill } = props;
  const { name, rss } = skill;

  const { isOpen: openDeleteModal, onOpen: onOpenDeleteModal, onClose: onCloseDeleteModal } = useDisclosure();

  const methods = useForm({
    defaultValues: {
      name: skill.name,
      rss: skill.rss,
    },
    resolver: yupResolver(schema)

  });
  const { isOpen, onOpen, onClose } = useDisclosure({
    onClose: () => {
      methods.reset();
    }
  });

  return (
    <>
      <Tr py='14px' key={skill._id}>
        <Td
          pl='15px'
          pr='20px'
          py='14px'
          color='rgba(134, 139, 148, 1)'
          fontSize={{
            base: 'fs.14',
            md: 'fs.14',
            lg: 'fs.16',
            xl: 'fs.16',
          }}
          fontWeight='fw.500'
          width='360px'>
          {name}</Td>
        <Td
          pl='15px'
          pr='20px'
          py='14px'
          color='rgba(134, 139, 148, 1)'
          fontSize={{
            base: 'fs.14',
            md: 'fs.14',
            lg: 'fs.16',
            xl: 'fs.16',
          }}
          fontWeight='fw.500'
          width='360px'>
          {rss.map((e) => {
            return <Text> {rss[rss.length - 1] === e ? e : e + " , "}</Text>;
          })}</Td>

        <Td
          pl='15px'
          pr='20px'
          py='14px'
          color='rgba(134, 139, 148, 1)'
          fontSize={{
            base: 'fs.14',
            md: 'fs.14',
            lg: 'fs.16',
            xl: 'fs.16',
          }}
          fontWeight='fw.500'
          width='360px'>
          <Flex gap='10px'>
            <Stack direction='row'>
              <Button bg='transparent' background="#27AC2D" color="white" fontSize="14px" onClick={onOpen} fontWeight="500" _hover={{ background: "#E3F4E4", color: "#27AC2D" }} borderRadius="6px">Edit</Button>
              <Button bg='transparent' background="#FF6A52" color="white" fontSize="14px" onClick={onOpenDeleteModal} fontWeight="500" _hover={{ background: "#FCE9E8", color: "#FF6A52" }} borderRadius="6px">Delete</Button>
            </Stack>
          </Flex>

        </Td>


      </Tr >
      <SkillsEditDialog skill={skill} methods={methods} isOpen={isOpen} onClose={onClose} />
      {
        openDeleteModal &&
        <DeleteSkillModal skill={skill} onClose={onCloseDeleteModal} />
      }
    </>
  );
}

export default SkillsListItem;