import { Avatar, Button, Flex, Spinner, Stack, Td, Text, Tr, useDisclosure } from "@chakra-ui/react";
import { memo } from "react";
import DeactivateModal from "../UsersPage/DeactivateModal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { removeUserManagerAPI } from "../../apis/users.api";
import { GET_ALL_MANAGERS } from "../../apis/constants.api";
import ManagerProfile from "./ManagerProfile";

const COLOR = {
  ONBOARDING: { color: 'blue.500', bg: 'blue.100' },
  INVITED: { color: 'blue.500', bg: 'blue.100' },
  DEACTIVATED: { color: 'gray.500', bg: 'gray.100' },
  ACCEPTED: { color: 'green.500', bg: 'green.100' },
  REJECTED: { color: 'red.500', bg: 'red.100' }
};
function ManagerListItem({ manager, isLoading }) {
  const queryClient = useQueryClient();
  const { isOpen: openDeactivateModal, onOpen: onOpenDeactivateModal, onClose: onCloseDeactivateModal } = useDisclosure();
  const { isOpen: openProfileModal, onOpen: onOpenProfileModal, onClose: onCloseProfileModal } = useDisclosure();

  const { mutate, isLoading: isDeactivate } = useMutation(removeUserManagerAPI, {
    onSuccess: () => {
      queryClient.invalidateQueries([GET_ALL_MANAGERS]);
      onCloseDeactivateModal();
    }
  });

  const onSubmit = async () => {
    const payload = {
      id: manager?._id
    };
    await mutate(payload);
    queryClient.invalidateQueries([GET_ALL_MANAGERS]);
  };
  return (
    <>
      <Tr py='14px' key={manager._id}>
        <Td
          px='20px'
          py='14px'
          color='rgba(32, 43, 60, 1)'
          fontSize='fs.16'
          fontWeight='fw.500'>
          <Avatar
            fontSize='fs.20'
            fontWeight='fw.600'
            width={{
              base: '30px',
              md: '30px',
              lg: '40px',
              xl: '40px',
            }}
            height={{
              base: '30px',
              md: '30px',
              lg: '40px',
              xl: '40px',
            }}
            color="white"
            borderRadius='6px'
            name={`${manager.name}`}
            src={manager.profilePicture}
            className="cursor-pointer"
            onClick={onOpenProfileModal} />
        </Td>
        <Td
          pl='0px'
          pr='20px'
          py='14px'
          color='rgba(134, 139, 148, 1)'
          fontSize={{
            base: 'fs.14',
            md: 'fs.14',
            lg: 'fs.16',
            xl: 'fs.16',
          }}
          fontWeight='fw.500'
          width='360px' onClick={onOpenProfileModal}
          className="cursor-pointer">
          {manager.name}</Td>
        <Td
          pl='0px'
          pr='20px'
          py='14px'
          color='rgba(134, 139, 148, 1)'
          fontSize={{
            base: 'fs.14',
            md: 'fs.14',
            lg: 'fs.16',
            xl: 'fs.16',
          }}
          fontWeight='fw.500'
          width='360px'>
          {manager.email}</Td>
        <Td
          pl='0px'
          pr='20px'
          py='14px'
          color={manager.isOnboardingComplete ? "#E1251A" : "#27AC2D"}
          fontSize={{
            base: 'fs.14',
            md: 'fs.14',
            lg: 'fs.16',
            xl: 'fs.16',
          }}
          fontWeight='fw.500'>
          <Flex width="-webkit-fit-content" borderRadius="40px" background={COLOR[manager?.status]?.bg} alignItems="center" justifyContent="center" height={{ base: "30px", md: "30px" }} px="16px" gap="6px" >
            <Text fontSize={{ base: "fs.12", md: "fs.14" }} fontWeight="fw.500" color={COLOR[manager?.status]?.color} >{manager.status}</Text>
          </Flex>
        </Td>
        <Td
          pl='0px'
          pr='20px'
          py='14px'
          width='230px'>
          <Flex gap='10px'>
            <Stack direction='row'>
              {manager.status !== "DEACTIVATED" && <Button disabled={isLoading} bg='transparent' background="#FF6A52" color="white" fontSize="14px" fontWeight="500" onClick={onOpenDeactivateModal} _hover={{ background: "#FCE9E8", color: "#FF6A52" }} borderRadius="6px">
                {
                  isLoading && <Spinner />
                }
                Deactivate
              </Button>}

            </Stack>
          </Flex>
        </Td>
      </Tr >
      {
        openDeactivateModal &&
        <DeactivateModal data={manager} onClose={onCloseDeactivateModal} onSubmit={onSubmit} isDeactivate={isDeactivate} />
      }
      {openProfileModal && (
        <ManagerProfile isOpen={openProfileModal} onClose={onCloseProfileModal} manager={manager} />
      )}
    </>

  );
}

export default memo(ManagerListItem);