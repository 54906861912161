import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import ReactPaginate from 'react-paginate';
import SkillsListItem from './SkillsListItem';

export default function SkillsTable({ handlePageChange, skillsList, page, totalPages, isSkillLoadings }) {
  return (
    <>
      <Box
        pt={{
          base: '15px',
          md: '15px',
          lg: '25px',
          xl: '25px',
        }}
        bg='rgba(255, 255, 255, 1)'
        borderRadius='10px'>
        <TableContainer
          border='1px solid rgba(234, 236, 240, 1)'
          borderRadius='0px 0px 10px 10px'
          height="calc(100vh - 375px)"
          overflowY='scroll'
          overflowX='scroll'
          css={` &::-webkit-scrollbar { width: 0; height: 0;} &::-webkit-scrollbar-thumb { background-color: rgba(0, 0, 0, 0);}`}>
          <Box position='relative'>
            <Table variant='simple' style={{ borderSpacing: '0 10px' }}>
              <Thead
                bgGradient='linear-gradient(0deg, #EAECF0, #EAECF0),linear-gradient(0deg, #F9FAFB, #F9FAFB)'
                textAlign='center'
                position='sticky'
                top='0px'
                zIndex='1'
              >
                <Tr>
                  <Th
                    fontSize={{
                      base: 'fs.14',
                      md: 'fs.14',
                      lg: 'fs.16',
                      xl: 'fs.18',
                    }}
                    fontWeight='fw.400'
                    color=' rgba(134, 139, 148, 1)'
                    px='0px'
                    py='13px'
                    pl="16px"
                    textTransform="capitalize">
                    Name</Th>
                  <Th
                    fontSize={{
                      base: 'fs.14',
                      md: 'fs.14',
                      lg: 'fs.16',
                      xl: 'fs.18',
                    }}
                    fontWeight='fw.400'
                    color=' rgba(134, 139, 148, 1)'
                    px='0px'
                    py='13px'
                    textTransform="capitalize">
                    Rss</Th>

                  <Th
                    fontSize={{
                      base: 'fs.14',
                      md: 'fs.14',
                      lg: 'fs.16',
                      xl: 'fs.18',
                    }}
                    fontWeight='fw.400'
                    color=' rgba(134, 139, 148, 1)'
                    px='0px'
                    py='13px'
                    textTransform="capitalize">
                    Action</Th>

                </Tr>

              </Thead>

              <Tbody
                bg='transparent'>
                {isSkillLoadings && <Tr><Th colSpan={10} textAlign={'center'}> LOADING...</Th></Tr>}
                {skillsList?.map((skill) => (
                  <SkillsListItem key={skill._id} skill={skill} />
                ))}
                {!isSkillLoadings && !skillsList.length && <Tr><Th colSpan={10} textAlign={'center'}>No skills found</Th></Tr>}
              </Tbody>
            </Table>
          </Box >
        </TableContainer >
        <Box position="relative" mt="20px">
          <ReactPaginate
            pageCount={Math.ceil(totalPages)}
            containerClassName={"paginationButtons"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
            previousLabel={<span>&larr; Previous</span>}
            nextLabel={<span>Next &rarr;</span>}
            onPageChange={handlePageChange}
            forcePage={page - 1}
          />
        </Box>
      </Box >
    </>
  );
}
