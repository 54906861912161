import {
  Box,
  Flex
} from '@chakra-ui/react';
import Header from '../Headar';
import SideNavigation from '../SideNavigation';

function DashboardLayout({ children }) {
  return (
    <Box id='dashboard-layout' className='layout dashboardLayout' position="relative" height="100dvh">
      <Box pos={'relative'} height={'80px'}>
        <Box position="fixed" zIndex="2" width="100%" background="white" top="0" height={{ lg: "auto", xl: "80px" }} px={3} borderBottom="1px solid #E5E5E5">
          <Header />
        </Box>
      </Box>
      <Flex width="100%" height={'calc(100dvh - 80px)'} direction={{ base: 'column-reverse', md: 'row' }}>
        <Box className='sidebar' width={{ base: '100%', md: "80px" }} height={{ base: '80px', md: '100%' }} background="white" borderRight="1px solid #E5E5E5" >
          <SideNavigation />
        </Box>
        <Box bg="#F7F7F7" flex="1 1 auto" overflowY={'scroll'}>
          {children}
        </Box>
      </Flex>
    </Box>
  );
}

export default DashboardLayout;