import { Avatar, Button, Flex, Link, Stack, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { Link as ReactLink } from 'react-router-dom';
import ArchiveDialog from "./ArchiveDialog";
import ProjectEditDialog from "./ProjectEditDialog";
const ProjectListItem = ({ project }) => {
  const { isOpen: openEditModal, onOpen: onOpenEditModal, onClose: onCloseEditModal } = useDisclosure();
  const { isOpen: openArchiveModal, onOpen: onOpenArchiveModal, onClose: onCloseArchiveModal } = useDisclosure();


  return (<>

    <Tr py='14px' >
      <Td
        pr='20px'
        py='14px'
        color='#4747ab'
        textDecoration={"underline"}
        fontSize={{
          base: 'fs.14',
          md: 'fs.14',
          lg: 'fs.16',
          xl: 'fs.16',
        }}
        fontWeight='fw.500'>
        <Link as={ReactLink} to={`/boards/${project._id}`}> {project.name}</Link></Td>
      <Td
        pr='20px'
        py='14px'
        color='rgba(134, 139, 148, 1)'
        fontSize={{
          base: 'fs.14',
          md: 'fs.14',
          lg: 'fs.16',
          xl: 'fs.16',
        }}
        fontWeight='fw.500'>
        {project.key}</Td>
      <Td
        pl="0px"
        py='14px'
        color='rgba(134, 139, 148, 1)'
        fontSize={{ base: 'fs.14', md: 'fs.14', lg: 'fs.16', xl: 'fs.16', }}
        fontWeight='fw.500'
      >
        {project.team.flatMap((e) => e.name).join(", ")}</Td>
      <Td
        pl="0px"
        py='14px'
        color='rgba(134, 139, 148, 1)'
        fontSize={{ base: 'fs.14', md: 'fs.14', lg: 'fs.16', xl: 'fs.16', }}
        fontWeight='fw.500'
      >
        <Avatar width="50px" src={project.icon} objectFit={'cover'} />
      </Td>
      <Td
        pl='0px'
        pr='20px'
        py='14px'
        width='230px'>
        <Flex gap='10px' >
          <Stack direction='row'>
            {<Button bg='transparent' background="#27AC2D" onClick={onOpenEditModal} color="white" fontSize="14px" fontWeight="500" _hover={{ background: "#E3F4E4", color: "#27AC2D" }} borderRadius="6px">Edit</Button>}
            {<Button bg='transparent' background="#FF6A52" onClick={onOpenArchiveModal} color="white" fontSize="14px" fontWeight="500" _hover={{ background: "#FCE9E8", color: "#FF6A52" }} borderRadius="6px">Archive</Button>}
          </Stack>
        </Flex>
      </Td>
    </Tr >
    <ProjectEditDialog project={project} openEditModal={openEditModal} onCloseEditModal={onCloseEditModal} />
    <ArchiveDialog project={project} openArchiveModal={openArchiveModal} onCloseArchiveModal={onCloseArchiveModal} />
  </>);
};

export default ProjectListItem;