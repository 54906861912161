import { Divider, ListItem, Text } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { GET_NOTIFICATIONS, GET_UNREAD_NOTIFICATION_COUNT } from "../../apis/constants.api";
import { readNotificationAPI } from "../../apis/notifications.api";

export default function NotificationListItem({ notification, isVisible }) {
  const [isRead, setRead] = useState(notification?.isRead);
  const queryClient = useQueryClient();
  const { mutate, isLoading: isMarking } = useMutation({
    mutationFn: readNotificationAPI,
    onSuccess: () => {
      setRead(true);
      console.log(' !!!!! Invalidating Query !!!! ');
      queryClient.invalidateQueries({
        queryKey: [
          GET_NOTIFICATIONS,
        ]
      });
      queryClient.invalidateQueries({
        queryKey: [
          GET_UNREAD_NOTIFICATION_COUNT,
        ]
      });
    }
  });

  useEffect(() => {
    if (!isMarking && !isRead && isVisible) {
      mutate({
        notificationIds: [notification._id]
      });
    }
  }, [isRead, isVisible, isMarking]);

  return (
    <>
      <ListItem id={`notifications_${notification._id}`} py={4}>
        <Text fontSize={'md'} fontWeight={isRead ? 'semibold' : 'bold'} color={isRead ? 'gray.500' : 'gray.800'}>{notification?.title}</Text>
        <Text fontSize={'sm'} color={isRead ? 'gray.500' : 'gray.800'}>{notification?.description}</Text>
      </ListItem>
      <Divider />
    </>
  );
}