import { useContext } from "react";
import { AppContext } from "../../../../contexts/AppContext";
import { isAdmin } from "../../../../utils/users.utils";
import AdministratorProfile from "./AdministratorProfile";
import UserProfile from "./UserProfile";


export default function UserDetails() {
    const [stateApp] = useContext(AppContext);
    const isAdminUser = isAdmin(stateApp.user);

    if (isAdminUser) {
        return <AdministratorProfile />;
    }

    return <UserProfile />;
}