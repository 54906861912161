import { Box, Button, ButtonGroup, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Spinner, Text, useToast } from '@chakra-ui/react';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from 'react';
import Select from 'react-select';
import accept from "../../../src/assets/icon/accept.png";
import { addMemberToChannel, getAllChannels } from '../../apis/channel.api';
import { GET_ALL_MEMBERS, GET_CHANNEL } from '../../apis/constants.api';
import { updateUserStatusAPI } from '../../apis/users.api';
import { useForm, Controller } from 'react-hook-form';

const PAGE_SIZE = process.env.REACT_APP_PAGE_SIZE || 25;
export default function AcceptUserModal(props) {
  const { user, onClose } = props;
  const [options, setOptions] = useState([]);
  const [page,] = useState(1);
  const [inputSearch,] = useState('');
  const queryClient = useQueryClient();
  const toast = useToast();
  const { handleSubmit, control, formState: { errors } } = useForm();


  const channelsQuery = useQuery({
    queryKey: [GET_CHANNEL, { skip: (page - 1) * PAGE_SIZE, limit: PAGE_SIZE, search: inputSearch }],
    queryFn: getAllChannels
  });

  const { mutate: updateUserStatus, isLoading } = useMutation(updateUserStatusAPI, {
    onSuccess: () => {
      queryClient.invalidateQueries([GET_ALL_MEMBERS]);
    }
  });

  const { mutate } = useMutation(addMemberToChannel, {
    onSuccess: () => {
    }
  });


  useEffect(() => {
    if (channelsQuery.data) {
      const channelsData = channelsQuery.data?.data?.data?.channels?.map(channel => ({
        value: channel._id,
        label: channel.name
      }));
      setOptions(channelsData);
    }
  }, [channelsQuery.data]);


  const onAccept = async (data) => {
    try {
      await updateUserStatus({ userId: props.user._id, status: 'ACCEPTED' });
      const promises = data.selectedChannel.map(channel =>
        mutate({ channelId: channel.value, userId: props.user._id })
      );
      const responses = await Promise.allSettled(promises);
      if (responses.length) {
        onClose();
      }
      const responseError = responses.every((e) => e.status === "fulfilled");
      if (!responseError) {
        toast({
          title: "Error",
          description: "Channel is not fullfiled",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: 'top-right'
        });
      }
    } catch (error) {
      console.error(' !!! ERROR ', error);
    }

  };


  return (
    <Modal closeOnOverlayClick={false} onClose={onClose} size="lg" isOpen={true} isCentered >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Image alt='accept' src={accept} />
        </ModalHeader>
        <ModalCloseButton border="1px solid #868B944D" />
        <ModalBody>
          <Text fontSize="fs.18" fontWeight="fw.600">{`Accept ${user?.name} Request`}</Text>
          <Text fontSize="fs.14" fontWeight="fw.400" color="#475467" mt="5px" >Are you sure you want to accept this user request? </Text>

          <Box mt="16px">
            <form onSubmit={handleSubmit(onAccept)}>
              <Controller
                name="selectedChannel"
                control={control}
                rules={{ required: 'Please select at least one channel' }}
                render={({ field }) => (
                  <Select
                    {...field}
                    isMulti
                    options={options}
                    onChange={(value) => field.onChange(value)}
                  />
                )}
              />
              {errors.selectedChannel && <Text color="red.500">{errors.selectedChannel.message}</Text>}
              <ButtonGroup variant='outline' spacing='5' width="100%" my="18px" isDisabled={isLoading}>
                <Button fontWeight="fw.600" width="100%" borderRadius="8px" onClick={onClose} height="44px" fontSize="fs.16">Cancel</Button>
                <Button type="submit" fontWeight="fw.600" width="100%" borderRadius="8px" fontSize="fs.16" height="44px" background={'green.500'} color="white" _hover={{ background: "#2b7b51" }}>
                  Accept
                  {isLoading && <Spinner size="sm" ml={2} />}
                </Button>
              </ButtonGroup>
            </form>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
