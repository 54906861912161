
import { Flex } from '@chakra-ui/react';
import MessageContent from '../../Message';

function MessagesPage() {
  return (
    <Flex dir="column" className="page messages-page" h="100%">
      <MessageContent />
    </Flex>
  );
}

export default MessagesPage;