import { Button, ButtonGroup, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Spinner, Text, useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import reject from "../../../../src/assets/icon/reject.png";
import { GET_SKILLS } from '../../../apis/constants.api';
import { deleteSkill } from '../../../apis/skills.api';

export default function DeleteSkillModal(props) {
  const { skill, onClose } = props;
  const queryClient = useQueryClient();
  const { reset, handleSubmit } = useForm();

  const toast = useToast();

  const { mutate: deleteSkills, isLoading } = useMutation(deleteSkill, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([GET_SKILLS]);
      reset();
      onClose();


      if (data.data.message) {
        toast({
          title: "Success",
          description: data.data.message,
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
      }
    }
  });

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  const onDelete = async () => {
    try {
      const skillId = skill._id;
      await deleteSkills(skillId);
      queryClient.invalidateQueries([GET_SKILLS]);
    } catch (error) {
      console.error(' !!! ERROR ', error);
    }
  };

  return (
    <Modal closeOnOverlayClick={false} onClose={onClose} size="lg" isOpen={true} isCentered >
      <form onSubmit={handleSubmit(onDelete)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Image alt='reject' src={reject} />
          </ModalHeader>
          <ModalCloseButton border="1px solid #868B944D" />
          <ModalBody>
            <Text fontSize="fs.18" fontWeight="fw.600">{`Delete ${skill?.name} Request`}</Text>
            <Text fontSize="fs.14" fontWeight="fw.400" color="#475467" mt="5px" >Are you sure you want to delete this skill?</Text>
            <ButtonGroup variant='outline' spacing='5' width="100%" my="18px">
              <Button {...actionButtonChakraProps} onClick={onClose}>Cancel</Button>
              <Button  {...actionButtonChakraProps} type='submit' background={'red.500'} color="white" _hover={{ background: "red.600" }}>
                Delete
                {isLoading && <Spinner size="sm" ml={2} />}
              </Button>
            </ButtonGroup>
          </ModalBody>
        </ModalContent>
      </form>
    </Modal>
  );
}

const actionButtonChakraProps = {
  fontWeight: "fw.600",
  width: "100%",
  borderRadius: "8px",
  height: "44px",
  fontSize: "fs.16",
};