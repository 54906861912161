import { Avatar, Box, Button, Flex, Spinner, Tag, TagCloseButton, TagLabel, Text, WrapItem, useDisclosure } from "@chakra-ui/react";
import { IconAt, IconEdit, IconMapPin, IconSchool } from "@tabler/icons-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ReactSelect from "react-select";
import { GET_SKILLS, GET_USER_DATA } from "../../../../apis/constants.api";
import { getSkills } from "../../../../apis/skills.api";
import { updateProfileAPI } from "../../../../apis/users.api";
import { AppContext } from "../../../../contexts/AppContext";
import EducationDialog from "../../EducationDialog";
import ExperienceDialog from "../../ExperienceDialog";
import ProfileDialog from "../../ProfileDialog";
import ExperienceArrows from "./ExperienceArrows";

export default function UserProfile() {
  const [stateApp,] = useContext(AppContext);
  const { isOpen: openProfileModal, onOpen: onOpenProfileModal, onClose: onCloseProfileModal } = useDisclosure();
  const { isOpen: openExpModal, onOpen: onOpenExpModal, onClose: onCloseExpModal } = useDisclosure();
  const { isOpen: openEducationModal, onOpen: onOpenEducationModal, onClose: onCloseEducationModal } = useDisclosure();
  const { user } = stateApp;
  const [skillList, setSkillList] = useState(user?.currentProfession?.skills);

  const handleRemoveSkill = (index) => {
    const newSkillList = skillList.filter((_, i) => i !== index);
    setSkillList(newSkillList);
  };
  useEffect(() => {
    setSkillList(user?.currentProfession?.skills);
  }, [user]);


  const PAGE_SIZE = process.env.REACT_APP_PAGE_SIZE;
  const [page,] = useState(1);
  const [isEdit, setIsEdit] = useState({
    skill: false,

  });
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      skills: []
    }
  });
  const queryClient = useQueryClient();

  const { data: skills } = useQuery({
    queryKey: [GET_SKILLS, {
      skip: PAGE_SIZE * (page - 1),
      limit: PAGE_SIZE,
      search: ""
    }],
    queryFn: getSkills,
  });

  const skillOption = (skills?.data.data || [])?.map((e) => ({ label: e.name, value: e._id }));

  const { mutate, isLoading } = useMutation(updateProfileAPI, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_USER_DATA] });
      reset();
      setIsEdit({ skill: false });
    }
  });

  const onSubmit = (data) => {
    const _skills = skillList?.map((e) => e._id) || [];
    const data_skill = data?.skills?.map((e) => e.value) || [];

    if (_skills || data_skill) {
      const _data = [..._skills, ...data_skill];
      const payload = {
        currentProfession: {
          skills: _data,
          profession: user?.currentProfession?.profession,
          yearsOfExperience: user.currentProfession.yearsOfExperience
        }
      };
      mutate(payload);

    }
  };

  return (
    <Box padding="25px" width="100%" display={"flex"} justifyContent={"center"} >
      <Box width={{ base: '100%', md: '85%', }} maxW={800} >
        <Flex flexDirection="column" background="white" borderRadius="br.10" className="scrollBar" pb={10} >
          <Flex flexDirection="column">
            <Flex px={{ md: "10px", lg: "20px", xl: "30px" }} width={{ md: "100%", lg: "100%" }} py={"20px"} justifyContent={"space-between"}>
              <Flex flexDirection="row" >
                <Box mt="30px">
                  <WrapItem  >
                    <Avatar size='xl' borderRadius="br.50" name={user?.name} src={user?.profilePicture} />
                  </WrapItem>
                </Box>
                <Flex borderRadius="br.10" py="35px" px="18px" justifyContent="center">
                  <Flex flexDirection="column">
                    <Text fontSize="fs.26" fontWeight="fw.600" >{user?.name}</Text>
                    <Text fontSize="fs.14" fontWeight="fw.400" color="#868B94" >
                      <Flex flexDirection="row" gap={"6px"} alignItems={"center"} >
                        <IconAt size={18} stroke={2} />
                        {user?.email}
                      </Flex>
                      <Flex flexDirection="row" gap={"6px"} alignItems={"center"} >
                        <IconMapPin size={18} stroke={2} />
                        {user?.address?.city}  {user?.address?.state}, {user?.address?.country}
                      </Flex>
                    </Text>
                  </Flex>

                </Flex>
              </Flex>
              <IconEdit style={{ margin: "30px 25px", cursor: "pointer" }} onClick={() => { onOpenProfileModal(); reset(); }} />
            </Flex>

            <Flex flexDirection="row" width={"100%"} gap={15} px={30}>
              <Flex borderRadius="br.10" width={"100%"} py="20px" px="18px" background={"blackAlpha.100"} justifyContent={"space-between"}>
                <Flex flexDirection={"column"} gap={1}>
                  <Text fontSize="fs.20" fontWeight="fw.500">{user?.currentProfession?.profession}</Text>
                  <Text fontSize="fs.16" fontWeight="fw.400" color="#868B94"  >{user?.currentProfession?.yearsOfExperience} years of experience</Text>
                  <Box mt={4}>
                    <ExperienceArrows yearsOfExperience={user?.currentProfession?.yearsOfExperience || 0} />
                  </Box>
                </Flex>

                <IconEdit style={{ margin: "10px 8px", cursor: "pointer" }} onClick={onOpenExpModal} />
              </Flex>
            </Flex>

            <form onSubmit={handleSubmit(onSubmit)}>
              <Flex flexDirection="row" width={"100%"} gap={"10px"} px="30px" mt={15}>
                <Flex borderRadius="br.10" width={"100%"} py="25px" px="30px" background={"blackAlpha.100"} flexDirection="column" gap={5}>
                  <Flex justifyContent="space-between">
                    <Text fontSize="fs.16" fontWeight="fw.600">Skills</Text>
                    <IconEdit style={{ cursor: "pointer" }} onClick={() => setIsEdit({ skill: true })} />
                  </Flex>
                  {skillList?.length ? <Flex flexDirection="row" alignItems={"center"} flexWrap="wrap">
                    {skillList?.map((e, index) => {
                      return (
                        <> <Tag
                          size="lg"
                          key={index}
                          borderRadius="full"
                          variant='subtle'
                          colorScheme="purple"
                          m={1}
                        >
                          <TagLabel>{e.name}</TagLabel>
                          {isEdit.skill && <TagCloseButton onClick={() => handleRemoveSkill(index)} />}
                        </Tag>
                        </>

                      );
                    })}
                  </Flex> : <></>}
                  {isEdit.skill && <Box width={"100%"}>
                    <Controller
                      name="skills"
                      control={control}
                      render={({ field }) => (
                        <ReactSelect
                          placeholder={`Select...`}
                          options={skillOption}
                          isSearchable={true}
                          onChange={(value) => field.onChange(value)}
                          {...field}
                          isMulti
                        />
                      )}
                    />
                  </Box>}

                  {isEdit.skill && <Flex gap="10px">
                    <Button
                      background="#EAE9EC"
                      onClick={() => setIsEdit({ skill: false })}
                      px="10px"
                      py="5px"
                      fontSize="sm"
                      height={"30px"}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      px="10px"
                      gap={1}
                      py="5px"
                      fontSize="sm"
                      height={"30px"}
                      background="linear-gradient(134.59deg, #713EDD -1.98%, #A17DEE 111.17%)"
                      _hover={{ background: "linear-gradient(134.59deg, #A17DEE -1.98% ,#713EDD 111.17%)" }}
                      color="white"
                    >
                      Save {isLoading && <Spinner size={"xs"} />}
                    </Button>
                  </Flex>}
                </Flex>
              </Flex>
            </form>

            <Flex flexDirection="row" width={"100%"} gap={"10px"} px="30px" mt={15}>
              <Flex borderRadius="br.10" width={"100%"} py="25px" px="30px" background={"blackAlpha.100"} flexDirection="column" gap={5}>
                <Flex justifyContent="space-between">
                  <Text fontSize="fs.16" fontWeight="fw.600">Education</Text>
                  <IconEdit style={{ cursor: "pointer" }} onClick={onOpenEducationModal} />
                </Flex>
                <Flex flexDirection="row" gap={3} alignItems={"center"}>
                  <IconSchool size={28} stroke={2} color="gray" />
                  <Flex flexDirection={"column"} gap={"2px"}>
                    <Text fontSize="fs.18" fontWeight="fw.500" pl={1}>{user?.school?.name}</Text>
                    <Text fontSize="fs.14" fontWeight="fw.400" color="#868B94" >
                      <Flex flexDirection="row" gap={"6px"} alignItems={"center"} >
                        {user?.school?.city}, {user?.school?.country}
                      </Flex>
                    </Text>
                  </Flex>
                </Flex>

              </Flex>

            </Flex>
          </Flex>
        </Flex>
      </Box>

      {
        openProfileModal && <ProfileDialog openProfileModal={openProfileModal} onCloseProfileModal={onCloseProfileModal} user={user} />
      }
      {

        openExpModal && <ExperienceDialog openExpModal={openExpModal} onCloseExpModal={onCloseExpModal} user={user} />
      }
      {

        openEducationModal && <EducationDialog openEducationModal={openEducationModal} onCloseEducationModal={onCloseEducationModal} user={user} />
      }
    </Box >
  );
}