import { Box, Spinner, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import ReactPaginate from "react-paginate";
import { GET_PROJECTS } from "../../../apis/constants.api";
import { getMyProjectsAPI } from "../../../apis/project.api";
import ProjectListItem from "./ProjectListItem";


const ProjectTable = () => {

  const PAGE_SIZE = process.env.REACT_APP_PAGE_SIZE;
  const [page, setPage] = useState(1);


  const { data: project, isFetching: isProjectLoading, isFetching: isProjectFetching } = useQuery({
    queryKey: [GET_PROJECTS, {
      skip: PAGE_SIZE * (page - 1),
      limit: PAGE_SIZE
    }],
    queryFn: getMyProjectsAPI,
  });

  const totalPages = (project?.data?.total || 1) / PAGE_SIZE;
  const projectList = project?.data?.data || [];
  const handlePageChange = useCallback((data) => {// data.selected start from '0'
    setPage(data.selected + 1);
  }, []);
  return (<>

    <Box>
      <TableContainer
        border='1px solid rgba(234, 236, 240, 1)'
        borderRadius='0px 0px 10px 10px'
        height="calc(100vh - 375px)"
        overflowY='scroll'
        overflowX='scroll'
        css={`
              &::-webkit-scrollbar {
                  width: 0;
                  height: 0;
              }

              &::-webkit-scrollbar-thumb {
                  background-color: rgba(0, 0, 0, 0);
              }
          `}
      >
        <Box position='relative'>
          <Table variant='simple'>
            <Thead
              bgGradient='linear-gradient(0deg, #EAECF0, #EAECF0),linear-gradient(0deg, #F9FAFB, #F9FAFB)'
              textAlign='center'
              position='sticky'
              top='0px'
              zIndex='1'
            >
              <Tr>
                <Th
                  fontSize={{
                    base: 'fs.14',
                    md: 'fs.14',
                    lg: 'fs.16',
                    xl: 'fs.18',
                  }}
                  fontWeight='fw.400'
                  color=' rgba(134, 139, 148, 1)'
                  py='13px'
                  textTransform="capitalize">
                  Name</Th>
                <Th
                  fontSize={{
                    base: 'fs.14',
                    md: 'fs.14',
                    lg: 'fs.16',
                    xl: 'fs.18',
                  }}
                  fontWeight='fw.400'
                  color=' rgba(134, 139, 148, 1)'
                  py='13px'
                  textTransform="capitalize">
                  Key</Th>
                <Th
                  fontSize={{
                    base: 'fs.14',
                    md: 'fs.14',
                    lg: 'fs.16',
                    xl: 'fs.18',
                  }}
                  fontWeight='fw.400'
                  color=' rgba(134, 139, 148, 1)'
                  px='0px'
                  py='13px'
                  textTransform="capitalize">
                  Members</Th>
                <Th
                  fontSize={{
                    base: 'fs.14',
                    md: 'fs.14',
                    lg: 'fs.16',
                    xl: 'fs.18',
                  }}
                  fontWeight='fw.400'
                  color=' rgba(134, 139, 148, 1)'
                  px='0px'
                  py='13px'
                  textTransform="capitalize">
                  Icon</Th>
                <Th
                  textAlign="start"
                  fontSize={{
                    base: 'fs.14',
                    md: 'fs.14',
                    lg: 'fs.16',
                    xl: 'fs.18',
                  }}
                  fontWeight='fw.400'
                  color=' rgba(134, 139, 148, 1)'
                  py='13px'
                  textTransform="capitalize">
                  <Box>Action</Box>
                </Th>
              </Tr>
            </Thead>

            <Tbody
              bg='transparent'>
              {isProjectLoading && <Tr><Th colSpan={8} textAlign={'center'}> LOADING...</Th></Tr>}
              {projectList.map((project) => <ProjectListItem key={project._id} project={project} />)}
              {!isProjectLoading && !projectList.length && <Tr><Th colSpan={10} textAlign={'center'}>NO PROJECTS FOUND</Th></Tr>}
            </Tbody >
          </Table >
        </Box >
      </TableContainer >
    </Box>

    <Box position="relative" mt="20px">
      <ReactPaginate
        pageCount={totalPages}
        containerClassName={"paginationButtons"}
        disabledClassName={"paginationDisabled"}
        activeClassName={"paginationActive"}
        previousLabel={<span>&larr; Previous</span>}
        nextLabel={<span>Next &rarr;</span>}
        onPageChange={handlePageChange}
        forcePage={page - 1}
      />
    </Box>
  </>);
};
export default ProjectTable;