import { Avatar, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Table, TableContainer, Tag, Tbody, Td, Tr } from "@chakra-ui/react";
import React from "react";

const ManagerProfile = ({ isOpen, onClose, manager }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Profile Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column" alignItems="center" gap={4}>
            <Avatar
              size="xl"
              name={manager.name}
              src={manager.profilePicture}
            />
            <TableContainer>
              <Table variant='simple' size='sm' >
                <Tbody>
                  <Tr>
                    <Td pl="0" borderBottom="0" fontSize="fs.16" fontWeight="fw.400" color="#202B3C">Full Name:</Td>
                    <Td pl="0" borderBottom="0" fontSize="fs.16" fontWeight="fw.400" color="#868B94">{manager?.name}</Td>
                  </Tr>
                  <Tr>
                    <Td pl="0" borderBottom="0" fontSize="fs.16" fontWeight="fw.400" color="#202B3C">Email:</Td>
                    <Td pl="0" borderBottom="0" fontSize="fs.16" fontWeight="fw.400" color="#868B94">{manager?.email}</Td>
                  </Tr>
                  <Tr>
                    <Td pl="0" borderBottom="0" fontSize="fs.16" fontWeight="fw.400" color="#202B3C">Role:</Td>
                    <Td pl="0" borderBottom="0" fontSize="fs.16" fontWeight="fw.400" color="#868B94">{manager?.role}</Td>
                  </Tr>
                  <Tr>
                    <Td pl="0" borderBottom="0" fontSize="fs.16" fontWeight="fw.400" color="#202B3C">Status:</Td>
                    <Td pl="0" borderBottom="0" fontSize="fs.16" fontWeight="fw.400" color="#868B94">{manager?.status}</Td>
                  </Tr>
                  <Tr>
                    <Td pl="0" borderBottom="0" fontSize="fs.16" fontWeight="fw.400" color="#202B3C">Country:</Td>
                    <Td pl="0" borderBottom="0" fontSize="fs.16" fontWeight="fw.400" color="#868B94">{manager?.address.country}</Td>
                  </Tr>
                  <Tr>
                    <Td pl="0" borderBottom="0" fontSize="fs.16" fontWeight="fw.400" color="#202B3C">State:</Td>
                    <Td pl="0" borderBottom="0" fontSize="fs.16" fontWeight="fw.400" color="#868B94">{manager?.address.state}</Td>
                  </Tr>
                  <Tr>
                    <Td pl="0" borderBottom="0" fontSize="fs.16" fontWeight="fw.400" color="#202B3C">City:</Td>
                    <Td pl="0" borderBottom="0" fontSize="fs.16" fontWeight="fw.400" color="#868B94">{manager?.address.city}</Td>
                  </Tr>
                  {manager?.role === "Student" && (
                    <Tr>
                      <Td pl="0" borderBottom="0" fontSize="fs.16" fontWeight="fw.400" color="#202B3C">Seeking degree:</Td>
                      <Td pl="0" borderBottom="0" fontSize="fs.16" fontWeight="fw.400" color="#868B94">{manager?.seekingDegree ? manager?.seekingDegree : "--"}</Td>
                    </Tr>
                  )}
                  <Tr>
                    <Td pl="0" borderBottom="0" fontSize="fs.16" fontWeight="fw.400" color="#202B3C">Interested Careers:</Td>
                    <Td pl="0" borderBottom="0" fontSize="fs.16" fontWeight="fw.400" color="#868B94">
                      <Flex flexWrap="wrap" gap="10px">
                        {manager?.interestedCareers.length > 0 && manager?.interestedCareers?.map(skill => (

                          <Tag size={'sm'} key={skill._id} variant='outline' colorScheme='gray'>
                            {skill}
                          </Tag>
                        ))}
                      </Flex>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ManagerProfile;
