import { Controller, useForm } from "react-hook-form";
import { addComments } from "../../../apis/comments.api";
import { yupResolver } from "@hookform/resolvers/yup";
import { addCommentSchema } from "../form.schema";
import { Avatar, Box, Button, Flex, Input, Spinner, Text, Wrap, WrapItem, useDisclosure } from "@chakra-ui/react";
import { createContext, useState } from "react";
import { SlateInputField } from "../../RichTextBox/SlateInputField";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { GET_COMMENTS } from "../../../apis/constants.api";
import { faArrowDownWideShort, faArrowUpShortWide } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DeleteCommentDialog from "../DeleteCommentDialog";
import CommentList from "./CommentList";

export const BoardsContext = createContext();

const AddComments = ({ stateApp, initialValue, id }) => {
  const { control: controlComment, handleSubmit: handleSubmitComment, reset: resetComment, setValue: setValueComment, getValues: getValues } = useForm({
    defaultValues: {
      text: '',
      plainText: ""
    },
    resolver: yupResolver(addCommentSchema)
  });

  const queryClient = useQueryClient();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isfocusComment, setIsfocusComment] = useState(false);
  const [deleteData, setDeleteData] = useState("");

  const { onClose: onCloseDelete, isOpen, onOpen } = useDisclosure();

  const { mutate: addComment, isLoading } = useMutation(addComments, {
    onSuccess: data => {
      queryClient.invalidateQueries({ queryKey: [GET_COMMENTS] });
      resetComment();
      setIsfocusComment(false);
    },
  });

  const onSubmit = (values) => {
    const payload = {
      // text: JSON.stringify(values?.text),
      text: values?.text,
      taskId: id,
      plainText: values.plainText
      // plainText: values.plainText
    };
    addComment(payload);
  };


  return (<>
    <BoardsContext.Provider value={{ setIsfocusComment, setDeleteData, handleSubmitComment, onOpen, resetComment, id }}>
      <Flex flexWrap={{ base: "wrap", md: "nowrap" }}>
        <Box p="0px 25px 15px 25px">
          <Flex justifyContent="space-between" alignItems="center">
            <Text fontSize="fs.16" fontWeight="fw.500" >Comments</Text>
          </Flex>
          <Box mt="15px" width={"100%"}>
            <Flex alignItems="center" gap="10px" mt="10px">
              <Wrap>
                <WrapItem>
                  <Avatar src={stateApp.user?.profilePicture} name={stateApp.user?.name} size={'sm'} />
                </WrapItem>
              </Wrap>
              {isfocusComment ? <Controller
                name="text"
                control={controlComment}
                render={({ field }) =>
                  <SlateInputField
                    placeholder="Add a comment..."
                    initValue={initialValue}
                    textContainerStyle={{ minHeight: 35, margin: 10, padding: "0px 13px", width: "545px" }}
                    onChange={field.onChange}
                    setValue={setValueComment}
                    name={"plainText"}
                  />
                }
              /> : <Input width={"565px"} mb={"7px"} placeholder='Add a comment...' bgColor="#F7FAFC" height="40px" borderRadius="10px"
                onFocus={() => setIsfocusComment(true)} onBlur={() => setIsfocusComment(false)} />}
            </Flex>
            <Flex gap="5px">
              <Box width={"45px"}></Box>
              {isfocusComment ? <><Button onClick={handleSubmitComment(onSubmit)} fontSize={"12px"} height="27px" background="linear-gradient(134.59deg, #713EDD -1.98%, #A17DEE 111.17%)"
                _hover={{ background: "linear-gradient(134.59deg, #A17DEE -1.98% ,#713EDD 111.17%)" }} color="white" gap={4}>Save  {isLoading && <Spinner size='xs' />}</Button>
                <Button background="#EAE9EC" fontSize={"12px"} height="27px" onClick={() => setIsfocusComment(false)} >Cancel</Button></> : <></>}
            </Flex>
          </Box>
          <CommentList id={id} stateApp={stateApp} controlComment={controlComment} initialValue={initialValue} setValueComment={setValueComment} />
        </Box>
      </Flex>
      <DeleteCommentDialog isOpen={isOpen} onClose={onCloseDelete} setIsfocusComment={setIsfocusComment} comment={deleteData} />
    </BoardsContext.Provider>
  </>);
};

export default AddComments;