import { Button, ButtonGroup, FormControl, FormErrorMessage, FormLabel, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Spinner, Text, Textarea } from '@chakra-ui/react';
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import reject from "../../../src/assets/icon/reject.png";
import { GET_ALL_MEMBERS } from '../../apis/constants.api';
import { updateUserStatusAPI } from '../../apis/users.api';

export default function RejectUserModal(props) {
  const { user, onClose } = props;
  const queryClient = useQueryClient();
  const { formState: { errors }, reset, handleSubmit, register } = useForm();

  const { mutate: updateUserStatus, isLoading } = useMutation(updateUserStatusAPI, {
    onSuccess: () => {
      queryClient.invalidateQueries([GET_ALL_MEMBERS]);
      reset();
      onClose();
    }
  });

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  const onReject = async ({ reason }) => {
    try {
      await updateUserStatus({ userId: user._id, status: 'REJECTED', reason });
    } catch (error) {
      console.error(' !!! ERROR ', error);
    }
  };

  return (
    <Modal closeOnOverlayClick={false} onClose={onClose} size="lg" isOpen={true} isCentered >
      <form onSubmit={handleSubmit(onReject)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Image alt='reject' src={reject} />
          </ModalHeader>
          <ModalCloseButton border="1px solid #868B944D" />
          <ModalBody>
            <Text fontSize="fs.18" fontWeight="fw.600">{`Reject ${user?.name} Request`}</Text>
            <Text fontSize="fs.14" fontWeight="fw.400" color="#475467" mt="5px" >Are you sure you want to reject this user? Please enter rejection reason.</Text>
            <FormControl isInvalid={!!errors?.reason} mt={4}>
              <FormLabel htmlFor="reason">Reason *</FormLabel>
              <Textarea
                {...register("reason", {
                  required: "This field is required",
                })}
                errorBorderColor='red.500'
                placeholder='Enter reason for rejection here...' />
              <FormErrorMessage>
                {errors?.reason && errors?.reason?.message}
              </FormErrorMessage>
            </FormControl>
            <ButtonGroup variant='outline' spacing='5' width="100%" my="18px">
              <Button {...actionButtonChakraProps} onClick={onClose}>Cancel</Button>
              <Button  {...actionButtonChakraProps} type='submit' background={'red.500'} color="white" _hover={{ background: "red.600" }}>
                Reject
                {isLoading && <Spinner size="sm" ml={2} />}
              </Button>
            </ButtonGroup>
          </ModalBody>
        </ModalContent>
      </form>
    </Modal>
  );
}

const actionButtonChakraProps = {
  fontWeight: "fw.600",
  width: "100%",
  borderRadius: "8px",
  height: "44px",
  fontSize: "fs.16",
};