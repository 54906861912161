import { Avatar, AvatarGroup, Box, Button, Flex, GridItem, HStack, Menu, MenuButton, MenuItem, MenuList, Text, WrapItem, useDisclosure } from "@chakra-ui/react";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { memo, useContext, useState } from "react";
import { AppContext } from "../../../contexts/AppContext";
import { isAdmin } from "../../../utils/users.utils";
import ArchiveChannelDialog from "../ArchiveChannelDialog";
import ChannelsModal from "../ChannelTabDialog";

function ChannelListItem({ channel }) {
  const [stateApp] = useContext(AppContext);
  const { user } = stateApp;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isEdit, setIsEdit] = useState(false);
  const { isOpen: openArchiveModal, onOpen: onOpenArchiveModal, onClose: onCloseArchiveModal } = useDisclosure();

  return (
    <>
      <GridItem key={channel.images} padding="20px" border="1px solid #E3E4E8" borderRadius="br.10">
        <Flex alignItems="center" gap="5px" justifyContent="space-between">
          <WrapItem>
            <Avatar h="55px" width="55px" name={channel?.title} src={channel?.iconURL} sx={{ img: { objectFit: 'contain' } }} />
          </WrapItem>
          <HStack>
            {
              isAdmin(user) &&
              <Flex alignItems="center" gap="5px" justifyContent="space-between" py="8px" px="9px" background={!channel?.isArchived ? "rgba(39, 172, 45, 0.13)" : '#f0f0f0'} borderRadius="br.50">
                <Box borderRadius="br.10" height="8px" width="8px" background={!channel?.isArchived ? "#27AC2D" : "#898989"}></Box>
                <Text fontSize="fs.12" fontWeight="fw.500" color={!channel?.isArchived ? "#27AC2D" : "#898989"}>{!channel?.isArchived ? 'Active' : 'Archive'}</Text>
              </Flex>
            }
            {isAdmin(user) &&
              <Flex>
                <Menu >
                  <MenuButton width="22px" padding="0px">
                    <Button height="20px" width="20px" padding="0px" background="white" _hover={{ background: "white" }}><FontAwesomeIcon icon={faEllipsisVertical} /></Button>
                  </MenuButton>
                  <MenuList minWidth="100px"  >
                    <MenuItem height="40px" fontSize="fs.16" fontWeight="fw.500" onClick={() => { onOpen(); setIsEdit(true); }} >Edit</MenuItem>
                    <MenuItem height="40px" fontSize="fs.16" fontWeight="fw.500" onClick={() => onOpenArchiveModal()}>{channel?.isArchived ? 'Active' : 'Archive'}</MenuItem>
                  </MenuList>
                </Menu>
              </Flex>
            }
          </HStack>
        </Flex>
        <Flex mt="22px" alignItems="center" gap="15px">
          <Box>
            <Text fontSize="fs.22" fontWeight="fw.500">{channel?.name}</Text>
            <Flex alignItems="center" gap="6px">
              <Text fontSize="fs.14" fontWeight="fw.400" color="gray.600" noOfLines={3}>{channel?.description}</Text>
            </Flex>
          </Box>
        </Flex>
        <Flex justifyContent="space-between" mt="20px">
          <Box>
            <Text fontSize="fs.14" fontWeight="fw.400" color="#868B94"> Members</Text>
            <Text fontSize="fs.18" fontWeight="fw.600">{channel?.members?.length}</Text>
          </Box>
          <Box>
            <Text fontSize="fs.14" fontWeight="fw.400" color="#868B94">Region</Text>
            <Text fontSize="fs.18" fontWeight="fw.600">{channel?.region}</Text>
          </Box>
        </Flex>
        <Flex alignItems="center" justifyContent="space-between" mt="25px">
          <Flex>
            <AvatarGroup size='sm' max={5} >
              {channel?.members?.map?.((member, index) =>
                <React.Fragment key={member.joinDate + index}>
                  <Avatar key={member.user?._id} width="35px" height="35px" name={member?.user?.name} src={member?.user?.profileImage} />
                </React.Fragment>
              )}
            </AvatarGroup>
          </Flex>

        </Flex>
      </GridItem>
      <ChannelsModal isOpen={isOpen} onClose={onClose} channel={isEdit && channel} />
      {!channel?.isArchived && <ArchiveChannelDialog openArchiveModal={openArchiveModal} onCloseArchiveModal={onCloseArchiveModal} channel={channel} />}

    </>
  );
}

export default memo(ChannelListItem);