import { protectedRoute } from "../config/api.config";

export function getProducts({ queryKey }) {
  const { skip, limit, search } = queryKey[1];
  const queryString = `?skip=${skip}&limit=${limit}&search=${search}`;
  return protectedRoute.get(`/products` + queryString);
}

export function addNewProduct(payload) {
  return protectedRoute.post(`/products`, payload);
}

export function updateProduct({ productId, ...payload }) {
  return protectedRoute.put(`/products/${productId}`, payload);
}

export function deleteProduct(productId) {
  return protectedRoute.delete(`/products/${productId}`);
}

export function publishProduct(productId) {
  return protectedRoute.put(`/products/${productId}/publish`);
}

export function unPublishProduct(productId) {
  return protectedRoute.put(`/products/${productId}/unpublish`);
}