import { Box, Button, Flex, Spinner, Text, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { updateUserIdDocAPI } from "../../../apis/users.api";
import back from "../../../assets/Union 11.png";
import front from "../../../assets/front-of-id-card.png";
import { protectedRoute } from "../../../config/api.config";
import UploadDocs from "../../UploadDocs";
import { useDispatch, useSelector } from "react-redux";
import { setDocumentDetails } from "../../../store/SignupStepsSlice";

const UploadDocuments = ({ goToNext, goToPrevious }) => {
  const [error] = useState(null);
  const toast = useToast();
  const dispatch = useDispatch();
  const getDocuments = useSelector(
    (state) => state?.SignupSteps?.documentDetails
  );

  const [fileIds, setFiles] = useState({
    front: null,
    back: null,
  });

  const hiddenBackFileInput = useRef(null);

  const hiddenFileInput = useRef(null);
  const handleClick = () => {
    hiddenFileInput.current?.click();
  };
  useEffect(() => {
    if (getDocuments) {
      setFiles({
        front: {
          url: getDocuments.idFrontDoc || null,
          preview: getDocuments.idFrontDoc || null,
          status: getDocuments.idFrontDoc ? "success" : null,
        },
        back: {
          url: getDocuments.idBackDoc || null,
          preview: getDocuments.idBackDoc || null,
          status: getDocuments.idBackDoc ? "success" : null,
        },
      });
    }
  }, [getDocuments]);

  const handleImageUpload = async (event, side = "front") => {
    const { files } = event.target;
    if (!files?.length) {
      return;
    }
    const file = files[0];
    const reader = new FileReader();

    setFiles((fileIds) => ({
      ...fileIds,
      [side]: { file: file, preview: null, status: "uploading" },
    }));

    reader.onloadend = () => {
      const base64String = reader.result;
      setFiles((fileIds) => ({
        ...fileIds,
        [side]: { ...fileIds[side], preview: base64String },
      }));
    };

    if (file) {
      reader.readAsDataURL(file);
    }
    const payload = {
      fileName: file.name,
      contentType: file.type,
    };

    try {
      const response = await protectedRoute.post(
        "/files/getPreSignedURL",
        payload
      );
      if (response.status === 201) {
        const uploadImgResponse = await axios.put(response.data, file, {
          headers: {
            "Content-Type": file.type,
          },
        });

        const url = response.data.slice(0, response.data.indexOf("?"));
        if (uploadImgResponse.status === 200) {
          setFiles((fileIds) => ({
            ...fileIds,
            [side]: { ...fileIds[side], url, status: "success" },
          }));
        } else {
          setFiles((fileIds) => ({
            ...fileIds,
            [side]: { ...fileIds[side], status: "error" },
          }));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRetry = (side) => {
    const input = {
      target: {
        files: [fileIds[side].file],
      },
    };
    handleImageUpload(input, side);
  };

  const handleRemove = (side = "front") => {
    setFiles({ ...fileIds, [side]: null });
  };

  const handleBackClick = () => {
    hiddenBackFileInput.current?.click();
  };


  //submit
  const handleOnClick = async () => {
	goToNext();

    const payload = {
      idFrontDoc: fileIds?.front?.url,
      idBackDoc: fileIds?.back?.url,
    };
    dispatch(setDocumentDetails(payload));
    // await mutate(payload);
  };

  const isButtonEnabled = useMemo(() => {
    if (
      fileIds?.front?.url &&
      fileIds?.front?.status === "success" &&
      fileIds?.back?.url &&
      fileIds?.back?.status === "success"
    )
      return true;
    return false;
  }, [fileIds]);
  return (
    <>
      <Box borderRadius={{ base: "20px", md: "20px" }} bgColor="white">
        <Box px={{ base: "0", xl: "25px" }}>
          <Text
            textAlign="center"
            fontSize={{
              base: "fs.25",
              md: "fs.30",
              lg: "fs.30",
              xl: "fs.38",
            }}
            fontWeight="fw.600"
          >
            Upload Documents
          </Text>
          <Text
            textAlign="center"
            fontSize={{
              base: "fs.12",
              md: "fs.14",
              ld: "fs.16",
              xl: "fs.18",
            }}
            fontWeight="fw.400"
            pt="10px"
            pb="20px"
            color="#868B94"
          ></Text>
          <hr />
        </Box>
        <Box>
          <Text
            pt="30px"
            fontSize={{
              base: "fs.14",
              md: "fs.16",
              ld: "fs.18",
              xl: "fs.20",
            }}
            fontWeight="fw.500"
          >
            Identity Card
          </Text>
          <Flex
            flexWrap={{ base: "wrap", sm: "nowrap" }}
            justifyContent="space-between"
            pt="18px"
            gap={{ base: "10px", lg: "20px" }}
          >
            <UploadDocs
              value={fileIds.front}
              image={front}
              text={"Front-facing Image"}
              handleChange={(e) => handleImageUpload(e)}
              handleRemove={() => handleRemove()}
              handleClick={handleClick}
              handleRetry={() => handleRetry("front")}
              hiddenFileInput={hiddenFileInput}
            />

            <UploadDocs
              value={fileIds.back}
              image={back}
              text={"Back-facing Image"}
              handleChange={(e) => handleImageUpload(e, "back")}
              handleRemove={() => handleRemove("back")}
              handleClick={handleBackClick}
              handleRetry={() => handleRetry("back")}
              hiddenFileInput={hiddenBackFileInput}
            />
          </Flex>
        </Box>
        <Box mt={{ base: "20px", md: "20px", lg: "30px", xl: "40px" }}>
          <Text fontSize={14} fontWeight="fw.400" color="#858585">
            * Make sure the area where you are taking the picture is well-lit.{" "}
          </Text>
          <Text fontSize={14} fontWeight="fw.400" color="#858585">
            * Choose a plain, neutral background for the picture.{" "}
          </Text>
          <Text fontSize={14} fontWeight="fw.400" color="#858585">
            * Place the ID card on a flat surface and make sure it is not bent
            or folded.
          </Text>
        </Box>
        <Flex
          mt={{ base: "30px", md: "50px", lg: "30px", xl: "30px" }}
          className="gap-3"
        >
          <Button
            fontSize={{
              base: "fs.12",
              md: "fs.14",
              lg: "fs.16",
              xl: "fs.18",
            }}
            fontWeight="fw.500"
            height={{ base: "40px", lg: "50px", "2xl": "55px" }}
            width="50%"
            borderRadius="10px"
            onClick={(e) => goToPrevious(e)}
            backgroundColor={"#F6F2FD"}
            border={`1.5px solid #713EDD`}
            // isDisabled={isLoading}
          >
            Back
          </Button>
          <Button
            isDisabled={!isButtonEnabled}
            onClick={handleOnClick}
            type="submit"
            color="white"
            background="linear-gradient(150.62deg, #713EDD 5.97%, #A17DEE 64.37%)"
            _hover={{ animation: "changeBg 0.5s ease infinite" }}
            width="50%"
            height={{ base: "40px", lg: "50px", "2xl": "55px" }}
            fontSize={{
              base: "fs.12",
              md: "fs.14",
              ld: "fs.16",
              xl: "fs.18",
            }}
            fontWeight="fw.500"
            borderRadius="10px"
            gap={5}
          >
            Next
            {/* {isLoading && <Spinner />} */}
          </Button>
        </Flex>
        {error && (
          <Text
            textAlign={"center"}
            fontSize={{
              base: "fs.12",
              md: "fs.14",
              ld: "fs.16",
              xl: "fs.18",
            }}
            fontWeight="fw.400"
            color="#E1251A"
          >
            {error}
          </Text>
        )}
      </Box>
    </>
  );
};
export default UploadDocuments;
