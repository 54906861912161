import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
// import { loadTranslation } from "../../utilities/language"
import SliderComponent from "../../Slider/index";
import BackButton from "../../BackButton/index";
import { MdDone } from 'react-icons/md';

const ResetEmail = props => {
    return (
        <>
            <Flex
                flexWrap={{ base: "wrap", lg: "nowrap" }}
                flexDirection={{ base: "column", lg: "unset" }}
                minHeight={{ base: "100%", "2xl": "100%" }}
            >
                <SliderComponent />
                <Flex
                    background={{
                        sm: "linear-gradient(148.84deg, #713EDD 12.15%, #A17DEE 79.83%)",
                        xl: "white"
                    }}
                    position={{ lg: "absolute", xl: "unset" }}
                    py={{ base: "0px", md: "30px", lg: "25px", xl: "0" }}
                    pl={{ base: "0px", md: "30px", lg: "25px", xl: "0" }}
                    pr={{ base: "0px", md: "30px", lg: "25px", xl: "0" }}
                    alignItems="center"
                    width={{ base: "100%", xl: "35%" }}
                    height={{ base: "100vh", lg: "100%", xl: "auto" }}
                    justifyContent={{ base: "space-between", xl: "end" }}
                >
                    <Flex
                        overflow="hidden"
                        padding={{ lg: "20px" }}
                        height={{ base: "100%", md: "auto", lg: "100%", xl: "auto" }}
                        width={{ base: "100%", xl: "50%" }}
                        alignItems="center"
                        position={{ base: "unset", xl: "absolute" }}
                        right="0"
                        justifyContent={{ base: "center", lg: "end", xl: "space-between" }}
                    >
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            padding={{ base: "25px", md: "25px", "2xl": "50px" }}
                            boxShadow={{ base: "0", md: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
                            width={{ base: "500px", lg: "550px", xl: "70%" }}
                            height={{ base: "100%", md: "calc(100dvh - 100px)" }}
                            borderRadius={{ base: "20px", md: "20px" }}
                            bgColor="white"
                        >
                            <Box padding={{ base: "0", xl: "30px" }}>
                                <Flex justifyContent="center">
                                    <Flex
                                        justifyContent="center"
                                        alignItems="center"
                                        p="20px"
                                        width="100px"
                                        height="100px"
                                        background="#24f424"
                                        borderRadius="50px"
                                    >
                                        <MdDone size={50} color="white" />
                                    </Flex>
                                </Flex>
                                <Text
                                    textAlign="center"
                                    fontSize={{
                                        base: "fs.25",
                                        md: "fs.30",
                                        lg: "fs.35",
                                        xl: "fs.43"
                                    }}
                                    fontWeight="fw.500"
                                >
                                    Reset Email has been Sent
                                </Text>
                                <Text
                                    textAlign="center"
                                    fontSize={{
                                        base: "fs.12",
                                        md: "fs.14",
                                        ld: "fs.16",
                                        xl: "fs.18"
                                    }}
                                    pt="10px"
                                    pb="20px"
                                    color="darkgray"
                                >


                                </Text>
                            </Box>
                        </Box>
                        {/* <BackButton /> */}
                    </Flex>
                </Flex>
            </Flex>
        </>
    );
};
export default ResetEmail;
