import { Button, ButtonGroup, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Spinner, Text } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import reject from "../../../src/assets/icon/reject.png";

export default function DeactivateModal(props) {
  const { data, onClose, onSubmit, isDeactivate } = props;
  const { handleSubmit } = useForm();


  return (
    <Modal closeOnOverlayClick={false} onClose={onClose} size="lg" isOpen={true} isCentered >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Image alt='reject' src={reject} />
          </ModalHeader>
          <ModalCloseButton border="1px solid #868B944D" />
          <ModalBody>
            <Text fontSize="fs.18" fontWeight="fw.600">{`Deactivate ${data?.name} Request`}</Text>
            <Text fontSize="fs.14" fontWeight="fw.400" color="#475467" mt="5px" >Are you sure you want to deactivate this user?</Text>
            <ButtonGroup variant='outline' spacing='5' width="100%" my="18px">
              <Button {...actionButtonChakraProps} onClick={onClose}>Cancel</Button>
              <Button  {...actionButtonChakraProps} type='submit' background={'red.500'} color="white" _hover={{ background: "red.600" }}>
                Deactivate
                {isDeactivate && <Spinner size="sm" ml={2} />}
              </Button>
            </ButtonGroup>
          </ModalBody>
        </ModalContent>
      </form>
    </Modal>
  );
}

const actionButtonChakraProps = {
  fontWeight: "fw.600",
  width: "100%",
  borderRadius: "8px",
  height: "44px",
  fontSize: "fs.16",
};