import { Box, Flex, Image, Tag, Text, Tooltip, VStack } from '@chakra-ui/react';
import React, { useState } from 'react';
import { preSignedUrl } from '../../../apis/ebook.api';
import ErrorImage from '../../../assets/no_image_2.jpeg';

const EBookCard = ({ book }) => {
  const { title, pdfUrl, thumbnailUrl, relevantSkills } = book;
  const [pdfUrlNew, setPdfUrlNew] = useState('');

  const handleViewPDF = async () => {
    const response = await preSignedUrl(pdfUrl);
    setPdfUrlNew(response.data || '');
    localStorage.setItem('url', response.data);
    window.open(`/ebook-view`, '_blank');
  };

  return (
    <Box background="white" borderWidth="1px" borderRadius="20px" overflow="hidden" boxShadow="md" width="100%" display="flex" flexFlow="column" justifyContent="space-between">
      <Box
        width="100%"
        height="260px"
        borderRadius="6px"
        background="#fff"
        position="relative"
        cursor="pointer"
        onClick={handleViewPDF}
      >
        <Image
          src={`${process.env.REACT_APP_THUMBNAIL_URL}${thumbnailUrl}`}
          fallbackSrc={ErrorImage}
          alt={title}
          objectFit="cover"
          objectPosition={"top"}
          height="100%"
          width="100%"
          borderRadius="6px"
          aspectRatio="16 / 10"
        />
      </Box>
      <VStack gap={2} p={3} align={'stretch'}>
        <Box>
          <Tooltip label={title}>
            <Text fontWeight="semibold" fontSize="lg" mb="2" overflow="hidden" textOverflow="ellipsis" className='ellipsis'>
              {title}
            </Text>
          </Tooltip>
        </Box>
        <Flex gap={2} wrap={'wrap'}>
          {relevantSkills.map((skill, i) => (
            <Tag size="sm" key={i} variant='outline' borderRadius='full'>
              {skill.name}
            </Tag>
          ))}
        </Flex>
      </VStack>
    </Box>
  );
};

export default EBookCard;
