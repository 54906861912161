import { Box, Button, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Text, forwardRef, useDisclosure } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useImperativeHandle } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from 'yup';
import { GET_ALL_MANAGERS } from "../../apis/constants.api";
import { addUserManagerAPI } from "../../apis/users.api";
import PasswordField from "../PasswordField/PasswordField";

const schema = yup
  .object({
    name: yup.string().required(),
    email: yup.string().required(),
    password: yup.string().required(),
  })
  .required();

const AddNewManager = forwardRef(function AddNewManager(props, ref) {
  const queryClient = useQueryClient();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      name: '',
      email: '',
      password: ''
    },
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  useImperativeHandle(ref, () => {
    return {
      openDialog() {
        onOpen();
      },
      closeDialog() {
        onClose();
      }
    };
  }, [onClose, onOpen]);

  const onSubmit = async data => {
    const payload = {
      name: data?.name,
      email: data?.email,
      password: data?.password,
    };

    mutate(payload);
  };

  const { mutate, isLoading } = useMutation(addUserManagerAPI, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_ALL_MANAGERS] });
      onClose();
    }
  });

  return (
    <Modal closeOnOverlayClick={false} onClose={onClose} isOpen={isOpen} isCentered >
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <ModalOverlay />
        <ModalContent maxWidth="599px" borderRadius="10px">
          <ModalHeader fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16", xl: "fs.18" }} fontWeight="fw.600">Add Manager</ModalHeader>
          <ModalCloseButton border="1px solid #868B944D" />
          <ModalBody pt="4px" pb="30px">
            <Box>
              <Box mt="30px">
                <Text fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} fontWeight="fw.500">Name</Text>
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) =>
                    <Input {...field} placeholder="Enter your name" height="50px" _hover={{ borderColor: "#713EDD" }} background="#F7FAFC" mt="8px" _focusVisible={{ borderColor: "#713EDD" }} />
                  }
                />
              </Box>
              <Box mt="30px">
                <Text fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} fontWeight="fw.500">Email</Text>
                <Controller
                  name="email"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) =>
                    <Input {...field} _autofill={''} placeholder="Enter email" height="50px" _hover={{ borderColor: "#713EDD" }} background="#F7FAFC" mt="8px" _focusVisible={{ borderColor: "#713EDD" }} />
                  }
                />
              </Box>
              <Box mt="30px">
                <Text fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} fontWeight="fw.500">Password</Text>
                <Controller
                  name="password"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) =>
                    <PasswordField autoComplete={"new-password"} placeholder={`*******`} register={field} _hover={{ borderColor: "#713EDD" }} _focusVisible={{ borderColor: "#713EDD" }} />
                  }
                />
              </Box>
            </Box>
          </ModalBody>
          <ModalFooter gap="20px" background="#F5F4F6" borderRadius="0px 0px 10px 10px">
            <Button background="#EAE9EC" onClick={onClose} width="83px" height="40px" fontSize="fs.12" fontWeight="fw.500" color="#202B3C">Cancel</Button>
            <Button
              type="submit"
              background="#713EDD"
              width="83px"
              height="40px"
              fontSize="fs.12"
              fontWeight="fw.500"
              color="white"
              _hover={{ border: "1px solid #713EDD", background: "#F6F2FD", color: "#713EDD" }}>
              Add {isLoading && <Spinner />}
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
});

export default AddNewManager;