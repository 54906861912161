import { useContext, useMemo } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AppContext } from "../../contexts/AppContext";
import { isAdmin } from "../../utils/users.utils";
import { authRoutes, onboardingRoutes, screeningRoutes } from "./utils";

const ProtectedRoute = ({ component: Component, layout: Layout }) => {
  const location = useLocation();
  const [stateApp] = useContext(AppContext);
  const { user, auth, isUserLoading } = stateApp;

  const redirectTo = useMemo(() => {
    let redirectTo = null;
    if (!isUserLoading) {
      if (!auth?.token) {
        redirectTo = '/signin';
      } else {
        if (isAdmin(user)) {
          if ([...onboardingRoutes, ...authRoutes, ...screeningRoutes].includes(location.pathname))
            redirectTo = '/';
        } else {
          if (!user?.isOnboardingComplete) {
            redirectTo = '/user-signup-steps';
          } else {
            if (user.status !== "ACCEPTED") {
              if (user?.status === 'REJECTED')
                redirectTo = '/screening/rejected';
              else if (user?.status === 'DEACTIVATED')
                redirectTo = '/screening/deactivated';
              else
                redirectTo = '/screening/progress';
            }
          }
        }
      }
    }

    return redirectTo;
  }, [user, auth, isUserLoading, location.pathname]);

  const renderComponent = () => {
    if (Layout) {
      return (
        <Layout>
          <Component />
        </Layout>);
    }

    return <Component />;
  };

  return (
    !redirectTo || (redirectTo === location.pathname) ? renderComponent() : <Navigate to={redirectTo} replace />
  );
};

export default ProtectedRoute;