import { protectedRoute } from "../config/api.config";


// export function addNewChannels(payload) {
//   return protectedRoute.post('/channels/create', payload);
// }

export function getAllChannels({ queryKey }) {
  const { skip, limit, includeArchive } = queryKey[1];
  const queryString = `?skip=${skip}&limit=${limit}&includeArchive=${includeArchive}`;
  return protectedRoute.get('/channels/list-all-channels' + queryString);
}

export function getMyChannels({ queryKey }) {
  const [key, data] = queryKey;
  return protectedRoute.get('/channels/get-my-channels', { params: data });
}

export function archiveChannelAPI(channelId) {
  return protectedRoute.put(`/channels/${channelId}/archive-channel`);
}


export function addMemberToChannel(data) {
  return protectedRoute.put(`/channels/${data.channelId}/add-member`, { userId: data.userId });
}


export function updateChannels({ channelId, ...payload }) {
  return protectedRoute.put(`/channels/${channelId}`, payload);
}




