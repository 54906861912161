import {
  useQuery,
} from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import LogRocket from 'logrocket';
import { createContext, useEffect, useState } from "react";
import { GET_MY_QUOTES, GET_NOTIFICATIONS, GET_UNREAD_NOTIFICATION_COUNT, GET_USER_DATA } from '../apis/constants.api';
import { getNotificationsAPI, getUnreadNotificationCountAPI } from '../apis/notifications.api';
import { getMyQuotes, getUserDataAPI } from '../apis/users.api';
import FullScreenLoader from '../component/shared/FullScreenLoader';
import useTimer from '../hooks/useTimer.hook';
import { isUserAccepted } from '../utils/users.utils';

export const AppContext = createContext(null);

const defaultState = {
  user: null, // store user information(e.g, name, profilePhoto, address, phone, etc.)
  auth: null, // Store authentication information(e.g, auth token, reset token)
  isUserLoading: true,
  errors: false,
  notifications: [],
  unReadNotificationCount: 0,
  myQuotes: [],
};

export default function AppContextProvider({ children }) {
  const currentUrl = new URL(window.location.href);
  const isTimedOut = useTimer(2);
  const apiToken = localStorage.getItem('@apiToken');
  const [stateApp, setStateApp] = useState(defaultState);

  const { data, isLoading, isFetching, isError } = useQuery({
    queryKey: [GET_USER_DATA],
    queryFn: getUserDataAPI,
    enabled: !!apiToken || !!(stateApp.auth?.token),
  });

  useQuery({
    queryKey: [GET_NOTIFICATIONS],
    queryFn: getNotificationsAPI,
    enabled: isUserAccepted(stateApp?.user),
    onSuccess: (data) => {
      setStateApp(prevState => ({ ...prevState, notifications: data.data.data }));
    },
  });

  useQuery({
    queryKey: [GET_UNREAD_NOTIFICATION_COUNT],
    queryFn: getUnreadNotificationCountAPI,
    enabled: isUserAccepted(stateApp?.user),
    onSuccess: (data) => {
      setStateApp(prevState => ({ ...prevState, unReadNotificationCount: data.data.count }));
    }
  });

  useQuery({
    queryKey: [GET_MY_QUOTES],
    queryFn: getMyQuotes,
    enabled: isUserAccepted(stateApp?.user),
    onSuccess: (data) => {
      setStateApp(prevState => ({ ...prevState, myQuotes: data.data.todayQuote }));
    }
  });

  useEffect(() => {
    if (isFetching) {
      setStateApp(state => ({ ...state, isUserLoading: true }));
    } else {
      setStateApp(state => ({
        ...state,
        isUserLoading: false,
        auth: { token: !isError && apiToken },
        user: data?.data?.data,
        errors: isError
      }));
      if (isError) {
        localStorage.removeItem("@apiToken");
      }
    }
  }, [data, isLoading, isError, isFetching, apiToken]);

  useEffect(() => {
    if (!isEmpty(stateApp?.user)) {
      LogRocket.identify(stateApp.user?._id, {
        name: stateApp?.user?.name,
        email: stateApp?.user?.email,
        profilePictureUrl: stateApp?.user?.profilePicture,
        // Add your own custom user variables here, ie:
      });
    }
  }, [stateApp?.user]);

  return (
    <AppContext.Provider value={[stateApp, setStateApp]}>
      {(isFetching && currentUrl.pathname !== "/profile" || !isTimedOut) && <FullScreenLoader isLoading={isLoading} isFetching={isFetching} />}
      <div className={`page-content`}>
        {children}
      </div>
    </AppContext.Provider>
  );
}