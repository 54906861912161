import {
  Box, Button, Flex, FormControl, FormLabel, Input, Menu, Modal, ModalBody,
  ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Text,
  useToast,
} from "@chakra-ui/react";
import isEmpty from "lodash/isEmpty";
import { Controller, useForm } from "react-hook-form";
import Style from '../style.module.css';
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";

import ReactSelect from "react-select";
import { convertArrayToOptionLabels } from "../../utils/object.utils";
import { GET_CITIES, GET_COUNTRIES, GET_USER_DATA } from "../../apis/constants.api";
import { getCitiesAPI2, getCountriesAPI } from "../../apis/common.api";
import { updateProfileAPI } from "../../apis/users.api";
import { educationSchema } from "./form.schema";

const EducationDialog = ({ openEducationModal, user, onCloseEducationModal }) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const { control, handleSubmit, formState: { errors }, watch, setValue, register, reset } = useForm({
    defaultValues: {
      name: user?.school.name,
      country: null,
      city: null,

    },
    resolver: yupResolver(educationSchema)

  });

  const { data: countries, isFetching: isCountriesLoading } = useQuery({
    queryKey: [GET_COUNTRIES],
    queryFn: getCountriesAPI
  });

  const countriesOptions = useMemo(() => convertArrayToOptionLabels(countries?.data || [], 'name', 'isoCode'), [countries?.data]);
  const selectedCountry = watch('country') || countriesOptions.find((e) => e.label === user?.school?.country);
  const selectedCity = watch('city');

  const { data: cities, isFetching: isCitiesLoading } = useQuery({
    queryKey: [GET_CITIES, (selectedCountry?.value || '')],
    queryFn: getCitiesAPI2,
    enabled: !!selectedCountry
  });

  const citiesOptions = useMemo(() => convertArrayToOptionLabels(cities?.data || [], 'name', 'name'), [cities?.data]);
  const defaultCity = citiesOptions.find((e) => String(e.label) === String(user?.school?.city));

  useEffect(() => {
    if (selectedCountry) {
      setValue('city', selectedCity || '');
    }
  }, [selectedCountry]);

  const { mutate, isLoading } = useMutation(updateProfileAPI, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_USER_DATA] });
      onCloseEducationModal();
      reset();
      toast({
        title: "Profile updated.",
        description: "Your Education has been successfully updated.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    },
    onError: (error) => {
      toast({
        title: "An error occurred.",
        description: "Unable to update Education. Please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  });

  useEffect(() => {
    if (countriesOptions.length && user) {
      reset({
        name: user.school.name,
        city: citiesOptions.find((e) => e.label === user?.school?.city) || null,
        country: countriesOptions.find((e) => e.label === user?.school?.country) || null,
      });
    }
  }, [countriesOptions, openEducationModal, user, reset]);

  const onSubmit = async (data) => {
    const payload = {
      school: {
        name: data.name,
        country: data.country.label,
        city: data.city?.label,
      }
    };
    await mutate(payload);

  };
  return (<>
    <Modal isOpen={openEducationModal} onClose={onCloseEducationModal} isCentered size="xl">
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Education</ModalHeader>
          <ModalCloseButton border="1px solid #868B944D" />
          <ModalBody>
            <FormControl>
              <FormLabel
                fontSize={{
                  base: "fs.12",
                  md: "fs.14",
                  ld: "fs.16",
                  xl: "fs.18"
                }}
                fontWeight="fw.500"
              >
                School Name
              </FormLabel>
              <Input
                isDisabled={isLoading}
                {...register("name", { required: true })}
                focusBorderColor="#713EDD"
                border="1.5px solid rgba(210, 210, 210, 0.5)"
                placeholder={`Enter your School name`}
                type="text"
                fontSize={{
                  base: "fs.12",
                  md: "fs.14",
                  ld: "fs.16",
                  xl: "fs.18"
                }}
                borderRadius="10px"
                height={{ base: "40px", lg: "50px", "2xl": "55px" }}
              />
              {errors.name && (
                <Text color="#E1251A">
                  School name is required
                </Text>
              )}
              <Box>
                <Text
                  pt="25px"
                  fontSize={{
                    base: "fs.12",
                    md: "fs.14",
                    ld: "fs.16",
                    xl: "fs.18"
                  }}
                  fontWeight="fw.500"
                >
                  Country
                </Text>
                <Menu>
                  <Controller
                    name="country"
                    control={control}
                    render={({ field }) => (
                      <ReactSelect
                        {...register("country", {
                          validate: value =>
                            value !== "" || `Please select your country.`
                        })}
                        {...field}
                        isLoading={isCountriesLoading}
                        placeholder={`Select your country`}
                        options={countriesOptions}
                        defaultValue={countriesOptions?.find((e) => e.label === user?.school?.country)}
                        // styles={styles}
                        isDisabled={isLoading}
                        className={Style.controller_select}
                      />
                    )}
                  />
                  {errors.country && (
                    <Text color="#E1251A">
                      Please select your country.
                    </Text>
                  )}
                </Menu>
              </Box>
              <Box>
                <Text
                  pt="25px"
                  fontSize={{
                    base: "fs.12",
                    md: "fs.14",
                    ld: "fs.16",
                    xl: "fs.18"
                  }}
                  fontWeight="fw.500"
                >
                  City
                </Text>
                <Menu>
                  <Controller
                    {...register("city", { required: true })}
                    name="city"
                    control={control}
                    render={({ field }) => (
                      <ReactSelect
                        isDisabled={
                          isEmpty(watch("country"))
                        }
                        {...field}
                        placeholder="Select your city"
                        options={citiesOptions}
                        isLoading={isCitiesLoading}
                        value={defaultCity || null}
                        // defaultValue={defaultCity || null}
                        className={Style.controller_select}
                      />
                    )}
                  />
                  {errors.city && (
                    <Text color="#E1251A">
                      Please select your city.
                    </Text>
                  )}
                </Menu>
              </Box>
            </FormControl>
          </ModalBody>
          <ModalFooter background="#F5F4F6" borderRadius=" 0px 0px 10px 10px">
            <Flex gap="10px">
              <Button background="#EAE9EC" onClick={onCloseEducationModal}>Cancel</Button>
              <Button type="submit" px={{ md: "15px", lg: "30px" }} py="8px" background="linear-gradient(134.59deg, #713EDD -1.98%, #A17DEE 111.17%)"
                _hover={{ background: "linear-gradient(134.59deg, #A17DEE -1.98% ,#713EDD 111.17%)" }} gap={5} color="white">Update {isLoading && <Spinner />}</Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal >

  </>);
};

export default EducationDialog;