import { Box, Button, ButtonGroup, Flex, Spinner } from "@chakra-ui/react";
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useContext } from 'react';
import { updateComments } from '../../../apis/comments.api';
import { GET_COMMENTS } from '../../../apis/constants.api';
import { BoardsContext } from './index';

const UpdateCommentActions = ({ e, setIsEditId, isEditId, isFetching }) => {
  const queryClient = useQueryClient();
  const boardsContextActions = useContext(BoardsContext);

  const { mutate: updateComment } = useMutation(updateComments, {
    onSuccess: data => {
      queryClient.invalidateQueries({ queryKey: [GET_COMMENTS] });
      boardsContextActions.resetComment();
      boardsContextActions.setIsfocusComment(false);
      setIsEditId("");
    },
  });

  const onSubmitUpdate = (values) => {
    const payload = {
      text: values?.text,
      taskId: boardsContextActions.id,
      plainText: values?.plainText
    };
    updateComment({ commentId: isEditId, ...payload });
  };

  return (
    <Flex gap="5px">
      <Box width={"45px"}></Box>
      {(isEditId !== e._id) ? <>
        <ButtonGroup size='sm' marginLeft={"-10px"} >
          <Button background={"white"} _hover={{ textDecoration: 'underline' }} padding={"0px"} onClick={() => setIsEditId(e._id)} > Edit</Button>
          <Button background={"white"} _hover={{ textDecoration: 'underline' }} padding={"0px"} onClick={() => { boardsContextActions.onOpen(); boardsContextActions.setDeleteData(e); }}>Delete</Button>
        </ButtonGroup>
      </> : <><Button onClick={boardsContextActions.handleSubmitComment(onSubmitUpdate)} fontSize={"12px"} height="27px" gap={4}
        background="linear-gradient(134.59deg, #713EDD -1.98%, #A17DEE 111.17%)"

        _hover={{ background: "linear-gradient(134.59deg, #A17DEE -1.98% ,#713EDD 111.17%)" }} color="white">Save {isFetching && <Spinner size={'xs'} />}</Button>
        <Button background="#EAE9EC" fontSize={"12px"} height="27px" onClick={() => setIsEditId("")}>Cancel</Button>
      </>}
    </Flex>
  );
};

export default UpdateCommentActions;