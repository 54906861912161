import { useQuery } from '@tanstack/react-query';
import { uniqBy } from 'lodash';
import React, { useCallback, useState } from 'react';
import Select from 'react-select';
import useDebounce from '../../../hooks/useDebounce.hook';

const PAGE_SIZE = process.env.REACT_APP_PAGE_SIZE || 25;
const InfiniteSelect = (props) => {
  const { width, useKey, onChange, selectProps, value, queryKey, queryFn, onSuccess } = props;

  if (['value', 'onChange', 'queryKey', 'queryFn', 'onSuccess'].some(key => !props.hasOwnProperty(key))) {
    throw new Error('missing-required-props');
  }

  const [inputValue, setInputValue] = useState('');
  const [page, setPage] = useState(1);
  const [options, setOptions] = useState([]);
  const debouncedSearch = useDebounce(inputValue);

  const { data,
    isFetching,
    isLoading,
  } = useQuery({
    queryKey: [queryKey, { skip: (page - 1) * PAGE_SIZE, limit: PAGE_SIZE, search: debouncedSearch }],
    queryFn,
    onSuccess: (data) => onSuccess(data, concatOptions)
  });

  const concatOptions = useCallback((newOps) => {
    setOptions((oldOps) => uniqBy([...oldOps, ...newOps], 'value'));
  }, [setOptions]);

  const handleInputChange = (newValue) => {
    setInputValue(newValue);
  };

  const handleMenuScrollToBottom = () => {
    if (!isFetching && options.length < useKey === "project" ? data?.data?.total : data?.data?.totalSkills) {
      setPage(page + 1);
    }
  };

  return (
    <Select
      {...selectProps}
      styles={{
        control: (base) => ({
          ...base,
          minHeight: '50px',
          minWidth: width,
        })
      }}
      value={value}
      options={options}
      onInputChange={handleInputChange}
      onChange={onChange}
      isLoading={isLoading || isFetching}
      onMenuScrollToBottom={handleMenuScrollToBottom}
    />
  );
};

export default InfiniteSelect;