import React, { useState } from "react";
// import { loadTranslation } from "../src/app/utilities/language"
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Link,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { Link as ReactLink } from "react-router-dom";
import { resetPassword } from "../../../apis/auth.api";
import SliderComponent from "../../Slider/index";
import Style from "../../style.module.css";
import { resetPasswordSchema } from "./formSchema";

const RecoverPassword = (props) => {
  const toast = useToast();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [email, setEmail] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(resetPasswordSchema),
  });

  const { mutate, isLoading } = useMutation(resetPassword, {
    onSuccess: (data) => {
      setFormSubmitted(true);
      toast({
        title: "Success",
        description:
          "Reset password mail has been sent. Please check your inbox.",
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    },
    onError: (error) => {
      let errorMessage;

      if (error?.response?.data?.message) {
        errorMessage = error.response.data.message;
      }

      toast({
        title: "Error",
        description: errorMessage,
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    },
  });

  const onSubmit = (data) => {
    setEmail(data.email);
    const payload = {
      email: data.email,
    };
    mutate(payload);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit, (error) => console.log(error))}>
        <Flex
          flexWrap={{ base: "wrap", lg: "nowrap" }}
          flexDirection={{ base: "column", lg: "unset" }}
        >
          <SliderComponent />
          <Flex
            background={{
              sm: "linear-gradient(148.84deg, #713EDD 12.15%, #A17DEE 79.83%)",
              xl: "white",
            }}
            position={{ lg: "absolute", xl: "unset" }}
            py={{ base: "0px", md: "30px", lg: "25px", xl: "0" }}
            pl={{ base: "0px", md: "30px", lg: "25px", xl: "0" }}
            pr={{ base: "0px", md: "30px", lg: "25px", xl: "0" }}
            alignItems="center"
            width={{ base: "100%", xl: "35%" }}
            minHeight={{ sm: "100vh", xl: "auto" }}
            justifyContent={{ base: "space-between", xl: "end" }}
          >
            <Flex
              overflow="hidden"
              height={{ base: "100%", md: "auto", lg: "auto", xl: "auto" }}
              padding={{ lg: "20px" }}
              ml="auto"
              width={{ base: "100%", md: "100%", lg: "50%", "2xl": "50%" }}
              alignItems="center"
              position={{ base: "unset", xl: "absolute" }}
              right="0"
              justifyContent={{
                base: "center",
                lg: "end",
                xl: "space-between",
              }}
            >
              <Box
                className={Style.overflow}
                padding={{ base: "25px", md: "25px", "2xl": "50px" }}
                boxShadow={{
                  base: "0",
                  md: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                }}
                width={{ base: "500px", sm: "550px", xl: "80%" }}
                borderRadius={{ base: "20px", md: "20px" }}
                bgColor="white"
              >
               
                {formSubmitted ? (
                  <Box px={{ base: "10px", xl: "20px" }}>
                    <Text
                      textAlign="center"
                      fontSize={{
                        base: "fs.25",
                        md: "fs.30",
                        lg: "fs.30",
                        xl: "fs.38",
                      }}
                      fontWeight="fw.600"
                    >
                      Reset your Password
                    </Text>
                    <Text
                      textAlign="center"
                      fontSize={{
                        base: "fs.12",
                        md: "fs.14",
                        lg: "fs.16",
                        xl: "fs.18",
                      }}
                      fontWeight="fw.400"
                      pt="10px"
                      pb="20px"
                    >
                      We sent a reset password email to {email}. Please click
                      the reset password link to set your new password.
                    </Text>
                    <hr />
                    <Text
                      textAlign="center"
                      fontSize={{
                        base: "fs.12",
                        md: "fs.14",
                        lg: "fs.16",
                        xl: "fs.18",
                      }}
                      fontWeight="fw.400"
                      pt="10px"
                      pb="10px"
                      color="#868B94"
                    >
                      Haven't received the email?
                    </Text>
                    <Text
                      textAlign="center"
                      fontSize={{
                        base: "fs.12",
                        md: "fs.14",
                        lg: "fs.16",
                        xl: "fs.18",
                      }}
                      fontWeight="fw.400"
                      pt="3px"
                      pb="10px"
                      color="#868B94"
                    >
                      Please check your spam folder, or?{" "}
                      <Link
                        color="blue.500"
                        onClick={() => setFormSubmitted(false)}
                      >
                        Try again
                      </Link>
                      .
                    </Text>
                  </Box>
                ) : (
                  <>
                    <Box px={{ base: "10px", xl: "20px" }}>
                      <Text
                        textAlign="center"
                        fontSize={{
                          base: "fs.25",
                          md: "fs.30",
                          lg: "fs.30",
                          xl: "fs.38",
                        }}
                        fontWeight="fw.600"
                      >
                        Recover your Password
                      </Text>
                      <Text
                        textAlign="center"
                        fontSize={{
                          base: "fs.12",
                          md: "fs.14",
                          lg: "fs.16",
                          xl: "fs.18",
                        }}
                        fontWeight="fw.400"
                        pt="10px"
                        pb="20px"
                        color="#868B94"
                      ></Text>
                      <hr />
                    </Box>
                    <FormControl mt={{ base: "50px", "2xl": "80px" }}>
                      <FormLabel
                        fontSize={{
                          base: "fs.12",
                          md: "fs.14",
                          lg: "fs.16",
                          xl: "fs.18",
                        }}
                        fontWeight="fw.500"
                      >
                        Email
                      </FormLabel>
                      <Input
                        {...register("email")}
                        isDisabled={isLoading}
                        focusBorderColor="#713EDD"
                        border="1.5px solid rgba(210, 210, 210, 0.5)"
                        placeholder={`Enter your Email`}
                        type="email"
                        fontSize={{
                          base: "fs.12",
                          md: "fs.14",
                          lg: "fs.16",
                          xl: "fs.16",
                        }}
                        fontWeight="fw.400"
                        borderRadius="10px"
                        height={{ base: "40px", lg: "50px", "2xl": "55px" }}
                      />
                      {errors.email && (
                        <Text fontSize={"12px"} mt={1} color="#E1251A">
                          {errors.email.message}
                        </Text>
                      )}
                    </FormControl>
                    <Box
                      mt={{ base: "20px", md: "20px", lg: "20px", xl: "25px" }}
                    >
                      <Button
                        isDisabled={isLoading}
                        color="white"
                        background="linear-gradient(150.62deg, #713EDD 5.97%, #A17DEE 64.37%)"
                        _hover={{ animation: "changeBg 0.5s ease infinite" }}
                        width="100%"
                        height={{ base: "40px", lg: "50px", "2xl": "50px" }}
                        fontSize={{
                          base: "fs.14",
                          md: "fs.16",
                          lg: "fs.18",
                          xl: "fs.20",
                        }}
                        fontWeight="fw.500"
                        borderRadius="10px"
                        type="submit"
                      >
                        Recover
                        {isLoading && <Spinner ml={1} />}
                      </Button>
                    </Box>
                    <Box mt={4} textAlign={"center"}>
                      <Link as={ReactLink} to="/signin">
                        Sign In
                      </Link>
                    </Box>
                  </>
                )}
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </form>
    </>
  );
};
export default RecoverPassword;
