import React, { useContext, useEffect, useMemo } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Menu,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import Style from "../../style.module.css";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import Select from "react-select";
import isEmpty from "lodash/isEmpty";
import { updateSchoolInfoAPI } from "../../../apis/users.api";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getCountriesAPI,
  GET_COUNTRIES,
  GET_CITIES,
  getCitiesAPI2,
} from "../../../apis/index.api";
import { convertArrayToOptionLabels } from "../../../utils/object.utils";
import { AppContext } from "../../../contexts/AppContext";
import { useDispatch, useSelector } from "react-redux";
import { setSchoolDetails } from "../../../store/SignupStepsSlice";

const SchoolAttend = ({ goToNext, goToPrevious }) => {
  const dispatch = useDispatch();
  const schoolDetails = useSelector(
    (state) => state?.SignupSteps?.schoolDetails
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      name: schoolDetails?.name || "",
      country: schoolDetails?.country || "",
      city: schoolDetails?.city || "",
    },
  });

  const [, setItems] = useState();
  useEffect(() => {
    const payLoadData = localStorage.getItem("isAStudent");
    if (!isEmpty(payLoadData)) {
      const payLoad = JSON.parse(payLoadData);
      setItems(payLoad);
    }
  }, []);

  const [isSubmitting] = useState(false);
  const [error, setError] = useState("");
  const toast = useToast();
  const [AppState] = useContext(AppContext);
  const { user } = AppState;



  const onSubmit = async (data) => {
    const { name, city, country } = data;
    if (!name || typeof name !== "string") {
      setError("Invalid school name");
      return;
    }
    const payload = {
      name: name.trim(),
      country: country.label,
      city: city?.label,
    };

    dispatch(setSchoolDetails(payload));
    goToNext();
  };

  const selectedCountry = watch("country");

  const { data: countries, isFetching: isCountriesLoading } = useQuery({
    queryKey: [GET_COUNTRIES],
    queryFn: getCountriesAPI,
  });

  const { data: cities, isFetching: isCitiesLoading } = useQuery({
    queryKey: [GET_CITIES, selectedCountry?.value || ""],
    queryFn: getCitiesAPI2,
    enabled: !!selectedCountry,
  });

  const countriesOptions = useMemo(
    () => convertArrayToOptionLabels(countries?.data || [], "name", "isoCode"),
    [countries?.data]
  );
  const citiesOptions = useMemo(
    () => convertArrayToOptionLabels(cities?.data || [], "name", "isoCode"),
    [cities?.data]
  );

  useEffect(() => {
    if (schoolDetails?.city) {
      const selectedCity = citiesOptions?.find(
        (option) => option.label == schoolDetails.city
      );
      setValue("city", selectedCity);
    }
  });

  useEffect(() => {
    if (schoolDetails?.country) {
      const selectedCountry = countriesOptions.find(
        (option) => option.label === schoolDetails.country
      );
      setValue("country", selectedCountry || null); // or an empty object if no match
    }

    if (schoolDetails?.name) {
      setValue("name", schoolDetails.name);
    }
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box borderRadius={{ base: "20px", md: "20px" }} bgColor="white">
          <Box px={{ base: "0", xl: "25px" }}>
            <Text
              textAlign="center"
              fontSize={{
                base: "fs.25",
                md: "fs.30",
                lg: "fs.30",
                xl: "fs.38",
              }}
              fontWeight="fw.600"
            >
              School you're attending
            </Text>
            <Text
              textAlign="center"
              fontSize={{
                base: "fs.12",
                md: "fs.14",
                ld: "fs.16",
                xl: "fs.18",
              }}
              fontWeight="fw.400"
              pt="10px"
              pb="20px"
              color="#868B94"
            ></Text>
            <hr />
          </Box>
          <FormControl mt={{ base: "30px", md: "30px", lg: "40px" }}>
            <FormLabel
              fontSize={{
                base: "fs.12",
                md: "fs.14",
                ld: "fs.16",
                xl: "fs.18",
              }}
              fontWeight="fw.500"
            >
              School Name
            </FormLabel>
            <Input
              isDisabled={isSubmitting}
              {...register("name", { required: true })}
              focusBorderColor="#713EDD"
              border="1.5px solid rgba(210, 210, 210, 0.5)"
              placeholder={`Enter your School name`}
              type="text"
              fontSize={{
                base: "fs.12",
                md: "fs.14",
                ld: "fs.16",
                xl: "fs.18",
              }}
              borderRadius="10px"
              height={{ base: "40px", lg: "50px", "2xl": "55px" }}
            />
            {errors.name && (
              <Text color="#E1251A">School name is required</Text>
            )}
            <Box>
              <Text
                pt="25px"
                fontSize={{
                  base: "fs.12",
                  md: "fs.14",
                  ld: "fs.16",
                  xl: "fs.18",
                }}
                fontWeight="fw.500"
              >
                Country
              </Text>
              <Menu>
                <Controller
                  name="country"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      isLoading={isCountriesLoading}
                      placeholder="Select your country"
                      options={countriesOptions}
                      defaultValue={countriesOptions.find(
                        (option) => option.label === field.value?.value
                      )} // Correctly bind the value
                      onChange={(selectedOption) => {
                        field.onChange(selectedOption);
                        setValue("city", null); // Reset city when country changes
                      }}
                      isDisabled={isSubmitting}
                      className={Style.controller_select}
                    />
                  )}
                />
                {errors.country && (
                  <Text color="#E1251A">Please select your country.</Text>
                )}
              </Menu>
            </Box>
            <Box>
              <Text
                pt="25px"
                fontSize={{
                  base: "fs.12",
                  md: "fs.14",
                  ld: "fs.16",
                  xl: "fs.18",
                }}
                fontWeight="fw.500"
              >
                City
              </Text>
              <Menu>
                <Controller
                  name="city"
                  control={control}
                  render={({ field }) => (
                    <Select
                      isDisabled={isEmpty(watch("country")) || isSubmitting}
                      {...field}
                      placeholder="Select your city"
                      options={citiesOptions}
                      onChange={(selectedOption) => {
                        field.onChange(selectedOption);
                      }}
                      defaultValue={citiesOptions?.find(
                        (option) => option.label === field.value?.value
                      )} // Correctly bind the value
                      isLoading={isCitiesLoading}
                      className={Style.controller_select}
                    />
                  )}
                />

              </Menu>
            </Box>
          </FormControl>
          <Flex
            mt={{ base: "30px", md: "50px", lg: "30px", xl: "30px" }}
            className="gap-3"
          >
            <Button
              fontSize={{
                base: "fs.12",
                md: "fs.14",
                lg: "fs.16",
                xl: "fs.18",
              }}
              fontWeight="fw.600"
              height={{ base: "40px", lg: "50px", "2xl": "55px" }}
              width="50%"
              borderRadius="10px"
              onClick={(e) => goToPrevious(e)}
              backgroundColor={"#F6F2FD"}
              border={`1.5px solid #713EDD`}
            //   isDisabled={isLoading}
            >
              Back
            </Button>
            <Button
              isDisabled={isSubmitting}
              type="submit"
              color="white"
              background="linear-gradient(150.62deg, #713EDD 5.97%, #A17DEE 64.37%)"
              _hover={{ animation: "changeBg 0.5s ease infinite" }}
              width="50%"
              height={{ base: "40px", lg: "50px", "2xl": "55px" }}
              fontSize={{
                base: "fs.12",
                md: "fs.14",
                ld: "fs.16",
                xl: "fs.18",
              }}
              fontWeight="fw.500"
              borderRadius="10px"
              gap={5}
            >
              Next
              {/* {isLoading && <Spinner />} */}
            </Button>
            {error && (
              <Text
                textAlign={"center"}
                fontSize={{
                  base: "fs.12",
                  md: "fs.14",
                  ld: "fs.16",
                  xl: "fs.18",
                }}
                fontWeight="fw.400"
                color="#E1251A"
              >
                {error}
              </Text>
            )}
          </Flex>
        </Box>
      </form>
    </>
  );
};
export default SchoolAttend;
