import React, { useContext, useState } from 'react';
// import { loadTranslation } from "../../utilities/language"
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Spinner,
  Text,
  useToast
} from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';

import { Link } from 'react-router-dom';

import { GET_USER_DATA, loginInAPI } from '../../../apis/index.api';

import { yupResolver } from '@hookform/resolvers/yup';
import { AppContext } from '../../../contexts/AppContext';
import SliderComponent from '../../Slider/index';
import Style from '../../style.module.css';
import { signinFormSchema } from './formSchema';
import PasswordField from '../../PasswordField/PasswordField';

const SignInPage = props => {
  const [error, setError] = useState("");
  const queryClient = useQueryClient();
  const [, setStateApp] = useContext(AppContext);
  const toast = useToast();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(signinFormSchema)
  });

  const { mutate, isLoading } = useMutation(loginInAPI, {
    onSuccess: (data) => {
      localStorage.setItem('@apiToken', data.data.access_token);
      setStateApp(state => ({ ...state, auth: { ...state.auth, token: data.data.access_token } }));
      queryClient.invalidateQueries({ queryKey: [GET_USER_DATA] });
      reset();
    },

    onError: (error) => {
      toast({
        position: 'top-right',
        status: 'error',
        title: 'Error',
        description: error.response.data.displayMessage
      });
    },
  });


  const onSubmit = async data => {
    await mutate(data);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex flexDirection={{ base: 'column', lg: 'unset' }}>
          <SliderComponent />
          <Flex
            background={{
              sm: 'linear-gradient(148.84deg, #713EDD 12.15%, #A17DEE 79.83%)',
              xl: 'white',
            }}
            position={{ lg: 'absolute', xl: 'unset' }}
            py={{ base: '0px', md: '30px', lg: '25px', xl: '0' }}
            pl={{ base: '0px', md: '30px', lg: '25px', xl: '0' }}
            pr={{ base: '0px', md: '30px', lg: '25px', xl: '0' }}
            alignItems="center"
            width={{ base: '100%', xl: '35%' }}
            minHeight={{ base: '100vh', xl: 'auto' }}
            justifyContent={{ base: 'space-between', xl: 'end' }}
          >
            <Flex
              overflow="hidden"
              height={{ base: 'auto', md: 'auto', lg: '100%', xl: 'auto' }}
              padding={{ lg: '20px' }}
              ml="auto"
              width={{ base: '100%', md: '100%', lg: '50%', '2xl': '50%' }}
              alignItems="center"
              position={{ base: 'unset', xl: 'absolute' }}
              right="0"
              justifyContent={{
                base: 'center',
                lg: 'end',
                xl: 'space-between',
              }}
            >
              <Box
                className={Style.overflow}
                padding={{ base: '25px', lg: '25px', '2xl': '50px' }}
                boxShadow={{
                  base: '0',
                  md: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                }}
                width={{ base: '500px', xl: '80%' }}
                height={{ base: '100%', }}
                borderRadius={{ base: '20px', md: '20px' }}
                bgColor="white"
              >
                <Box px={{ base: '0', xl: '30px' }}>
                  <Text
                    textAlign="center"
                    fontSize={{
                      base: 'fs.25',
                      md: 'fs.30',
                      lg: 'fs.30',
                      xl: 'fs.38',
                    }}
                    fontWeight="fw.600"
                  >
                    Welcome back

                  </Text>
                  <Text
                    textAlign="center"
                    fontSize={{
                      base: 'fs.12',
                      md: 'fs.14',
                      ld: 'fs.16',
                      xl: 'fs.18',
                    }}
                    fontWeight="fw.400"
                    pt="10px"
                    pb="20px"
                    color="#868B94"
                  >
                  </Text>
                  <hr />
                </Box>
                {error && (
                  <Text
                    textAlign={"center"}
                    fontSize={{
                      base: "fs.12",
                      md: "fs.14",
                      ld: "fs.16",
                      xl: "fs.18"
                    }}
                    fontWeight="fw.400"
                    color="#E1251A"
                  >
                    {error}
                  </Text>
                )}
                <FormControl
                  mt={{ base: '30px', md: '30px', lg: '40px', xl: '70px' }}
                >
                  <FormLabel
                    fontSize={{
                      base: 'fs.12',
                      md: 'fs.14',
                      lg: 'fs.16',
                      xl: 'fs.18',
                    }}
                    fontWeight="fw.500"
                  >
                    Email
                  </FormLabel>
                  <Input
                    {...register('email')}
                    border="1.5px solid rgba(210, 210, 210, 0.5)"
                    focusBorderColor="#713EDD"
                    placeholder={`Enter your Email`}
                    type="email"
                    fontSize={{ base: 'fs.12', md: 'fs.14', ld: 'fs.16' }}
                    fontWeight="fw.400"
                    borderRadius="10px"
                    height={{ base: '40px', md: '50px', '2xl': '55px' }}
                  />
                  {errors.email && (
                    <Text color="#E1251A" fontSize={'12px'} mt={1}>{errors.email.message}</Text>
                  )}
                  <FormLabel
                    pt="25px"
                    fontSize={{
                      base: 'fs.12',
                      md: 'fs.14',
                      lg: 'fs.16',
                      xl: 'fs.18',
                    }}
                    fontWeight="fw.500"
                  >
                    Password
                  </FormLabel>
                  <PasswordField placeholder="*******" register={register("password")} />
                  {errors.password && (
                    <Text color="#E1251A" fontSize={'12px'} mt={1}>{errors.password.message}</Text>
                  )}
                </FormControl>
                <Flex
                  px={{ base: '0', md: '10px' }}
                  justifyContent="flex-end"
                  pt={{ base: '10px', md: '20px' }}
                >
                  <Link
                    to="/recover-password"
                    _hover={{ textDecoration: 'none' }}
                    fontSize={{
                      base: 'fs.12',
                      md: 'fs.14',
                      ld: 'fs.16',
                      xl: 'fs.18',
                    }}
                    fontWeight="fw.400"
                    color="#713EDD"
                  >
                    Forgot your password?
                  </Link>
                </Flex>
                <Box mt={{ base: '30px', md: '50px', lg: '40px', xl: '55px' }}>
                  <Button
                    color="white"
                    background="linear-gradient(150.62deg, #713EDD 5.97%, #A17DEE 64.37%)"
                    _hover={{
                      background: '#F6F2FD',
                      color: '#713EDD',
                      border: '1.5px solid #713EDD',
                    }}
                    width="100%"
                    height={{ base: '40px', md: '50px', '2xl': '60px' }}
                    fontSize={{
                      base: 'fs.12',
                      md: 'fs.14',
                      lg: 'fs.16',
                      xl: 'fs.18',
                    }}
                    fontWeight="fw.600"
                    borderRadius="10px"
                    type="submit"
                    gap={5}
                  >
                    Sign In
                    {isLoading && <Spinner />}
                  </Button>
                </Box>

                <Flex justifyContent="center" mt="20px">
                  <Text
                    fontSize={{
                      base: 'fs.12',
                      md: 'fs.14',
                      ld: 'fs.16',
                      xl: 'fs.18',
                    }}
                    mr={2}
                    fontWeight="fw.400"
                    color="#868B94"
                  >
                    Don't have an account?
                  </Text>
                  <Link
                    ml="10px"
                    to="/signup"
                    _hover={{ color: '#713edd' }}
                    color="#202B3C"
                    fontSize={{
                      base: 'fs.12',
                      md: 'fs.14',
                      ld: 'fs.16',
                      xl: 'fs.18',
                    }}
                    fontWeight="fw.500"
                  >
                    Sign Up
                  </Link>
                </Flex>

              </Box>
            </Flex>
          </Flex>
        </Flex>
      </form>
    </>
  );
};
export default SignInPage;
