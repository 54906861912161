import { FONT_SIZES, FONT_WEIGHTS } from "./font";
import { BORDER_RADIUS } from "./radius";

import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
	fontSizes: {
		fs: FONT_SIZES,
	},
	fontWeights: {
		fw: FONT_WEIGHTS,
	},
	radii: { // radii is not typo.
		br: BORDER_RADIUS,
	},
	fonts: {
		body: "Poppins",
		heading: "Poppins",
		mono: "Poppins",
	},
	components: {
		Input: {
			outline: 'none'
		},
	},
	breakpoints: {
		'2xl': '1440px',
	},
	sizes: {
		container: {
			base: '300px',
			sm: '640px',
			md: '768px',
			lg: '1024px',
			xl: '1280px',
			'2xl': '1560px'
		},
	},
	colors: {
		primary: "#713edd"
	}
});

export default theme;