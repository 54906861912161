import { protectedRoute } from "../config/api.config";

export function getCommentsById({ queryKey }) {
  const { skip, limit, taskId } = queryKey[1];
  const queryString = `?skip=${skip}&limit=${limit}`;
  return protectedRoute.get(`task/${taskId}/comments${queryString}`);
}


export function addComments(payload) {
  return protectedRoute.post('/comments/add', payload);
}

export function updateComments({ commentId, ...payload }) {
  return protectedRoute.patch(`/comments/${commentId}`, payload);
}

export function deleteComments(commentId) {
  return protectedRoute.delete(`/comments/${commentId}`);
}
