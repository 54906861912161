import { protectedRoute } from "../config/api.config";

export function getMyProjectsAPI({ queryKey }) {
  const { skip, limit } = queryKey[1];
  return protectedRoute.get(`/project/findAll`, {
    params: {
      skip,
      limit
    }
  });
}
export function createProjectAPI(payload) {
  return protectedRoute.post('/project/create', payload);
}

export function getProjectById({ queryKey }) {
  const { projectId } = queryKey[1];
  return protectedRoute.get(`/project/${projectId}`);
}

export function updateProject({ projectId, ...payload }) {
  return protectedRoute.patch(`/project/${projectId}`, payload);
}

export function getUserByProject({ queryKey }) {
  const { projectId } = queryKey[1];
  return protectedRoute.get(`/project/${projectId}/users`);
}

export function archiveProject(projectId) {
  return protectedRoute.patch(`/project/${projectId}/archive`);
}