export const onboardingRoutes = [
  '/career-details',
  '/school-attending',
  '/interested-careers',
  '/your-profession',
  '/upload-id-document',
  '/upload-documents',
  "/user-signup-steps"
];

export const authRoutes = [
  '/signin',
  '/signup',
  '/recover-password',
  '/reset-email',
  '/set-password/:token'
];

export const screeningRoutes = [
  '/screening/deactivated',
  '/screening/rejected',
  '/screening/progress'
];
