import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  careerDetails: [],
  schoolDetails: [],
  profession: [],
  interestedCareers: [],
  documentDetails: [],
  uploadDocs: { urls: [], names: [] },

};

const SignupStepsSlice = createSlice({
  name: 'userSingUp',
  initialState,
  reducers: {
    setCareerDetails: (state, action) => {
      state.careerDetails = action.payload;
    },
    setSchoolDetails: (state, action) => {
      state.schoolDetails = action.payload;
    },
    setYourProfession: (state, action) => {
      state.profession = action.payload;
    },
    setInterestedCareers: (state, action) => {
      state.interestedCareers = action.payload;
    },
    setDocumentDetails : (state, action) => {
      state.documentDetails = action.payload;
    },
    setUploadDocs : (state, action) => {
      state.uploadDocs = action.payload;
    },
    clearSignupSteps: (state) => {
      state.careerDetails = [];
      state.schoolDetails = [];
      state.profession = [];
      state.documentDetails = [];
      state.interestedCareers = [];
      state.uploadDocs = [];
    },
  },
});

export const { setCareerDetails, setSchoolDetails, setUploadDocs , setDocumentDetails ,setYourProfession, setInterestedCareers, clearCareerDetails } = SignupStepsSlice.actions;
export default SignupStepsSlice.reducer;
