import React from "react";
import {
  Box,
  Button,
  Flex,
  Spinner,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { useForm } from "react-hook-form";
import { useState } from 'react';

import { updateInterestedCareersAPI } from '../../../apis/users.api';
import { useMutation } from '@tanstack/react-query';
import { useDispatch, useSelector } from "react-redux";
import { setInterestedCareers } from "../../../store/SignupStepsSlice";

const InterestedCareers = ({ goToNext, goToPrevious }) => {
  const dispatch = useDispatch();
  const interestedCareers = useSelector((state) => state?.SignupSteps?.interestedCareers);
  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      interestedCareers: interestedCareers?.interestedCareers || '',
      reasonToJoin: interestedCareers?.reasonToJoin || '',
    },
  });

  const [selectedButtons, setSelectedButtons] = useState(interestedCareers?.interestedCareers || ['Networks and systems']);
  const [isSubmitting,] = useState(false);


  const handleButtonClick = buttonId => {
    if (selectedButtons.includes(buttonId)) {
      setSelectedButtons(selectedButtons.filter(id => id !== buttonId));
    } else if (selectedButtons.length < 3) {
      setSelectedButtons([...selectedButtons, buttonId]);
    }
  };
  const onSubmit = async data => {
    goToNext();

    const payload = {
      interestedCareers: selectedButtons,
      reasonToJoin: data.reasonToJoin,
    };
    dispatch(setInterestedCareers(payload));
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          borderRadius={{ base: '20px', md: '20px' }}
          bgColor="white"
        >
          <Box px={{ base: '0', md: '10px', xl: '20px' }}>
            <Text
              textAlign="center"
              fontSize={{
                base: 'fs.25',
                md: 'fs.30',
                lg: 'fs.30',
                xl: 'fs.38',
              }}
              fontWeight="fw.600"
            >
              Interested Careers
            </Text>
            <Text
              textAlign="center"
              fontSize={{
                base: 'fs.12',
                md: 'fs.14',
                ld: 'fs.16',
                xl: 'fs.18',
              }}
              fontWeight="fw.400"
              pt="10px"
              pb="20px"
              color="#868B94"
            >
            </Text>
            <hr />
          </Box>
          <Box mt={{ base: '20px' }}>
            <Box>
              <Text
                fontSize={{
                  base: 'fs.12',
                  md: 'fs.14',
                  ld: 'fs.16',
                  xl: 'fs.18',
                }}
                fontWeight="fw.500"
              >
                Choose up to 3 careers you are interested in.
              </Text>
            </Box>
            <Flex
              pt="20px"
              flexWrap={{ base: 'wrap', md: 'unset' }}
              justifyContent="center"
              mt="10px"
              gap={{ base: '5px', '2xl': '20px' }}
            >
              <Button
                isDisabled={isSubmitting}
                type="button"
                onClick={() => handleButtonClick('Networks and systems')}
                border={`1.5px solid ${selectedButtons.includes('Networks and systems')
                  ? '#713EDD'
                  : 'rgba(210, 210, 210, 0.5)'
                  }`}
                backgroundColor={
                  selectedButtons.includes('Networks and systems')
                    ? '#F6F2FD'
                    : 'white'
                }
                color={
                  selectedButtons.includes('Networks and systems')
                    ? '#713EDD'
                    : '#000'
                }
                _hover={{
                  background: '#F6F2FD',
                  color: '#713EDD',
                  border: '1.5px solid #713EDD',
                }}
                fontWeight="fw.400"
                width={{ base: '100%', md: '50%' }}
                fontSize={{
                  base: 'fs.12',
                  md: 'fs.14',
                  lg: 'fs.16',
                  xl: 'fs.16',
                }}
                borderRadius="10px"
                height={{ base: '40px', lg: '50px', '2xl': '55px' }}
              >
                Networks and systems
              </Button>
              <Button
                isDisabled={isSubmitting}
                type="button"
                onClick={() => handleButtonClick('Computer support')}
                border={`1.5px solid ${selectedButtons.includes('Computer support')
                  ? '#713EDD'
                  : 'rgba(210, 210, 210, 0.5)'
                  }`}
                backgroundColor={
                  selectedButtons.includes('Computer support')
                    ? '#F6F2FD'
                    : 'white'
                }
                color={
                  selectedButtons.includes('Computer support')
                    ? '#713EDD'
                    : '#000'
                }
                _hover={{
                  background: '#F6F2FD',
                  color: '#713EDD',
                  border: '1.5px solid #713EDD',
                }}
                fontWeight="fw.400"
                width={{ base: '100%', md: '50%' }}
                fontSize={{
                  base: 'fs.12',
                  md: 'fs.14',
                  lg: 'fs.16',
                  xl: 'fs.16',
                }}
                borderRadius="10px"
                height={{ base: '40px', lg: '50px', '2xl': '55px' }}
              >
                Computer support
              </Button>
            </Flex>
            <Flex
              flexWrap={{ base: 'wrap', md: 'unset' }}
              justifyContent="center"
              mt={{ base: '5px', md: '25px' }}
              gap={{ base: '5px', '2xl': '20px' }}
            >
              <Button
                isDisabled={isSubmitting}
                type="button"
                onClick={() => handleButtonClick('Cybersecurity')}
                border={`1.5px solid ${selectedButtons.includes('Cybersecurity')
                  ? '#713EDD'
                  : 'rgba(210, 210, 210, 0.5)'
                  }`}
                backgroundColor={
                  selectedButtons.includes('Cybersecurity')
                    ? '#F6F2FD'
                    : 'white'
                }
                color={
                  selectedButtons.includes('Cybersecurity')
                    ? '#713EDD'
                    : '#000'
                }
                _hover={{
                  background: '#F6F2FD',
                  color: '#713EDD',
                  border: '1.5px solid #713EDD',
                }}
                fontWeight="fw.400"
                width={{ base: '100%', md: '50%' }}
                fontSize={{
                  base: 'fs.12',
                  md: 'fs.14',
                  lg: 'fs.16',
                  xl: 'fs.16',
                }}
                borderRadius="10px"
                height={{ base: '40px', lg: '50px', '2xl': '55px' }}
              >
                Cybersecurity
              </Button>
              <Button
                isDisabled={isSubmitting}
                type="button"
                onClick={() => handleButtonClick('Software development')}
                border={`1.5px solid ${selectedButtons.includes('Software development')
                  ? '#713EDD'
                  : 'rgba(210, 210, 210, 0.5)'
                  }`}
                backgroundColor={
                  selectedButtons.includes('Software development')
                    ? '#F6F2FD'
                    : 'white'
                }
                color={
                  selectedButtons.includes('Software development')
                    ? '#713EDD'
                    : '#000'
                }
                _hover={{
                  background: '#F6F2FD',
                  color: '#713EDD',
                  border: '1.5px solid #713EDD',
                }}
                fontWeight="fw.400"
                width={{ base: '100%', md: '50%' }}
                fontSize={{
                  base: 'fs.12',
                  md: 'fs.14',
                  lg: 'fs.16',
                  xl: 'fs.16',
                }}
                borderRadius="10px"
                height={{ base: '40px', lg: '50px', '2xl': '55px' }}
              >
                Software development
              </Button>
            </Flex>
            <Flex
              flexWrap={{ base: 'wrap', md: 'unset' }}
              justifyContent="center"
              mt={{ base: '5px', md: '25px' }}
              gap={{ base: '5px', '2xl': '20px' }}
            >
              <Button
                isDisabled={isSubmitting}
                type="button"
                onClick={() => handleButtonClick('Web development')}
                border={`1.5px solid ${selectedButtons.includes('Web development')
                  ? '#713EDD'
                  : 'rgba(210, 210, 210, 0.5)'
                  }`}
                backgroundColor={
                  selectedButtons.includes('Web development')
                    ? '#F6F2FD'
                    : 'white'
                }
                color={
                  selectedButtons.includes('Web development')
                    ? '#713EDD'
                    : '#000'
                }
                _hover={{
                  background: '#F6F2FD',
                  color: '#713EDD',
                  border: '1.5px solid #713EDD',
                }}
                fontWeight="fw.400"
                width={{ base: '100%', md: '50%' }}
                fontSize={{
                  base: 'fs.12',
                  md: 'fs.14',
                  lg: 'fs.16',
                  xl: 'fs.16',
                }}
                borderRadius="10px"
                height={{ base: '40px', lg: '50px', '2xl': '55px' }}
              >
                Web development
              </Button>
              <Button
                isDisabled={isSubmitting}
                type="button"
                onClick={() => handleButtonClick('Data Science/Analyst')}
                border={`1.5px solid ${selectedButtons.includes('Data Science/Analyst')
                  ? '#713EDD'
                  : 'rgba(210, 210, 210, 0.5)'
                  }`}
                backgroundColor={
                  selectedButtons.includes('Data Science/Analyst')
                    ? '#F6F2FD'
                    : 'white'
                }
                color={
                  selectedButtons.includes('Data Science/Analyst')
                    ? '#713EDD'
                    : '#000'
                }
                _hover={{
                  background: '#F6F2FD',
                  color: '#713EDD',
                  border: '1.5px solid #713EDD',
                }}
                fontWeight="fw.400"
                width={{ base: '100%', md: '50%' }}
                fontSize={{
                  base: 'fs.12',
                  md: 'fs.14',
                  lg: 'fs.16',
                  xl: 'fs.16',
                }}
                borderRadius="10px"
                height={{ base: '40px', lg: '50px', '2xl': '55px' }}
              >
                Data Science/Analyst
              </Button>
            </Flex>
            <Flex justifyContent="start" mt={{ base: '5px', md: '25px' }}>
              <Button
                isDisabled={isSubmitting}
                type="button"
                onClick={() => handleButtonClick('Cloud computing')}
                border={`1.5px solid ${selectedButtons.includes('Cloud computing')
                  ? '#713EDD'
                  : 'rgba(210, 210, 210, 0.5)'
                  }`}
                backgroundColor={
                  selectedButtons.includes('Cloud computing')
                    ? '#F6F2FD'
                    : 'white'
                }
                color={
                  selectedButtons.includes('Cloud computing')
                    ? '#713EDD'
                    : '#000'
                }
                _hover={{
                  background: '#F6F2FD',
                  color: '#713EDD',
                  border: '1.5px solid #713EDD',
                }}
                fontWeight="fw.400"
                width={{ base: '100%', md: '48%' }}
                fontSize={{
                  base: 'fs.12',
                  md: 'fs.14',
                  lg: 'fs.16',
                  xl: 'fs.16',
                }}
                borderRadius="10px"
                height={{ base: '40px', lg: '50px', '2xl': '55px' }}
              >
                Cloud computing
              </Button>
            </Flex>
          </Box>
          <Box mt={{ base: '30px' }}>
            <Box>
              <Text
                fontSize={{
                  base: 'fs.12',
                  md: 'fs.14',
                  ld: 'fs.16',
                  xl: 'fs.18',
                }}
                fontWeight="fw.500"
              >
                Why do you want to join
              </Text>
            </Box>
            <Textarea
              {...register('reasonToJoin', { required: true })}
              isDisabled={isSubmitting}
              mt="8px"
              size="sm"
              height="150px"
              borderRadius="10px"
              border="2px solid #8080803d"
              focusBorderColor="#713EDD"
              resize="none"
            />
            {errors.reasonToJoin && (
              <Text color="#E1251A">Reason to join is required.</Text>
            )}
          </Box>
          <Flex
            mt={{ base: "30px", md: "50px", lg: "30px", xl: "30px" }}
            className="gap-3"
          >
            <Button
              fontSize={{
                base: "fs.12",
                md: "fs.14",
                lg: "fs.16",
                xl: "fs.18",
              }}
              fontWeight="fw.500"
              height={{ base: "40px", lg: "50px", "2xl": "55px" }}
              width="50%"
              borderRadius="10px"
              onClick={(e) => goToPrevious(e)}
              backgroundColor={"#F6F2FD"}
              border={`1.5px solid #713EDD`}
              // isDisabled={isLoading}
            >
              Back
            </Button>
            <Button
              isDisabled={isSubmitting}
              type="submit"
              color="white"
              background="linear-gradient(150.62deg, #713EDD 5.97%, #A17DEE 64.37%)"
              _hover={{ animation: "changeBg 0.5s ease infinite" }}
              width="50%"
              height={{ base: "40px", lg: "50px", "2xl": "55px" }}
              fontSize={{
                base: "fs.12",
                md: "fs.14",
                ld: "fs.16",
                xl: "fs.18"
              }}
              fontWeight="fw.500"
              borderRadius="10px"
              gap={5}
            >

              Next
              {/* {isLoading && <Spinner />} */}
            </Button>
          </Flex>
        </Box>
      </form>
    </>
  );
};
export default InterestedCareers;
