import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';
import SocketProvider from './component/Socket';
import './config/logrocket';
import theme from './config/theme-config/theme';
import AppContextProvider from './contexts/AppContext';
import ChatContextProvider from './contexts/ChatContexts';
import AppRoutes from './routes';
import "./index.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from 'react-redux';
import { store, persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <>
      <ToastContainer
        toastClassName={() =>
          "relative flex py-4 px-3 rounded overflow-hidden cursor-pointer bg-white shadow-lg"
        }
        bodyClassName={() => "text-black text-base font-normal"}
        position="bottom-left"
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop={false}
        closeButton={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ChakraProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <AppContextProvider>
              <ChatContextProvider>
                <SocketProvider>
                  <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                      <AppRoutes />
                    </PersistGate>
                  </Provider>
                </SocketProvider>
              </ChatContextProvider>
            </AppContextProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </ChakraProvider>
    </>
  );
}

export default App;
