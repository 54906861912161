import { CloseIcon, RepeatIcon } from '@chakra-ui/icons';
import { Box, Flex, HStack, Image, Spinner, Text, VStack } from '@chakra-ui/react';
import { IconFileTypeBmp, IconFileTypeDoc, IconFileTypeDocx, IconFileTypePpt, IconFileTypeSvg, IconFileTypeXls, IconFileTypeZip, IconPdf, IconUpload } from '@tabler/icons-react';
import { isEmpty, last } from 'lodash';
import { nanoid } from 'nanoid';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { getPreSignedThumbnailURL, uploadToS3 } from '../../apis/common.api';

const ThumbImageUpload = ({
  onUpload,
  innerMessage = "Drag 'n' drop some files here, or click to select files",
  icon = <IconUpload />,
  acceptedFiles = { 'image/*': [] },
  maxFiles = 2,
  isPDF = false,
  maxSize = 1024 * 1024 * 2, // 2MB
  onRemove,
  error,
  defaultValue
}) => {
  const [file, setFile] = useState(null);

  useEffect(() => {
    // Setting default images if any
    if (!isEmpty(defaultValue)) {
      setFile({
        id: nanoid(),
        fileURL: defaultValue,
        status: 'success'
      });
    }
  }, []);

  const onDrop = useCallback(async (acceptedFiles) => {
    uploadFileToS3(acceptedFiles[0]);
  }, [onUpload, isPDF]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptedFiles,
    multiple: false,
    maxFiles,
    maxSize
  });

  const removeFile = (id) => {
    setFile();
  };

  const uploadFileToS3 = async (file) => {
    const previewUrl = URL.createObjectURL(file);

    setFile({ id: nanoid(), file: file, preview: previewUrl, status: 'loading' });

    try {
      const request = {
        fileName: file.path,
        contentType: file.type
      };

      // Assume getPreSignedURL is a function that returns a promise with the URL for S3 upload
      const response = await getPreSignedThumbnailURL(request, isPDF);
      const { url, key } = response.data;
      const fileURL = url.slice(0, url.indexOf('?'));

      const uploadFile = fileURL.split("/")[3].replace(/%20/g, " ");

      const upload = await uploadToS3(url, file);
      if (upload.status === 200) {
        // Update the file status upon successful upload

        onUpload && onUpload({ url: uploadFile, key, file });
        setFile(prev => ({ ...prev, status: 'uploaded' }));
      }
    } catch (error) {
      // Handle any errors that occur during the process
      console.error('Error occurred:', error);
      setFile(prev => ({ ...prev, status: 'error' }));
    }
  };

  const retryUpload = () => uploadFileToS3(file.file);

  const renderPreview = useCallback((file) => {
    const ext = last((file?.fileURL || file?.file?.name || '').split("."));

    if (['jpg', 'jpeg', 'png', 'gif', 'webp', 'avif',].includes(ext)) {
      return <Image src={file.preview || file.fileURL} alt="preview" w="50px" h="full" aspectRatio={'1/1'} objectFit={'cover'} />;
    } else if (['mp4', 'mov'].includes(ext)) {
      return <video src={file.fileURL}></video>;
    } else {
      const mapper = {
        pdf: <IconPdf />,
        xls: <IconFileTypeXls />,
        xlsx: <IconFileTypeXls />,
        doc: <IconFileTypeDoc />,
        docx: <IconFileTypeDocx />,
        ppt: <IconFileTypePpt />,
        bmp: <IconFileTypeBmp />,
        zip: <IconFileTypeZip />,
        svg: <IconFileTypeSvg />
      };

      return mapper[ext];
    }
  }, []);

  return (
    <VStack>
      <Box {...getRootProps()}
        flexDirection="column"
        _hover={{
          background: "#F6F2FD",
          color: "#713EDD",
          border: "1.5px dashed #713EDD"
        }}
        display={'flex'}
        background="#F7FAFC"
        border="1.5px dashed #D2D2D2CC"
        width="100%"
        borderRadius="10px"
        cursor={'pointer'}
        minH={'150px'}
      >
        <input {...getInputProps()} />
        <Flex py={2} borderBottom='1px dashed #D2D2D2CC' gap={2} justify={'center'} align={'center'} color={'gray'}>
          {icon}
          <Text textAlign={'center'} fontSize={'fs.14'}>{innerMessage}</Text>
        </Flex>
        <HStack wrap={'wrap'} p={2}>
          {
            file && (
              <Box key={file.id} p={2} display="flex" alignItems="center" pos={'relative'} border={file.status === "error" ? '2px solid red' : '1px solid grey'} borderRadius={'10px'}>
                {renderPreview(file)}
                {file.status === 'loading' &&
                  <Box cursor={'pointer'} bg="white" h={'30px'} aspectRatio={'1/1'} borderRadius={'100%'} pos={'absolute'} top={0} left={0} bottom={0} right={0} margin="auto" display={'flex'} justifyContent={'center'} alignItems={'center'} >
                    <Spinner color='purple.500' />
                  </Box>
                }
                {
                  file.status === 'error' &&
                  <Box onClick={(e) => { e.stopPropagation(); retryUpload(); }} cursor={'pointer'} bg="white" h={'30px'} aspectRatio={'1/1'} borderRadius={'100%'} pos={'absolute'} top={0} left={0} bottom={0} right={0} margin="auto" display={'flex'} justifyContent={'center'} alignItems={'center'} >
                    <RepeatIcon />
                  </Box>
                }
                <Box
                  p={2}
                  bg={'black'}
                  color={'white'}
                  pos={'absolute'}
                  top={0} right={0}
                  onClick={(e) => { e.stopPropagation(); removeFile(file.id); }}
                  transform={'translate(50%, -50%)'}
                  aspectRatio={'1/1'} h="25px" borderRadius={'100%'}
                  cursor={'pointer'}
                  display={'flex'} justifyContent={'center'} alignItems={'center'}
                >
                  <CloseIcon fontSize={'10px'} />
                </Box>
              </Box>
            )
          }
        </HStack>
      </Box>
      {error && <Text color={'red.500'} fontSize={'fs.14'}>{error}</Text>}
    </VStack >
  );
};

export default ThumbImageUpload;

