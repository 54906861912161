import DOMPurify from 'dompurify';
import { memo } from 'react';

function HTMLComponent({ htmlContent }) {
  const sanitizedHtml = DOMPurify.sanitize(htmlContent);

  return (
    <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
  );
}

export default memo(HTMLComponent);

