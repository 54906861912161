import {
  Box, Button, Flex, Input, Modal, ModalBody,
  ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Text,
  useToast
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import Style from '../style.module.css';

import ReactSelect from "react-select";
import { GET_USER_DATA } from "../../apis/constants.api";
import { updateProfileAPI } from "../../apis/users.api";
import { expSchema } from "./form.schema";

const ExperienceDialog = ({ openExpModal, user, onCloseExpModal }) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const generateYearOptions = (startYear, endYear) => {
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
      years.push({ value: year, label: `${year} year${year > 1 ? "s" : ""}` });
    }
    years.push({ value: "15+", label: "15+ years" });
    return years;
  };


  const yearsOptions = generateYearOptions(1, 15);
  const { control, handleSubmit, formState: { errors }, reset, register } = useForm({
    defaultValues: {
      profession: user?.currentProfession?.profession,
      yearsOfExperience: yearsOptions.find((e) => e.value === Number(user?.currentProfession?.yearsOfExperience)),
    },
    resolver: yupResolver(expSchema)

  });

  const year = yearsOptions.find((e) => e.value === Number(user?.currentProfession?.yearsOfExperience));
  const { mutate, isLoading } = useMutation(updateProfileAPI, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_USER_DATA] });
      onCloseExpModal();
      reset();
      toast({
        title: "Profile updated.",
        description: "Your Experience has been successfully updated.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    },
    onError: (error) => {
      toast({
        title: "An error occurred.",
        description: "Unable to update Experience. Please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  });

  const onSubmit = async (data) => {
    const payload = {
      currentProfession: {
        profession: data.profession,
        skills: user?.currentProfession?.skills?.map((e) => e._id),
        yearsOfExperience: data.yearsOfExperience.value
      }
    };
    await mutate(payload);

  };
  return (<>
    <Modal isOpen={openExpModal} onClose={onCloseExpModal} isCentered size="xl">
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Profession</ModalHeader>
          <ModalCloseButton border="1px solid #868B944D" />
          <ModalBody>
            <Text
              pt="20px"
              pb="8px"
              fontSize={{
                base: "fs.12",
                md: "fs.14",
                ld: "fs.16",
                xl: "fs.18"
              }}
              fontWeight="fw.500"
            >
              Profession
            </Text>
            <Input
              {...register("profession", { required: true })}
              isDisabled={isLoading}
              border="1.5px solid rgba(210, 210, 210, 0.5)"
              focusBorderColor="#713EDD"
              placeholder={`Your Profession`}
              type="text"
              fontSize={{ base: "fs.14", md: "fs.16", lg: "fs.18" }}
              borderRadius="10px"
              height={{ base: "40px", lg: "50px", xl: "55px" }}
            />

            <Box>
              <Text
                pt="20px"
                pb="8px"
                fontSize={{
                  base: "fs.12",
                  md: "fs.14",
                  ld: "fs.16",
                  xl: "fs.18"
                }}
                fontWeight="fw.500"
              >
                How many years of programming experience?
              </Text>
              <Controller
                name="yearsOfExperience"
                control={control}
                render={({ field }) => (
                  <ReactSelect
                    value={year || null}
                    options={yearsOptions}
                    placeholder={`Select...`}
                    isLoading={isLoading}
                    loadingMessage={() => "Loading..."}
                    openMenuOnFocus={false}
                    isSearchable
                    {...field}
                    className={Style.skill_controller}
                  />
                )}
              />
            </Box>
          </ModalBody>
          <ModalFooter background="#F5F4F6" borderRadius=" 0px 0px 10px 10px">
            <Flex gap="10px">
              <Button background="#EAE9EC" onClick={onCloseExpModal}>Cancel</Button>
              <Button type="submit" gap={5} px={{ md: "15px", lg: "30px" }} py="8px" background="linear-gradient(134.59deg, #713EDD -1.98%, #A17DEE 111.17%)"

                _hover={{ background: "linear-gradient(134.59deg, #A17DEE -1.98% ,#713EDD 111.17%)" }} color="white">Update {isLoading && <Spinner />}</Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal >

  </>);
};

export default ExperienceDialog;