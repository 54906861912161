import { isEmpty } from 'lodash';
import { useContext, useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AppContext } from '../../contexts/AppContext';

const PublicRoute = ({ component: Component }) => {
  const location = useLocation();
  const [stateApp] = useContext(AppContext);
  const { user, auth, isUserLoading } = stateApp;

  const redirectTo = useMemo(() => {
    let redirectTo = null;
    if (!isUserLoading) {
      if (auth?.token) {
        if (!isEmpty(user))
          redirectTo = '/';
      }
    }

    if (redirectTo === location.pathname)
      redirectTo = null;

    return redirectTo;
  }, [user, auth, isUserLoading, location.pathname]);

  return (
    !redirectTo ? <Component /> : <Navigate to={redirectTo} replace />
  );
};

export default PublicRoute;