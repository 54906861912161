import {
  Box, useSteps
} from '@chakra-ui/react';
import React from 'react';
import CareerDetails from '../CareerDetails';
import SchoolAttend from '../SchoolAttending';
import Profession from '../YourProfession';
import UploadDocuments from '../UploadDocument';
import DocumentsPageData from '../DocumentsPageData';
import InterestedCareers from '../InterestedCareers';
import Style from '../../style.module.css';

function StepperCompo() {
  const steps = [
    { title: 'Career Details', description: 'CareerDetails', component: CareerDetails },
    { title: 'School Attend', description: 'SchoolAttend', component: SchoolAttend },
    { title: 'Profession Details', description: 'Profession', component: Profession },
    { title: 'Interested Careers', description: 'InterestedCareers', component: InterestedCareers },
    { title: 'Upload Documents', description: 'UploadDocuments', component: UploadDocuments },
    { title: 'Upload ID', description: 'DocumentsPageData', component: DocumentsPageData },
  ];

  const { activeStep, goToNext, goToPrevious } = useSteps({
    index: 0,
    count: steps.length,
  });

  const StepComponent = steps[activeStep].component;

  return (
    <Box
      className={Style.overflow}
      padding={{ base: '25px', md: '25px', '2xl': '50px' }}
      boxShadow={{
        base: '0',
        md: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
      }}
      zIndex={111}
      width={{ base: '500px', sm: "550px", xl: '80%' }}
      height={{ base: '100%', md: 'calc(100dvh - 100px)' }}
      borderRadius={{ base: '20px', md: '20px' }}
      bgColor="white"
    >
      <Box mt={4}>
        <StepComponent goToNext={goToNext} goToPrevious={goToPrevious} />
      </Box>
    </Box>
  );
}

export default StepperCompo;

