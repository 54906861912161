import * as yup from "yup";

export const basicSchema = yup.object({
  name: yup.string().required('Name is required'),
  description: yup.string(),
  iconURL: yup.string().required('Icon is required'),
  region: yup.string().required('Region is required'),
  open: yup.boolean()
}).required();

export const userSchema = yup.object({
  members: yup.array().required(),
}).required();
