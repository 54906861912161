import { protectedRoute } from "../config/api.config";

export function getSkills({ queryKey }) {
  const { skip, limit, search } = queryKey[1];
  return protectedRoute.get(`/skills/list`, { params: { skip, limit, search } });
}
export function addNewSkills(payload) {
  return protectedRoute.post(`/skills/create`, payload);
}

export function updateSkill({ skillId, ...payload }) {
  return protectedRoute.patch(`/skills/${skillId}`, payload);
}

export function deleteSkill(skillId) {

  return protectedRoute.delete(`/skills/${skillId}`);
}