import { MeetingProvider } from "@videosdk.live/react-sdk";
import { useContext, useEffect, useState } from "react";
import { MeetingAppProvider } from "../../contexts/MeetingAppContextDef";
import { MeetingContainer } from "./meeting/MeetingContainer";
import { LeaveScreen } from "./components/screen/LeaveScreen";
import { AppContext } from "../../contexts/AppContext";
import { useSocket } from "../Socket";
import { useParams } from "react-router-dom";
import CallNotification from "./components/CallNotification";
import { useDispatch, useSelector } from 'react-redux';
import { setChannel } from "../../store/channelSlice";

function VideoCall() {
  const [meetingId, setMeetingId] = useState("");
  const { userName, channelType } = useSelector((state) => state.channel);
  const [micOn, setMicOn] = useState(false);
  const [webcamOn, setWebcamOn] = useState(false);
  const [isMeetingStarted, setMeetingStarted] = useState(false);
  const [isMeetingLeft, setIsMeetingLeft] = useState(false);
  const [participantName, setParticipantName] = useState(userName || userName);
  const dispatch = useDispatch();

  const isMobile = window.matchMedia(
    "only screen and (max-width: 768px)"
  ).matches;
  const params = useParams();
  const { meetingID } = params;
  const { socket, callCreatedData } = useSocket();
  const [stateApp] = useContext(AppContext);
  const { user } = stateApp;
  const [isIncoming, setIsIncoming] = useState(false);
  const [token, setToken] = useState("");
  const { isToken, chatType } = useSelector((state) => state.token);
  const [isLeave, setIsLeave] = useState(true);

  const capitalize = s => (s && s[0].toUpperCase() + s.slice(1)) + 's' || "";

  meetingID !== "call_ended" && dispatch(setChannel({ userName: userName, roomId: meetingID, channelType: channelType || capitalize(chatType) }));

  const { roomId } = useSelector((state) => state.channel);

  useEffect(() => {
    if (isMeetingStarted && !isMeetingLeft) {
      window.history.replaceState(null, null, `/call/${roomId}`);
    } else {
      window.history.replaceState(null, null, `/call/call_ended`);
    }
  }, [isMeetingStarted, meetingId, isMeetingLeft, roomId]);

  useEffect(() => {
    if (isMobile || !isMobile) {
      window.onbeforeunload = () => {
        return "Are you sure you want to exit?";
      };
    }
  }, [isMobile]);

  useEffect(() => {
    if (meetingID === roomId && (meetingID !== "undefined")) {
      setMeetingId(meetingID);
      setMeetingStarted(true);
      setToken(isToken);
    }
  }, [meetingID, isToken, roomId]);

  useEffect(() => {
    if (callCreatedData?.roomId && callCreatedData?.callOwnerDetail?._id !== user?._id && callCreatedData?.roomId !== meetingID) {
      setIsIncoming(true);
    }
  }, [callCreatedData, meetingID, user?._id]);

  const onReject = async () => {
    setIsIncoming(false);
    callCreatedData?.type === "Directs" && await socket.emit('CALL_REJECT', { type: callCreatedData.type, roomId: callCreatedData.roomId });
  };

  const handleVideoCall = async () => {
    if (callCreatedData?.roomId) {
      window.open(`/call/${callCreatedData.roomId}`, '_blank');
    }
    setIsIncoming(false);
  };

  return (
    <>
      <MeetingAppProvider>
        {isMeetingStarted ? (
          <MeetingProvider
            config={{
              meetingId,
              micEnabled: micOn,
              webcamEnabled: webcamOn,
              name: participantName ? participantName : userName,
              multiStream: true,
            }}
            token={token}
            reinitialiseMeetingOnConfigChange={true}
            joinWithoutUserInteraction={true}
          >
            <MeetingContainer
              onMeetingLeave={() => {
                setToken("");
                setMeetingId("");
                setParticipantName("");
                setWebcamOn(false);
                setMicOn(false);
                setMeetingStarted(false);
                setIsLeave(false);
              }}
              setIsMeetingLeft={setIsMeetingLeft}
            />
          </MeetingProvider>
        ) : (
          <LeaveScreen />
        )}

        {isIncoming && <CallNotification isIncoming={isIncoming} onReceive={handleVideoCall} onReject={onReject} />}
      </MeetingAppProvider>
    </>
  );
}

export default VideoCall;
