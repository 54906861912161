export const regionsOption = [
  { value: 'africa', label: 'Africa' },
  { value: 'asia', label: 'Asia' },
  { value: 'central america', label: 'Central America' },
  { value: 'europe', label: 'Europe' },
  { value: 'middle east', label: 'Middle East' },
  { value: 'north america', label: 'North America' },
  { value: "pacific", label: 'Pacific' },
  { value: 'south america', label: 'South America' }
];
