import {
  Box,
  CircularProgress,
  Flex,
  IconButton,
  Image,
  Stack,
  Tag,
  Td,
  Text,
  Tr,
  useDisclosure
} from '@chakra-ui/react';
import { IconPencil, IconTrash } from '@tabler/icons-react';
import React, { useState } from 'react';
import { preSignedUrl } from '../../../apis/ebook.api';
import EBookDeleteDialog from './EBookDeleteDialog';
import EBookEditDialog from './EBookEditDialog';


function EBookListItems(props) {
  // const { onOpen, isOpen, onClose } = useDisclosure();
  const { onOpen: onEditOpen, isOpen: isEditOpen, onClose: onEditClose } = useDisclosure();
  const { onOpen: onDeleteOpen, isOpen: isDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const { book, } = props;
  const { title, pdfUrl, thumbnailUrl, relevantSkills
  } = book;
  const [isLoading, setIsLoading] = useState(false);
  const [_, setPdfUrlNew] = useState(null);

  const handleViewPDF = async () => {
    const response = await preSignedUrl(pdfUrl);
    setPdfUrlNew(response.data);
    localStorage.setItem("url", response.data);
    window.open(`/ebook-view`, '_blank');
  };

  return (
    <>
      <Tr cursor="pointer" _hover={{ background: "#F9FAFB" }}>
        <Td pl="14px" py="16px">
          <Box width="60px" height="60px" borderRadius="6px" background="#f6f6f6">
            <Image src={`${process.env.REACT_APP_THUMBNAIL_URL}${thumbnailUrl}`} alt='cardImg' objectFit={'contain'} height={'100%'} width={'100%'} borderRadius={10} onClick={handleViewPDF} />
          </Box>
        </Td>
        <Td pl="26px" py="16px">
          <Text className='ellipsis' noOfLines={1} fontSize={{ base: "fs.12", md: "fs.14", xl: "fs.16" }} fontWeight="fw.500" textOverflow="ellipsis" width={{ base: "400px", md: "250px", lg: "400px", xl: "auto", '2xl': "665px" }} overflow="visible" display="-webkit-box">
            {title}
          </Text>
        </Td>
        <Td pl="26px" py="16px" maxW={'35%'}>
          <Flex gap={2} wrap={'wrap'}>
            {relevantSkills.map((skill, i) => (
              <Tag size="md" key={i} variant='solid' backgroundColor='#868B94'>
                {skill.name}
              </Tag>
            ))}
          </Flex>
        </Td>
        <Td pl='16px' pr='10px' py='16px' width='230px'>
          <Flex gap='10px' align='center' justify={'center'}>
            <Stack direction='row'>
              <IconButton
                bg='transparent'
                icon={<IconPencil />}
                onClick={onEditOpen}
                disabled={isLoading}
                variant={'ghost'}
                spinner={<CircularProgress size="24px" color="purple.500" />}
              />
              <IconButton
                bg='transparent'
                icon={<IconTrash color='red' />}
                onClick={onDeleteOpen}
                isLoading={isLoading}
                loadingText="Deleting..."
                variant={'ghost'}
                spinner={<CircularProgress size="24px" color="purple.500" />}
                _hover={{ bg: 'red.100' }}
              />
            </Stack>
          </Flex>
        </Td>
      </Tr>

      {/* Edit Book Modal */}
      {isEditOpen && <EBookEditDialog book={book} {...{ isEditOpen, onEditOpen, onEditClose }} />}
      {/* Delete Confirmation */}
      {isDeleteOpen && <EBookDeleteDialog book={book} {...{ isDeleteOpen, onDeleteClose, onDeleteOpen }} />}
    </>
  );
}

export default EBookListItems;
