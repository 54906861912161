import { protectedRoute } from "../config/api.config";

export function getNotificationsAPI() {
  return protectedRoute.get('/notification');
}

export function getUnreadNotificationCountAPI() {
  return protectedRoute.get('/notification/unread-count');
}

export function readNotificationAPI(payload) {
  return protectedRoute.patch('/notification/read', payload);
}


export function getUnreadMessagesCountAPI() {
  return protectedRoute.get('/messages/unread-count');
}

