import {
  Box, Button, Input,
  Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Text,
  useToast
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Controller, FormProvider } from "react-hook-form";
import InputArray from "../../MultipleFields";
import { updateSkill } from "../../../apis/skills.api";
import { GET_SKILLS } from "../../../apis/constants.api";


const SkillsEditDialog = ({ skill, methods, isOpen, onClose }) => {

  const queryClient = useQueryClient();
  const toast = useToast();
  const errors = methods.formState.errors;
  const { mutate: executeUpdate, isLoading: isUpdating } = useMutation(updateSkill, {
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [GET_SKILLS] });
      methods.reset();

      if (data.data.message) {
        toast({
          title: "Success",
          description: data.data.message,
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
      }
    },


  });

  const onSubmit = async (data) => {
    const _rss = data.rss.filter((e) => e);
    const payload = {
      name: data.name,
      rss: _rss

    };
    const skillId = skill._id;
    executeUpdate({ skillId, ...payload });
    onClose();
    methods.reset();
  };
  return (<>


    <Modal closeOnOverlayClick={false} onClose={onClose} isOpen={isOpen} isCentered >
      <FormProvider {...methods} >
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <ModalOverlay />
          <ModalContent maxWidth="599px" borderRadius="10px">
            <ModalHeader fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16", xl: "fs.18" }} fontWeight="fw.600">Update Skill</ModalHeader>
            <ModalCloseButton border="1px solid #868B944D" />
            <ModalBody pt="4px" pb="30px">

              <Box>
                <Box mt="30px">
                  <Text fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} fontWeight="fw.500">Name</Text>
                  <Controller
                    name="name"
                    control={methods.control}
                    rules={{ required: true }}
                    render={({ field, fieldState }) =>
                      <> <Input {...field}
                        errorBorderColor="red.500"
                        isInvalid={errors?.name?.message || fieldState?.['name']?.message}
                        placeholder="Enter your name" height="50px" _hover={{ borderColor: "#713EDD" }}
                        background="#F7FAFC" mt="8px" _focusVisible={{ borderColor: "#713EDD" }} />

                        {(errors?.name?.message || fieldState?.['name']?.message) && <Text size={'small'} fontSize={'sm'} mt={1} color="red.500">{errors?.name?.message || fieldState?.['name']?.message}</Text>}
                      </>

                    }
                  />
                </Box>
                <Box mt="15px">
                  <InputArray control={methods.control} defaultValue={skill.rss} name="rss" />
                </Box>

              </Box>
            </ModalBody>
            <ModalFooter gap="20px" background="#F5F4F6" borderRadius="0px 0px 10px 10px">
              <Button background="#EAE9EC" onClick={onClose} width="83px" height="40px" fontSize="fs.12" fontWeight="fw.500" color="#202B3C">Cancel</Button>
              <Button
                type="submit"
                background="#713EDD"
                width="83px"
                height="40px"
                fontSize="fs.12"
                fontWeight="fw.500"
                color="white"
                _hover={{ border: "1px solid #713EDD", background: "#F6F2FD", color: "#713EDD" }}>
                Add
                {isUpdating && <Spinner size="sm" ml={2} />}
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </FormProvider>

    </Modal>

  </>);
};

export default SkillsEditDialog;