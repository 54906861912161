import { Avatar, Box, Button, Divider, Flex, Text, useToast } from "@chakra-ui/react";
import { IconSend2, IconX } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { isEqual, orderBy } from "lodash";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select, { components } from 'react-select';
import { GET_SEARCH_USERS } from "../../../apis/constants.api";
import { searchUsersAPI } from "../../../apis/users.api";
import { AppContext } from "../../../contexts/AppContext";
import { ChatContext } from "../../../contexts/ChatContexts";
import useDebounce from "../../../hooks/useDebounce.hook";
import { useSocket } from '../../Socket';
import { TbLoader3 } from "react-icons/tb";

export default function NewMessageView() {
  const [inputSearch, setSearch] = useState('');
  const [selectedValue, setValue] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const debouncedSearch = useDebounce(inputSearch);
  const [appState] = useContext(AppContext);
  const [chatState] = useContext(ChatContext);
  const { directs } = chatState;
  const navigate = useNavigate();
  const toast = useToast();
  const { socket } = useSocket();

  const { data: usersData, isLoading } = useQuery({
    queryKey: [GET_SEARCH_USERS, { search: debouncedSearch }],
    queryFn: searchUsersAPI,
    select: (data) => data?.data?.users?.map(user => ({
      value: user?._id,
      label: user?.name,
      fullObj: user
    }))
  });

  const directId = useMemo(() => {
    const membersToHave = orderBy([...selectedValue.map(sv => sv.value), appState.user?._id]);
    const direct = directs.find(direct => {
      const membersHave = orderBy(direct?.members?.map?.(member => member?.user?._id));
      return isEqual(membersHave, membersToHave);
    });

    return direct?._id;
  }, [selectedValue, directs, appState.user?._id]);

  const handleValueChange = (newValue) => {
    setValue(newValue);
  };


  useEffect(() => {
    if (directId && isLoader) {
      navigate(`/message/direct/${directId}`);
    }
  }, [directId]);


  const redirectToDirect = useCallback(async () => {
    if (directId) {
      setIsLoader(true);
      await navigate(`/message/direct/${directId}`);
      setIsLoader(false);
    }
    else {
      const directMembers = [...selectedValue.map(sv => sv.value), appState.user?._id];
      setIsLoader(true);
      socket.emit("DIRECT_CREATE", { userIds: directMembers }, (response) => {
        if (response?.data?._id) {
          setIsLoader(false);
          navigate(`/message/direct/${response.data._id}`);
        } else {
          toast({
            title: 'Error',
            description: "We are having a problem creating the direct message.",
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
      });
    }
  }, [directId, selectedValue, appState.user?._id, socket, navigate, toast]);

  const UserOption = (props) => {
    const { data: { fullObj }, selectOption } = props;
    return (
      <Box px={2} key={fullObj?._id} onClick={() => selectOption(props.data)} cursor={'pointer'}>
        <Flex my={2} gap={2} alignItems={'center'}>
          <Avatar size="20px" sx={{ borderRadius: 5, img: { borderRadius: 5 } }} src={fullObj?.profilePicture} name={fullObj?.name} />
          <Box>
            <Text fontWeight={'semibold'}>{fullObj?.name}</Text>
            <Text fontSize={'xs'}>{fullObj?.email}</Text>
          </Box>
        </Flex>
        <Divider />
      </Box>
    );
  };

  const MultiValueLabel = (props) => {
    const { data: { fullObj } } = props;
    return (
      <Flex gap={2} alignItems={'center'} pr={2}>
        <Avatar size="sm" sx={{ borderRadius: 5, img: { borderRadius: 5 } }} src={fullObj?.profilePicture} name={fullObj?.name} />
        <Box>
          <Text fontSize={'sm'} fontWeight={'semibold'}>{fullObj?.name}</Text>
        </Box>
      </Flex>
    );
  };

  const MultiValueRemove = (props) => {
    return (<components.MultiValueRemove {...props}>
      <IconX stroke={2} size={15} />
    </components.MultiValueRemove>);
  };

  return (
    <Box className="new-direct" bg="white" margin="35px" width="100%" flex={'1 1 auto'}>
      <Box py={2} borderBottom={'2px solid'} borderColor={'primary'}>
        <Text fontWeight={'semibold'} fontSize={'2xl'} p={2} px={6}>New Message</Text>
        <Divider colorScheme="blackAlpha" borderColor={'blackAlpha.400'} />
        <Flex pt={2} px={4}>
          <Select
            autoFocus
            menuIsOpen
            isMulti
            options={usersData}
            isLoading={isLoading}
            placeholder="To: Someone or someone@example.com"
            components={{ Option: UserOption, MultiValueLabel, MultiValueRemove }}
            styles={{
              container: (otherProps) => ({
                ...otherProps,
                backgroundColor: 'white',
                flex: '1 1 auto'
              }),
              control: (otherProp) => ({
                ...otherProp,
                backgroundColor: 'transparent',
                border: 'none !important',
                boxShadow: 'transparent !important',
                outline: 'none'
              }),
              dropdownIndicator: () => ({
                display: 'none'
              }),
              indicatorSeparator: () => ({ display: 'none' })
            }}
            onInputChange={setSearch}
            onChange={handleValueChange}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                redirectToDirect();
              }
            }}
          />
          {
            selectedValue.length > 0 &&
            <Button
              variant={'filled'}
              bg="primary"
              px={1}
              color="white"
              onClick={redirectToDirect}
            >
              {isLoader ? <TbLoader3 className="animate-spin text-xl text-white" /> : <IconSend2 stroke={2} />
              }

            </Button>
          }
        </Flex>
      </Box>
    </Box>
  );
}