import React , { useState , useEffect } from 'react';
import {
  Tr, Td, Box, Image, Text, Flex, Button, Modal, ModalOverlay, ModalBody,
  ModalHeader, ModalContent, ModalCloseButton, useDisclosure, useToast, Stack,
  ModalFooter, Input, CircularProgress
} from '@chakra-ui/react';
import { useLocation , useNavigate } from 'react-router-dom';
import { preSignedUrl } from '../../../apis/ebook.api';
import { Worker, Viewer, RenderPageProps } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const EBookView = () => {
  const pdfUrlNew = localStorage.getItem("url");
  const navigate = useNavigate(); // Initialize useNavigate hook
  const { onOpen, isOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [modalType, setModalType] = useState('');
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  useEffect(() => {
    if (pdfUrlNew) {
      const observer = new MutationObserver(() => {
        removeToolbarButtons();
      });

      observer.observe(document.body, { childList: true, subtree: true });
      removeToolbarButtons();

      const timer = setTimeout(() => {
        localStorage.removeItem("url");
        console.log("PDF URL cleared from localStorage after 2 minutes");
      }, 120000);

      return () => {
        observer.disconnect();
        clearTimeout(timer);
      };
    } else {
      navigate('/library');
      const observer = new MutationObserver(() => {
        removeToolbarButtons();
      });

      observer.observe(document.body, { childList: true, subtree: true });
      removeToolbarButtons();
      return () => observer.disconnect();
    }
  }, [pdfUrlNew]);

  const removeToolbarButtons = () => {
    const selectors = [
      '[data-testid="get-file__download-button"]',
      '[data-testid="open__button"]',
      '[data-testid="print__button"]',
      '[data-testid="toolbar__more-actions-popover-target"]',
    ];

    selectors.forEach(selector => {
      const button = document.querySelector(selector);
      if (button) {
        const parentDiv = button.closest('.rpv-toolbar__item');
        if (parentDiv) {
          parentDiv.remove();
        }
      }
    });
  };

  return (
    <>
      {pdfUrlNew ? (
        <div style={{height:"92vh"}} >
          <Worker workerUrl='/pdf.worker.min.js'>
            <Viewer
              fileUrl={pdfUrlNew}
              plugins={[defaultLayoutPluginInstance]}
            />
          </Worker>
        </div>
      ) : (
        <div>Please select a PDF to view</div>
      )}
    </>
  );
};

export default EBookView;
