import {
	Box,
	Image,
	Table,
	TableContainer,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { GET_PRODUCTS } from '../../../apis/constants.api';
import { getProducts } from '../../../apis/products.api';
import Style from "../../style.module.css";
import ProductListItem from './ProductListItem';
import useDebounce from '../../../hooks/useDebounce.hook';

export default function ProductTable({ searchQuery }) {
	const PAGE_SIZE = process.env.REACT_APP_PAGE_SIZE;
	// const PAGE_SIZE = 25;
	const [page, setPage] = useState(1);
	const debouncedSearch = useDebounce(searchQuery);

	const { data: products, isFetching: isProductsLoading } = useQuery({
		queryKey: [GET_PRODUCTS, {
			skip: PAGE_SIZE * (page - 1),
			limit: PAGE_SIZE,
			search: debouncedSearch,
		}],
		queryFn: getProducts,
	});

	useEffect(() => {
		setPage(1);
	}, [searchQuery]);

	const totalPages = (products?.data?.total || 1) / PAGE_SIZE;
	const productsList = products?.data?.data || [];

	const handlePageChange = useCallback((data) => {// data.selected start from '0'
		setPage(data.selected + 1);
	}, []);

	return (
		<>
			<Box>
				<TableContainer border="1px solid #EAECF0" borderRadius="10px" height="calc(100vh - 325px)" overflowY="auto" className={Style.overflow}>
					<Table size='sm' >
						<Thead height="50px" px="23px" py="13px" background="#F9FAFB" position="sticky" top="0" zIndex="10">
							<Tr >
								<Th textTransform="capitalize" color="#868B94" fontSize="fs.16" fontWeight="fw.400" width="102px" pl="26px" >Image</Th>
								<Th textTransform="capitalize" color="#868B94" fontSize="fs.16" fontWeight="fw.400" width="774px" >Name</Th>
								<Th textTransform="capitalize" color="#868B94" fontSize="fs.16" fontWeight="fw.400" width="149px" isNumeric>Price</Th>
								<Th textTransform="capitalize" color="#868B94" fontSize="fs.16" fontWeight="fw.400" width="231px">Quantity</Th>
								<Th textTransform="capitalize" color="#868B94" fontSize="fs.16" fontWeight="fw.400" width="167px">Ratings</Th>
								<Th textTransform="capitalize" color="#868B94" fontSize="fs.16" fontWeight="fw.400" width="167px">Published</Th>
								<Th textTransform="capitalize" color="#868B94" fontSize="fs.16" fontWeight="fw.400" width="167px">Published At</Th>
								<Th textTransform="capitalize" color="#868B94" fontSize="fs.16" fontWeight="fw.400" width="20px" isNumeric pr="26px">View</Th>
								<Th textTransform="capitalize" color="#868B94" fontSize="fs.16" fontWeight="fw.400" width="20px" isNumeric pr="26px">Actions</Th>
							</Tr>
						</Thead>
						<Tbody >
							{isProductsLoading && <Tr><Th colSpan={9} textAlign={'center'}> LOADING...</Th></Tr>}
							{productsList.map((product) => <ProductListItem key={product._id} product={product} />)}
							{!isProductsLoading && !productsList.length && <Tr><Th colSpan={10} textAlign={'center'}>NO PRODUCTS FOUND</Th></Tr>}
						</Tbody>
					</Table>
				</TableContainer>
			</Box>
			<Box position="relative" mt="20px">
				<ReactPaginate
					pageCount={Math.ceil(totalPages)}
					containerClassName={"paginationButtons"}
					disabledClassName={"paginationDisabled"}
					activeClassName={"paginationActive"}
					previousLabel={<span>&larr; Previous</span>}
					nextLabel={<span>Next &rarr;</span>}
					onPageChange={handlePageChange}
					forcePage={page - 1}
				/>
			</Box>
		</>
	);
}
