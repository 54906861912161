import { uniq } from 'lodash';
import isEqual from 'lodash/isEqual';
import React, { useCallback, useContext, useEffect, createContext, useState } from 'react';
import { io } from 'socket.io-client';
import { AppContext } from '../../contexts/AppContext';
import { ChatContext } from '../../contexts/ChatContexts';

const SocketContext = createContext(null);
let socket;

function SocketProvider({ children }) {
  const [stateApp] = useContext(AppContext);
  const [chatState, ___, { addMessage, refetchChannels, refetchDirects, endCall, refetchUnreadCount, refetchLastMessages, channelDialogToogle, updateAllChannels }] = useContext(ChatContext);

  const [onlineUsers, setOnlineUsers] = useState({});

  const { user } = stateApp;
  const { channels, directs, loaders: { isChannelLoading, isDirectsLoading } } = chatState;

  const [callCreatedData, setCallCreatedData] = useState(null);
  const [callEndStatus, setCallEndStatus] = useState(null);
  const [tokenData, setTokenData] = useState(null);

  const handleNewMessage = useCallback((data) => {
    const { channelId } = data?._doc || {};

    addMessage(channelId, [data?._doc]);
  }, []);

  useEffect(() => {
    if (user?._id) {
      socket = io(process.env.REACT_APP_API_URL, {
        query: {
          userId: user._id
        }
      });

      socket?.on?.('connect_error', (error) => {
        console.error('Socket connection error:', error);
      });

      socket?.on?.('disconnect', () => {
        console.warn('Socket disconnected');
      });

      socket?.on?.(user._id, (data) => {
        console.log('%c >>> USER SOCKET EVENT  ', "color:blue", data);

        switch (data?.identifier) {
          case "MESSAGE_NEW":
            return handleNewMessage(data), refetchUnreadCount(), refetchLastMessages();
          case "DIRECT_NEW":
            return refetchDirects();
          case "DIRECT_EDIT":
          case "DIRECT_DELETE":
            return refetchDirects();
          case "CHANNEL_NEW":
            updateAllChannels();
            channelDialogToogle();
            return refetchChannels();
          case "CHANNEL_EDIT":
          case "CHANNEL_DELETE":
            return refetchChannels();
          case "CALL_CREATED":
            setCallCreatedData(data);
            return;
          case "CALL_FINISHED":
            endCall(data.channelId, data._id, data.duration);
            setCallEndStatus(data);
            refetchLastMessages();
            setCallCreatedData(null);
            return;
          case "VIDEO_SDK_TOKEN_CREATED":
            setTokenData(data);
            return;
          default:
            break;
        }
      });

      socket?.on?.('presenceUpdate', (update) => {
        setOnlineUsers(prevUsers => ({ ...prevUsers, ...update }));
      });

      socket?.on('reconnect', () => {
        console.log('Reconnected to socket server');
      });

    }

    return () => {
      socket?.disconnect();
      socket = null;
    };
  }, [user, user?._id]);

  useEffect(() => {
    let usersToTrack = [];

    if (!isChannelLoading) {
      const users = channels.map(channel => channel.members.map(member => member.user?._id));

      usersToTrack.push(...users.flat());
    }
    if (!isDirectsLoading) {
      const users = directs.map(direct => direct.members.map(member => member.user?._id));

      usersToTrack.push(...users.flat());
    }

    usersToTrack = uniq(usersToTrack.filter(ut => ut));

    socket?.emit?.('subscribeToPresence', usersToTrack);

    return () => {
      socket?.emit?.('unsubscribeFromPresence', usersToTrack);
    };
  }, [user, channels, directs, isChannelLoading, isDirectsLoading]);

  const clearCallEndStatus = useCallback(() => {
    setCallEndStatus(null);
  }, []);

  const clearTokenData = useCallback(() => {
    setTokenData(null);
  }, []);



  return (
    <SocketContext.Provider value={{ onlineUsers: onlineUsers, socket, callCreatedData, callEndStatus, clearCallEndStatus, tokenData, clearTokenData }}>
      {children}
    </SocketContext.Provider>
  );
}

export default React.memo(SocketProvider, (pp, np) => isEqual(pp, np));

export const useSocket = () => {
  return useContext(SocketContext);
};
