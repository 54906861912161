import React, { useState } from 'react';
import {
  IconChevronLeft,
  IconChevronRight,
  IconDownload,
  IconX,
  IconExternalLink
} from '@tabler/icons-react';

const FileViewer = ({ urls, setIsDocViewerOpen }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : urls.length - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex < urls.length - 1 ? prevIndex + 1 : 0));
  };

  const handleDownload = () => {
    window.open(urls[currentIndex], '_blank');
  };

  const handleClose = () => {
    // Implement close functionality here
    setIsDocViewerOpen(false);
  };

  const handleOpenNewTab = () => {
    window.open(urls[currentIndex], '_blank');
  };

  const renderFileContent = (url) => {
    const fileExtension = url.split('.').pop().toLowerCase();

    switch (fileExtension) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'webp':
      case 'tiff':
      case 'gif':
      case 'bmp':
      case 'ico':
      case 'heic':
      case 'heif':
      case 'raw':
      case 'cr2':
      case 'nef':
      case 'arw':
      case 'dng':
      case 'avif':
        return <img src={url} alt="File content" className="h-full w-full max-w-full max-h-full object-contain" />;
      case 'mp3':
      case 'wav':
      case 'ogg':
      case 'aac':
      case 'flac':
      case 'm4a':
      case 'wma':
      case 'alac':
      case 'mid':
      case 'midi':
      case 'amr':
      case 'mpeg':
        return <audio src={url} controls className="w-full" />;
      case 'mp4':
      case 'webm':
      case 'wmv':
      case 'flv':
      case 'mkv':
      case 'm4v':
      case '3gp':
      case 'mpg':
      case 'mov':
      case 'ogv':
      case 'ts':
      case 'vob':
        return <video src={url} controls className="h-full w-full max-w-full max-h-full" />;
      case 'pdf':
        // case 'xlsx':
        return <iframe src={url} className="w-full h-full" title="PDF Viewer" />;
      default:
        return <p>Unsupported file type</p>;
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-[0.2] flex items-center justify-center">
      <div className="bg-white rounded-lg p-4 max-w-[79rem] w-full max-h-[90vh] h-full flex flex-col">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">File Viewer</h2>
          <div className="flex space-x-2">
            <button onClick={handleDownload} className="p-2 hover:bg-gray-100 rounded">
              <IconDownload size={24} />
            </button>
            <button onClick={handleOpenNewTab} className="p-2 hover:bg-gray-100 rounded">
              <IconExternalLink size={24} />
            </button>
            <button onClick={handleClose} className="p-2 hover:bg-gray-100 rounded">
              <IconX size={24} />
            </button>
          </div>
        </div>
        <div className="flex-grow overflow-auto flex items-center justify-center">
          {renderFileContent(urls[currentIndex])}
        </div>
        <div className="flex justify-between items-center mt-4">
          <button onClick={handlePrevious} className="p-2 hover:bg-gray-100 rounded">
            <IconChevronLeft size={24} />
          </button>
          <span>{`${currentIndex + 1} / ${urls.length}`}</span>
          <button onClick={handleNext} className="p-2 hover:bg-gray-100 rounded">
            <IconChevronRight size={24} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default FileViewer;