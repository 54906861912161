
import { Box } from '@chakra-ui/react';
import GroupContent from '../../Groups';

function GroupPage() {

  return (
    <Box className="page groups-page">
      <GroupContent />
    </Box>
  );
}

export default GroupPage;