import { useEffect, useState } from "react";
import { IconBold, IconCode, IconUnderline, IconH1, IconPhotoPlus, IconQuote, IconItalic, IconList, IconListNumbers } from '@tabler/icons-react';
import { Editor } from "slate";
import { useSlate } from "slate-react";
import { css, cx } from "@emotion/css";
import { Box, Button, ButtonGroup } from "@chakra-ui/react";
import {
  isMarkActive,
  toggleBlock,
  toggleMark,
} from "./SlateEditorUtil";
import { InsertImageButton } from "./SlateImage";
import { isBlockActive } from "../Message/MessageInput/ulility";
import { AddLinkButton, RemoveLinkButton } from "../Message/MessageInput/Buttons";

const BlockButton = ({ format, icon: Icon }) => {
  const editor = useSlate();

  return (
    <Button
      aria-label={format}
      // icon={<Icon />}
      size={'sm'}
      variant={"unstyled"}
      border={'1px solid'}
      borderColor={'gray.200'}
      p={1}
      {...isBlockActive(
        editor,
        format
      ) ? {
        backgroundColor: "gray.100"
      } : {}}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      <Box
        display={"flex"}
        justifyContent={'center'}
        alignItems={'center'}>
        <Icon size={18} stroke={2} />
      </Box>
    </Button>
  );
};

const MarkButton = ({ format, icon: Icon }) => {
  const editor = useSlate();
  return (
    <Button
      p={1}
      aria-label={format}
      size={'sm'}
      variant={"unstyled"}
      border={'1px solid'}
      borderColor={'gray.200'}
      {...isMarkActive(
        editor,
        format
      ) ? {
        backgroundColor: "gray.100"
      } : {}}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      <Box
        display={"flex"}
        justifyContent={'center'}
        alignItems={'center'}>
        <Icon size={18} stroke={2} />
      </Box>
    </Button>
  );
};
export const SlateToolbar = () => {
  return (
    <Box display="flex" p={2} padding="5px"
      margin=" 0px"
      backgroundColor="gray.300"
      borderRadius=" 10px 10px 0 0" className={cx(
        css`
          & > * {
            display: inline-block;
          }

          & > * + * {
            margin-left: 5px;
          }
        `
      )}>
      <MarkButton format="bold" icon={IconBold} />
      <MarkButton format="italic" icon={IconItalic} />
      <MarkButton format="underline" icon={IconUnderline} />
      <MarkButton format="code" icon={IconCode} />
      <BlockButton format="heading-one" icon={IconH1} />
      <BlockButton format="heading-two" icon={IconH1} />
      <BlockButton format="block-quote" icon={IconQuote} />
      <BlockButton format="numbered-list" icon={IconListNumbers} />
      <BlockButton format="bulleted-list" icon={IconList} />
      <ButtonGroup size="sm" isAttached variant="outline" mr={1}>
        <InsertImageButton icon={IconPhotoPlus} />
      </ButtonGroup>
      <AddLinkButton />
      <RemoveLinkButton />
    </Box >
  );
};

export const HoveringToolbar = () => {
  const editor = useSlate();
  const [showToolbar, setShowToolbar] = useState(false);
  const { selection } = editor;
  useEffect(() => {
    if (!selection || Editor.string(editor, selection) === "") {
      setShowToolbar(false);
    } else {
      setShowToolbar(true);
    }
  }, [selection, editor]);

  return (
    <Box hidden={!showToolbar}>
      <SlateToolbar />
    </Box>
  );
};
