import { Box, Grid, GridItem, Skeleton, SkeletonCircle, SkeletonText } from "@chakra-ui/react";
import { nanoid } from "nanoid";
import { useMemo } from "react";

export default function BookCardSkeleton({ noOfCards = 1 }) {
  const array = useMemo(() => {
    return new Array(noOfCards).fill('').map(item => nanoid());
  }, [noOfCards]);

  return (
    <Grid templateColumns="repeat(auto-fill, minmax(280px, 1fr))" gap={12}>
      {
        array.map(item => (
          <GridItem key={`book_card_skeleton_` + item} padding='6' boxShadow='sm' bg='white' w={'100%'} borderRadius={'br.10'}>
            <Skeleton
              height='240px'
              bg='green.500'
              color='white'
              fadeDuration={1}
            ></Skeleton>
            <SkeletonText mt='4' noOfLines={1} spacing='4' skeletonHeight='4' />
            <Box display="flex">
              <SkeletonText mt='4' noOfLines={1} spacing='4' skeletonHeight='6'
                width="50px" />
              <SkeletonText mt='4' ml='3' noOfLines={1} spacing='4' skeletonHeight='6'
                width="50px" />
            </Box>

          </GridItem>
        ))
      }
    </Grid>
  );
}