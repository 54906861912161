export function isSysAdmin(user) {
  return user?.role === "SysAdmin";
}

export function isAdmin(user, strictly) {
  if (strictly) {
    return user?.role === "Admin";
  }

  return ["SysAdmin", "Admin"].includes(user?.role);
}

export function isUserAccepted(user) {
  if (user?.status === "ACCEPTED") {
    return true;
  }

  return false;
}