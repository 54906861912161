import { Avatar, Box, Flex, Text } from "@chakra-ui/react";
import { faArrowUpRightFromSquare, faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { ChatContext } from "../../../../../../../contexts/ChatContexts";
import { Link } from "react-router-dom";

export default function YourGroups() {
    const [chatState] = useContext(ChatContext);
    const { channels } = chatState || {};

    return (
        <Box>
            <Flex pt="35px" pb="10px" alignItems="center" justifyContent="space-between">
                <Text fontSize="fs.18" fontWeight="fw.500" textAlign="start">Your Groups</Text>
                <FontAwesomeIcon icon={faEllipsis} />
            </Flex>
            <Box paddingX="25px" paddingY="8px" borderRadius="br.10" background="white" height="245px" overflowY="scroll" display={
                channels && channels.length === 0 ? "flex" : "block"
            }
                alignItems={
                    channels && channels.length === 0
                        ? "center"
                        : "unset"
                }
                justifyContent={
                    channels && channels.length === 0
                        ? "center"
                        : "unset"
                }>
                {channels && channels.length > 0 ? (
                    channels.map((channel, index) => (
                        <div key={index}>
                            <Flex mt="15px" alignItems="center" gap="15px">
                                <Avatar
                                    size="md"
                                    name={channel?.name}
                                    src={channel?.iconURL || ""}
                                    sx={{
                                        img: { objectFit: "contain" },
                                    }}
                                    border="1px solid gray.300"
                                />
                                <Box>
                                    <Text fontSize="fs.18" fontWeight="fw.500">{channel.name}</Text>
                                </Box>
                                <Link to={`/message/channel/${channel?._id}`}>
                                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="cursor-pointer" />
                                </Link>
                            </Flex>
                            <div className="flex w-[100%] bg-gray-50 h-[2px] mt-2" />
                        </div>
                    ))
                ) : (
                    <Text className="text-lg" color="black.500" textAlign="center">
                        No groups available.
                    </Text>
                )}
            </Box>
        </Box>
    );
}
