import { Box, Flex, Image, Text } from "@chakra-ui/react";
import Logo from "../../assets/logo/signin-signuplogo.png";
import partnership from "../../assets/bgimg/partnership.png";

// import { Trans } from "@lingui/macro";
import React, { useState } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Style from '../Slider/style.module.css';

export default function SliderComponent() {

    return (
        <Flex width={{ base: "100%", lg: "100%", xl: "69.5%" }} minHeight={{ xl: "100vh", '2xl': "100vh" }}>
            <Box overflow="none" background="linear-gradient(148.84deg, #713EDD 12.15%, #A17DEE 79.83%)" minHeight="100vh" display={{ base: "none", lg: "flex" }} alignItems="center" width="100%">
                <Flex zIndex={{ base: "2", xl: "0" }} flexDirection="column" width={{ base: "100%", lg: "52%", xl: "100%" }} height='100%' >
                    <Box width="100%" display="flex" justifyContent="start" alignItems="start">
                        <Box pt="50px" pl="50px" width={{ base: "50px", lg: "auto" }} height={{ base: "50px", lg: "auto" }}><Image alt='Logo' src={Logo} /></Box>
                    </Box>
                    <Flex width={{ base: "100%", xl: "75%" }} height={{ base: "auto", lg: "100%", xl: "100%" }} justifyContent="center" alignItems="center" flexDirection="column">
                        <div style={{ width: "112%", display: 'flex', justifyContent: 'center' }} className={Style.div_slide}>
                            <Image width="auto" height="calc(100% - 100px)" src={partnership} />
                        </div>
                    </Flex>
                </Flex>
            </Box >
        </Flex>
    );
}