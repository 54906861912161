export function convertArrayToOptionLabels(array, labelKey, valueKey) {
    return array?.map?.(item => ({ value: item[valueKey], label: item[labelKey], fullObject: item }));
}

export function queryStringToObject(queryString) {
    const params = new URLSearchParams(queryString);
    const obj = {};
    for (const [key, value] of params) {
        if (obj.hasOwnProperty(key)) {
            if (Array.isArray(obj[key])) {
                obj[key].push(value);
            } else {
                obj[key] = [obj[key], value];
            }
        } else {
            obj[key] = value;
        }
    }
    return obj;
}

export function objectToQueryString(obj) {
    const keyValuePairs = [];
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) { // Ensure the key is directly on the object
            if (Array.isArray(obj[key])) { // Handle array values
                obj[key].forEach(value => {
                    keyValuePairs.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
                });
            } else if (obj[key] !== null && typeof obj[key] === 'object') { // Handle nested objects
                // Recursive call to handle nested objects (you might flatten it or handle it differently depending on your needs)
                const nestedQueryString = objectToQueryString(obj[key]);
                keyValuePairs.push(nestedQueryString);
            } else {
                // Handle other types like string, number, etc.
                keyValuePairs.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
            }
        }
    }
    return keyValuePairs.join('&');
}
