import { Box, Flex, FormControl, FormLabel, Input, Switch, Text, Textarea } from "@chakra-ui/react";
import { useCallback } from "react";
import { Controller } from "react-hook-form";
import ReactSelect from "react-select";
import ImageDropZone from "../../Dropzone/ImageDropzone";
import Style from '../../style.module.css';
import { regionsOption } from "../Constants";

const BasicFields = ({ channel, control, errors }) => {
  const selectedRegion = useCallback((value) => value ? regionsOption?.find(op => op.value === value) : null, []);
  return (
    <>
      <Box mt="20px" px={1}>
        <Box>
          <Controller
            name="iconURL"
            control={control}
            render={({ field, fieldState }) =>
              <ImageDropZone
                {...field}
                innerMessage='Upload Channel Icon'
                acceptedFiles={{ 'image/*': ['jpg', 'jpeg', 'png', 'webp'] }}
                maxFiles={5}
                defaultImages={channel?.iconURL ? [channel?.iconURL] : []}
                onFileChange={(files) => field.onChange(files[0])}
                error={errors?.iconURL?.message || fieldState?.['iconURL']?.message}
              />
            }
          />
        </Box>
        <Flex gap="20px" >
          <Box mt="20px" width="100%">
            <Text fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} fontWeight="fw.500">Title</Text>
            <Controller
              name="name"
              control={control}
              defaultValue={channel?.name}
              rules={{ required: true }}
              render={({ field, fieldState }) =>
                <>
                  <Input {...field}
                    errorBorderColor="red.500"
                    isInvalid={errors?.name?.message || fieldState?.['name']?.message}
                    placeholder="Enter title" height="50px" _hover={{ borderColor: "#713EDD" }} mt="8px" _focusVisible={{ borderColor: "#713EDD" }} />
                  {(errors?.name?.message || fieldState?.['name']?.message)
                    && <Text size={'fs.14'} fontSize={'sm'} mt={1} color="red.500">{errors?.name?.message || fieldState?.['name']?.message}</Text>}
                </>
              }
            />
          </Box>
        </Flex>
        <Box mt="20px">
          <Text fontSize="fs.16" fontWeight="fw.500">Description</Text>
          <Controller
            name="description"
            defaultValue={channel?.description}
            control={control}
            rules={{ required: true }}
            render={({ field }) =>
              <Textarea {...field} height="50px" mt="8px" _focusVisible={{ borderColor: "#713EDD" }} borderRadius="10px" placeholder='Description' />
            }
          />
        </Box>
        <Flex gap="20px" mt="20px">
          <Box w="100%">
            <Text fontSize="fs.16" fontWeight="fw.500" mb="8px">Region</Text>
            <Controller
              name="region"
              control={control}
              defaultValue={regionsOption.find((e) => e?.value === channel?.region)}
              rules={{ required: true }}
              render={({ field, fieldState }) =>
                <>
                  <ReactSelect
                    defaultValue={selectedRegion(field.value)}
                    isSearchable={true}
                    onChange={(value) => field.onChange(value.value)}
                    placeholder='Select region...'
                    options={regionsOption}
                    className={Style.select_controller}
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  />
                  {(errors?.region?.message || fieldState?.['region']?.message)
                    && <Text size={'fs.14'} fontSize={'sm'} mt={1} color="red.500">{errors?.region?.message || fieldState?.['region']?.message}</Text>}
                </>
              }
            />
          </Box>

        </Flex>


        <Box mt="20px" mb="27px">
          <FormControl>
            <FormLabel fontSize="fs.16" fontWeight="fw.500" mb="8px" >Open</FormLabel>
            <Controller
              name="open"
              control={control}
              defaultValue={channel?.open}
              render={({ field }) =>
                <Switch colorScheme='purple' size='lg'
                  onChange={(value) => field.onChange(value)} />
              }
            />

          </FormControl>
        </Box>

      </Box>
    </>);
};
export default BasicFields;