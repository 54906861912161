import React, { useState } from "react";
// import { loadTranslation } from "../../utilities/language"

import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Input,
    List,
    ListItem,
    Spinner,
    Text,
    useToast
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { setPassword } from "../../../apis/auth.api";
import { GET_USER_DATA } from "../../../apis/constants.api";
import SignOutText from "../../SingoutText";
import SliderComponent from "../../Slider/index";
import Style from "../../style.module.css";
import { lengthCheck, oneLowercaseLetterCheck, oneNumberLetterCheck, oneSpecialCharacterCheck, oneUppercaseLetterCheck } from "../SignUp/formschema";
import { setPasswordFormSchema } from "./formSchema";
import PasswordField from "../../PasswordField/PasswordField";

const SetNewPassword = props => {
    let { token } = useParams();
    const [isPasswordFocused, setPasswordFocus] = useState(false);
    const [isError, setIsError] = useState(false);

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm({
        defaultValues: {
            password: "",
            confirmPassword: ""
        },
        resolver: yupResolver(setPasswordFormSchema)
    });
    const password = watch('password');
    const navigate = useNavigate();
    const toast = useToast();
    const queryClient = useQueryClient();

    const { mutate, isLoading } = useMutation(setPassword, {
        onSuccess: data => {
            if (!data?.data?.success) {
                setIsError(true);
                toast({
                    position: 'top-right',
                    status: 'error',
                    title: 'Error',
                    description: data?.data?.message
                });
            } else {
                queryClient.invalidateQueries({ queryKey: [GET_USER_DATA] });
                navigate("/signin");
            }
        },
        onError: (error) => {
            if (error?.response?.data?.message) {
                setIsError(true);
                toast({
                    position: 'top-right',
                    status: 'error',
                    title: 'Error',
                    description: error?.response?.data?.message
                });
            }
        }
    });
    const onSubmit = data => {
        const payload = {
            password: data.password,
            token: token
        };

        mutate(payload);
    };
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Flex flexDirection={{ base: "column", lg: "unset" }}>
                    <SliderComponent />
                    <Flex
                        background={{
                            sm: "linear-gradient(148.84deg, #713EDD 12.15%, #A17DEE 79.83%)",
                            xl: "white"
                        }}
                        position={{ lg: "absolute", xl: "unset" }}
                        py={{ base: "0px", md: "30px", lg: "25px", xl: "0" }}
                        pl={{ base: "0px", md: "30px", lg: "25px", xl: "0" }}
                        pr={{ base: "0px", md: "30px", lg: "25px", xl: "0" }}
                        alignItems="center"
                        width={{ base: "100%", xl: "35%" }}
                        height={{ base: "100vh", xl: "auto" }}
                        justifyContent={{ base: "space-between", xl: "end" }}
                    >
                        <Flex
                            overflow="hidden"
                            height={{ base: "auto", md: "auto", lg: "100%", xl: "auto" }}
                            padding={{ lg: "20px" }}
                            ml="auto"
                            width={{ base: "100%", md: "100%", lg: "50%", "2xl": "50%" }}
                            alignItems="center"
                            position={{ base: "unset", xl: "absolute" }}
                            right="0"
                            justifyContent={{
                                base: "center",
                                lg: "end",
                                xl: "space-between"
                            }}
                        >
                            <Box
                                className={Style.overflow}
                                padding={{ base: "25px", lg: "25px", "2xl": "50px" }}
                                boxShadow={{
                                    base: "0",
                                    md: "rgba(0, 0, 0, 0.35) 0px 5px 15px"
                                }}
                                width={{ base: "500px", xl: "80%" }}
                                height={{ base: "100%" }}
                                borderRadius={{ base: "20px", md: "20px" }}
                                bgColor="white"
                            >
                                {isError ? (<Text textAlign="center"
                                    fontSize={{
                                        base: "fs.15",
                                        md: "fs.10",
                                        lg: "fs.10",
                                        xl: "fs.18"
                                    }}
                                    fontWeight="fw.600">Sorry, your password reset link expired.</Text>) : (

                                    <Box>

                                        <Box px={{ base: "0", xl: "30px" }}>
                                            <Text
                                                textAlign="center"
                                                fontSize={{
                                                    base: isError ? "fs.20" : "fs.25",
                                                    md: isError ? "fs.25" : "fs.30",
                                                    lg: isError ? "fs.25" : "fs.30",
                                                    xl: isError ? "fs.30" : "fs.38",
                                                }}
                                                fontWeight="fw.600"
                                            >
                                                Create New Password
                                            </Text>
                                            <Text
                                                textAlign="center"
                                                fontSize={{
                                                    base: "fs.12",
                                                    md: "fs.14",
                                                    ld: "fs.16",
                                                    xl: "fs.18"
                                                }}
                                                fontWeight="fw.400"
                                                pt="10px"
                                                pb="20px"
                                                color="#868B94"
                                            >


                                            </Text>
                                            <hr />
                                        </Box>

                                        <FormControl
                                            mt={{ base: "30px", md: "30px", lg: "40px", xl: "70px" }}
                                        >
                                            <FormLabel
                                                fontSize={{
                                                    base: "fs.12",
                                                    md: "fs.14",
                                                    lg: "fs.16",
                                                    xl: "fs.18"
                                                }}
                                                fontWeight="fw.500"
                                            >
                                                New Password
                                            </FormLabel>
                                            {/* <Input
                                                {...register("password")}
                                                isDisabled={isLoading}
                                                type="password"
                                                border="1.5px solid rgba(210, 210, 210, 0.5)"
                                                focusBorderColor="#713EDD"
                                                placeholder="*******"
                                                fontSize={{ base: "fs.12", md: "fs.14", ld: "fs.16" }}
                                                fontWeight="fw.400"
                                                borderRadius="10px"
                                                height={{ base: "40px", md: "50px", "2xl": "55px" }}
                                                onFocus={() => setPasswordFocus(true)}
                                                onBlur={() => setPasswordFocus(false)}
                                            /> */}
                                            <PasswordField placeholder={`*******`} register={register('password')} onFocus={() => setPasswordFocus(true)}
                                                onBlur={() => setPasswordFocus(false)} />
                                            {errors.password && (
                                                <Text color="#E1251A">{errors.password.message}</Text>
                                            )}
                                            {
                                                isPasswordFocused &&
                                                <List className='show'>
                                                    <ListItem color={lengthCheck.test(password) ? 'green.500' : 'initial'} listStyleType={lengthCheck.test(password) ? 'disc' : 'circle'}>
                                                        <Text fontSize="xs">Password length must be 7-12 characters long.</Text>
                                                    </ListItem>
                                                    <ListItem color={oneUppercaseLetterCheck.test(password) ? 'green.500' : 'initial'} listStyleType={oneUppercaseLetterCheck.test(password) ? 'disc' : 'circle'}>
                                                        <Text fontSize="xs">Password must include at least 1 Uppercase letter.</Text>
                                                    </ListItem>
                                                    <ListItem color={oneLowercaseLetterCheck.test(password) ? 'green.500' : 'initial'} listStyleType={oneLowercaseLetterCheck.test(password) ? 'disc' : 'circle'}>
                                                        <Text fontSize="xs">Password must include at least 1 Lowercase letter.</Text>
                                                    </ListItem>
                                                    <ListItem color={oneNumberLetterCheck.test(password) ? 'green.500' : 'initial'} listStyleType={oneNumberLetterCheck.test(password) ? 'disc' : 'circle'}>
                                                        <Text fontSize="xs">Password must include at least 1 number letter.</Text>
                                                    </ListItem>
                                                    <ListItem color={oneSpecialCharacterCheck.test(password) ? 'green.500' : 'initial'} listStyleType={oneSpecialCharacterCheck.test(password) ? 'disc' : 'circle'}>
                                                        <Text fontSize="xs">Password must include at least 1 special character.</Text>
                                                    </ListItem>
                                                </List>
                                            }
                                            <FormLabel
                                                pt="25px"
                                                fontSize={{
                                                    base: "fs.12",
                                                    md: "fs.14",
                                                    lg: "fs.16",
                                                    xl: "fs.18"
                                                }}
                                                fontWeight="fw.500"
                                            >
                                                Confirm New Password
                                            </FormLabel>
                                            <PasswordField disabled={isLoading} placeholder={`*******`} register={register('confirmPassword')} />
                                            {errors.confirmPassword && (
                                                <Text color="#E1251A">{errors.confirmPassword.message}</Text>
                                            )}
                                        </FormControl>

                                        <Box mt={{ base: "30px", md: "50px", lg: "40px", xl: "55px" }}>
                                            <Button
                                                isDisabled={isLoading}
                                                color="white"
                                                background="linear-gradient(150.62deg, #713EDD 5.97%, #A17DEE 64.37%)"
                                                _hover={{
                                                    background: "#F6F2FD",
                                                    color: "#713EDD",
                                                    border: "1.5px solid #713EDD"
                                                }}
                                                width="100%"
                                                height={{ base: "40px", md: "50px", "2xl": "60px" }}
                                                fontSize={{
                                                    base: "fs.12",
                                                    md: "fs.14",
                                                    lg: "fs.16",
                                                    xl: "fs.18"
                                                }}
                                                fontWeight="fw.600"
                                                borderRadius="10px"
                                                type="submit"
                                            >
                                                Submit
                                                {isLoading && <Spinner ml={1} />}
                                            </Button>
                                        </Box>

                                    </Box>
                                )}
                                <SignOutText />
                            </Box>
                        </Flex>
                    </Flex>
                </Flex>
            </form>
        </>
    );
};

export default SetNewPassword;

export const getStaticPaths = async () => {
    return {
        paths: [], //indicates that no page needs be created at build time
        fallback: "blocking" //indicates the type of fallback
    };
};
