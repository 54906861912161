import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { GET_SKILLS } from "../../../apis/constants.api";
import { addNewSkills, getSkills } from "../../../apis/skills.api";
import useDebounce from "../../../hooks/useDebounce.hook";
import InputArray from "../../MultipleFields";
import { schema } from "./form";
import SkillsTable from './SkillsTable';
const Skills = () => {

  const methods = useForm({
    defaultValues: {
      name: '',
      rss: []
    },
    resolver: yupResolver(schema)
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const PAGE_SIZE = process.env.REACT_APP_PAGE_SIZE;
  const [page, setPage] = useState(1);
  const searchQuery = methods.watch("search", "");
  const debouncedSearch = useDebounce(searchQuery);
  const queryClient = useQueryClient();

  const { data: skills, isFetching: isSkillLoadings } = useQuery({
    queryKey: [GET_SKILLS, {
      skip: PAGE_SIZE * (page - 1),
      limit: PAGE_SIZE,
      search: debouncedSearch,
    }],
    queryFn: getSkills,
  });

  useEffect(() => {
    setPage(1);
  }, [searchQuery]);

  const errors = methods.formState.errors;
  const toast = useToast();

  const { mutate, isLoading } = useMutation(addNewSkills, {
    onSuccess: data => {
      queryClient.invalidateQueries({ queryKey: [GET_SKILLS] });
      methods.reset();
      onClose();

      if (data.data.message) {
        toast({
          title: "Success",
          description: data.data.message,
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
      }
    },
    onError: (data) => {
      toast({
        title: "Error",
        description: data?.response?.data?.message || 'We are having problem adding skill. Try again later.',
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    }
  });

  const totalPages = skills?.data.totalSkills / PAGE_SIZE;
  const skillsList = skills?.data.data || [];

  const handlePageChange = useCallback((data) => {
    setPage(data.selected + 1);
  }, []);

  const onSubmit = (data) => {
    const _rss = data.rss.filter((e) => e);
    const payload = {
      name: data.name,
      rss: _rss
    };
    mutate(payload);
  };

  return (<>
    <Flex dir="column" flex="1 1 auto" padding={{ base: "15px", lg: "25px", xl: "35px" }} background="#F7F7F7" height="100%">
      <Box w="100%" background="white" borderRadius="br.10" padding="25px">

        <Flex width="100%" alignItems="center" justifyContent="space-between" gap={{ md: "10px", lg: "0" }}>
          <InputGroup width="auto">
            <InputLeftElement pointerEvents='none'>
              <FontAwesomeIcon icon={faMagnifyingGlass} style={{ color: "#A0A6B1" }} />
            </InputLeftElement>
            <Input
              {...methods.register("search")}
              focusBorderColor='#713EDD' variant='filled' width={{ lg: "auto", xl: "300px" }} type='tel' background='#EBEDF5'
              placeholder='Search' />
          </InputGroup>
          <Box pr="20px">
            <Button onClick={onOpen} px={{ md: "15px", lg: "30px" }} py="8px" border="1px solid #713EDD" fontSize="fs.16" fontWeight="fw.500" background="linear-gradient(134.59deg, #713EDD -1.98%, #A17DEE 111.17%)" _hover={{ background: "linear-gradient(134.59deg, #A17DEE -1.98% ,#713EDD 111.17%)" }} color="#FFFFFF">+ Add Skills</Button>

            <Modal closeOnOverlayClick={false} onClose={onClose} isOpen={isOpen} isCentered >
              <FormProvider {...methods} >
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <ModalOverlay />
                  <ModalContent maxWidth="599px" borderRadius="10px">
                    <ModalHeader fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16", xl: "fs.18" }} fontWeight="fw.600">Add Skill</ModalHeader>
                    <ModalCloseButton border="1px solid #868B944D" />
                    <ModalBody pt="4px" pb="30px">
                      <Box>
                        <Box mt="30px">
                          <Text fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} fontWeight="fw.500">Name</Text>
                          <Controller
                            name="name"
                            control={methods.control}
                            rules={{ required: true }}
                            render={({ field, fieldState }) =>
                              <> <Input {...field}
                                errorBorderColor="red.500"
                                isInvalid={errors?.name?.message || fieldState?.['name']?.message}
                                placeholder="Enter your name" height="50px" _hover={{ borderColor: "#713EDD" }}
                                background="#F7FAFC" mt="8px" _focusVisible={{ borderColor: "#713EDD" }} />

                                {(errors?.name?.message || fieldState?.['name']?.message) && <Text size={'small'} fontSize={'sm'} mt={1} color="red.500">{errors?.name?.message || fieldState?.['name']?.message}</Text>}
                              </>

                            }
                          />
                        </Box>
                        <Box mt="15px">
                          <InputArray control={methods.control} name="rss" />
                        </Box>

                      </Box>
                    </ModalBody>
                    <ModalFooter gap="20px" background="#F5F4F6" borderRadius="0px 0px 10px 10px">
                      <Button background="#EAE9EC" onClick={onClose} width="83px" height="40px" fontSize="fs.12" fontWeight="fw.500" color="#202B3C">Cancel</Button>
                      <Button
                        type="submit"
                        background="#713EDD"
                        width="83px"
                        height="40px"
                        fontSize="fs.12"
                        fontWeight="fw.500"
                        color="white"
                        _hover={{ border: "1px solid #713EDD", background: "#F6F2FD", color: "#713EDD" }}>
                        Add
                        {isLoading && <Spinner size="sm" ml={2} />}
                      </Button>
                    </ModalFooter>
                  </ModalContent>
                </form>
              </FormProvider>

            </Modal>
          </Box>
        </Flex>
        <Box mt="25px" >
          <SkillsTable handlePageChange={handlePageChange} page={page} skillsList={skillsList} totalPages={totalPages} isSkillLoadings={isSkillLoadings} />
        </Box>
      </Box>
    </Flex>
  </>);
};

export default Skills;