import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  List,
  ListItem,
  Menu,
  Spinner,
  Text,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import isEmpty from 'lodash/isEmpty';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { GET_CITIES, GET_COUNTRIES, GET_STATES, GET_USER_DATA, getCitiesAPI, getCountriesAPI, getStatesAPI, sendOTP, signupAPI } from '../../../apis/index.api';
import { AppContext } from '../../../contexts/AppContext';
import { convertArrayToOptionLabels } from '../../../utils/object.utils';
import PasswordField from '../../PasswordField/PasswordField';
import SliderComponent from '../../Slider/index';
import Style from '../../style.module.css';
import { lengthCheck, oneLowercaseLetterCheck, oneNumberLetterCheck, oneSpecialCharacterCheck, oneUppercaseLetterCheck, signupFormSchema } from './formschema';
import OtpModal from './otpModel';

const CreateAccount = props => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [error,] = useState('');
  const [isPasswordFocused, setPasswordFocus] = useState(false);
  const [, setStateApp] = useContext(AppContext);
  const toast = useToast();
  const [isOtpModalOpen, setOtpModalOpen] = useState(false);
  const [signupEmail, setSignupEmail] = useState('');
  const [isVerified, setIsVerified] = useState(false);

  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    reset
  } = useForm({
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      password: null,
      confirmPassword: null,
      city: null,
      state: null,
      country: null,
    },
    resolver: yupResolver(signupFormSchema)
  });

  const selectedCountry = watch('country');
  const selectedState = watch('state');
  const password = watch('password');

  const { data: countries, isFetching: isCountriesLoading } = useQuery({
    queryKey: [GET_COUNTRIES],
    queryFn: getCountriesAPI
  });
  const { data: states, isFetching: isStatesLoading } = useQuery({
    queryKey: [GET_STATES, (selectedCountry?.value || '')],
    queryFn: getStatesAPI,
    enabled: !!selectedCountry
  });
  const { data: cities, isFetching: isCitiesLoading } = useQuery({
    queryKey: [GET_CITIES, (selectedCountry?.value || ''), (selectedState?.value || '')],
    queryFn: getCitiesAPI,
    enabled: !!selectedState?.value
  });

  useEffect(() => {
    if (selectedCountry) {
      setValue('state', selectedCountry.value);
      setValue('city', selectedCountry.value);
    }
  }, [selectedCountry]);
  useEffect(() => {
    if (selectedState) {
      setValue('city', selectedState.value);
    }
  }, [selectedState]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  const { mutate, isLoading: isSubmitting } = useMutation(signupAPI, {
    onSuccess: data => {
      localStorage.setItem('@apiToken', data.data.access_token);
      setStateApp(state => ({ ...state, auth: { ...state.auth, token: data.data.access_token } }));
      queryClient.invalidateQueries({ queryKey: [GET_USER_DATA] });
      navigate('/user-signup-steps', { replace: true });
      reset();
    },

    onError: (error) => {
      let errorMessage;

      if (error?.response?.data?.message) {
        errorMessage = error.response.data.message;
      }
      toast({
        title: "Error",
        description: errorMessage,
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    }
  });

  const countriesOptions = useMemo(() => convertArrayToOptionLabels(countries?.data || [], 'name', 'isoCode'), [countries?.data]);
  const statesOptions = useMemo(() => convertArrayToOptionLabels(states?.data || [], 'name', 'isoCode'), [states?.data]);
  const citiesOptions = useMemo(() => convertArrayToOptionLabels(cities?.data || [], 'name', 'isoCode'), [cities?.data]);

  const onSubmit = async data => {
    if (isSubmitting) return;

    if (!isVerified) {
      setSignupEmail(data.email);
      try {
        const response = await sendOTP({ email: data.email });
        if (response.data) {
          toast({
            title: "Success",
            description: response.message,
            status: "success",
            duration: 2000,
            isClosable: true,
            position: "top-right",
          });
          setOtpModalOpen(true);

        } else {
          toast({
            title: "Error",
            description: response.message,
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top-right",
          });
          console.error("Failed to send OTP:", response.message);
        }
      } catch (error) {
        toast({
          title: "Error",
          description: error?.response?.data?.message,
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
        console.error("Error sending OTP:", error);
      }
    }
  };

  useEffect(() => {
    if (isVerified && !isSubmitting) {
      const payload = {
        email: signupEmail,
        password: watch('password'),
        phoneNumber: watch('phone'),
        address: {
          city: watch('city')?.label,
          state: watch('state')?.label,
          country: watch('country')?.label,
        },
        name: watch('name'),
      };
      mutate(payload);
    }
  }, [isVerified, isSubmitting, mutate, signupEmail, watch]);


  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex
          flexWrap={{ base: 'wrap', lg: 'nowrap' }}
          flexDirection={{ base: 'column', lg: 'unset' }}
        >
          <SliderComponent />
          <Flex
            background={{
              sm: 'linear-gradient(148.84deg, #713EDD 12.15%, #A17DEE 79.83%)',
              xl: 'white',
            }}
            position={{ lg: 'absolute', xl: 'unset' }}

            py={{ base: '0px', md: '30px', lg: '25px', xl: '0' }}
            pl={{ base: '0px', md: '30px', lg: '25px', xl: '0' }}
            pr={{ base: '0px', md: '30px', lg: '25px', xl: '0' }}
            alignItems="center"
            width={{ base: '100%', xl: '35%' }}
            minHeight={{ lg: '100%', xl: 'auto' }}
            justifyContent={{ base: 'space-between', xl: 'end' }}
          >
            <Flex
              overflow="hidden"
              padding={{ lg: '20px' }}
              ml="auto"
              width={{ base: '100%', md: '100%', lg: '50%', '2xl': '50%' }}
              height={{ base: '100vh', md: 'auto', lg: 'auto', xl: 'auto' }}
              alignItems="center"
              position={{ base: 'unset', xl: 'absolute' }}
              right="0"
              justifyContent={{
                base: 'center',
                lg: 'end',
                xl: 'space-between',
              }}
            >
              <Box
                className={Style.overflow}
                padding={{ base: '25px', md: '25px', '2xl': '50px' }}
                boxShadow={{
                  base: '0',
                  md: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                }}
                zIndex={111}
                width={{ base: '500px', sm: "550px", xl: '80%' }}
                height={{ base: '100%', md: 'calc(100dvh - 100px)' }}
                borderRadius={{ base: '20px', md: '20px' }}
                bgColor="white"
              >

                <Box px={{ base: '0', xl: '10px' }}>
                  <Text
                    textAlign="center"
                    fontSize={{
                      base: 'fs.25',
                      md: 'fs.30',
                      lg: 'fs.30',
                      xl: 'fs.38',
                    }}
                    fontWeight="fw.600"
                  >
                    Create your Account
                  </Text>
                  <Text
                    textAlign="center"
                    fontSize={{
                      base: 'fs.12',
                      md: 'fs.14',
                      lg: 'fs.16',
                      xl: 'fs.18',
                    }}
                    fontWeight="fw.400"
                    pt="10px"
                    pb="24px"
                    color="#868B94"
                  >
                  </Text>
                  <hr />
                </Box>
                <FormControl mt={{ base: '40px' }}>
                  <FormLabel
                    fontSize={{
                      base: 'fs.12',
                      md: 'fs.14',
                      lg: 'fs.16',
                    }}
                    fontWeight="fw.500"
                  >
                    Full Name
                  </FormLabel>
                  <Input
                    {...register('name')}
                    focusBorderColor="#713EDD"
                    placeholder={`Enter your Full name`}
                    border="1.5px solid rgba(210, 210, 210, 0.5)"
                    type="text"
                    fontSize={{
                      base: 'fs.12',
                      md: 'fs.14',
                      lg: 'fs.16',
                    }}
                    fontWeight="fw.400"
                    borderRadius="10px"
                    height={{ base: '40px', lg: '50px', '2xl': '55px' }}
                    disabled={isSubmitting}
                  />
                  {errors.name && (
                    <Text color="red" mt={1} fontSize={'12px'}>{errors?.name?.message}</Text>
                  )}

                  <FormLabel
                    pt={{ base: '25px' }}
                    fontSize={{
                      base: 'fs.12',
                      md: 'fs.14',
                      lg: 'fs.16',
                    }}
                    fontWeight="fw.500"
                  >
                    Email
                  </FormLabel>

                  <Input
                    {...register('email')}
                    focusBorderColor="#713EDD"
                    placeholder={`Enter your Email`}
                    border="1.5px solid rgba(210, 210, 210, 0.5)"
                    type="email"
                    fontSize={{
                      base: 'fs.12',
                      md: 'fs.14',
                      lg: 'fs.16',
                    }}
                    fontWeight="fw.400"
                    borderRadius="10px"
                    height={{ base: '40px', lg: '50px', '2xl': '55px' }}
                    disabled={isVerified || isSubmitting}
                  />
                  {errors.email && (
                    <Text color="red" mt={1} fontSize={'12px'}>{errors.email.message}</Text>
                  )}

                  <FormLabel
                    pt={{ base: '25px' }}
                    fontSize={{
                      base: 'fs.12',
                      md: 'fs.14',
                      lg: 'fs.16',
                    }}
                    fontWeight="fw.500"
                  >
                    Password
                  </FormLabel>
                  <PasswordField disabled={isSubmitting} placeholder={`*******`} autoComplete={"new-password"} register={register('password')} onFocus={() => setPasswordFocus(true)}
                    onBlur={() => setPasswordFocus(false)} />
                  {errors.password && (
                    <Text color="red" mt={1} fontSize={'12px'}>{errors.password.message}</Text>
                  )}
                  {
                    isPasswordFocused &&
                    <List className='show'>
                      <ListItem color={lengthCheck.test(password) ? 'green.500' : 'initial'} listStyleType={lengthCheck.test(password) ? 'disc' : 'circle'}>
                        <Text fontSize="xs">Password length must be 7-12 characters long.</Text>
                      </ListItem>
                      <ListItem color={oneUppercaseLetterCheck.test(password) ? 'green.500' : 'initial'} listStyleType={oneUppercaseLetterCheck.test(password) ? 'disc' : 'circle'}>
                        <Text fontSize="xs">Password must include at least 1 Uppercase letter.</Text>
                      </ListItem>
                      <ListItem color={oneLowercaseLetterCheck.test(password) ? 'green.500' : 'initial'} listStyleType={oneLowercaseLetterCheck.test(password) ? 'disc' : 'circle'}>
                        <Text fontSize="xs">Password must include at least 1 Lowercase letter.</Text>
                      </ListItem>
                      <ListItem color={oneNumberLetterCheck.test(password) ? 'green.500' : 'initial'} listStyleType={oneNumberLetterCheck.test(password) ? 'disc' : 'circle'}>
                        <Text fontSize="xs">Password must include at least 1 number letter.</Text>
                      </ListItem>
                      <ListItem color={oneSpecialCharacterCheck.test(password) ? 'green.500' : 'initial'} listStyleType={oneSpecialCharacterCheck.test(password) ? 'disc' : 'circle'}>
                        <Text fontSize="xs">Password must include at least 1 special character.</Text>
                      </ListItem>
                    </List>
                  }
                  <FormLabel
                    pt={{ base: '25px' }}
                    fontSize={{
                      base: 'fs.12',
                      md: 'fs.14',
                      lg: 'fs.16',
                    }}
                    fontWeight="fw.500"
                  >
                    Confirm Password
                  </FormLabel>
                  <PasswordField disabled={isSubmitting} placeholder={`*******`} register={register('confirmPassword')}
                  />
                  {errors.confirmPassword && (
                    <Text color="red" mt={1} fontSize={'12px'}>{errors.confirmPassword.message}</Text>
                  )}
                  <FormLabel
                    pt={{ base: '25px' }}
                    fontSize={{
                      base: 'fs.12',
                      md: 'fs.14',
                      lg: 'fs.16',
                    }}
                    fontWeight="fw.500"
                  >
                    Phone Number
                  </FormLabel>
                  <Controller
                    name="phone"
                    control={control}
                    render={({ field: { onChange, onBlur, onFocus, value } }) => (
                      <PhoneInput
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        focus="outline-none"
                        tabIndex="none"
                        placeholder={`Enter phone number`}
                        disabled={isSubmitting}
                        className={Style.inputPhoneNumber}
                      />
                    )}
                  />
                  {errors.phone && (
                    <Text color="red" mt={1} fontSize={'12px'}>{errors.phone.message}</Text>
                  )}
                  <FormLabel
                    pt={{ base: '25px' }}
                    fontSize={{
                      base: 'fs.12',
                      md: 'fs.14',
                      lg: 'fs.16',
                    }}
                    fontWeight="fw.500"
                  >
                    Country
                  </FormLabel>
                  <Menu>
                    <Controller
                      name="country"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...register('country')}
                          {...field}
                          isLoading={isCountriesLoading}
                          placeholder={`Select your country`}
                          options={countriesOptions}
                          isDisabled={isSubmitting}
                          className={Style.select_controller}
                        />
                      )}
                    />
                    {errors.country && (
                      <Text color="red" mt={1} fontSize={'12px'}>{errors.country?.message}</Text>
                    )}
                  </Menu>
                  <Flex
                    justifyContent="space-between"
                    gap="10px"
                    pt={{ base: '25px' }}
                  >
                    <Box width="50%">
                      <Text
                        pb="8px"
                        fontSize={{
                          base: 'fs.12',
                          md: 'fs.14',
                          lg: 'fs.16',
                        }}
                        fontWeight="fw.500"
                      >
                        State
                      </Text>
                      <Menu>
                        <Controller
                          name="state"
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...register('state')}
                              {...field}
                              placeholder={`Select your state`}
                              options={statesOptions}
                              className={Style.select_controller}
                              isLoading={isStatesLoading}
                              isDisabled={
                                isEmpty(watch('country')) || isSubmitting
                              }
                            />
                          )}
                        />
                        {errors.state && (
                          <Text color="red" mt={1} fontSize={'12px'}>{errors?.state?.message}</Text>
                        )}
                      </Menu>
                    </Box>
                    <Box width="50%">
                      <Text
                        pb="8px"
                        fontSize={{
                          base: 'fs.12',
                          md: 'fs.14',
                          lg: 'fs.16',
                        }}
                        fontWeight="fw.500"
                      >
                        City
                      </Text>
                      <Menu>
                        <Controller
                          name="city"
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              placeholder={`Select your city`}
                              options={citiesOptions}
                              className={Style.select_controller}
                              isLoading={isCitiesLoading}
                              isDisabled={isEmpty(watch('state')) || isSubmitting || !selectedState.value}
                            />
                          )}
                        />
                        {errors.city && (
                          <Text color="red" mt={1} fontSize={'12px'}>{errors?.city?.message}</Text>
                        )}
                      </Menu>
                    </Box>
                  </Flex>
                </FormControl>
                <Box mt={{ base: '30px', md: '30px', lg: '40px', xl: '40px' }}>
                  <Button
                    color="white"
                    background="linear-gradient(150.62deg, #713EDD 5.97%, #A17DEE 64.37%)"
                    _hover={{ animation: 'changeBg 0s ease infinite' }}
                    width="100%"
                    height={{ base: '40px', lg: '50px', '2xl': '55px' }}
                    fontSize={{
                      base: 'fs.12',
                      md: 'fs.14',
                      lg: 'fs.16',
                      xl: 'fs.18',
                    }}
                    fontWeight="fw.600"
                    borderRadius="10px"
                    type="submit"
                    display={'flex'}
                    gap={5}
                    loadingText="Sign Up..."
                    disabled={isSubmitting}
                  >

                    Sign Up
                    {isSubmitting && <Spinner />}
                  </Button>
                </Box>

                <Flex justifyContent="center" mt={{ base: '10px', xl: '20px' }}>
                  <Text
                    fontSize={{ base: 'fs.12', lg: 'fs.14' }}
                    fontWeight="fw.400"
                    color="darkgray"
                    mr={2}
                  >
                    Already have an account?
                  </Text>
                  <Link
                    to="/signin"
                    ml="10px"
                    _hover={{ textDecoration: 'none' }}
                    fontSize={{ base: 'fs.12', lg: 'fs.14' }}
                    fontWeight="fw.600"
                    mr={3}
                  >
                    Sign In
                  </Link>
                </Flex>
                {error && (
                  <Text
                    textAlign={"center"}
                    fontSize={'14px'}
                    fontWeight="fw.400"
                    color="red"
                  >
                    {error}
                  </Text>
                )}
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </form >

      <OtpModal
        isOpen={isOtpModalOpen}
        onClose={() => setOtpModalOpen(false)}
        email={signupEmail}
        onSignupSuccess={setIsVerified}
      />
    </>
  );
};

export default CreateAccount;
