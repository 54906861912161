import AwsS3 from '@uppy/aws-s3';
import Compressor from '@uppy/compressor';
import Uppy from '@uppy/core';
import ThumbnailGenerator from '@uppy/thumbnail-generator';
import { protectedRoute } from './api.config';

const uppyUtility = new Uppy({
  autoProceed: true,
})
  .use(Compressor)
  // .use(ThumbnailGenerator)
  .use(AwsS3, {
    shouldUseMultipart: false,
    getUploadParameters: async (file) => {
      const response = await protectedRoute.post('/files/getPreSignedURL', {
        fileName: file.name,
        contentType: file.type,
      });

      return {
        method: 'PUT',
        url: response.data,
        fields: {},
      };
    }
  });

export default uppyUtility;