import { protectedRoute } from "../config/api.config";


export function addNewTask(payload) {
  return protectedRoute.post('/task', payload);
}

export function getTasks({ queryKey }) {
  const { projectId } = queryKey[1];
  const queryString = `?projectId=${projectId}`;
  return protectedRoute.get(`/task` + queryString);
}

export function updateTask({ taskId, ...payload }) {
  return protectedRoute.patch(`/task/${taskId}`, payload);
}

export function getMyTasks() {
  return protectedRoute.get(`/task/my-tasks`);
}