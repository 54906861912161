import { CloseIcon, RepeatIcon } from '@chakra-ui/icons';
import { Box, Image, Spinner, Text, VStack } from '@chakra-ui/react';
import { IconUpload } from '@tabler/icons-react';
import isEmpty from 'lodash/isEmpty';
import { nanoid } from 'nanoid';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { retry, upload } from './utils';

const ImageDropZone = ({
  innerMessage = "Drag 'n' drop some files here, or click to select files",
  icon = <IconUpload />,
  isEditing = false,
  acceptedFiles = { 'image/*': [] },
  maxFiles = 2,
  maxSize = 1024 * 1024 * 2, // 2MB
  defaultImages = [],
  onRemove,
  error,
  onFileChange,
  multiple = false,
  borderRadius = '100%'
}) => {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    // Setting default images if any
    if (!isEmpty(defaultImages)) {
      setFiles(defaultImages.map((img) => ({
        id: nanoid(),
        fileURL: img,
        status: 'success'
      })));
    }
  }, []);

  useEffect(() => {
    const uploadedFiles = [];
    for (let i in files) {
      if (files[i].status === 'success') {
        uploadedFiles.push(files[i].fileURL);
      }
    }

    onFileChange?.(uploadedFiles);
  }, [files]);

  const onDrop = useCallback((acceptedFiles) => {
    upload(acceptedFiles, setFiles);
  }, []);

  const retryUpload = (id) => {
    retry(id, files, setFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptedFiles,
    maxFiles,
    maxSize,
    multiple
  });

  const removeFile = (id) => {
    setFiles(files.filter(file => file.id !== id));
    onRemove && onRemove(id);
  };

  return (
    <VStack align={'flex-start'}>
      <Box {...getRootProps()}
        flexDirection="column"
        _hover={{
          background: "#F6F2FD",
          color: "#713EDD",
          border: "1.5px dashed #713EDD"
        }}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        background="#F7FAFC"
        border="1.5px dashed #D2D2D2CC"
        h={isEditing ? "60px" : "150px"}
        width="100%"
        cursor={'pointer'}
        w="auto"
        aspectRatio={'1/1'}
        borderRadius={borderRadius}
      >
        <input {...getInputProps()} />
        {
          files.length === 0 ?
            <>
              {icon}
              <Text textAlign={'center'}>{innerMessage}</Text>
            </>
            :
            <>
              {
                files.map((file) => (
                  <Box key={file.id} p={2} display="flex" alignItems="center" pos={'relative'} border={file.status === "error" ? '2px solid red' : '1px solid grey'} borderRadius={borderRadius} aspectRatio={'1/1'}>

                    <Image src={file.preview || file.fileURL} alt="preview" objectFit={'cover'} borderRadius={borderRadius} aspectRatio={'1/1'} />
                    {file.status === 'loading' &&
                      <Box cursor={'pointer'} bg="white" h={'30px'} aspectRatio={'1/1'} borderRadius={'100%'} pos={'absolute'} top={0} left={0} bottom={0} right={0} margin="auto" display={'flex'} justifyContent={'center'} alignItems={'center'} >
                        <Spinner color='purple.500' />
                      </Box>
                    }
                    {
                      file.status === 'error' &&
                      <Box onClick={() => retryUpload(file.id)} cursor={'pointer'} bg="white" h={'30px'} aspectRatio={'1/1'} borderRadius={'100%'} pos={'absolute'} top={0} left={0} bottom={0} right={0} margin="auto" display={'flex'} justifyContent={'center'} alignItems={'center'} >
                        <RepeatIcon />
                      </Box>
                    }
                    <Box
                      p={2}
                      bg={'black'}
                      color={'white'}
                      pos={'absolute'}
                      top={2}
                      right={2}
                      onClick={(e) => { e.stopPropagation(); removeFile(file.id); }}
                      transform={'translate(50%, -50%)'}
                      aspectRatio={'1/1'} h="25px" borderRadius={'100%'}
                      cursor={'pointer'}
                      display={'flex'} justifyContent={'center'} alignItems={'center'}
                    >
                      <CloseIcon fontSize={'10px'} />
                    </Box>
                  </Box>
                ))
              }</>
        }
      </Box>
      {error && <Text color={'red.500'} fontSize={'fs.14'}>{error}</Text>}
    </VStack >
  );
};

export default ImageDropZone;