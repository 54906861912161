import { CloseIcon, RepeatIcon } from '@chakra-ui/icons';
import { Box, Flex, HStack, Image, Spinner, Text, VStack } from '@chakra-ui/react';
import { IconFileTypeBmp, IconFileTypeDoc, IconFileTypeDocx, IconFileTypePpt, IconFileTypeSvg, IconFileTypeXls, IconFileTypeZip, IconPdf, IconUpload } from '@tabler/icons-react';
import { last } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { nanoid } from 'nanoid';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { upload } from './utils';

const CustomDropzone = ({
  innerMessage = "Drag 'n' drop some files here, or click to select files",
  icon = <IconUpload size={16} />,
  acceptedFiles = { 'image/*': [] },
  maxFiles = 2,
  maxSize = 1024 * 1024 * 2, // 2MB
  defaultImages = [],
  onRemove,
  error,
  onFileChange,
  multiple = false,
  wrapperStyle
}) => {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    // Setting default images if any
    if (!isEmpty(defaultImages)) {
      setFiles(defaultImages.map((img) => ({
        id: nanoid(),
        fileURL: img,
        status: 'success'
      })));
    }
  }, []);

  useEffect(() => {
    const uploadedFiles = [];
    for (let i in files) {
      if (files[i].status === 'success') {
        uploadedFiles.push(files[i].fileURL);
      }
    }

    onFileChange?.(uploadedFiles);
  }, [files]);

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0 && !multiple) {
      setFiles([]);
    }

    upload(acceptedFiles, setFiles);
  }, []);

  const retryUpload = (id) => {
    retryUpload(id, files, setFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptedFiles,
    maxFiles,
    maxSize,
    multiple
  });

  const removeFile = (id) => {
    setFiles(files.filter(file => file.id !== id));
    onRemove && onRemove(id);
  };

  const renderPreview = useCallback((file) => {
    const ext = last((file.fileURL || file?.file?.name).split("."));

    if (['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(ext)) {
      return <Image src={file.preview || file.fileURL} alt="preview" w="50px" h="full" aspectRatio={'1/1'} objectFit={'cover'} />;
    } else if (['mp4', 'mov'].includes(ext)) {
      return <video src={file.fileURL}></video>;
    } else {
      const mapper = {
        pdf: <IconPdf />,
        xls: <IconFileTypeXls />,
        xlsx: <IconFileTypeXls />,
        doc: <IconFileTypeDoc />,
        docx: <IconFileTypeDocx />,
        ppt: <IconFileTypePpt />,
        bmp: <IconFileTypeBmp />,
        zip: <IconFileTypeZip />,
        svg: <IconFileTypeSvg />
      };

      return mapper[ext];
    }
  }, []);

  return (
    <VStack>
      <Box {...getRootProps()}
        flexDirection="column"
        _hover={{
          background: "#F6F2FD",
          color: "#713EDD",
          border: "1.5px dashed #713EDD"
        }}
        display={'flex'}
        background="#F7FAFC"
        border="1.5px dashed #D2D2D2CC"
        width="100%"
        borderRadius="10px"
        cursor={'pointer'}
        minH={'150px'}
        {...wrapperStyle}
      >
        <input {...getInputProps()} />
        <Flex py={2} borderBottom='1px dashed #D2D2D2CC' gap={2} justify={'center'} align={'center'} color={'gray'}>
          {icon}
          <Text textAlign={'center'} fontSize={'fs.14'}>{innerMessage}</Text>
        </Flex>
        <HStack wrap={'wrap'} p={2}>
          {
            files.map((file) => (
              <Box key={file.id} p={2} display="flex" alignItems="center" pos={'relative'} border={file.status === "error" ? '2px solid red' : '1px solid grey'} borderRadius={'10px'}>
                {renderPreview(file)}
                {file.status === 'loading' &&
                  <Box cursor={'pointer'} bg="white" h={'30px'} aspectRatio={'1/1'} borderRadius={'100%'} pos={'absolute'} top={0} left={0} bottom={0} right={0} margin="auto" display={'flex'} justifyContent={'center'} alignItems={'center'} >
                    <Spinner color='purple.500' />
                  </Box>
                }
                {
                  file.status === 'error' &&
                  <Box onClick={(e) => { e.stopPropagation(); retryUpload(file.id); }} cursor={'pointer'} bg="white" h={'30px'} aspectRatio={'1/1'} borderRadius={'100%'} pos={'absolute'} top={0} left={0} bottom={0} right={0} margin="auto" display={'flex'} justifyContent={'center'} alignItems={'center'} >
                    <RepeatIcon />
                  </Box>
                }
                <Box
                  p={2}
                  bg={'black'}
                  color={'white'}
                  pos={'absolute'}
                  top={0} right={0}
                  onClick={(e) => { e.stopPropagation(); removeFile(file.id); }}
                  transform={'translate(50%, -50%)'}
                  aspectRatio={'1/1'} h="25px" borderRadius={'100%'}
                  cursor={'pointer'}
                  display={'flex'} justifyContent={'center'} alignItems={'center'}
                >
                  <CloseIcon fontSize={'10px'} />
                </Box>
              </Box>
            ))
          }
        </HStack>
      </Box>
      {error && <Text color={'red.500'}>{error}</Text>}

    </VStack >
  );
};

export default CustomDropzone;