import {
  Button,
  ButtonGroup,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text
} from '@chakra-ui/react';
import { IconTrash } from '@tabler/icons-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { toast } from 'react-toastify';
import { GET_EBOOK } from '../../../apis/constants.api';
import { deletePublishEBook } from '../../../apis/ebook.api';


const EBookDeleteDialog = ({ book, isDeleteOpen, onDeleteClose, onDeleteOpen }) => {
  const queryClient = useQueryClient();

  const { mutate: deletePublishEBooks, isLoading } = useMutation(deletePublishEBook, {
    onSuccess: data => {
      queryClient.invalidateQueries({ queryKey: [GET_EBOOK] });
      onDeleteClose();
      toast({
        title: 'E-Book deleted successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    },
    onError: () => {
      toast({
        title: 'An error occurred',
        description: 'Failed to delete E-Book',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  });

  const handleDelete = () => deletePublishEBooks(book?._id);

  return (
    <Modal closeOnOverlayClick={false} onClose={onDeleteClose} size="lg" isOpen={onDeleteOpen} isCentered >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex background={'#ffecec'} borderRadius={'50%'} p={2} h={'35px'} w={'35px'} justify={'center'} alignItems={'center'}>
            <IconTrash size={25} style={{ color: "#e45b5b" }} />
          </Flex>
        </ModalHeader>
        <ModalCloseButton border="1px solid #868B944D" />
        <ModalBody>
          <Text fontSize="fs.18" fontWeight="fw.600">{`Delete "${book?.title}"`}</Text>
          <Text fontSize="fs.14" fontWeight="fw.400" color="#475467" mt="5px" >Are you sure you want to delete this book?</Text>

          <ButtonGroup variant='outline' spacing='5' width="100%" my="18px">
            <Button onClick={onDeleteClose} ml='auto'>Cancel</Button>
            <Button
              type='submit'
              background={'red.500'}
              color="white"
              onClick={handleDelete}
              _hover={{ background: "red.600" }}
              isLoading={isLoading}
              spinnerPlacement='end'
              disabled={isLoading}
              loadingText='Deleting'
            >
              Delete
            </Button>
          </ButtonGroup>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EBookDeleteDialog;