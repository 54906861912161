import { Box, Flex } from "@chakra-ui/react";
import { ErrorBoundary } from "@sentry/react";
import { Route, Routes } from "react-router-dom";
import Style from '../style.module.css';
import MessageHistoryView from "./MessageHistoryView";
import MessageSideBar from "./MessageSiderBar";
import NewMessageView from "./NewMessageView";

export default function MessageContent() {
  return (
    <Flex flex="1 1 auto">
      <Box className={Style.overflow} display={{ base: "none", xl: "unset" }} minW="270px" maxW="500px" resize="horizontal" background="white" borderRight="1px solid #E5E5E5" overflowX={'auto'}>
        <MessageSideBar />
      </Box>
      <ErrorBoundary>
        <Routes>
          <Route path="/direct/new" element={<NewMessageView />} />
          <Route path="/:channelType/:channelId" element={<MessageHistoryView />} />
        </Routes>
      </ErrorBoundary>
    </Flex >
  );
}