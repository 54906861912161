import './style.css';

export default function FullScreenLoader({
}) {
  return (
    <div className="full-screen-loader">
      <div className="loader"></div>
    </div>
  );
}
