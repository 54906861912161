import {
  Box, Button, Flex, Input, Menu, Modal, ModalBody,
  ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Text,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import isEmpty from 'lodash/isEmpty';
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { getCitiesAPI, getCountriesAPI, getStatesAPI } from "../../apis/common.api";
import { GET_CITIES, GET_COUNTRIES, GET_STATES, GET_USER_DATA } from "../../apis/constants.api";
import { updateProfileAPI } from "../../apis/users.api";
import { convertArrayToOptionLabels } from "../../utils/object.utils";
import ProfileDropZone from "../Dropzone/ImageDropzone";
import Style from '../style.module.css';
import ChangePassDialog from "./ChangePassDialog";
import { profileSchema } from "./form.schema";

const ProfileDialog = ({ openProfileModal, user, onCloseProfileModal }) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const [isChangePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
  const openChangePasswordModal = () => setChangePasswordModalOpen(true);
  const closeChangePasswordModal = () => setChangePasswordModalOpen(false);

  const { control, handleSubmit, formState: { errors }, watch, setValue, register, reset } = useForm({
    defaultValues: {
      name: '',
      state: null,
      city: null,
      country: null,
      profilePicture: '',
    },
    resolver: yupResolver(profileSchema)

  });

  const { data: countries, isFetching: isCountriesLoading } = useQuery({
    queryKey: [GET_COUNTRIES],
    queryFn: getCountriesAPI
  });
  const countriesOptions = useMemo(() => convertArrayToOptionLabels(countries?.data || [], 'name', 'isoCode'), [countries?.data]);
  const selectedCountry = watch('country') || countriesOptions.find((e) => e.label === user?.address?.country);

  const { data: states, isFetching: isStatesLoading } = useQuery({
    queryKey: [GET_STATES, (selectedCountry?.value || '')],
    queryFn: getStatesAPI,
    enabled: !!selectedCountry
  });

  const statesOptions = useMemo(() => convertArrayToOptionLabels(states?.data || [], 'name', 'isoCode'), [states?.data]);

  const selectedState = watch('state') || statesOptions.find((e) => e.label === user?.address?.state);


  const { data: cities, isFetching: isCitiesLoading } = useQuery({
    queryKey: [GET_CITIES, (selectedCountry?.value || ''), (selectedState?.value || '')],
    queryFn: getCitiesAPI,
    enabled: !!selectedState?.value
  });


  const citiesOptions = useMemo(() => convertArrayToOptionLabels(cities?.data || [], 'name', 'name'), [cities?.data]);
  const selectedCity = watch('city') || citiesOptions.find((e) => e.label == user?.address?.city);

  useEffect(() => {
    if (selectedCountry) {
      setValue('state', selectedState);
      setValue('city', selectedCity);
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState) {
      setValue('city', selectedCity);
    }
  }, [selectedState]);

  const { mutate, isLoading } = useMutation(updateProfileAPI, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_USER_DATA] });
      onCloseProfileModal();
      reset();
      toast({
        title: "Profile updated.",
        description: "Your profile has been successfully updated.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    },
    onError: (error) => {
      toast({
        title: "An error occurred.",
        description: "Unable to update profile. Please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  });

  useEffect(() => {
    if (selectedCountry) {
      reset({
        name: user.name,
        state: statesOptions?.find((e) => e.label === user?.address?.state) || null,
        city: citiesOptions?.find((e) => e.label === user?.address?.city) || null,
        country: countriesOptions.find((e) => e.label === user?.address?.country) || null,
        profilePicture: user.profilePicture,
      });
    }
  }, [countriesOptions, openProfileModal, user]);

  const onSubmit = async (data) => {
    console.log("pressed");
    const payload = {
      name: data.name,
      profilePicture: data.profilePicture,
      address: {
        country: data.country?.label,
        state: data.state?.label,
        city: data.city?.label
      }
    };
    await mutate(payload);

  };


  return (<>
    <Modal isOpen={openProfileModal} onClose={onCloseProfileModal} isCentered size="xl">
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Profile</ModalHeader>
          <ModalCloseButton border="1px solid #868B944D" />
          <ModalBody>
            <Box>
              <Box >
                <Text fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} mb="8px" fontWeight="fw.500">Profile</Text>
                <Controller
                  name="profilePicture"
                  control={control}
                  render={({ field }) =>
                    <ProfileDropZone
                      {...field}
                      innerMessage='Upload Profile Image'
                      acceptedFiles={{ 'image/*': ['jpg', 'jpeg', 'png', 'webp'] }}
                      maxFiles={5}
                      defaultImages={user?.profilePicture ? [user?.profilePicture] : []}
                      onFileChange={(files) => field.onChange(files[0])}
                    />
                  }
                />
              </Box>
              <Box >
                <Text fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} fontWeight="fw.500">User Name </Text>
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: true }}
                  render={({ field, fieldState }) =>
                    <>
                      <Input {...field} placeholder="Full name "
                        errorBorderColor="red.500"
                        isInvalid={errors?.name?.message || fieldState?.['name']?.message}
                        height="50px" _hover={{ borderColor: "#713EDD" }}
                        background="#F7FAFC" mt="8px" _focusVisible={{ borderColor: "#713EDD" }} />

                      {(errors?.name?.message || fieldState?.['name']?.message)
                        && <Text size={'small'} fontSize={'sm'} mt={1} color="red.500">{errors?.name?.message || fieldState?.['name']?.message}</Text>}
                    </>

                  }

                />
              </Box>
              <Box mt="20px">
                <Text fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} fontWeight="fw.500" mb={2}>Country</Text>
                <Menu>
                  <Controller
                    name="country"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...register('country')}
                        {...field}
                        isLoading={isCountriesLoading}
                        placeholder={`Select your country`}
                        defaultValue={countriesOptions?.find((e) => e.label === user?.address?.country)}
                        options={countriesOptions}
                        className={Style.select_controller}
                      />
                    )}
                  />
                  {errors.country && (
                    <Text color="#E1251A">Please select your country.</Text>
                  )}
                </Menu>
              </Box>
              <Flex
                justifyContent="space-between"
                gap="8px"
                pt={{ base: '20px' }}
                pb={{ base: '10px' }}
              >
                <Box width="50%">
                  <Text
                    pb="8px"
                    fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} fontWeight="fw.500"
                  >
                    State
                  </Text>
                  <Menu>
                    <Controller
                      name="state"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...register('state', { required: true })}
                          {...field}
                          placeholder={`Select your state`}
                          options={statesOptions}
                          className={Style.select_controller}
                          isLoading={isStatesLoading}
                          value={statesOptions.find((e) => e.label === user?.address?.state)}
                          isDisabled={
                            isEmpty(watch('country'))
                          }
                        />
                      )}
                    />
                    {errors.state && (
                      <Text color="#E1251A">Please select your state.</Text>
                    )}
                  </Menu>
                </Box>
                <Box width="50%">
                  <Text
                    pb="8px"
                    fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} fontWeight="fw.500"
                  >
                    City
                  </Text>
                  <Menu>
                    <Controller
                      name="city"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          placeholder={`Select your city`}
                          options={citiesOptions}
                          className={Style.select_controller}
                          isLoading={isCitiesLoading}
                          value={citiesOptions?.find((e) => e.label === user?.address?.city)}
                          isDisabled={isEmpty(watch('state'))}
                        />
                      )}
                    />
                    {errors.city && (
                      <Text color="#E1251A">Please select your city.</Text>
                    )}
                  </Menu>
                </Box>
              </Flex>
              <Button onClick={openChangePasswordModal} px={{ md: "15px", lg: "30px" }} py="8px" background="linear-gradient(134.59deg, #713EDD -1.98%, #A17DEE 111.17%)"
                _hover={{ background: "linear-gradient(134.59deg, #A17DEE -1.98% ,#713EDD 111.17%)" }} gap={5} color="white">Change Password</Button>
            </Box>
          </ModalBody>
          <ModalFooter background="#F5F4F6" borderRadius=" 0px 0px 10px 10px">
            <Flex gap="10px">
              <Button background="#EAE9EC" onClick={onCloseProfileModal}>Cancel</Button>
              <Button type="submit" px={{ md: "15px", lg: "30px" }} py="8px" background="linear-gradient(134.59deg, #713EDD -1.98%, #A17DEE 111.17%)"
                _hover={{ background: "linear-gradient(134.59deg, #A17DEE -1.98% ,#713EDD 111.17%)" }} gap={5} color="white">Update {isLoading && <Spinner />}</Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal >

    <ChangePassDialog
      isOpen={isChangePasswordModalOpen}
      onClose={closeChangePasswordModal}
    />

  </>);
};

export default ProfileDialog;