import {
	Box,
	Flex,
	Grid,
	Image,
	Table,
	TableContainer,
	Tbody,
	Th,
	Thead,
	Tr
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useContext, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { GET_EBOOK } from '../../../apis/constants.api';
import { getEBooks } from '../../../apis/ebook.api';
import NoDataLogo from '../../../assets/no_data.png';
import { AppContext } from '../../../contexts/AppContext';
import useDebounce from '../../../hooks/useDebounce.hook';
import { isAdmin } from '../../../utils/users.utils';
import Style from "../../style.module.css";
import BookCardSkeleton from './BookCardsSkeletor';
import EBookCard from './EBookCard';
import EBookListItems from './EBookListItems';


const PAGE_SIZE = process.env.REACT_APP_PAGE_SIZE || 20;
export default function EBooksTable({ searchQuery }) {
	const [page, setPage] = useState(1);
	const [AppState] = useContext(AppContext);
	const { user } = AppState;
	const debouncedSearch = useDebounce(searchQuery);

	const { data: eBooks, isFetching: isPageLoad } = useQuery({
		queryKey: [GET_EBOOK, {
			skip: PAGE_SIZE * (page - 1),
			limit: PAGE_SIZE,
			search: debouncedSearch,
		}],
		queryFn: getEBooks,
	});

	const totalPages = (eBooks?.data?.total || 1) / PAGE_SIZE;
	const eBooksList = eBooks?.data?.data || [];

	const handlePageChange = useCallback((data) => {
		setPage(data.selected + 1);
	}, []);

	return (
		<>
			{isAdmin(user) ? (
				<Box>
					<TableContainer
						border="1px solid #EAECF0"
						borderRadius="10px"
						height="calc(100vh - 325px)"
						overflowY="auto"
						className={Style.overflow}
					>
						<Table size='sm' layout="5">
							<Thead
								height="50px"
								px="23px"
								py="13px"
								background="#F9FAFB"
								position="sticky"
								top="0"
								zIndex="10"
							>
								<Tr>
									<Th
										textTransform="capitalize"
										color="#868B94"
										fontSize="fs.16"
										fontWeight="fw.400"
										w={'60px'}
									>
										Image
									</Th>
									<Th
										textTransform="capitalize"
										color="#868B94"
										fontSize="fs.16"
										fontWeight="fw.400"
										width="102px"
										pl="26px"
									>
										Title
									</Th>
									<Th
										textTransform="capitalize"
										color="#868B94"
										fontSize="fs.16"
										fontWeight="fw.400"
										width="102px"
										pl="26px"
									>
										Relevant Skills
									</Th>
									<Th
										textTransform="capitalize"
										color="#868B94"
										fontSize="fs.16"
										fontWeight="fw.400"
										width="auto"
										textAlign={'center'}
									>
										Action
									</Th>
								</Tr>
							</Thead>
							<Tbody>
								{isPageLoad && <Tr><Th colSpan={4} textAlign={'center'}>LOADING...</Th></Tr>}
								{!isPageLoad && eBooksList?.length === 0 && <Tr><Th colSpan={4} textAlign={'center'}>NO EBOOKS FOUND. PLEASE ADD SOME</Th></Tr>}
								{eBooksList?.map((eBook) => (
									<EBookListItems
										key={eBook._id}
										book={eBook}
									// onDelete={callGetEBooksAPI}
									/>
								))}
							</Tbody>
						</Table>
					</TableContainer>

				</Box >
			) : (
				<Box
					overflowY="auto"
					className={Style.overflow}
					pb={"5px"}
					height={{ base: "calc(100vh - 225px)", md: "calc(100vh - 240px)" }}
				>
					{isPageLoad && (
						<BookCardSkeleton noOfCards={6} />
					)}
					{!isPageLoad && eBooksList && eBooksList.length === 0 &&
						<Flex
							width={'100%'}
							marginLeft={'auto'}
							marginRight={'auto'}
							marginTop={'100px'}
							padding={'10px'}
							height={'400px'}
							alignItems="center"
							justifyContent="center"
						>
							<Box>
								<Image src={NoDataLogo} height={"250px"} alt="No data found" />
							</Box>
						</Flex>
					}
					{
						eBooksList?.length > 0 &&
						<Grid templateColumns="repeat(auto-fill, minmax(280px, 1fr))" gap={12}>
							{eBooksList?.map((eBook) => (
								<EBookCard key={eBook._id} book={eBook} />
							))}
						</Grid>
					}
				</Box>
			)
			}
			{
				eBooksList && eBooksList.length > 0 ? (
					<Box position="relative" mt="20px"
					>
						<ReactPaginate
							initialPage={page - 1}
							pageCount={Math.ceil(totalPages)}
							containerClassName={"paginationButtons"}
							disabledClassName={"paginationDisabled"}
							activeClassName={"paginationActive"}
							previousLabel={<span>&larr; Previous</span>}
							nextLabel={<span>Next &rarr;</span>}
							onPageChange={handlePageChange}
						/>
					</Box>) : null
			}
		</>
	);
}
