export function formatNumber(number, locale = 'en-US', options = {}) {
  return new Intl.NumberFormat(locale, options).format(number);
}

export function formatNumberWithSuffix(number) {
  if (number >= 1000000) {
    return new Intl.NumberFormat('en-US', {
      notation: 'compact',
      maximumFractionDigits: 1
    }).format(number / 1000000) + 'M';
  } else if (number >= 1000) {
    return new Intl.NumberFormat('en-US', {
      notation: 'compact',
      maximumFractionDigits: 1
    }).format(number / 1000) + 'K';
  } else {
    return new Intl.NumberFormat('en-US').format(number);
  }
}
