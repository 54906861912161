import { Box, Button, Flex, Input, Spinner, Text, useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { GET_USER_DATA } from "../../../../apis/constants.api";
import { updateProfileAPI } from "../../../../apis/users.api";
import { AppContext } from "../../../../contexts/AppContext";
import ProfileDropZone from "../../../Dropzone/ImageDropzone";
import Style from "../../../style.module.css";
import ChangePassDialog from "../../ChangePassDialog";
import { useNavigate } from "react-router-dom";

export default function AdministratorProfile() {
  const toast = useToast();
  const [stateApp,] = useContext(AppContext);
  const { user } = stateApp;
  const [isChangePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
  const navigate = useNavigate();

  const openChangePasswordModal = () => setChangePasswordModalOpen(true);
  const closeChangePasswordModal = () => setChangePasswordModalOpen(false);

  const { control, handleSubmit, formState: { errors }, reset } = useForm({
    defaultValues: {
      name: '',
      profilePicture: '',
    },
  });

  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(updateProfileAPI, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_USER_DATA] });
      toast({
        title: "Profile updated.",
        description: "Your profile has been successfully updated.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    },
    onError: (error) => {
      toast({
        title: "An error occurred.",
        description: "Unable to update profile. Please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  });

  useEffect(() => {
    const { name, profilePicture } = stateApp.user || {};
    reset({
      name,
      profilePicture
    });
  }, [stateApp?.user]);

  const onSubmit = async (data) => {
    const payload = {
      name: data.name,
      profilePicture: data.profilePicture || null,
    };
    await mutate(payload);
  };

  return (
    <>
      <Box padding="25px" width="100%" display={"flex"} justifyContent={"center"} height="calc(100vh-130px)" className="test" >
        <Box width="55%">
          <Flex flexDirection="column" background="white" borderRadius="br.10" className={Style.overflow}  >
            <Flex flexDirection="column"  >
              <form onSubmit={handleSubmit(onSubmit)}>
                <Flex p={6} direction="column" gap={6}>
                  <Box >
                    <Text fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} fontWeight="fw.500">User Name </Text>
                    <Controller
                      name="name"
                      control={control}
                      rules={{ required: true, pattern: /[A-Za-z ]*/ }}
                      render={({ field, fieldState }) =>
                        <>
                          <Input {...field} placeholder="Full name "
                            errorBorderColor="red.500"
                            isInvalid={errors?.name?.message || fieldState?.['name']?.message}
                            height="50px" _hover={{ borderColor: "#713EDD" }}
                            background="#F7FAFC" mt="8px" _focusVisible={{ borderColor: "#713EDD" }} />

                          {(errors?.name?.message || fieldState?.['name']?.message)
                            && <Text size={'small'} fontSize={'sm'} mt={1} color="red.500">{errors?.name?.message || fieldState?.['name']?.message}</Text>}
                        </>
                      }
                    />
                  </Box>
                  <Box >
                    <Text fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} fontWeight="fw.500">Email</Text>

                    <Input
                      value={stateApp?.user?.email}
                      disabled
                      errorBorderColor="red.500"
                      height="50px" _hover={{ borderColor: "#713EDD" }}
                      background="#F7FAFC" mt="8px" _focusVisible={{ borderColor: "#713EDD" }} />
                  </Box>
                  <Button onClick={openChangePasswordModal} px={{ md: "15px", lg: "30px" }} py="8px" background="linear-gradient(134.59deg, #713EDD -1.98%, #A17DEE 111.17%)"
                    _hover={{ background: "linear-gradient(134.59deg, #A17DEE -1.98% ,#713EDD 111.17%)" }} gap={5} color="white">Change Password </Button>
                  <Box >
                    <Text fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} mb="8px" fontWeight="fw.500">Profile</Text>
                    <Controller
                      name="profilePicture"
                      control={control}
                      render={({ field }) =>
                        <ProfileDropZone
                          {...field}
                          innerMessage='Upload Profile Image'
                          acceptedFiles={{ 'image/*': ['jpg', 'jpeg', 'png', '.webp'] }}
                          maxFiles={5}
                          defaultImages={user?.profilePicture ? [user?.profilePicture] : []}
                          onFileChange={(files) => field.onChange(files[0])}
                          wrapperStyle={{ w: 'auto', aspectRatio: '1/1', borderRadius: '100%' }}
                        />
                      }
                    />
                  </Box>

                  <Flex gap="10px" mt={2} justify={'end'}>
                    <Button background="#EAE9EC" onClick={() => navigate("/")} >Cancel</Button>
                    <Button type="submit" px={{ md: "15px", lg: "30px" }} py="8px" background="linear-gradient(134.59deg, #713EDD -1.98%, #A17DEE 111.17%)"
                      _hover={{ background: "linear-gradient(134.59deg, #A17DEE -1.98% ,#713EDD 111.17%)" }} gap={5} color="white">Update {isLoading && <Spinner />}</Button>
                  </Flex>
                </Flex>
              </form>
            </Flex>
          </Flex>
        </Box>
      </Box>

      <ChangePassDialog
        isOpen={isChangePasswordModalOpen}
        onClose={closeChangePasswordModal}
      />

    </>
  );
}