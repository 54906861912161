import * as yup from "yup";

const optionSchema = yup.object({
  label: yup.string(),
  value: yup.string(),
});

export const profileSchema = yup
  .object({
    name: yup.string(),
    state: optionSchema,
    // city: optionSchema,
    country: optionSchema,
    profilePicture: yup.string(),
  })
  .required();

export const expSchema = yup
  .object({
    profession: yup.string(),
    yearsOfExperience: optionSchema,
  })
  .required();


export const educationSchema = yup
  .object({
    name: yup.string(),
    // city: optionSchema,
    country: optionSchema,
  })
  .required();