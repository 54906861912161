import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Avatar, Box, Button, Flex, Spinner, Stack, Tag, Td, Tr, useDisclosure, useToast } from '@chakra-ui/react';
import { IconCheck } from '@tabler/icons-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isEmpty, isEqual } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import Select from 'react-select';
import { GET_ALL_MEMBERS } from '../../apis/constants.api';
import { updateUserRoleAPI, updateUserStatusAPI } from '../../apis/users.api';
import AcceptUserModal from './AcceptUserModal';
import DeactivateModal from './DeactivateModal';
import RejectUserModal from './RejectUserModal';
import ViewDocumentsModal from './ViewDocumentsModal';

const COLOR = {
  ONBOARDING: 'blue',
  INVITED: 'blue',
  DEACTIVATED: 'gray',
  ACCEPTED: 'green',
  REJECTED: 'red',
};

const UserStatusOptions = [
  { value: 'ONBOARDING', label: 'Onboarding' },
  { value: 'ACCEPTED', label: 'Accepted' },
  { value: 'REJECTED', label: 'Rejected' },
  { value: 'DEACTIVATED', label: 'Deactivated' },
];

const UserRoleOptions = [
  { value: 'Mentor', label: 'Lead' },
  { value: 'Student', label: 'Member' },
];

function UserListView({ user }) {
  const { isOpen: openRejectModal, onOpen: onOpenRejectModal, onClose: onCloseRejectModal } = useDisclosure();
  const { isOpen: openAcceptModal, onOpen: onOpenAcceptModal, onClose: onCloseAcceptModal } = useDisclosure();
  const { isOpen: openDeactivateModal, onOpen: onOpenDeactivateModal, onClose: onCloseDeactivateModal } = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [newStatus, setNewStatus] = useState(null);
  const [newRole, setNewRole] = useState(null);
  const queryClient = useQueryClient();

  const { mutate, isLoading: isDeactivating } = useMutation(updateUserStatusAPI, {
    onSuccess: () => {
      queryClient.invalidateQueries([GET_ALL_MEMBERS]);
      toast({
        position: 'top-right',
        status: 'success',
        title: 'Status Updated'
      });
      onCloseDeactivateModal();
      onCloseAcceptModal();
      onCloseRejectModal();
    }
  });

  const { mutate: mutateUserRole, isLoading: isRoleChanging } = useMutation(updateUserRoleAPI, {
    onSuccess: () => {
      queryClient.invalidateQueries([GET_ALL_MEMBERS]);
      toast({
        position: 'top-right',
        status: 'success',
        title: 'Role Updated'
      });
    }
  });

  const onSubmit = async () => {
    const payload = {
      userId: user?._id,
    };
    await mutate(payload);
    queryClient.invalidateQueries([GET_ALL_MEMBERS]);

  };

  const handleChange = (value) => {
    setNewStatus(value);
  };

  const Option = (props) => {
    return (
      <Box py={1} px={2} onClick={() => props.selectOption(props.data)} cursor={'pointer'}>
        <Tag colorScheme={COLOR[props.data?.value]} borderRadius='full'>
          {props.data?.label}
        </Tag>
      </Box>);
  };

  const SingleValue = (props) => {
    return (
      <Flex justify={'center'} align='center' flex="1 1 auto">
        <Tag colorScheme={COLOR[props.data?.value]} borderRadius={'full'} w="fit-content">{props.children}</Tag>
      </Flex>
    );
  };

  const ValueContainer = ({
    children,
    ...props
  }) => <Box style={{ display: 'flex', alignItems: 'center', padding: '5px 10px' }}>{children}</Box>;

  const currentUserStatusValue = useMemo(() => {
    const find = UserStatusOptions.find(us => us.value === user.status);
    return find;
  }, [user.status]);

  const currentUserRoleValue = useMemo(() => {
    const find = UserRoleOptions.find(us => us.value?.toLowerCase() === user.role.toLowerCase());
    return find;
  }, [user.role]);

  const isStatusChanged = useMemo(() => {
    return !isEmpty(newStatus) ? !isEqual(newStatus, currentUserStatusValue) : false;
  }, [newStatus, currentUserStatusValue]);

  const isRoleChanged = useMemo(() => {
    return !isEmpty(newRole) ? !isEqual(newRole, currentUserRoleValue) : false;
  }, [newRole, currentUserRoleValue]);

  const onClick = useCallback(() => {
    if (newStatus.value === "ACCEPTED") {
      onOpenAcceptModal();
    } else if (newStatus.value === 'REJECTED') {
      onOpenRejectModal();
    } else {
      mutate({
        userId: user?._id,
        status: newStatus?.value
      });
    }
  }, [newStatus]);

  const updateUserRole = useCallback(() => {
    if (isRoleChanging)
      return;

    mutateUserRole({
      userId: user?._id,
      role: newRole?.value
    });
  }, [newRole]);

  return (
    <>
      <Tr py='14px'>
        <Td
          px='20px'
          py='14px'
          color='rgba(32, 43, 60, 1)'
          fontSize='fs.16'
          fontWeight='fw.500'>
          <Avatar
            fontSize='fs.20'
            fontWeight='fw.600'
            width={{
              base: '30px',
              md: '30px',
              lg: '40px',
              xl: '40px',
            }}
            height={{
              base: '30px',
              md: '30px',
              lg: '40px',
              xl: '40px',
            }}
            color="white"
            borderRadius='6px'
            name={`${user.name}`}
            src={user.profilePicture}
            onClick={onOpen}
            className='cursor-pointer'
          />
        </Td>
        <Td
          pl='0px'
          pr='20px'
          py='14px'
          color='rgba(134, 139, 148, 1)'
          fontSize={{
            base: 'fs.14',
            md: 'fs.14',
            lg: 'fs.16',
            xl: 'fs.16',
          }}
          fontWeight='fw.500'
          onClick={onOpen}
          className='cursor-pointer'
        >
          {user.name}</Td>
        <Td
          pl='0px'
          pr='20px'
          py='14px'
          color='rgba(134, 139, 148, 1)'
          fontSize={{
            base: 'fs.14',
            md: 'fs.14',
            lg: 'fs.16',
            xl: 'fs.16',
          }}
          fontWeight='fw.500'
          width='360px'>
          {user.email}</Td>
        <Td
          pl='0px'
          pr='20px'
          py='14px'
          color='rgba(134, 139, 148, 1)'
          fontSize={{
            base: 'fs.14',
            md: 'fs.14',
            lg: 'fs.16',
            xl: 'fs.16',
          }}
          fontWeight='fw.500'
          width='170px'>
          <Flex width="150px" gap={4}>
            <Select
              styles={{
                container: (styles) => ({
                  ...styles,
                  width: '100%'
                })
              }}
              defaultValue={currentUserRoleValue}
              options={UserRoleOptions}
              onChange={setNewRole}
            />
            {
              isRoleChanged &&
              <Button disabled={isRoleChanging} onClick={updateUserRole} variant={'unstyled'} display='flex' justifyContent={'center'} alignItems={'center'} bg="green.500" color='white' p={1} w={20}>
                {
                  isRoleChanging ?
                    <Spinner />
                    :
                    <IconCheck stroke={2} size={18} />
                }
              </Button>
            }
          </Flex>
        </Td>
        <Td
          pl='0px'
          pr='20px'
          py='14px'
          color='rgba(134, 139, 148, 1)'
          fontSize={{
            base: 'fs.14',
            md: 'fs.14',
            lg: 'fs.16',
            xl: 'fs.16',
          }}
          fontWeight='fw.500'>
          {user.address.country}</Td>
        <Td
          pl='0px'
          pr='20px'
          py='14px'
          fontSize={{
            base: 'fs.14',
            md: 'fs.14',
            lg: 'fs.16',
            xl: 'fs.16',
          }}
          fontWeight='fw.500'>
          <Flex width="230px" gap={4}>
            <Select
              styles={{
                container: (styles) => ({
                  ...styles,
                  width: '100%'
                })
              }}
              components={{ Option, SingleValue, ValueContainer }}
              defaultValue={currentUserStatusValue}
              options={UserStatusOptions}
              onChange={handleChange}
            />
            {
              isStatusChanged &&
              <Button disabled={isDeactivating} onClick={onClick} variant={'unstyled'} display='flex' justifyContent={'center'} alignItems={'center'} bg="green.500" color='white' p={1} w={25}>
                {
                  isDeactivating ?
                    <Spinner />
                    :
                    <IconCheck stroke={2} size={18} />
                }
              </Button>
            }
          </Flex>
        </Td>
        {/* <Td
          pl='0px'
          pr='20px'
          py='14px'
          color={user.isOnboardingComplete ? "#E1251A" : "#27AC2D"}
          fontSize={{
            base: 'fs.14',
            md: 'fs.14',
            lg: 'fs.16',
            xl: 'fs.16',
          }}
          fontWeight='fw.500'>
          <Flex gap='10px' justifyContent={'center'}>
            <Stack direction='row'>
              <Button bg='blue.100' onClick={onOpen} color="white" fontSize="14px" fontWeight="500" _hover={{ background: "blue.200", color: "blue.700" }} borderRadius="100%" aspectRatio={"1/1"}>
                <ExternalLinkIcon color="blue.600" />
              </Button>
            </Stack>
          </Flex>
        </Td> */}
      </Tr>
      {
        openAcceptModal &&
        <AcceptUserModal user={user} onClose={onCloseAcceptModal} />
      }
      {
        openRejectModal &&
        <RejectUserModal user={user} onClose={onCloseRejectModal} />
      }
      {
        openDeactivateModal &&
        <DeactivateModal data={user} onClose={onCloseDeactivateModal} onSubmit={onSubmit} isDeactivate={isDeactivating} />
      }
      <ViewDocumentsModal onClose={onClose} isOpen={isOpen} data={user} />
    </>
  );
}

export default UserListView;