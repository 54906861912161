import { HStack, Skeleton, SkeletonCircle } from "@chakra-ui/react";
import { nanoid } from "nanoid";
import { useState } from "react";

export default function SkeletonChatDirectItem({ noOfItems = 1 }) {
  const [items] = useState(new Array(noOfItems).fill('').map(item => nanoid()));

  return (
    <>
      {items.map(item =>
        <HStack w="100%" key={item} my={2}>
          <SkeletonCircle size='6' />
          <Skeleton w='80%' h={'20px'} borderRadius={'5px'} />
        </HStack>
      )}
    </>
  );
}