import { Editor, Transforms, Range, Element } from "slate";
import { ImageElement } from "./SlateImage";
import { useMemo } from "react";
import { css } from "@emotion/css";
import isHotkey, { isKeyHotkey } from "is-hotkey";

export const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code",
};
const LIST_TYPES = ["numbered-list", "bulleted-list"];

const allowedSchemes = ['http:', 'https:', 'mailto:', 'tel:'];

export const SlateElement = (props) => {
  const { attributes, children, element } = props;

  const safeUrl = useMemo(() => {
    let parsedUrl = null;
    try {
      parsedUrl = new URL(element.url);
      // eslint-disable-next-line no-empty
    } catch { }
    if (parsedUrl && allowedSchemes.includes(parsedUrl.protocol)) {
      return parsedUrl.href;
    }
    return 'about:blank';
  }, [element.url]);

  switch (element.type) {
    case "block-quote":
      return <blockquote className="blockquote_font" {...attributes}>{children}</blockquote>;
    case "bulleted-list":
      return <ul className="ul_font" {...attributes}>{children}</ul>;
    case "heading-one":
      return <h1 className="h1_font" {...attributes}>{children}</h1>;
    case "heading-two":
      return <h2 className="h2_font"{...attributes}>{children}</h2>;
    case "list-item":
      return <li className="li_font" {...attributes}>{children}</li>;
    case "numbered-list":
      return <ol className="ol_font" {...attributes}>{children}</ol>;
    case "image":
      return <ImageElement {...props} />;
    case "link":
      return (
        <a
          {...attributes}
          href={safeUrl}
          // className={
          //   selected
          //     ? css`
          //       // box-shadow: 0 0 0 3px #ddd;
          //       color : cornflowerblue;
          //     `
          //     : ''
          // }
          className={css`
              // box-shadow: 0 0 0 3px #ddd;
              color : cornflowerblue;
            `}
        >
          <InlineChromiumBugfix />
          {children}
          <InlineChromiumBugfix />
        </a>
      );
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const InlineChromiumBugfix = () => (
  <span
    contentEditable={false}
    className={css`
      font-size: 0;
    `}
  >
    {String.fromCodePoint(160) /* Non-breaking space */}
  </span>
);

export const SlateLeaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

export const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => n.type === format,
  });
  return !!match;
};

export const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) => LIST_TYPES.includes(n.type),
    split: true,
  });

  Transforms.setNodes(editor, {
    type: isActive ? "paragraph" : isList ? "list-item" : format,
  });

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

export const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

export const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

export const handleEnter = (editor, nativeEvent) => {
  const { selection } = editor;
  if (selection && Range.isCollapsed(selection)) {
    const [node, path] = Editor.parent(editor, selection.anchor.path);

    if (Element.isElement(node)) {
      if (node.type === "code-block") {
        Editor.insertText(editor, "\n");
      } else if (LIST_TYPES.includes(node.type)) {
        console.log(" Inside List element");
        Transforms.splitNodes(editor);
      } else if (node.children[0].code) {
        // Transforms.insertText(editor, "\n");
        Transforms.insertNodes(editor, {
          type: "code-line",
          children: [{ text: "", code: true }],
        });
        console.log(" Inside Code");
      }
      else if (node.type === "link") {

        if (isKeyHotkey('right', nativeEvent)) {
          Transforms.move(editor, { unit: 'offset', reverse: false });
          return;
        }
        if (isKeyHotkey('left', nativeEvent)) {
          Transforms.move(editor, { unit: 'offset' });
          return;
        }
        // If inside a link, move out of the link and insert a new paragraph
        // Transforms.unwrapNodes(editor, { match: n => n.type === 'link' });
        Transforms.insertNodes(editor, {
          type: 'paragraph',
          children: [{ text: "" }],
        });
        Transforms.select(editor, Editor.end(editor, []));
      }
      else {
        // Transforms.insertText(editor, "\n");
        Transforms.insertNodes(editor, {
          type: node.type,
          children: [{ text: "" }],
        });
      }
    }
  }
};