import { Box, Button, Flex, Grid, Spinner, Text, useDisclosure } from "@chakra-ui/react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useContext, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { getAllChannels, getMyChannels } from "../../apis/channel.api";
import { GET_CHANNEL, GET_MY_CHANNELS } from "../../apis/constants.api";
import { AppContext } from "../../contexts/AppContext";
import { isAdmin } from "../../utils/users.utils";

import ChannelsModal from "./ChannelTabDialog";
import ChannelListItem from "./GroupContent/ChannelListItem";
import ChannelListItemSkeleton from "./Skeleton";

const PAGE_SIZE = process.env.REACT_APP_PAGE_SIZE || 10;
export default function YourGroup() {
    const { isOpen: addNewChannelsIsOpen, onOpen: addNewChannelsOnOpen, onClose: addNewChannelsOnClose } = useDisclosure();
    const [stateApp] = useContext(AppContext);
    const [totalRecords, setTotalRecords] = useState(0);

    const [page, setPage] = useState(1);

    const key = isAdmin(stateApp?.user) ? GET_CHANNEL : GET_MY_CHANNELS;
    const queryFn = isAdmin(stateApp?.user) ? getAllChannels : getMyChannels;

    const { data: channelsData, isLoading, isFetching } = useQuery({
        queryKey: [key, {
            skip: PAGE_SIZE * (page - 1),
            limit: PAGE_SIZE,
            includeArchive: isAdmin(stateApp?.user)
        }],
        queryFn: queryFn,
    });

    const channels = channelsData?.data?.data?.channels;

    const handlePageChange = useCallback(() => {
        setPage(page + 1);
    }, [page]);

    return (
        <>
            <Box padding="35px" width="100%"  >
                <Box background="white" borderRadius="br.10" padding="25px" height="calc(100dvh - 150px)" display={"flex"} flexDir={'column'}>
                    <Flex justifyContent="space-between" alignItems="center" pb="20px">
                        <Text fontSize="fs.22" fontWeight="fw.700" >{isAdmin(stateApp?.user) ? "Channel" : "My Channels"}</Text>
                        {isAdmin(stateApp.user) && <Box >
                            <Button px={{ base: 2, md: "15px", lg: "30px" }} fontSize={{ base: 12, sm: 14, md: 16 }} onClick={addNewChannelsOnOpen} py="8px" background="linear-gradient(134.59deg, #713EDD -1.98%, #A17DEE 111.17%)" _hover={{ background: "linear-gradient(134.59deg, #A17DEE -1.98% ,#713EDD 111.17%)" }} color="white"><FontAwesomeIcon className="fa-md" icon={faPlus} /> &nbsp; Add Channel</Button>
                        </Box>}
                    </Flex>
                    <Box flex={'1'} id="infiniteScrollContainer" overflowY={'scroll'}>
                        <InfiniteScroll
                            dataLength={channels?.length || 100}
                            next={handlePageChange}
                            hasMore={channels?.length < totalRecords}
                            pullDownToRefresh={false}
                            scrollableTarget="infiniteScrollContainer"
                            loader={<Text textAlign={'center'}><Spinner my="2.5" size={'lg'} color="purple.500" /></Text>}
                            scrollThreshold={0.25}
                        >
                            <Grid templateColumns={{ md: "repeat(2, 1fr)", lg: "repeat(3, 1fr)", xl: "repeat(4, 1fr)" }} gap={6}>
                                {channels?.map((channel) =>
                                    <ChannelListItem key={channel._id} channel={channel} />
                                )}
                                {
                                    (isLoading || isFetching) &&
                                    <ChannelListItemSkeleton noOfItems={isLoading ? 5 : 1} />
                                }
                            </Grid>
                        </InfiniteScroll>
                    </Box>
                </Box>
            </Box>
            {
                addNewChannelsIsOpen &&
                <ChannelsModal isOpen={addNewChannelsIsOpen} onClose={addNewChannelsOnClose} />
            }
        </>
    );
}