
import { protectedRoute } from "../config/api.config";

export function publishEBook(request) {
  return protectedRoute.post('/ebook/add', request);
}

export function deletePublishEBook(eBookId) {
  return protectedRoute.delete(`/ebook/${eBookId}`);
}

export function updatePublishEBook({ bookId, ...payload }) {
  return protectedRoute.put(`/ebook/update/${bookId}`, payload);
}

export function getEBooks({ queryKey }) {
  const { skip, limit, search } = queryKey[1];
  return protectedRoute.get(`/ebook`, { params: { skip, limit, search } });
}

export function preSignedUrl(url) {
  return protectedRoute.get(`/files/pre-signed-url?key=${url}`);
}
