import { Box, Spinner, Table, TableContainer, Tbody, Th, Thead, Tr } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { GET_ALL_MEMBERS } from '../../apis/index.api';
import { getAllUserDataAPI } from '../../apis/users.api';
import UserListView from './UserListView';

const PAGE_SIZE = process.env.REACT_APP_PAGE_SIZE || 25;
const UsersPage = ({ filters }) => {
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [userList, setUserList] = useState([]);

  const queryKey = () => {
    const query = [GET_ALL_MEMBERS, { skip: (page - 1) * PAGE_SIZE, limit: PAGE_SIZE, role: ['Mentor', 'Student'] }];

    if (filters.status) {
      query[1] = {
        ...query[1],
        status: filters.status.value
      };
    }

    if (filters.search) {
      query[1] = {
        ...query[1],
        search: filters.search
      };
    }

    return query;
  };
  const {
    data,
    isLoading,
    isFetching
  } = useQuery({
    queryKey: queryKey(),
    queryFn: getAllUserDataAPI,
  });

  useEffect(() => {
    if (data) {
      setUserList(data.data.data);
      setPageCount(data?.data?.total / PAGE_SIZE);
    }
  }, [data]);

  const handlePageChange = useCallback((data) => {
    setPage(data.selected + 1);
  }, []);


  return (
    <>
      <Box
        pt={{
          base: '15px',
          md: '15px',
          lg: '25px',
          xl: '25px',
        }}
        height="-webkit-fill-available"
        bg='rgba(255, 255, 255, 1)'
        borderRadius='10px'>
        <TableContainer
          border='1px solid rgba(234, 236, 240, 1)'
          borderRadius='0px 0px 10px 10px'
          height="calc(100vh - 375px)"
          overflowY='scroll'
          overflowX='scroll'
          css={` &::-webkit-scrollbar {width: 0; height: 0;} &::-webkit-scrollbar-thumb { background-color: rgba(0, 0, 0, 0); }`}>
          <Box position='relative'>
            <Table variant='simple' style={{ borderSpacing: '20px 0px' }}>
              <Thead
                bgGradient='linear-gradient(0deg, #EAECF0, #EAECF0),linear-gradient(0deg, #F9FAFB, #F9FAFB)'
                textAlign='center'
                position='sticky'
                top='0px'
                zIndex='1'
              >
                <Tr>
                  <Th
                    fontSize={{
                      base: 'fs.14',
                      md: 'fs.14',
                      lg: 'fs.16',
                      xl: 'fs.18',
                    }}
                    fontWeight='fw.400'
                    color=' rgba(134, 139, 148, 1)'
                    pl='20px'
                    py='13px'
                    textTransform="capitalize">
                    Profile</Th>
                  <Th
                    fontSize={{
                      base: 'fs.14',
                      md: 'fs.14',
                      lg: 'fs.16',
                      xl: 'fs.18',
                    }}
                    fontWeight='fw.400'
                    color=' rgba(134, 139, 148, 1)'
                    px='0px'
                    py='13px'
                    textTransform="capitalize">
                    Name</Th>
                  <Th
                    fontSize={{
                      base: 'fs.14',
                      md: 'fs.14',
                      lg: 'fs.16',
                      xl: 'fs.18',
                    }}
                    fontWeight='fw.400'
                    color=' rgba(134, 139, 148, 1)'
                    px='0px'
                    py='13px'
                    textTransform="capitalize">
                    Email</Th>
                  <Th
                    fontSize={{
                      base: 'fs.14',
                      md: 'fs.14',
                      lg: 'fs.16',
                      xl: 'fs.18',
                    }}
                    fontWeight='fw.400'
                    color=' rgba(134, 139, 148, 1)'
                    px='0px'
                    py='13px'
                    textTransform="capitalize">
                    Role</Th>
                  <Th
                    fontSize={{
                      base: 'fs.14',
                      md: 'fs.14',
                      lg: 'fs.16',
                      xl: 'fs.18',
                    }}
                    fontWeight='fw.400'
                    color=' rgba(134, 139, 148, 1)'
                    pl='0px'
                    pr='20px'
                    py='13px'
                    textTransform="capitalize">
                    Country</Th>
                  <Th
                    fontSize={{
                      base: 'fs.14',
                      md: 'fs.14',
                      lg: 'fs.16',
                      xl: 'fs.18',
                    }}
                    fontWeight='fw.400'
                    color=' rgba(134, 139, 148, 1)'
                    px='0px'
                    py='13px'
                    textTransform="capitalize">
                    Status</Th>
                  {/* <Th
                    fontSize={{
                      base: 'fs.14',
                      md: 'fs.14',
                      lg: 'fs.16',
                      xl: 'fs.18',
                    }}
                    fontWeight='fw.400'
                    color=' rgba(134, 139, 148, 1)'
                    px='0px'
                    py='13px'
                    textTransform="capitalize">
                    Document</Th> */}
                </Tr>
              </Thead>

              <Tbody
                bg='transparent'>
                {(isFetching || isLoading) && <Tr><Th colSpan={10} textAlign={'center'}><Spinner color="purple.500" /></Th></Tr>}
                {userList?.map((user, i) => (
                  <UserListView
                    key={user._id}
                    user={user} />
                ))}
                {/* {isLoading && <Tr><Th colSpan={10} textAlign={'center'}><Spinner color="purple.500" /></Th></Tr>} */}
                {!isLoading && !userList.length && <Tr><Th colSpan={10} textAlign={'center'}>No users found</Th></Tr>}
              </Tbody>
            </Table>
          </Box >
        </TableContainer >
        <Box position="relative" mt="20px">
          <ReactPaginate
            pageCount={Math.ceil(pageCount)}
            containerClassName={"paginationButtons"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
            previousLabel={<span>&larr; Previous</span>}
            nextLabel={<span>Next &rarr;</span>}
            onPageChange={handlePageChange}
          />
        </Box>
      </Box >

    </>
  );
};

export default UsersPage;
