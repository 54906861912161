import { Badge, Box, Flex } from "@chakra-ui/react";
import { useDroppable } from "@dnd-kit/core";
import KanbanItem from "./Item";
import { KanabanLaneSkeleton } from "./KanabanLaneSkeleton";


const KanbanLane = ({ title, items = [], setItems, isLoading, isFetching }) => {
  const status = title;
  let colorScheme;

  switch (status) {
    case "To Do":
      colorScheme = "gray";
      break;
    case "In Progress":
      colorScheme = "blue";
      break;
    case "Review":
      colorScheme = "yellow";
      break;
    case "Done":
      colorScheme = "green";
      break;
    default:
      colorScheme = "blackAlpha";
      break;
  }

  const { setNodeRef, isOver } = useDroppable({
    id: title,
  });

  const borderEffect = {
    true: {
      borderWidth: '3px',
      borderStyle: 'solid',
      borderColor: 'blue.200'
    },
    false: {
      border: 'none'
    }
  };
  return (
    <>
      <Box minW="402px" ref={setNodeRef}>
        <Flex flex="3" flexDirection="column" p="20px" background="#F7F7F7" borderRadius="8" {...borderEffect[isOver]}>
          <Flex justifyContent="space-between" alignItems="center">
            <Flex gap="15px" alignItems='center' mb={4}>
              <Badge colorScheme={colorScheme} size="md" variant="solid" sx={{ borderRadius: '15px', padding: '5px 10px' }}>{title}</Badge>
              <Badge colorScheme={"blackAlpha"} size="md" variant="solid" sx={{ padding: '5px 10px' }}>{items.length}</Badge>
            </Flex>
            {/* <Flex gap="20px">
                            <FontAwesomeIcon color="rgba(134, 139, 148, 1)" icon={faEllipsis} />
                        </Flex> */}
          </Flex>
          {isLoading ? (
            <KanabanLaneSkeleton />
          ) :
            (<Flex
              flex="1"
              flexDirection="column"
            >
              {items.map((e, key) => (
                <KanbanItem key={e._id} index={key} parent={title} item={e} />
              ))}
              {/* <AddCard addCard={(title) => addCards(title)} /> */}
              {/* <WorkingVersion /> */}
            </Flex>)}
        </Flex >
      </Box >

    </>


  );
};

export default KanbanLane;