import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Text
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import AsyncSelect from 'react-select/async';
import { publicRoute } from '../../../config/api.config';
import { setYourProfession } from "../../../store/SignupStepsSlice";
import Style from "../../style.module.css";

const generateYearOptions = (startYear, endYear) => {
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
        years.push({ value: year, label: `${year} year${year > 1 ? "s" : ""}` });
    }
    years.push({ value: "15+", label: "15+ years" });
    return years;
};

const yearsOptions = generateYearOptions(1, 15);

const Profession = ({ goToNext, goToPrevious }) => {
    const dispatch = useDispatch();
    const profession = useSelector((state) => state?.SignupSteps?.profession);
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        control
    } = useForm({
        defaultValues: {
            profession: "",
            yearsOfExperience: null,
            skills: null

        }
    });

    const [isSubmitting, setIsSubmitting] = useState(false);


    const promiseSkillOptions = (inputSearch) => {
        return new Promise(async (resolve, reject) => {
            try {
                const result = await publicRoute.get(`/skills/list?search=` + inputSearch);
                const options = result.data.data.map(skills => ({
                    label: skills.name,
                    value: skills.name,
                    id: skills._id
                }));
                resolve(options);
            } catch (error) {
                reject(error);
            }
        });
    };


    const [error,] = useState(null);
    useEffect(() => {
        if (profession) {
            setValue("profession", profession.profession || "");
            setValue("yearsOfExperience", yearsOptions.find(option => option.value == profession.yearsOfExperience) || null);
        }
    }, []);
    useEffect(() => {
        const fetchSkillsOptions = async () => {
            try {
                // Fetch options with an empty string or any default search query
                const options = await promiseSkillOptions('');
                if (profession) {
                    // Assuming `profession.skills` contains the skill values to be preselected
                    const selectedSkills = profession.skills || [];
                    const initialSkills = options.filter(option => selectedSkills.includes(option.id));
                    setValue("skills", initialSkills);
                }
            } catch (error) {
                console.error('Error fetching skills options:', error);
            }
        };

        fetchSkillsOptions();
    }, []); // Add `setValue` if it's coming from props or context



    const onSubmit = async data => {
        setIsSubmitting(true);
        const payload = {
            profession: data.profession,
            skills: data?.skills?.map(skill => skill.id),
            // year: data.year && data.year.value ? data.year.value : null,
            yearsOfExperience: `${data.yearsOfExperience.value}` || ""
        };
        dispatch(setYourProfession(payload));
        goToNext();

        try {
            // const result = await privateInstance.post(
            //     "/user/update-profession",
            //     payload
            // )
            // if (result.status === 201) {
            //     // toast({
            //     //     description: successMsg.yourProfessionSucMsg,
            //     //     status: alertStatus.success
            //     // })
            //     router.replace("/upload-document")
            // } else {
            //     // toast({
            //     //     description: errorMsg.yourProfessionErrMsg,
            //     //     status: alertStatus.error
            //     // })
            //     // throw new Error("We are having some problem creating your account.");
            // }
        } catch (error) {
            // toast({
            //     description: error.message,
            //     status: alertStatus.error
            // })
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box>
                    <Box px={{ base: "0", xl: "25px" }}>
                        <Text
                            textAlign="center"
                            fontSize={{
                                base: "fs.25",
                                md: "fs.30",
                                lg: "fs.30",
                                xl: "fs.38"
                            }}
                            fontWeight="fw.600"
                        >
                            Your Profession
                        </Text>
                        <Text
                            textAlign="center"
                            fontSize={{
                                base: "fs.12",
                                md: "fs.14",
                                ld: "fs.16",
                                xl: "fs.18"
                            }}
                            fontWeight="fw.400"
                            pt="10px"
                            pb="20px"
                            color="#868B94"
                        >


                        </Text>
                        <hr />
                    </Box>
                    <FormControl mt={{ base: "40px" }}>
                        <FormLabel
                            fontSize={{
                                base: "fs.12",
                                md: "fs.14",
                                ld: "fs.16",
                                xl: "fs.18"
                            }}
                            fontWeight="fw.500"
                        >
                            Current Profession
                        </FormLabel>
                        <Input
                            {...register("profession", { required: true })}
                            isDisabled={isSubmitting}
                            border="1.5px solid rgba(210, 210, 210, 0.5)"
                            focusBorderColor="#713EDD"
                            placeholder={`Your Profession`}
                            type="text"
                            fontSize={{ base: "fs.14", md: "fs.16", lg: "fs.18" }}
                            borderRadius="10px"
                            height={{ base: "40px", lg: "50px", xl: "55px" }}
                        />
                        {errors.profession && (
                            <Text color="#E1251A">
                                Current Profession is required
                            </Text>
                        )}
                        <Box>
                            <Text
                                pt="20px"
                                pb="8px"
                                fontSize={{
                                    base: "fs.12",
                                    md: "fs.14",
                                    ld: "fs.16",
                                    xl: "fs.18"
                                }}
                                fontWeight="fw.500"
                            >
                                How many years of programming experience?
                            </Text>
                            <Controller
                                name="yearsOfExperience"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        options={yearsOptions}
                                        placeholder={`Select...`}
                                        loadingMessage={() => "Loading..."}
                                        openMenuOnFocus={false}
                                        isSearchable
                                        {...field}
                                        className={Style.skill_controller}
                                    />
                                )}
                            />
                        </Box>
                        <FormLabel
                            pt="20px"
                            fontSize={{
                                base: "fs.12",
                                md: "fs.14",
                                ld: "fs.16",
                                xl: "fs.18"
                            }}
                            fontWeight="fw.500"
                        >
                            List your skills
                        </FormLabel>
                        <Controller
                            name="skills"
                            control={control}
                            render={({ field }) => (
                                <AsyncSelect
                                    cacheOptions
                                    defaultOptions
                                    placeholder={`Select...`}
                                    loadOptions={promiseSkillOptions}
                                    className={Style.select_controller}
                                    isDisabled={isSubmitting}
                                    {...field}
                                    isMulti
                                />
                            )}
                        />
                    </FormControl>
                    <Flex
                        mt={{ base: "30px", md: "50px", lg: "30px", xl: "30px" }}
                        className="gap-3"
                    >
                        <Button
                            fontSize={{
                                base: "fs.12",
                                md: "fs.14",
                                lg: "fs.16",
                                xl: "fs.18",
                            }}
                            fontWeight="fw.500"
                            height={{ base: "40px", lg: "50px", "2xl": "55px" }}
                            width="50%"
                            borderRadius="10px"
                            onClick={(e) => goToPrevious(e)}
                            backgroundColor={"#F6F2FD"}
                            border={`1.5px solid #713EDD`}
                        >
                            Back
                        </Button>
                        <Button
                            isDisabled={isSubmitting}
                            type="submit"
                            color="white"
                            background="linear-gradient(150.62deg, #713EDD 5.97%, #A17DEE 64.37%)"
                            _hover={{ animation: "changeBg 0.5s ease infinite" }}
                            width="50%"
                            height={{ base: "40px", lg: "50px", "2xl": "55px" }}
                            fontSize={{
                                base: "fs.12",
                                md: "fs.14",
                                ld: "fs.16",
                                xl: "fs.18"
                            }}
                            fontWeight="fw.500"
                            borderRadius="10px"
                            gap={5}
                        >

                            Next
                            {/* {isLoading && <Spinner />} */}
                        </Button>
                    </Flex>
                    {error && (
                        <Text
                            textAlign={"center"}
                            fontSize={{
                                base: "fs.12",
                                md: "fs.14",
                                ld: "fs.16",
                                xl: "fs.18"
                            }}
                            fontWeight="fw.400"
                            color="#E1251A"
                        >
                            {error}
                        </Text>
                    )}
                </Box>
            </form>
        </>
    );
};
export default Profession;
