import { Box } from "@chakra-ui/react";
import ActiveMembers from "./ActiveMembers";
import CompletedTasks from "../PlanSchedule/CompletedTasks";
import { AppContext } from "../../../../../contexts/AppContext";
import { useContext } from "react";
import YourGroups from "../News/TeamActivity/YourGroups";

export default function Projects() {
    const [AppState] = useContext(AppContext);
    const { user } = AppState;

    return (
        <Box gap="35px" flexDirection={{ md: "column", lg: "unset" }} >
            <ActiveMembers />
            {user?.role !== "SysAdmin" && <CompletedTasks />}
            <YourGroups />
        </Box>
    );
}
