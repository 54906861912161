import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import SliderComponent from '../../Slider';
import StepperCompo from './stepper';
import Style from '../../style.module.css';

const UserStepper = props => {
  return (
    <>
      <Flex
        flexWrap={{ base: 'wrap', lg: 'nowrap' }}
        flexDirection={{ base: 'column', lg: 'unset' }}
      >
        <SliderComponent />
        <Flex
          background={{
            sm: 'linear-gradient(148.84deg, #713EDD 12.15%, #A17DEE 79.83%)',
            xl: 'white',
          }}
          position={{ lg: 'absolute', xl: 'unset' }}

          py={{ base: '0px', md: '30px', lg: '25px', xl: '0' }}
          pl={{ base: '0px', md: '30px', lg: '25px', xl: '0' }}
          pr={{ base: '0px', md: '30px', lg: '25px', xl: '0' }}
          alignItems="center"
          width={{ base: '100%', xl: '35%' }}
          minHeight={{ lg: '100%', xl: 'auto' }}
          justifyContent={{ base: 'space-between', xl: 'end' }}
        >
          <Flex
            overflow="hidden"
            padding={{ lg: '20px' }}
            ml="auto"
            width={{ base: '100%', md: '100%', lg: '50%', '2xl': '50%' }}
            height={{ base: '100vh', md: 'auto', lg: 'auto', xl: 'auto' }}
            alignItems="center"
            position={{ base: 'unset', xl: 'absolute' }}
            right="0"
            justifyContent={{
              base: 'center',
              lg: 'end',
              xl: 'space-between',
            }}
          >
            <Box width="100%" >
              <StepperCompo />
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};
export default UserStepper;
