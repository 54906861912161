import { Box, Button, Flex, Grid, GridItem, IconButton, Image, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, NumberInput, NumberInputField, Spinner, Text, Textarea, useDisclosure, useToast } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { IconPencil } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { forwardRef, memo, useImperativeHandle, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { GET_PRODUCTS } from "../../../apis/constants.api";
import { updateProduct } from "../../../apis/products.api";
import CustomDropzone from "../../Dropzone/FileDropZone";
import Style from '../../style.module.css';
import { schema } from "./form";

const ProductEditDialog = forwardRef(function ProductEditDialog({ product }, ref) {
  const toast = useToast();
  const { stock } = product;
  const isInStock = stock > 0;
  const queryClient = useQueryClient();
  const [isEditing, setIsEditing] = useState(false);

  const methods = useForm({
    defaultValues: {
      images: product.images,
      title: product.title,
      description: product.description,
      stock: product.stock,
      price: product.price,
      ratings: product.ratings
    },
    resolver: yupResolver(schema)
  });
  const { formState: { errors } } = methods;

  const { isOpen, onOpen, onClose } = useDisclosure({
    onClose: () => {
      methods.reset();
      setIsEditing(false);
    }
  });

  useImperativeHandle(ref, () => {
    return {
      openDialog() {
        onOpen();
      }
    };
  }, [onOpen]);

  const { mutate: executeUpdate, isLoading: isUpdating } = useMutation(updateProduct, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_PRODUCTS] });
      // setIsEditing(false);
      methods.reset();
    },
  });

  const handleCancel = () => {
    setIsEditing(false);
    methods.reset();
  };

  const onSubmit = async (values) => {
    const payload = {
      images: values.images,
      title: values.title,
      description: values.description,
      price: parseInt(values.price),
      stock: parseInt(values.stock),
      ratings: parseInt(values.ratings)
    };
    const productId = product._id;
    await executeUpdate({ productId, ...payload });

    toast({
      title: 'Product updated successfully',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
    // onClose();
    methods.reset(payload);
  };

  const onErrors = (errors) => {
    console.log(' &&&&&& onErrors &&&&& ', errors);
  };

  return (
    <Modal closeOnOverlayClick={false} onClose={onClose} isOpen={isOpen} isCentered  >
      <form onSubmit={methods.handleSubmit(onSubmit, onErrors)}>
        <ModalOverlay />
        <ModalContent maxWidth="599px" borderRadius="10px">
          <ModalHeader fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16", xl: "fs.18" }} fontWeight="fw.600">
            <Flex gap={2} justify={'flex-start'} align={'center'}>
              {isEditing ? 'Edit' : 'View'} Product
              {!isEditing && (
                <IconButton
                  bg='transparent'
                  icon={<IconPencil />}
                  variant={'ghost'}
                  onClick={() => setIsEditing(true)}
                />
              )}
            </Flex>
          </ModalHeader>
          <ModalCloseButton border="1px solid #868B944D" />
          <ModalBody pt="10px" pb="26px" width="600px">
            <Box>
              <Grid
                templateAreas={` "nav main" `}
                gridTemplateColumns={'120px 1fr'}
                gap='30px'
              >
                <GridItem area={'nav'}>
                  <Text fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} fontWeight="fw.400" >Status:</Text>
                </GridItem>
                <GridItem area={'main'}>
                  <Flex mb="20px" width="-webkit-fit-content" borderRadius="40px" background={!isInStock ? "#FCE9E8" : '#E3F4E4'} alignItems="center" justifyContent="center" height={{ base: "30px" }} px="16px" gap="6px" >
                    <Box width={{ base: "10px", md: "12px" }} height={{ base: "10px", md: "12px" }} borderRadius="100px" background={!isInStock ? "#E1251A" : "#27AC2D"}></Box>
                    <Text fontSize={{ base: "fs.12" }} fontWeight="fw.500" color={!isInStock ? "#E1251A" : "#27AC2D"} >{isInStock ? 'In Stock' : 'Out of Stock'}</Text>
                  </Flex>
                </GridItem>
              </Grid>

              <Grid
                templateAreas={` "nav main" `}
                gridTemplateColumns={'120px 1fr'}
                gap='30px'
              >
                <GridItem area={'nav'}>
                  <Text fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} fontWeight="fw.400" >Photos:</Text>
                </GridItem>
                <GridItem area={'main'}>
                  {isEditing ?
                    <Controller
                      name="images"
                      control={methods.control}
                      render={({ field, fieldState }) =>
                        <>
                          <CustomDropzone
                            isEditing={isEditing}
                            innerMessage='Upload Product Images'
                            acceptedFiles={{ 'image/*': ['jpg', 'jpeg', 'png', '.webp'] }}
                            maxFiles={5}
                            multiple
                            defaultImages={product.images}
                            onFileChange={(files) => {
                              field.onChange(files);
                            }}
                            error={errors?.images?.message || fieldState?.['images']?.message}
                          />
                        </>
                      }
                    /> :
                    <>
                      <Box>
                        <Flex flexWrap={"wrap"} justify={'flex-start'} align='center' gap={2} overflowX={'scroll'} className={Style.overflow}>
                          {!isEditing && product.images.map((img, ind) =>
                            <Box h={100} aspectRatio={'1/1'}>
                              <Image borderRadius="10px" h={50} aspectRatio={'1/1'} width="100%" src={img} alt="cardImg" height="-webkit-fill-available" objectFit="cover" />
                            </Box>
                          )}
                        </Flex>
                      </Box>
                    </>
                  }
                </GridItem>

              </Grid>
              <Grid
                templateAreas={`"header header" "nav main" `}
                gridTemplateColumns={'150px 1fr'}
                gap='1'
                mt="15px"
              >
                <GridItem area={'nav'}>
                  <Text fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} fontWeight="fw.400" >Product Name:</Text>
                </GridItem>
                <GridItem area={'main'}>
                  {isEditing ? (
                    <Controller
                      name="title"
                      control={methods.control}
                      render={({ field, fieldState }) =>
                        <>
                          <Input
                            {...field}
                            isInvalid={fieldState?.error?.['title']?.message}
                            errorBorderColor='red.500'
                            placeholder="Title"
                          />
                          {(errors?.title?.message || fieldState?.['title']?.message) && <Text size={'small'} fontSize={'sm'} mt={1} color="red.500">{errors?.title?.message || fieldState?.['title']?.message}</Text>}
                        </>
                      }
                    />
                  ) : (
                    <Text fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} fontWeight="fw.400" color="#868B94">{product.title}</Text>
                  )}
                </GridItem>
              </Grid>
              <Grid
                templateAreas={`"header header" "nav main" `}
                gridTemplateColumns={'150px 1fr'}
                gap='1'
                mt="15px"
              >
                <GridItem area={'nav'}>
                  <Text fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} fontWeight="fw.400" >Description:</Text>
                </GridItem>
                <GridItem area={'main'}>
                  {isEditing ? (
                    <Controller
                      name="description"
                      control={methods.control}
                      render={({ field, fieldState }) =>
                        <>
                          <Textarea
                            {...field}
                            placeholder="Description"
                            isInvalid={fieldState?.error?.['description']?.message}
                            errorBorderColor='red.500'
                          />
                          {(errors?.description?.message || fieldState?.['description']?.message) && <Text size={'small'} fontSize={'sm'} mt={1} color="red.500">{errors?.description?.message || fieldState?.['description']?.message}</Text>}
                        </>
                      }
                    />
                  ) : (
                    <Text fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} fontWeight="fw.400" color="#868B94">{product.description}</Text>
                  )}
                </GridItem>
              </Grid>
              <Grid
                templateAreas={`"header header" "nav main" `}
                gridTemplateColumns={'150px 1fr'}
                gap='1'
                mt="15px"
              >
                <GridItem area={'nav'}>
                  <Text fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} fontWeight="fw.400" >Price:</Text>
                </GridItem>
                <GridItem area={'main'}>
                  {isEditing ? (
                    <Controller
                      name="price"
                      control={methods.control}
                      render={({ field, fieldState }) =>
                        <>
                          <NumberInput
                            {...field}
                            onChange={(strValue, numberValue) => field.onChange(numberValue || 0)}
                            max={10000000}
                            min={0}
                            precision={2}
                            clampValueOnBlur={false}
                            errorBorderColor="red.500"
                            isInvalid={fieldState?.error?.['price']?.message} >
                            <NumberInputField placeholder='Price' />
                          </NumberInput>
                          {(errors?.price?.message || fieldState?.['price']?.message) && <Text size={'small'} fontSize={'sm'} mt={1} color="red.500">{errors?.price?.message || fieldState?.['price']?.message}</Text>}
                        </>
                      }
                    />
                  ) : (
                    <Text fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} fontWeight="fw.400" color="#868B94">{product.price}</Text>
                  )}
                </GridItem>
              </Grid>
              <Grid
                templateAreas={`"header header" "nav main" `}
                gridTemplateColumns={'150px 1fr'}
                gap='1'
                mt="15px"
              >
                <GridItem area={'nav'}>
                  <Text fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} fontWeight="fw.400" >Quantity:</Text>
                </GridItem>
                <GridItem area={'main'}>
                  {isEditing ? (
                    <Controller
                      name="stock"
                      control={methods.control}
                      render={({ field, fieldState }) => {
                        return (
                          <>
                            <NumberInput
                              {...field}
                              onChange={(strValue, numberValue) => field.onChange(numberValue || 0)}
                              max={10000000}
                              min={0}
                              precision={2}
                              clampValueOnBlur={false}
                              errorBorderColor="red.500"
                              isInvalid={fieldState?.error?.['stock']?.message} >
                              <NumberInputField placeholder='Stock' />
                            </NumberInput>
                            {(errors?.stock?.message || fieldState?.['stock']?.message) && <Text size={'small'} fontSize={'sm'} mt={1} color="red.500">{errors?.stock?.message || fieldState?.['stock']?.message}</Text>}
                          </>
                        );
                      }}
                    />
                  ) : (
                    <Text fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} fontWeight="fw.400" color="#868B94">{product.stock} units</Text>
                  )}
                </GridItem>
              </Grid>
              <Grid
                templateAreas={`"header header" "nav main" `}
                gridTemplateColumns={'150px 1fr'}
                gap='1'
                mt="15px"
              >
                <GridItem area={'nav'}>
                  <Text fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} fontWeight="fw.400" >Ratings:</Text>
                </GridItem>
                <GridItem area={'main'}>
                  {isEditing ? (
                    <Controller
                      name="ratings"
                      control={methods.control}
                      render={({ field, fieldState }) => {
                        return (
                          <>
                            <NumberInput
                              {...field}
                              onChange={(strValue, numberValue) => field.onChange(numberValue || 0)}
                              max={5}
                              min={0}
                              precision={1}
                              clampValueOnBlur={false}
                              errorBorderColor="red.500"
                              isInvalid={fieldState?.error?.['ratings']?.message} >
                              <NumberInputField placeholder='Ratings' />
                            </NumberInput>
                            {(errors?.ratings?.message || fieldState?.['ratings']?.message) && <Text size={'small'} fontSize={'sm'} mt={1} color="red.500">{errors?.ratings?.message || fieldState?.['ratings']?.message}</Text>}
                          </>
                        );
                      }}
                    />
                  ) : (
                    <Text fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} fontWeight="fw.400" color="#868B94">{product.ratings}</Text>
                  )}
                </GridItem>
              </Grid>
              {
                isEditing &&
                <Box gap="10px" mt={6} display="flex" justifyContent={'flex-end'} alignItems={'center'}>

                  <Button
                    type="submit"
                    px={{ md: "15px", lg: "30px" }}
                    py="8px"
                    background="linear-gradient(134.59deg, #713EDD -1.98%, #A17DEE 111.17%)"
                    display="flex"
                    columnGap="5px"
                    color="white"
                    _hover={{ background: "linear-gradient(134.59deg, #A17DEE -1.98%, #713EDD 111.17%)" }}
                    ml={2}
                  >
                    Save
                    {isUpdating && <Spinner ml={2} size={'sm'} />}
                  </Button>
                  <Button
                    type="button"
                    background="#EAE9EC"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Box>
              }
            </Box>
          </ModalBody>
        </ModalContent>
      </form>
    </Modal >
  );
});

export default memo(ProductEditDialog);