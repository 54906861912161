import * as yup from 'yup';

const PasswordExpression = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>/?]).{7,12}$/;

export const setPasswordFormSchema = yup.object().shape({
  password: yup
    .string()
    .matches(
      PasswordExpression,
      "Password must be 7-12 characters and include at least one uppercase letter, one lowercase letter, one number, and one special character."
    )
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match.")
    .required("Confirm Password is required."),
});