import axios from "axios";
import { nanoid } from "nanoid";
import { getPreSignedURL } from "../../apis/common.api";

export function upload(acceptedFiles, setState) {
  acceptedFiles.forEach((file, index) => {
    const id = nanoid();
    const previewUrl = URL.createObjectURL(file);

    setState((prevFiles) => [...prevFiles, { id, file: file, preview: previewUrl, status: 'loading' }]);

    // Assume getPresignedUrl is a function that returns a promise with the URL and fields for S3 upload
    getPreSignedURL({ fileName: file.name, contentType: file.type }).then((response) => {

      const url = response.data;
      const fileURL = url.slice(0, url.indexOf('?'));
      axios.put(url, file, {
        headers: {
          'Content-Type': file.type
        }
      }).then(() => {
        setState((prevFiles) => prevFiles.map(pFile => {
          if (pFile.id === id) {
            return { ...pFile, status: 'success', fileURL: fileURL };
          }
          return pFile;
        }));
      }).catch(() => {
        setState((prevFiles) => prevFiles.map(pFile => {
          if (pFile.id === id) {
            return { ...pFile, status: 'error', fileURL: fileURL };
          }
          return pFile;
        }));
      });
    });
  });
}

export function retry(id, state, setState) {
  const file = state.find(file => file.id === id);

  if (file) {
    setState((prevFiles) => prevFiles.map(pFile => {
      if (pFile.id === id) {
        return { ...pFile, status: 'loading' };
      }
      return pFile;
    }));
    getPreSignedURL({ fileName: file.file.name, contentType: file.file.type }).then((response) => {

      const url = response.data;

      axios.put(url, file, {
        headers: {
          'Content-Type': file.type
        }
      }).then(() => {
        setState((prevFiles) => prevFiles.map(pFile => {
          if (pFile.id === id) {
            return { ...pFile, status: 'success' };
          }
          return pFile;
        }));
      }).catch(() => {
        setState((prevFiles) => prevFiles.map(pFile => {
          if (pFile.id === id) {
            return { ...pFile, status: 'error' };
          }
          return pFile;
        }));
      });
    });
  }
}