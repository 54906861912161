import {
    Avatar, AvatarGroup, Box, Flex,
    Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs, Text
} from "@chakra-ui/react";
import { faList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GET_PROJECTS, GET_TASKS } from "../../../../apis/constants.api";
import { getMyProjectsAPI, getProjectById } from "../../../../apis/project.api";
import { getTasks } from "../../../../apis/task.api";
import Board from "../../../Board";
import OverlayLoader from "../../../shared/OverlayLoader";
import InfiniteSelect from "../../../shared/InfiniteSelect";
import { AvtarSkeleton } from "../../../Board/KanabanLane/KanabanLaneSkeleton";

const PAGE_SIZE = process.env.REACT_APP_PAGE_SIZE;
export default function TabsList() {
    const [activeTab, setActiveTab] = useState(0);
    const params = useParams();
    const location = useLocation();
    const pathSegments = location.pathname.split('/').filter(Boolean);
    const lastSegment = pathSegments[pathSegments.length - 1];

    const [page, setPage] = useState(1);
    const navigate = useNavigate();

    const [lanes, setLanes] = useState([
        { title: "To Do", items: [] },
        { title: "In Progress", items: [] },
        { title: "Review", items: [] },
        { title: "Done", items: [] },
    ]);
    const { data: task, isLoading, isFetching } = useQuery({
        queryKey: [GET_TASKS, {
            projectId: lastSegment
        }],
        queryFn: getTasks,
    });

    const { data: projectById, isFetching: projectDataFetching, isLoading: projectDataLoading } = useQuery({
        queryKey: [GET_PROJECTS, {
            projectId: lastSegment,
        }],
        queryFn: getProjectById,
        enabled: Boolean(lastSegment)
    });

    const { data: projectData } = useQuery({
        queryKey: [GET_PROJECTS, {
            skip: PAGE_SIZE * (page - 1),
            limit: PAGE_SIZE,
        }],
        queryFn: getMyProjectsAPI
    });
    const projectByIdList = projectById?.data || [];
    const projectList = projectData?.data;
    const [SelectProject, setSelectProject] = useState(null);

    const distributeItems = (items) => {
        const newLanes = lanes.map(lane => ({
            ...lane,
            items: items?.filter(item => {
                if (lane.title === "To Do") return item.status === "Todo";
                if (lane.title === "In Progress") return item.status === "InProgress";
                if (lane.title === "Review") return item.status === "Review";
                if (lane.title === "Done") return item.status === "Done";
                return false;
            })
        }));
        setLanes(newLanes);
    };

    useEffect(() => {
        distributeItems(task?.data);
    }, [lastSegment, task?.data]);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    const handleSelectProject = (val) => {
        const projectId = projectList.data.find(item => item.key == val.value)._id;
        setSelectProject(val);
        navigate(`/boards/${projectId}`);
    };

    useEffect(() => {
        setSelectProject(projectList?.data.filter(value => value.key === projectByIdList?.[0]?.key).map(item => ({ value: item.key, label: item.name }))[0]);
    }, [projectByIdList]);

    return (
        <Box padding={{ base: "15px", lg: "25px", xl: "35px" }} width="100%"  >
            {
                true || isLoading || isFetching &&
                <OverlayLoader />
            }
            <Box background="white" borderRadius="br.10" padding="25px" height={{ md: "calc(100vh - 110px)", lg: "calc(100vh - 150px)" }} >
                <Tabs position="relative" variant="unstyled" >

                    <Flex justifyContent={"flex-start"} borderBottom="1px solid #E3E4E8" pb="10px" flexWrap={{ md: "wrap-reverse", '2xl': "nowrap" }} gap="20px">

                        <Flex>
                            {(projectDataLoading) ? < AvtarSkeleton /> :
                                <AvatarGroup size='sm' max={2}>
                                    {projectByIdList[0]?.team?.map((e) => {
                                        return <Avatar key={e?.profilePicture + e?.name} name={e?.name} src={e?.profilePicture} />;
                                    })}
                                </AvatarGroup>
                            }
                        </Flex>

                        <TabList position={"relative"} width={{ base: "100%", xl: "auto" }} >
                            {projectByIdList[0]?.team && <TabIndicator
                                height="4px"
                                bg="#713EDD"
                                borderRadius="1px"
                                left="13px !important"
                                width="93px !important"
                                bottom="-10px"
                            />}
                            <Flex gap={{ xl: "45px" }} alignItems={"center"}>
                                <Tab
                                    flex={{ base: "1", xl: "none" }}
                                    onClick={() => handleTabClick(1)}
                                    color={activeTab === 1 ? '#713EDD' : '#868B94'}>
                                    <Flex alignItems="center" gap={{ base: "5px", xl: "12px" }}>
                                        <FontAwesomeIcon icon={faList} />
                                        <Text fontSize={{ base: "fs.14", lg: "fs.16" }} fontWeight="fw.400">Boards</Text>
                                    </Flex>
                                </Tab>
                                <>
                                    <InfiniteSelect width={"200px"} useKey={"project"} onChange={handleSelectProject} value={SelectProject} queryKey={GET_PROJECTS} queryFn={getMyProjectsAPI} onSuccess={(data, setState) => {
                                        setState(projectList?.data?.map?.(item => ({ value: item.key, label: item.name })));
                                    }} selectProps={{
                                        isMulti: false,
                                        placeholder: "Select your project",
                                        className: "menu-project",
                                        menuPlacement: 'top',
                                    }} />
                                </>

                                {/* }
                                /> */}
                                {/* <Tab
                                    onClick={() => handleTabClick(0)}
                                    color={activeTab === 0 ? '#713EDD' : '#868B94'}
                                    flex={{ base: "1", xl: "none" }}
                                >
                                    <Flex alignItems="center" justifyContent="center" gap={{ base: "5px", xl: "12px" }}>
                                        <FontAwesomeIcon icon={faList} />
                                        <Text fontSize={{ base: "fs.14", lg: "fs.16" }} whiteSpace="nowrap" fontWeight="fw.400">List Tasks</Text>
                                    </Flex>
                                </Tab> */}
                                {/* <Tab
                                    onClick={() => handleTabClick(2)}
                                    color={activeTab === 2 ? '#713EDD' : '#868B94'}
                                    flex={{ base: "1", xl: "none" }}
                                >
                                    <Flex alignItems="center" justifyContent="center" gap={{ base: "5px", xl: "12px" }}>
                                        <FontAwesomeIcon icon={faCalendar} />
                                        <Text fontSize={{ base: "fs.14", lg: "fs.16" }} fontWeight="fw.400"> Calendar</Text>
                                    </Flex>
                                </Tab> */}
                                {/* <Tab
                                    onClick={() => handleTabClick(3)}
                                    color={activeTab === 3 ? '#713EDD' : '#868B94'}
                                    flex={{ base: "1", xl: "none" }}
                                >
                                    <Flex alignItems="center" justifyContent="center" gap={{ base: "5px", xl: "12px" }}>
                                        <FontAwesomeIcon icon={faChartGantt} />
                                        <Text fontSize={{ base: "fs.14", lg: "fs.16" }} fontWeight="fw.400"> Gantt</Text>
                                    </Flex>
                                </Tab>
                                <Tab
                                    onClick={() => handleTabClick(4)}
                                    color={activeTab === 4 ? '#713EDD' : '#868B94'}
                                    flex={{ base: "1", xl: "none" }}
                                >
                                    <Flex alignItems="center" justifyContent="center" gap={{ base: "5px", xl: "12px" }}>
                                        <FontAwesomeIcon icon={faTimeline} />
                                        <Text fontSize={{ base: "fs.14", lg: "fs.16" }} fontWeight="fw.400"> Timeline</Text>
                                    </Flex>
                                </Tab>
                                <Tab
                                    onClick={() => handleTabClick(5)}
                                    color={activeTab === 5 ? '#713EDD' : '#868B94'}
                                    flex={{ base: "1", xl: "none" }}
                                >
                                    <Flex alignItems="center" justifyContent="center" gap={{ base: "5px", xl: "12px" }}>
                                        <FontAwesomeIcon icon={faChartLine} />
                                        <Text fontSize={{ base: "fs.14", lg: "fs.16" }} fontWeight="fw.400"> Activity</Text>
                                    </Flex>
                                </Tab> */}
                            </Flex>

                        </TabList>
                        {/* <Stack spacing={4}>
                            <InputGroup bg="rgba(235, 237, 245, 0.30)" minWidth={{ md: "330px", '2xl': "100%" }}>
                                <InputLeftElement pointerEvents='none'>
                                    <FontAwesomeIcon icon={faMagnifyingGlass} color="#A0A6B1" />
                                </InputLeftElement>
                                <Input type='tel' placeholder='Search' />
                            </InputGroup>
                        </Stack> */}
                    </Flex>


                    <TabPanels height={{ md: "calc(100vh - 270px)", lg: "calc(100dvh - 310px)", '2xl': "calc(100dvh - 250px)" }} overflowY="scroll" className="scrollBar" >
                        {/* <TabPanel >
                            <TaskList TaskDetails={Task1TebDetails} teb1="Task 1" teb2="12" />
                            <TaskList TaskDetails={Task2TebDetails} teb1="Task 2" teb2="2 " />
                            <TaskList TaskDetails={Task3TebDetails} teb1="Task 3" teb2="10 " />
                        </TabPanel> */}
                        <TabPanel>
                            <Board isLoading={isLoading} isFetching={isFetching} setLanes={setLanes} lanes={lanes} />
                        </TabPanel>
                        {/* <TabPanel><DemoApp /></TabPanel>
                        <TabPanel ><GanttBoardView /></TabPanel>
                        <TabPanel><TimeLine /></TabPanel>
                        <TabPanel>3</TabPanel> */}
                    </TabPanels>
                </Tabs>

            </Box >
        </Box >
    );
}