import { Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Table, TableContainer, Tag, Tbody, Td, Text, Tr } from '@chakra-ui/react';

const ViewDocumentsModal = (props) => {
  const { onClose, isOpen, data } = props;

  const handleButtonClick = (imageUrl) => {
    window.open(imageUrl, '_blank');
  };


  return (
    <Modal closeOnOverlayClick={false} onClose={onClose} isOpen={isOpen} isCentered >
      <ModalOverlay />
      <ModalContent maxWidth="620px" borderRadius="10px">
        <ModalCloseButton border="1px solid #868B944D" />
        <ModalBody pt="10px" pb="26px" width="600px">
          <Text fontSize="fs.18" fontWeight="fw.600" mt="20px" mb="10px">Details</Text>
          <TableContainer>
            <Table variant='simple' size='sm' >
              <Tbody>
                <Tr>
                  <Td pl="0" borderBottom="0" fontSize="fs.16" fontWeight="fw.400" color="#202B3C">Full Name:</Td>
                  <Td pl="0" borderBottom="0" fontSize="fs.16" fontWeight="fw.400" color="#868B94">{data?.name}</Td>
                </Tr>
                <Tr>
                  <Td pl="0" borderBottom="0" fontSize="fs.16" fontWeight="fw.400" color="#202B3C">Email:</Td>
                  <Td pl="0" borderBottom="0" fontSize="fs.16" fontWeight="fw.400" color="#868B94">{data?.email}</Td>
                </Tr>
                <Tr>
                  <Td pl="0" borderBottom="0" fontSize="fs.16" fontWeight="fw.400" color="#202B3C">Phone Number:</Td>
                  <Td pl="0" borderBottom="0" fontSize="fs.16" fontWeight="fw.400" color="#868B94">{data?.phoneNumber ? data?.phoneNumber : "--"}</Td>
                </Tr>
                <Tr>
                  <Td pl="0" borderBottom="0" fontSize="fs.16" fontWeight="fw.400" color="#202B3C">Role:</Td>
                  <Td pl="0" borderBottom="0" fontSize="fs.16" fontWeight="fw.400" color="#868B94">{data?.role}</Td>
                </Tr>
                <Tr>
                  <Td pl="0" borderBottom="0" fontSize="fs.16" fontWeight="fw.400" color="#202B3C">Country:</Td>
                  <Td pl="0" borderBottom="0" fontSize="fs.16" fontWeight="fw.400" color="#868B94">{data?.address.country ? data?.address.country : "--"}</Td>
                </Tr>
                <Tr>
                  <Td pl="0" borderBottom="0" fontSize="fs.16" fontWeight="fw.400" color="#202B3C">State:</Td>
                  <Td pl="0" borderBottom="0" fontSize="fs.16" fontWeight="fw.400" color="#868B94">{data?.address.state ? data?.address.state : "--"}</Td>
                </Tr>
                <Tr>
                  <Td pl="0" borderBottom="0" fontSize="fs.16" fontWeight="fw.400" color="#202B3C">City:</Td>
                  <Td pl="0" borderBottom="0" fontSize="fs.16" fontWeight="fw.400" color="#868B94">{data?.address.city ? data?.address.city : "--"}</Td>
                </Tr>
                <Tr>
                  <Td pl="0" borderBottom="0" fontSize="fs.16" fontWeight="fw.400" color="#202B3C">Seeking degree:</Td>
                  <Td pl="0" borderBottom="0" fontSize="fs.16" fontWeight="fw.400" color="#868B94">{data?.seekingDegree ? data?.seekingDegree : "--"}</Td>
                </Tr>
                <Tr>
                  <Td pl="0" borderBottom="0" fontSize="fs.16" fontWeight="fw.400" color="#202B3C">School Name:</Td>
                  <Td pl="0" borderBottom="0" fontSize="fs.16" fontWeight="fw.400" color="#868B94"> {data?.school?.name ? data?.school?.name
                    : "--"}</Td>
                </Tr>
                <Tr>
                  <Td pl="0" borderBottom="0" fontSize="fs.16" fontWeight="fw.400" color="#202B3C">School Country:</Td>
                  <Td pl="0" borderBottom="0" fontSize="fs.16" fontWeight="fw.400" color="#868B94"> {data?.school?.country ? data?.school?.country
                    : "--"}</Td>
                </Tr>
                <Tr>
                  <Td pl="0" borderBottom="0" fontSize="fs.16" fontWeight="fw.400" color="#202B3C">School City:</Td>
                  <Td pl="0" borderBottom="0" fontSize="fs.16" fontWeight="fw.400" color="#868B94"> {data?.school?.city ? data?.school?.city
                    : "--"}</Td>
                </Tr>
                <Tr>
                  <Td pl="0" borderBottom="0" fontSize="fs.16" fontWeight="fw.400" color="#202B3C">years of experience</Td>
                  <Td pl="0" borderBottom="0" fontSize="fs.16" fontWeight="fw.400" color="#868B94"> {data?.currentProfession?.yearsOfExperience > 0 ? `${data?.currentProfession?.yearsOfExperience} year${data?.currentProfession?.yearsOfExperience > 1 ? "s" : ""}`
                    : "--"}</Td>
                </Tr>
                <Tr>
                  <Td pl="0" borderBottom="0" fontSize="fs.16" fontWeight="fw.400" color="#202B3C">Current Profession:</Td>
                  <Td pl="0" borderBottom="0" fontSize="fs.16" fontWeight="fw.400" color="#868B94">{data?.currentProfession?.profession ? data?.currentProfession?.profession : "--"}</Td>
                </Tr>
                <Tr>
                  <Td pl="0" borderBottom="0" fontSize="fs.16" fontWeight="fw.400" color="#202B3C">Interested Careers:</Td>
                  <Td pl="0" borderBottom="0" fontSize="fs.16" fontWeight="fw.400" color="#868B94">
                    <Flex flexWrap="wrap" gap="10px">
                      {data?.interestedCareers?.length > 0
                        ? data.interestedCareers.map((career, index) => (
                          <Tag size="sm" key={index} variant="outline" colorScheme="gray">
                            {career}
                          </Tag>
                        ))
                        : "--"}
                    </Flex>

                  </Td>
                </Tr>
                <Tr>
                  <Td pl="0" borderBottom="0" fontSize="fs.16" fontWeight="fw.400" color="#202B3C">List your skills:</Td>
                  <Td pl="0" borderBottom="0" fontSize="fs.16" fontWeight="fw.400" color="#868B94">
                    <Flex flexWrap="wrap" gap="10px">
                      {data?.currentProfession?.skills?.length > 0
                        ? data?.currentProfession?.skills?.map(skill => (
                          <Tag size={'sm'} key={skill._id} variant='outline' colorScheme='gray'>
                            {skill?.name}
                          </Tag>
                        ))
                        : "--"}
                    </Flex>

                  </Td>
                </Tr>
                <Tr>
                  <Text fontSize="fs.18" fontWeight="fw.600" mt="30px" mb="10px">Documents</Text>
                  <Flex gap="10px" justifyContent="flex-start" display="flex">

                    {data.documents?.idFrontDoc &&
                      <Button size={"sm"} fontSize="fs.14" border="1px solid #713EDD" background="white" color="#713EDD" fontWeight="fw.500" _hover={{ background: "linear-gradient(134.59deg, #A17DEE -1.98% ,#713EDD 111.17%)", color: "white" }} onClick={() => handleButtonClick(data.documents.idFrontDoc)}>View Front</Button>
                    }
                    {data.documents?.idBackDoc &&
                      <Button size={"sm"} fontSize="fs.14" border="1px solid #713EDD" background="white" color="#713EDD" fontWeight="fw.500" _hover={{ background: "linear-gradient(134.59deg, #A17DEE -1.98% ,#713EDD 111.17%)", color: "white" }} onClick={() => handleButtonClick(data.documents.idBackDoc)}>View Back</Button>
                    }

                    {data.documents?.docs?.length > 0 && data.documents?.docs.map((img, i) => (
                      <Button key={img + i} size={"sm"} fontSize="fs.14" border="1px solid #713EDD" background="white" color="#713EDD" fontWeight="fw.500" _hover={{ background: "linear-gradient(134.59deg, #A17DEE -1.98% ,#713EDD 111.17%)", color: "white" }} onClick={() => handleButtonClick(img)}
                      >Document&nbsp;{i + 1}</Button>
                    ))}
                  </Flex>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </ModalBody>
      </ModalContent>
    </Modal >
  );
};

export default ViewDocumentsModal;