import { Box, Button, Flex, HStack, Input, InputGroup, InputLeftAddon, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from "@chakra-ui/react";
import { IconSearch } from "@tabler/icons-react";
import { debounce } from "lodash";
import { useCallback, useRef, useState } from "react";
import Select from 'react-select';
import ManagerListView from "../Managers";
import UsersPage from "../UsersPage";
import AddNewManager from "./AddNewManager";


export default function MangerTabList() {
  const modalRef = useRef(null);
  const [activeTab, setActiveTab] = useState(0);
  const [filters, setFilters] = useState({
    search: '',
    status: null
  });

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const onOpen = () => modalRef?.current?.openDialog();

  const handleChange = (newValue) => {
    setFilters(oldFilters => ({ ...oldFilters, status: newValue }));
  };

  const handleInputChange = useCallback(debounce(({ target }) => {
    setFilters(oldFilters => ({ ...oldFilters, search: target.value }));
  }, 500), []);

  return (
    <>
      <Box padding={{ base: "15px", lg: "25px", xl: "35px" }} width="100%"  >
        <Box background="white" borderRadius="br.10" padding="25px" height={{ md: "calc(100vh - 110px)", lg: "calc(100vh - 150px)" }} >
          <Tabs position="relative" variant="unstyled" height="-webkit-fill-available" >
            <Flex direction={{ base: 'column', lg: 'row' }} justifyContent="space-between" borderBottom="1px solid #E3E4E8" pb="10px" flexWrap={{ md: "wrap-reverse", '2xl': "nowrap" }} gap="20px">
              <TabList width={{ base: "100%", xl: "auto" }} display="contents">
                <Flex gap={{ xl: "15px" }} width={{ base: "100%", xl: "auto" }} >
                  <Tab
                    onClick={() => handleTabClick(0)}
                    borderRadius={'br.10'}
                    color={activeTab === 0 ? '#FFFFFF' : '#868B94'}
                    bg={activeTab === 0 ? '#713EDD' : 'transparent'}
                    flex={{ base: "1", xl: "none" }}
                  >
                    <Flex alignItems="center" justifyContent="center" gap={{ base: "5px", xl: "12px" }}>
                      <Text fontSize={{ base: "fs.14", lg: "fs.16" }} whiteSpace="nowrap" fontWeight="fw.400">Managers</Text>
                    </Flex>
                  </Tab>
                  <Tab
                    flex={{ base: "1", xl: "none" }}
                    onClick={() => handleTabClick(1)}
                    borderRadius={'br.10'}
                    color={activeTab === 1 ? '#FFFFFF' : '#868B94'}
                    bg={activeTab === 1 ? '#713EDD' : 'transparent'}
                  >
                    <Flex alignItems="center" justifyContent="center" gap={{ base: "5px", xl: "12px" }}>
                      <Text fontSize={{ base: "fs.14", lg: "fs.16" }} fontWeight="fw.400">Leads/Member</Text>
                    </Flex>
                  </Tab>
                </Flex>
              </TabList>
              {
                activeTab === 0 &&
                <Button onClick={onOpen} px={{ md: "15px", lg: "30px" }} py="8px" border={'1px solid #713EDD'} fontSize="fs.16" fontWeight="fw.500" background="linear-gradient(134.59deg, #713EDD -1.98%, #A17DEE 111.17%)" _hover={{ background: "linear-gradient(134.59deg, #A17DEE -1.98% ,#713EDD 111.17%)" }} color="#FFFFFF">+ Add Manager</Button>
              } {
                activeTab === 1 &&
                <HStack>
                  <Select
                    isClearable
                    styles={{
                      container: (props) => ({
                        ...props,
                        height: '100%',
                        minWidth: '200px'
                      }),
                      menu: (props) => ({
                        ...props,
                        zIndex: 2
                      })
                    }}
                    options={[
                      { value: 'ONBOARDING', label: 'Onboarding' },
                      { value: 'ACCEPTED', label: 'Accepted' },
                      { value: 'REJECTED', label: 'Rejected' },
                      { value: 'DEACTIVATED', label: 'Deactivated' },
                    ]}
                    onChange={handleChange}
                  />
                  <InputGroup>
                    <InputLeftAddon color={'gray'}><IconSearch /></InputLeftAddon>
                    <Input
                      type="search"
                      placeholder="Search name or email"
                      minW={"250px"}
                      onChange={handleInputChange}
                    />
                  </InputGroup>
                </HStack>
              }
            </Flex>


            <TabPanels height="-webkit-fill-available" overflowY="scroll" className="scrollBar" >
              <TabPanel>
                <ManagerListView />
              </TabPanel>
              <TabPanel>
                <UsersPage filters={filters} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Box>

      <AddNewManager ref={modalRef} />
    </>
  );
};