import { Box, Button, Image, Link, Text } from '@chakra-ui/react';
import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import logo from '../../../assets/logo/logo.svg';
import { AppContext } from '../../../contexts/AppContext';

export default function ScreeningStatus() {
  const params = useParams();
  const [stateApp, setStateApp] = useContext(AppContext);
  const navigate = useNavigate();
  const { user } = stateApp;

  const defaultState = {
    user: null,
    auth: null,
  };

  useEffect(() => {
    if (user?.status === "ACCEPTED" && params !== user?.status) {
      navigate('/');
    }
  }, [user]);

  const handleLogOut = () => {
    localStorage.removeItem('@apiToken');
    setStateApp(defaultState);
  };

  const rejected = (
    <Box h='100dvh' width='100dvw' p={'2rem'}>
      <Image width="auto" height="auto" src={logo} />
      <Box height={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'} flexDir='column' gap={'30px'}>
        <Text fontWeight={'700'} fontSize={'34px'}>Sorry, your application has been rejected.</Text>
        <Text>Questions about your account status? Contact our developer relations team <Link href='mailto:talent@nangita.com' color={'purple.500'}>(talent@nangita.com)</Link></Text>
        <Button>Log out and return to main site</Button>
      </Box>
    </Box>
  );

  const progress = (
    <Box h='100dvh' width='100dvw' p={'2rem'}>
      <Image width="auto" height="auto" src={logo} />
      <Box height={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'} flexDir='column' gap={'30px'}>
        <Text fontWeight={'700'} fontSize={'34px'}>You application is in review.</Text>
        <Text>Questions about your account status? Contact our developer relations team <Link href='mailto:talent@nangita.com' color={'purple.500'}>(talent@nangita.com)</Link></Text>
        <Button onClick={handleLogOut}>Log out and return to main site</Button>
      </Box>
    </Box>
  );

  // TODO: Write a logic to handle other status values and return 404 if status values is out of expected boundary
  return (
    {
      'rejected': rejected,
      'progress': progress
    }[params.status]
  );
}