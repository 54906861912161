import { Avatar, Box, Flex, Text } from "@chakra-ui/react";
import { useCallback, useContext, useEffect, useState } from "react";
import { ChatContext } from "../../../../../../contexts/ChatContexts";
import { AppContext } from "../../../../../../contexts/AppContext";
import { useSocket } from "../../../../../Socket";
import UserStatus from "../../../../../Message/MessageSiderBar/UserStatus";

export default function ActiveMembers() {
  const [appState] = useContext(AppContext);
  const [chatState] = useContext(ChatContext);
  const { onlineUsers } = useSocket();
  const { directs, channels } = chatState || {};
  const [userDetails, setUserDetails] = useState([]);

  const getDirectDetails = useCallback(
    (direct) => {
      const withoutMe = direct?.members?.filter(
        (member) =>
          member?.user?._id !== appState?.user?._id &&
          onlineUsers[member?.user?._id] === true
      );
      return withoutMe.map((member) => ({
        id: member?.user?._id,
        name: member?.user?.name,
        profilePicture: member?.user?.profilePicture,
      }));
    },
    [appState, onlineUsers]
  );

  useEffect(() => {
    const details = [];
    const userIds = new Set();

    const addUniqueDetails = (user) => {
      if (!userIds.has(user.id)) {
        userIds.add(user.id);
        details.push(user);
      }
    };

    channels.forEach(channel => {
      const channelDetails = getDirectDetails(channel);
      channelDetails.forEach((detail) => {
        addUniqueDetails(detail);
      });
    });

    directs.forEach(direct => {
      const directDetails = getDirectDetails(direct);
      directDetails.forEach((detail) => {
        addUniqueDetails(detail);
      });
    });

    setUserDetails(details);
  }, [directs, channels, getDirectDetails]);


  return (
    <Box display={{ md: "none", lg: "unset" }}>
      <Text
        pt="35px"
        pb="10px"
        fontSize="fs.18"
        fontWeight="fw.500"
        textAlign="start"
      >
        Active Members
      </Text>
      <Box
        padding="25px"
        borderRadius="br.10"
        background="white"
        height="270px"
        overflowY="auto"
      >
        {userDetails.length === 0 ? (
          <p className="flex text-lg justify-center items-center h-full">
            No active member
          </p>
        ) : (
          userDetails.map((user, index) => (
            <>
              <Flex
                key={user.id}
                mt={index === 0 ? "0" : "15px"}
                alignItems="center"
                gap="15px"
              >
                <Avatar
                  size="sm"
                  sx={{
                    img: { objectFit: "contain" },
                    fontSize: "sm",
                  }}
                  name={user.name}
                  src={user.profilePicture}
                  border={"1px solid gray.300"}
                />
                <Box>
                  <Text fontSize="fs.16" fontWeight="fw.500">
                    {user.name}
                  </Text>
                  <div className="flex flex-row gap-2 items-center">
                    <UserStatus count={1} length={1} />
                  </div>
                </Box>
              </Flex>
              <div className="flex w-[100%] bg-gray-50 h-[2px] mt-1" />
            </>
          ))
        )}
      </Box>
    </Box>
  );
}
