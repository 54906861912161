import { Text } from '@chakra-ui/react';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ExperienceArrows = ({ yearsOfExperience }) => {
  const getFilledArrows = (years) => {
    if (years === "15+") return 5;
    if (Number(years) >= 10) return 4;
    if (Number(years) >= 5) return 3;
    if (Number(years) >= 1) return 1;
    return 0;
  };

  const filledArrows = getFilledArrows(yearsOfExperience);

  return (
    <Text fontSize="fs.14" fontWeight="fw.400" color="#868B94">
      {[...Array(5)].map((_, index) => (
        <FontAwesomeIcon
          key={index}
          icon={faPlay}
          fontSize={45}
          style={{ color: index < filledArrows ? "#713edd" : "#868B94" }}
        />
      ))}
    </Text>
  );
};

export default ExperienceArrows;