import { Box, Table, TableContainer, Tbody, Th, Thead, Tr } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';

import { GET_ALL_MANAGERS } from '../../apis/index.api';
import { getAllUserDataAPI } from '../../apis/users.api';
import ManagerListItem from './ManagerListItem';

const PAGE_SIZE = process.env.REACT_APP_PAGE_SIZE;

export default function ManagerListView() {
  const [page, setPage] = useState(1);
  const [managerList, setManagerList] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const { data, isLoading } = useQuery(
    [GET_ALL_MANAGERS, { skip: PAGE_SIZE * (page - 1), limit: PAGE_SIZE, role: ['Admin'] }],
    getAllUserDataAPI
  );

  useEffect(() => {
    if (data) {
      setManagerList(data.data.data);
      setPageCount(data?.data?.total / PAGE_SIZE);
    }
  }, [data]);

  const handlePageChange = useCallback((data) => {
    setPage(data.selected + 1);
  }, []);

  return (
    <Box
      pt={{
        base: '15px',
        md: '15px',
        lg: '25px',
        xl: '25px',
      }}
      bg='rgba(255, 255, 255, 1)'
      height="-webkit-fill-available"
      borderRadius='10px'>
      <TableContainer
        border='1px solid rgba(234, 236, 240, 1)'
        borderRadius='0px 0px 10px 10px'
        height="calc(100vh - 375px)"
        overflowY='scroll'
        overflowX='scroll'
        css={` &::-webkit-scrollbar { width: 0; height: 0;} &::-webkit-scrollbar-thumb { background-color: rgba(0, 0, 0, 0);}`}>
        <Box position='relative'>
          <Table variant='simple' style={{ borderSpacing: '0 10px' }}>
            <Thead
              bgGradient='linear-gradient(0deg, #EAECF0, #EAECF0),linear-gradient(0deg, #F9FAFB, #F9FAFB)'
              textAlign='center'
              position='sticky'
              top='0px'
              zIndex='1'
            >
              <Tr>
                <Th
                  fontSize={{
                    base: 'fs.14',
                    md: 'fs.14',
                    lg: 'fs.16',
                    xl: 'fs.18',
                  }}
                  fontWeight='fw.400'
                  color=' rgba(134, 139, 148, 1)'
                  pl='20px'
                  py='13px'
                  textTransform="capitalize">
                  Profile</Th>
                <Th
                  fontSize={{
                    base: 'fs.14',
                    md: 'fs.14',
                    lg: 'fs.16',
                    xl: 'fs.18',
                  }}
                  fontWeight='fw.400'
                  color=' rgba(134, 139, 148, 1)'
                  px='0px'
                  py='13px'
                  textTransform="capitalize">
                  Name</Th>
                <Th
                  fontSize={{
                    base: 'fs.14',
                    md: 'fs.14',
                    lg: 'fs.16',
                    xl: 'fs.18',
                  }}
                  fontWeight='fw.400'
                  color=' rgba(134, 139, 148, 1)'
                  px='0px'
                  py='13px'
                  textTransform="capitalize">
                  Email</Th>
                <Th
                  fontSize={{
                    base: 'fs.14',
                    md: 'fs.14',
                    lg: 'fs.16',
                    xl: 'fs.18',
                  }}
                  fontWeight='fw.400'
                  color=' rgba(134, 139, 148, 1)'
                  px='0px'
                  py='13px'
                  textTransform="capitalize">
                  Status</Th>
                <Th
                  fontSize={{
                    base: 'fs.14',
                    md: 'fs.14',
                    lg: 'fs.16',
                    xl: 'fs.18',
                  }}
                  fontWeight='fw.400'
                  color=' rgba(134, 139, 148, 1)'
                  pl='0px'
                  pr='20px'
                  py='13px'
                  textTransform="capitalize">
                  Action</Th>
              </Tr>
            </Thead>

            <Tbody
              bg='transparent'>
              {isLoading && <Tr><Th colSpan={10} textAlign={'center'}> LOADING...</Th></Tr>}
              {managerList?.map((manager, index) => (
                <ManagerListItem key={manager._id} manager={manager} isLoading={isLoading} />
              ))}
              {!isLoading && !managerList.length && <Tr><Th colSpan={10} textAlign={'center'}>No Managers found</Th></Tr>}
            </Tbody>
          </Table>
        </Box >
      </TableContainer >
      <Box position="relative" mt="20px">
        <ReactPaginate
          pageCount={Math.ceil(pageCount)}
          containerClassName={"paginationButtons"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
          previousLabel={<span>&larr; Previous</span>}
          nextLabel={<span>Next &rarr;</span>}
          onPageChange={handlePageChange}
        />
      </Box>
    </Box >
  );
}