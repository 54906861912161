import {
  Button, Flex, Modal, ModalBody, ModalCloseButton,
  ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs, Text
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { concat } from "lodash";
import { useCallback, useEffect, useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { updateChannels } from "../../../apis/channel.api";
import { GET_CHANNEL } from "../../../apis/constants.api";
import { regionsOption } from "../Constants";
import { basicSchema, userSchema } from "../form.schema";
import BasicFields from "./BasicFields";
import UserFields from "./UserFields";
import { useSocket } from '../../Socket';
import { ChatContext } from "../../../contexts/ChatContexts";

export default function ChannelsModal(props) {
  const { onClose, isOpen, channel } = props;
  const [activeTab, setActiveTab] = useState(0);
  const queryClient = useQueryClient();
  const [channelMembers, setChannelMembers] = useState(channel?.members || []);
  const { socket } = useSocket();
  const [chatState, ___, { channelDialogToogle }] = useContext(ChatContext);
  const { channelTabDialogOpen } = chatState || {};


  if (!channelTabDialogOpen) {
    onClose();
    channelDialogToogle();
  }
  
  const { control, handleSubmit, reset, formState: { errors }, getValues, watch, trigger } = useForm({
    defaultValues: {
      name: channel?.name,
      description: channel?.description,
      iconURL: channel?.iconURL,
      region: regionsOption.find((e) => e?.value === channel?.region),
      members: [],
      open: channel?.open
    },
    resolver: yupResolver((activeTab === 0) ? basicSchema : userSchema)
  });

  const isOpenChannel = watch('open');

  const onSubmit = async (values) => {

    const payload = {
      ...values,
      region: values?.region,
      members: concat(
        values.members.flatMap(member => member.value),
        channelMembers.map(member => member?.user?._id)
      ),
    };

    if (channel) {
      const channelId = channel?._id;
      updateChannel({ channelId, ...payload });
    } else {
      console.log("CHANNEL_CREATE calling....");
      socket.emit('CHANNEL_CREATE', payload, (response) => {
        if (response.success) {
          queryClient.invalidateQueries({ queryKey: [GET_CHANNEL] });
          reset();
          onClose();
          handleTabClick(0);
        } else {
          console.error('Channel creation failed:', response.error);
        }
      }
      );
    }
  };

  const { mutate: updateChannel, isLoading: isUpdating } = useMutation(updateChannels, {
    onSuccess: data => {
      queryClient.invalidateQueries({ queryKey: [GET_CHANNEL] });
      reset();
      onClose();
      handleTabClick(0);
    },
  });

  const handleClose = useCallback(() => {
    reset();
    onClose?.();
  }, [reset, onClose]);

  const handleTabClick = async (index) => {
    if (index > activeTab) {
      const isValid = await trigger();
      if (isValid) {
        setActiveTab(index);
      }
    } else {
      setActiveTab(index);
    }
  };

  useEffect(() => {
    if (channel && isOpen) {
      reset({
        name: channel?.name,
        description: channel?.description,
        iconURL: channel?.iconURL,
        region: regionsOption.find((e) => e?.value === channel?.region),
        members: [],
        open: channel?.open
      });
      setChannelMembers(channel?.members);
    }
  }, [channel, reset, isOpen]);

  const handleRemove = (userId) => {
    const newValues = channelMembers.filter(user => user?.user?._id !== userId);

    setChannelMembers(newValues);
  };

  return (
    <Modal onClose={handleClose} isOpen={isOpen} isCentered closeOnOverlayClick={false}>
      <form>
        <ModalOverlay />
        <ModalContent minW='600px'>
          <ModalHeader>{channel ? "Update Channel" : "Create New Channel"}</ModalHeader>
          <ModalCloseButton border="1px solid #868B944D" />
          <ModalBody>
            <Tabs position="relative" variant="unstyled" index={activeTab} onChange={handleTabClick}>
              <Flex justifyContent={"flex-start"} borderBottom="1px solid #E3E4E8" pb="10px" flexWrap={{ md: "wrap-reverse", '2xl': "nowrap" }} gap="20px">
                <TabList width={{ base: "100%", xl: "auto" }}>
                  <Flex width={{ base: "100%", xl: "auto" }}>
                    <Tab
                      flex={{ base: "1", xl: "none" }}
                      color={activeTab === 0 ? '#713EDD' : '#868B94'}>
                      <Flex alignItems="center" justifyContent="center" gap={{ base: "5px", xl: "12px" }}>
                        <Text fontSize={{ base: "fs.14", lg: "fs.16" }} fontWeight="fw.400">Basic Info</Text>
                      </Flex>
                    </Tab>
                    <Tab
                      flex={{ base: "1", xl: "none" }}
                      color={activeTab === 1 ? '#713EDD' : '#868B94'}>
                      <Flex alignItems="center" justifyContent="center" gap={{ base: "5px", xl: "12px" }}>
                        <Text fontSize={{ base: "fs.14", lg: "fs.16" }} fontWeight="fw.400">User Info</Text>
                      </Flex>
                    </Tab>
                  </Flex>
                </TabList>
              </Flex>

              <TabIndicator
                mt="-4.5px"
                height="4px"
                bg="#713EDD"
                width="auto"
                borderRadius="1px"
              />

              <TabPanels height={{ md: "calc(100vh - 270px)", lg: "calc(100dvh - 310px)", '2xl': "calc(100dvh - 250px)" }} overflowY="scroll" className="scrollBar">
                <TabPanel><BasicFields channel={channel} control={control} errors={errors} /></TabPanel>
                <TabPanel><UserFields channel={channel} control={control} isOpenChannel={isOpenChannel} errors={errors} channelMembers={channelMembers} handleRemove={handleRemove} /></TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>

          <ModalFooter background="#F5F4F6" borderRadius="0px 0px 10px 10px">
            <Flex gap="10px">
              <Button background="#EAE9EC" onClick={() => { onClose(); handleTabClick(0); }}>Cancel</Button>

              {activeTab === 1 ? (
                <Button
                  disabled={isUpdating}
                  type="button"
                  px={{ md: "15px", lg: "30px" }}
                  py="8px"
                  background="linear-gradient(134.59deg, #713EDD -1.98%, #A17DEE 111.17%)"
                  _hover={{ background: "linear-gradient(134.59deg, #A17DEE -1.98%, #713EDD 111.17%)" }}
                  color="white"
                  display="flex"
                  columnGap="5px"
                  onClick={handleSubmit(onSubmit)}
                >
                  {channel ? "Update" : "Save"}
                  {isUpdating && <Spinner color="white" />}
                </Button>
              ) : (
                <Button
                  type="button"
                  onClick={e => {
                    e.preventDefault();
                    handleTabClick(1);
                  }}
                  px={{ md: "15px", lg: "30px" }}
                  py="8px"
                  background="linear-gradient(134.59deg, #713EDD -1.98%, #A17DEE 111.17%)"
                  _hover={{ background: "linear-gradient(134.59deg, #A17DEE -1.98%, #713EDD 111.17%)" }}
                  color="white"
                >
                  Next
                </Button>
              )}
            </Flex>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
}
