import { Box, Flex, SkeletonCircle, SkeletonText } from "@chakra-ui/react";
import { nanoid } from "nanoid";
import { useState } from "react";

export default function ChannelListItemSkeleton({ noOfItems = 1 }) {
  const [items] = useState(new Array(noOfItems).fill('').map(i => nanoid()));

  return (
    <>
      {
        items?.map(item =>
          <Box key={item} padding='6' border='1px solid' borderColor="#E3E4E8" borderRadius="br.10">
            <SkeletonCircle size='14' />
            <SkeletonText mt='6' noOfLines={1} skeletonHeight='4' />
            <SkeletonText mt='4' noOfLines={4} skeletonHeight='2' />
            <Flex justify="space-between" align="center" mt={4}>
              <SkeletonText noOfLines={1} skeletonHeight='2' w={14} />
              <SkeletonText noOfLines={1} skeletonHeight='2' w={14} />
            </Flex>
            <Flex justify="space-between" align="center" mt={2} mb={6}>
              <SkeletonText noOfLines={1} skeletonHeight='4' w={14} />
              <SkeletonText noOfLines={1} skeletonHeight='4' w={14} />
            </Flex>
            <Flex mt={10}>
              <SkeletonCircle size='8' />
            </Flex>
          </Box>
        )
      }
    </>
  );
}
