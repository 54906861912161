import { css, cx } from "@emotion/css";
import { forwardRef } from "react";

const Toolbar = forwardRef(
  (
    { className, ...props },
    ref
  ) => (
    <Menu
      {...props}
      ref={ref}
      className={cx(
        className,
        css`
          padding: 5px;
          margin: 0px;
          background-color: var(--chakra-colors-gray-300);
          border-radius: 8px 8px 0 0;
        `
      )}
    />
  )
);

export const Menu = forwardRef(
  (
    { className, ...props },
    ref
  ) => (
    <div
      {...props}
      data-test-id="menu"
      ref={ref}
      className={cx(
        className,
        css`
          & > * {
            display: inline-block;
          }

          & > * + * {
            margin-left: 5px;
          }
        `
      )}
    />
  )
);


export default Toolbar;