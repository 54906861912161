import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Flex,
  Input,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import { BsUpload } from "react-icons/bs";
import Style from "../../style.module.css";
import { ImFileText2 } from "react-icons/im";
import { MdClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  updateCareerDetailsAPI,
  updateInterestedCareersAPI,
  updateProfessionAPI,
  updateSchoolInfoAPI,
  updateUserDocAPI,
  updateUserIdDocAPI,
} from "../../../apis/users.api";
import { GET_USER_DATA } from "../../../apis/constants.api";
import { protectedRoute } from "../../../config/api.config";
import { useDispatch, useSelector } from "react-redux";
import { setUploadDocs } from "../../../store/SignupStepsSlice";

const DocumentsPageData = ({ goToPrevious }) => {
  const hiddenFileInput = useRef(null);
  const [fileNames, setFileNames] = useState([]);
  const [fileProgress, setFileProgress] = useState({});
  const [fileURL, setFileURL] = useState([]);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const toast = useToast();
  const dispatch = useDispatch();
  const documentFile = useSelector((state) => state?.SignupSteps?.uploadDocs);
  const profession = useSelector((state) => state?.SignupSteps?.profession);
  const interestedCareers = useSelector((state) => state?.SignupSteps?.interestedCareers);
  const getDocuments = useSelector(
    (state) => state?.SignupSteps?.documentDetails
  );

  const careerDetails = useSelector(
    (state) => state?.SignupSteps?.careerDetails
  );
  const schoolDetails = useSelector(
    (state) => state?.SignupSteps?.schoolDetails
  );

  useEffect(() => {
    if (documentFile?.urls && documentFile?.names) {
      setFileURL(documentFile.urls);
      setFileNames(documentFile.names);
    }
  }, [documentFile]);

  const handleClick = () => {
    hiddenFileInput.current?.click();
  };

  const handleDrop = (event) => {
    const { files } = event.target;
    if (!files?.length) {
      return;
    }
    const newFileNames = Array.from(files).map((file) => file.name);
    setFileNames((prevFileNames) => [...prevFileNames, ...newFileNames]);

    Array.from(files).forEach(async (file) => {
      const payload = {
        fileName: file.name,
        contentType: file.type,
      };
      try {
        const response = await protectedRoute.post(
          "/files/getPreSignedURL",
          payload
        );

        if (response.status === 201) {
          const uploadImgResponse = await axios.put(response.data, file, {
            headers: {
              "Content-Type": file.type,
            },
          });

          if (uploadImgResponse.status === 200) {
            const url = response.data.slice(0, response.data.indexOf("?"));
            setFileURL((prevURLs) => {
              const updatedURLs = [...prevURLs, url];
              dispatch(
                setUploadDocs({
                  urls: updatedURLs,
                  names: [...fileNames, file.name],
                })
              );
              return updatedURLs;
            });

            // setFileURL(prevURLs => [...prevURLs, url]);
            // dispatch(setUploadDocs(prevURLs => [...prevURLs, url]))
          }
        }
      } catch (error) {
        console.log(error);
      }
      setFileProgress((prevProgress) => ({
        ...prevProgress,
      }));

      // Simulate progress update after 1 second
      setTimeout(() => {
        setFileProgress((prevProgress) => ({
          ...prevProgress,
          [file.name]: 100,
        }));

        // Hide the progress bar after 2 seconds
        setTimeout(() => {
          setFileProgress((prevProgress) => {
            const { [file.name]: _, ...rest } = prevProgress;
            return rest;
          });
        }, 2000);
      }, 1000);
    });
  };
  const handleRemoveFile = (filename) => {
    setFileNames((prevFileNames) =>
      prevFileNames.filter((name) => name !== filename)
    );
    // Additional logic to handle removing file progress if necessary
  };
  const { mutate: career, isLoading1 } = useMutation(updateCareerDetailsAPI, {
    onSuccess: () => {
    },
    onError: (error) => {
      const errorMessage = error?.response?.data?.message;
      toast({
        title: "Error",
        description: errorMessage,
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    },
  });
  const { mutate: yourProfession, isLoading3 } = useMutation(
    updateProfessionAPI,
    {
      onSuccess: () => {},
    }
  );

  const { mutate:uploadDoc, isLoading8 } = useMutation(updateUserIdDocAPI, {
    onSuccess: () => {
    },
    onError: (error) => {
      let errorMessage;

      if (error?.response?.data?.message) {
        errorMessage = error.response.data.message;
      }

      toast({
        title: "Error",
        description: errorMessage,
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    },
  });

  const { mutate: InterestedCareer, isLoading6 } = useMutation(updateInterestedCareersAPI, {
    onSuccess: () => {
    },
  });
  const { mutate: schoolAttedy, isLoading2 } = useMutation(
    updateSchoolInfoAPI,
    {
      onSuccess: () => {},
      onError: (error) => {
        let errorMessage;

        if (error?.response?.data?.message) {
          errorMessage = error.response.data.message.join(" , ");
        }

        toast({
          title: "Error",
          description: errorMessage,
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
      },
    }
  );

  const { mutate, isLoading } = useMutation(updateUserDocAPI, {
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [GET_USER_DATA] });
      navigate("/screening/progress");
    },
    onError: (error) => {
      let errorMessage;

      if (error?.response?.data?.message) {
        errorMessage = error.response.data.message;
      }

      toast({
        title: "Error",
        description: errorMessage,
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    },
  });

  //submit file all api call
  const handleOnclick = async () => {
    //carrier detail api call
    let carrierDetailpayload = {
      isAStudent: true,
    };
    carrierDetailpayload = {
      ...carrierDetailpayload,
      seekingDegree: careerDetails.seekingDegree,
    };
    await career(carrierDetailpayload);
    // school attendy

    const schoolpayload = {
      name: schoolDetails.name.trim(),
      country: schoolDetails.country,
      city: schoolDetails.city,
    };
    await schoolAttedy(schoolpayload);

    // your proffetion
    const professionPayload = {
      profession: profession.profession,
      skills: profession?.skills?.map((skill) => skill),
      yearsOfExperience: `${profession.yearsOfExperience}` || "",
    };
    await yourProfession(professionPayload);

    //InterestedCareer
	const careerPayload = {
		interestedCareers: interestedCareers.interestedCareers,
		reasonToJoin: interestedCareers.reasonToJoin,
	  };
	await InterestedCareer(careerPayload)
	//upload document front back
	const docPayload = {
		idFrontDoc: getDocuments?.idFrontDoc,
		idBackDoc: getDocuments?.idBackDoc,
	  };
	await uploadDoc(docPayload)
// document upload

    const payload = {
      docs: fileURL,
    };
    await mutate(payload);
  };

  return (
    <>
      <Box borderRadius={{ base: "20px", md: "20px" }} bgColor="white">
        <Box px={{ base: "0", xl: "20px" }}>
          <Text
            textAlign="center"
            fontSize={{
              base: "fs.25",
              md: "fs.30",
              lg: "fs.30",
              xl: "fs.38",
            }}
            fontWeight="fw.600"
          >
            Upload Documents
          </Text>
          <Text
            textAlign="center"
            fontSize={{
              base: "fs.12",
              md: "fs.14",
              ld: "fs.16",
              xl: "fs.18",
            }}
            fontWeight="fw.400"
            pt="10px"
            pb="20px"
            color="#868B94"
          ></Text>
          <hr />
        </Box>
        <Box pt="20px">
          <Text
            fontSize={{
              base: "fs.14",
              md: "fs.16",
              ld: "fs.18",
              xl: "fs.20",
            }}
            fontWeight="fw.500"
          >
            Upload documents
          </Text>
          <Box py="12px" px="10px">
            <Text
              fontSize={{
                base: "fs.10",
                md: "fs.12",
                ld: "fs.14",
                xl: "fs.16",
              }}
              fontWeight="fw.400"
              color="#868B94"
            >
              1.Copy of Diploma.
            </Text>
            <Text
              mt="10px"
              fontSize={{
                base: "fs.10",
                md: "fs.12",
                ld: "fs.14",
                xl: "fs.16",
              }}
              fontWeight="fw.400"
              color="#868B94"
            >
              2.Letter of interest
            </Text>
            <Text
              mt="10px"
              fontSize={{
                base: "fs.10",
                md: "fs.12",
                ld: "fs.14",
                xl: "fs.16",
              }}
              fontWeight="fw.400"
              color="#868B94"
            >
              3.Other documents
            </Text>
          </Box>
        </Box>
        <Box mt="10px" onClick={handleClick}>
          <Button
            flexDirection="column"
            _hover={{
              background: "#F6F2FD",
              color: "#713EDD",
              border: "1.5px dashed #713EDD",
            }}
            background="rgba(227, 227, 227, 0.1)"
            border="1.5px dashed #E3E3E3"
            h="194px"
            width="100%"
            borderRadius="10px"
          >
            <BsUpload size={20} />
            <Text
              pt="20px"
              fontSize={{ base: "fs.14", md: "fs.16" }}
              fontWeight="fw.400"
              color="#202B3C"
            >
              Dreg and Drop here{" "}
            </Text>
            <Text
              fontSize={{ base: "fs.14", md: "fs.16" }}
              fontWeight="fw.400"
              color="#202B3C"
            >
              Or{" "}
            </Text>
            <Text
              as="span"
              fontSize={{ base: "fs.14", md: "fs.16" }}
              fontWeight="fw.400"
              color="#713EDD"
            >
              Browse files
            </Text>
          </Button>
        </Box>
        <Input
          type="file"
          accept="image/*"
          ref={hiddenFileInput}
          onChange={(e) => handleDrop(e)}
          style={{ display: "none" }}
        />
        {fileNames.map((filename, index) => (
          <Flex
            gap="15px"
            mt="20px"
            px="14px"
            py="13px"
            background="rgba(227, 227, 227, 0.3)"
            width="100%"
            borderRadius="10px"
          >
            <Flex
              justifyContent="center"
              alignItems="center"
              background="#E3E3E3"
              px="14px"
              py="12px"
              borderRadius="4px"
              border="1px solid #E3E3E3"
            >
              <ImFileText2 />
            </Flex>
            <Box width="100%" display="grid" alignItems="center">
              {/* // <Box key={index} mt="20px"> */}
              <Flex key={index} justifyContent="space-between">
                <Text
                  fontSize={{ base: "fs.12", md: "fs.14" }}
                  fontWeight="fw.500"
                >
                  {filename}
                </Text>
                <MdClose onClick={() => handleRemoveFile(filename)} />
              </Flex>
              {fileProgress[filename] !== null && fileProgress[filename] && (
                <Slider
                  aria-label={`slider-ex-${index}`}
                  value={fileProgress[filename]}
                >
                  <SliderTrack
                    height="8px"
                    background="rgba(113, 62, 221, 0.15)"
                    borderRadius="4px"
                  >
                    <SliderFilledTrack bg="#713EDD" height="10px" />
                  </SliderTrack>
                  <SliderThumb display="none" />
                </Slider>
              )}
            </Box>
          </Flex>
        ))}
        <Flex
          mt={{ base: "30px", md: "50px", lg: "30px", xl: "30px" }}
          className="gap-3"
        >
          <Button
            fontSize={{
              base: "fs.12",
              md: "fs.14",
              lg: "fs.16",
              xl: "fs.18",
            }}
            fontWeight="fw.500"
            height={{ base: "40px", lg: "50px", "2xl": "55px" }}
            width="50%"
            borderRadius="10px"
            onClick={(e) => goToPrevious(e)}
            backgroundColor={"#F6F2FD"}
            border={`1.5px solid #713EDD`}
            isDisabled={isLoading}
          >
            Back
          </Button>
          <Button
            isDisabled={isLoading}
            onClick={handleOnclick}
            type="submit"
            color="white"
            background="linear-gradient(150.62deg, #713EDD 5.97%, #A17DEE 64.37%)"
            _hover={{ animation: "changeBg 0.5s ease infinite" }}
            width="50%"
            height={{ base: "40px", lg: "50px", "2xl": "55px" }}
            fontSize={{
              base: "fs.12",
              md: "fs.14",
              ld: "fs.16",
              xl: "fs.18",
            }}
            fontWeight="fw.500"
            borderRadius="10px"
            gap={5}
          >
            Complete
            {isLoading && <Spinner />}
          </Button>
        </Flex>
      </Box>
    </>
  );
};
export default DocumentsPageData;
