import { Box, Text } from "@chakra-ui/react";
import { BiLogOut } from "react-icons/bi";
import { AppContext } from "../../contexts/AppContext";
import { useContext } from "react";
import { useSocket } from "../Socket";

export default function SignOutText() {
  const [, setStateApp] = useContext(AppContext);
  const { socket } = useSocket();
  const defaultState = {
    user: null,
    auth: null,
  };
  const handleLogOut = () => {
    setStateApp(defaultState);
    socket?.disconnect();
    localStorage.removeItem('@apiToken');
    localStorage.removeItem("persist:channel");
    localStorage.removeItem("persist:token");
  };
  return (
    <Box
      display={{ base: 'flex', xl: 'none' }}
      _hover={{ color: 'blue' }}
      alignItems="center"
      justifyContent="end"
      mt={{ base: '20px', md: '20px', '2xl': '20px' }}
      onClick={() => handleLogOut()}
    >
      <BiLogOut size={21} />

      <Text ml={"4px"}>Sign out</Text>
    </Box>
  );
}