import * as yup from 'yup';

const PasswordExpression = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>/?]).{7,12}$/;

export const signinFormSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required."),
  password: yup
    .string()
    .required("Password is required"),
});