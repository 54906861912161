import * as yup from "yup";
export const schema = yup
  .object({
    title: yup.string().trim().required('Title is required'),
    description: yup.string().required('Description is required'),
    price: yup.number().min(0).required('Price is required'),
    stock: yup.number().min(0).required('Stock is required'),
    ratings: yup.number().min(0).max(5, "Ratings should not be more then 5").required('Ratings is required'),
    images: yup.array().of(yup.string().required()).min(1, 'Please upload min 1 image').max(5).required('Product images are required'),
  })
  .required();