import { useState, useEffect } from 'react';

const useTimer = seconds => {
  const [isTimeOut, setIsTimeOut] = useState(false);

  useEffect(() => {
    let intervalId;
    let remainingSeconds = seconds;

    const startTimer = () => {
      intervalId = setInterval(() => {
        remainingSeconds--;

        if (remainingSeconds <= 0) {
          clearInterval(intervalId);
          setIsTimeOut(true);
        }
      }, 1000);
    };

    startTimer();

    return () => {
      clearInterval(intervalId);
    };
  }, [seconds]);

  return isTimeOut;
};

export default useTimer;
