import { isValidPhoneNumber } from 'react-phone-number-input';
import * as yup from 'yup';

const PasswordExpression = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>/?]).{7,12}$/;

export const validatePhoneNumber = (value) => {
  if (!value) return true; // Let Yup's `required` handle empty values
  try {
    return isValidPhoneNumber(value);
  } catch (error) {
    return false;
  }
};

export const lengthCheck = /^.{7,12}$/;
export const oneUppercaseLetterCheck = /(?=.*[A-Z])/;
export const oneLowercaseLetterCheck = /(?=.*[a-z])/;
export const oneNumberLetterCheck = /(?=.*\d)/;
export const oneSpecialCharacterCheck = /(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>/?])/;

export const signupFormSchema = yup.object().shape({
  name: yup.string().required("Name is required."),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required."),
  phone: yup
    .string()
    .required('Phone number is required')
    .test('phone', 'Invalid phone number', validatePhoneNumber),
  state: yup.object().shape({
    label: yup.string().required(),
    value: yup.string().required(),
    fullObj: yup.object().json()
  }).required("State is required."),
  country: yup.object().shape({
    label: yup.string().required(),
    value: yup.string().required(),
    fullObj: yup.object().json()
  }).required("Country is required."),
  password: yup
    .string()
    .matches(
      PasswordExpression,
      "Password must be 7-12 characters and include at least one uppercase letter, one lowercase letter, one number, and one special character."
    )
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match.")
    .required("Confirm Password is required."),
});