
import { Box, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { get, isEmpty } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GET_PROJECTS } from '../../../apis/constants.api';
import { getMyProjectsAPI } from '../../../apis/project.api';
import BoardItems from '../../BoardListview/BoardItems';
import OverlayLoader from '../../shared/OverlayLoader';

function TaskBoard() {
  const navigate = useNavigate();
  const location = useLocation();

  const { projectId, baseRoute } = useMemo(() => {
    const splittedItems = location.pathname.split('/');
    const [_, baseRoute, projectId] = splittedItems;

    return {
      baseRoute,
      projectId
    };
  }, [location]);

  const { data, isLoading } = useQuery({
    queryKey: [GET_PROJECTS, {
      skip: 0,
      limit: 1
    }],
    queryFn: getMyProjectsAPI
  });

  const project = useMemo(() => get(data, 'data.data[0]'), [data]);

  useEffect(() => {
    if (!isLoading && !isEmpty(project) && !projectId) {
      navigate('/boards/' + project._id);
    }
  }, [project, isLoading, projectId]);

  return (
    <Box className="page task-board-page">
      <Box w="100%">
        {
          true || isLoading &&
          <OverlayLoader />
        }
        {
          !isLoading && isEmpty(project) &&
          <Box >
            <Text></Text>
          </Box>
        }
        {
          !isLoading && !isEmpty(project) &&
          <BoardItems />
        }
      </Box>
    </Box>
  );
}

export default TaskBoard;