import { Box, Button, Flex, Image, Input, Spinner, Text } from "@chakra-ui/react";
import { BsUpload } from "react-icons/bs";

const UploadDocs = ({ value, text, image, handleChange, handleRemove, handleClick, hiddenFileInput, handleRetry }) => {
  return (<>
    <Flex
      width={{ base: "100%", sm: "50%" }}
      height="auto"
      flexDirection="column"
      alignItems="center"
    >
      <Box
        border={value?.status === "error" ? "2px solid red" : ""}
        px="50px"
        py="20px"
        boxShadow="0px 1px 7px 1px rgba(0, 0, 0, 0.08)"
        borderRadius="8px"
      >
        {value?.preview ? (
          <img src={value?.preview} alt="front" />
        ) : (
          <Image alt="front" src={image} />
        )}
      </Box>
      <Text
        py="20px"
        fontSize={{
          base: "fs.12",
          md: "fs.14",
          ld: "fs.16",
          xl: "fs.16"
        }}
        fontWeight="fw.500"
      >

        {text}
      </Text>
      {value?.preview ? (
        <Flex w="100%" p={2} border={'1px solid'} borderColor={'gray.300'} borderRadius={'10px'}>
          {value?.status === "uploading" ?
            <Text w="100%" textAlign={"center"}>
              <Spinner mr={3} />
            </Text>
            :
            <Button
              onClick={value?.status === "success" ? handleRemove : handleRetry}
              color="red"
              w="100%"
              fontSize={{
                base: "fs.10",
                md: "fs.14",
                lg: "fs.15"
              }}
              size="sm"
              background="#ffffff00"
            >
              {value?.status === "success" ? "Remove" : "Retry"}
            </Button>
          }
        </Flex>
      ) : (
        <Button
          onClick={handleClick}
          border="1.5px dashed rgba(210, 210, 210, 0.5)"
          background="white"
          fontSize={{
            base: "fs.12",
            md: "fs.14",
            ld: "fs.16",
            xl: "fs.16"
          }}
          fontWeight="fw.400"
          height={{ base: "40px", lg: "50px", xl: "55px" }}
          _hover={{
            background: "#F6F2FD",
            color: "#713EDD",
            border: "1.5px dashed #713EDD"
          }}
          width="100%"
          borderRadius="10px"
        >
          <BsUpload />
          <Text ml="5px">
            Upload Image
          </Text>
        </Button>
      )}
      <Input
        id="image-upload"
        type="file"
        accept="image/*"
        ref={hiddenFileInput}
        onChange={e => handleChange(e)}
        style={{ display: "none" }}
      />
    </Flex>
  </>);
};

export default UploadDocs;