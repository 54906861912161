import React, { useEffect, useState } from 'react';

export default function UserStatus({ count, length }) {
  const [isGroup, setIsGroup] = useState(false);
  useEffect(() => {
    if (length > 1) {
      setIsGroup(true);
    }
    else {
      setIsGroup(false);
    }
  }, [count, length]);
  return (
    <div className='flex flex-row items-center text-sm text-black gap-1' >
      <div className={`w-3 h-3 rounded-full ${count ? "bg-green-150" : "bg-gray-150"}`} />
      {!isGroup && <span className="inline-block text-gray-600">{count ? "online" : "offline"}</span>}
      {isGroup && <span className="inline-block text-gray-600">{count ? `+${count} online` : "offline"}</span>}
    </div >
  );
}



