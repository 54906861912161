import { Avatar, Box, Button, Flex, Image, List, Menu, MenuButton, MenuItem, MenuList, Popover, PopoverContent, PopoverTrigger, Text, useBoolean } from "@chakra-ui/react";
import { faArrowRightFromBracket, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VisibilitySensor from "react-visibility-sensor";

import { IconBell, IconChevronDown } from "@tabler/icons-react";
import { isEmpty } from "lodash";
import { useContext, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from '../../assets/logo/logo.svg';
import { AppContext } from "../../contexts/AppContext";
import { formatNumberWithSuffix } from "../../utils/number.utils";
import { isAdmin } from "../../utils/users.utils";
import CreateTaskDialog from "../BoardListview/CreateTaskDialog";
import { useSocket } from "../Socket";
import CallNotification from "../VideoSDK/components/CallNotification";
import NotificationListItem from "./NotificationListItem";
import { setChannel } from "../../store/channelSlice";
import { useDispatch } from "react-redux";
import { setToken } from "../../store/tokenSlice";

export default function Header() {
    const { socket, callCreatedData, callEndStatus } = useSocket();
    const [isOpen, setIsOpen] = useBoolean(false);
    const [listContainer, setContainer] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const defaultState = {
        user: null,
        auth: null,
    };

    const [stateApp, setStateApp] = useContext(AppContext);
    const [isIncoming, setIsIncoming] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { user, notifications, unReadNotificationCount } = stateApp;
    dispatch(setToken({ isToken: callCreatedData?.token, chatType: callCreatedData?.type }));
    (callCreatedData?.roomId && dispatch(setChannel({ userName: user?.name, roomId: callCreatedData?.roomId, channelType: callCreatedData?.type })));
    useEffect(() => {
        const container = document.getElementById('notification-container');

        if (!isEmpty(container) && isEmpty(listContainer)) {
            setContainer(container);
        }
    }, []);

    useEffect(() => {
        if (callCreatedData?.roomId && callCreatedData?.callOwnerDetail?._id !== user._id) {
            setIsIncoming(true);
        }
    }, [callCreatedData, user]);

    useEffect(() => {
        let interval;
        if (isIncoming) {
            interval = setTimeout(async () => {
                setIsIncoming(false);
                if (callCreatedData?.type === "Directs") {
                    await socket.emit('CALL_REJECT', { type: callCreatedData.type, roomId: callCreatedData.roomId });
                }
            }, 30000);
        }
        return () => clearTimeout(interval);
    }, [isIncoming, callCreatedData]);


    useEffect(() => {
        if (callEndStatus?.callStatus === "Call ended") {
            setIsIncoming(false);
        }
    }, [callEndStatus]);

    const handleLogOut = () => {
        localStorage.removeItem('@apiToken');
        localStorage.removeItem("persist:channel");
        localStorage.removeItem("persist:token");
        setStateApp(defaultState);
    };

    const toggleIsOpen = () => {
        setIsModalOpen(true);
    };

    const toggleIsClose = () => {
        setIsModalOpen(false);
    };

    const navigateToUser = () => {
        navigate("/profile");
    };

    const formattedNotificationCount = useMemo(() => {
        return formatNumberWithSuffix(unReadNotificationCount);
    }, [unReadNotificationCount]);

    const onReject = async () => {
        setIsIncoming(false);
        if (callCreatedData?.type === "Directs") {
            await socket.emit('CALL_REJECT', { type: callCreatedData.type, roomId: callCreatedData.roomId });
        }
    };

    const handleVideoCall = () => {
        setIsIncoming(false);
        window.open(`/call/${callCreatedData.roomId}`, '_blank');
    };

    return (
        <>
            <Flex alignItems="center" justifyContent="space-between">
                <Flex py="20px" px={{ md: "15px", lg: "24px" }} width={{ md: "69px", lg: "100px" }} height="80px" alignItems="center" flexDirection="column">
                    <Link to="/" >
                        <Image width="auto" height="auto" src={logo} />
                    </Link>
                </Flex>
                <Flex py="20px" justifyContent="space-between" width="100%">
                    <Flex width="100%" alignItems="center" justifyContent="end" gap={{ md: "10px", lg: "0" }}>
                        {
                            (!isAdmin(user) && user?.role === 'Mentor' && location.pathname.includes("boards")) &&
                            < Box pr="20px">
                                <Button onClick={toggleIsOpen} px={{ md: "15px", lg: "30px" }} py="8px" background="linear-gradient(134.59deg, #713EDD -1.98%, #A17DEE 111.17%)" _hover={{ background: "linear-gradient(134.59deg, #A17DEE -1.98% ,#713EDD 111.17%)" }} color="white">Add Task</Button>
                            </Box>
                        }
                    </Flex>
                    <Flex pl="20px" alignItems="center" gap={{ md: "10px", lg: "20px" }}>
                        <Flex className="upload-icon" cursor="pointer">
                            <Popover placement="bottom" onOpen={setIsOpen.on} onClose={setIsOpen.off}>
                                <PopoverTrigger>
                                    <Button variant={'unstyled'} display={'flex'}>
                                        <Text color="gray.400">
                                            <IconBell size={25} />
                                        </Text>
                                        {
                                            unReadNotificationCount > 0 &&
                                            <Flex alignItems="center" justifyContent="center" height="18px" minW={'18px'} position="absolute" right="0" bottom='50%' background="red" color="white" fontSize="fs.10" fontWeight="fw.500" borderRadius="br.50" px="2px">{formattedNotificationCount}</Flex>
                                        }
                                    </Button>
                                </PopoverTrigger>
                                <PopoverContent
                                    zIndex={3}
                                    w={{ xs: 320, md: 400, lg: 400, xl: 400 }}
                                    maxH={'calc(100vh - 200px)'}
                                    overflowY={'auto'}
                                    boxShadow={'0 0 5px 2px rgba(0, 0, 0, 0.05)'}>
                                    <List
                                        id="notification-container"
                                        px={4}
                                        maxH={'calc(100vh - 200px)'}
                                        overflowY={'auto'}>
                                        {
                                            isOpen && listContainer && notifications?.map?.(notification =>
                                                <VisibilitySensor containment={listContainer}>
                                                    {
                                                        ({ isVisible }) =>
                                                            <NotificationListItem
                                                                key={notification._id}
                                                                notification={notification}
                                                                isVisible={isVisible}
                                                                containerRef={listContainer} />
                                                    }
                                                </VisibilitySensor>

                                            )
                                        }
                                        {
                                            isOpen && notifications.length === 0 &&
                                            <Text py={4} fontSize={'fs.14'} fontWeight={'semibold'} color={'black'} textAlign={'center'}>NO NEW NOTIFICATIONS FOUND</Text>
                                        }
                                    </List>
                                </PopoverContent>
                            </Popover>
                        </Flex>
                        <Flex alignItems="center" gap={{ md: "10px", lg: "24px" }} justifyContent="end">
                            <Menu>
                                <MenuButton backgroundColor="transparent" padding="0px" _hover={{ backgroundColor: "transparent" }} as={Box} cursor="pointer">
                                    <Flex alignItems="center" gap="15px">
                                        <Avatar src={user?.profilePicture} name={user?.name} size={'sm'} />
                                        <Text maxW={"120px"} fontSize={{ md: "fs.12", xl: "fs.16" }} fontWeight="fw.600" isTruncated>
                                            {user?.name}
                                        </Text>
                                        <IconChevronDown size={25} />
                                    </Flex>
                                </MenuButton>
                                <MenuList>
                                    <MenuItem onClick={navigateToUser} fontSize={{ md: "fs.12", xl: "fs.16" }} fontWeight="fw.500"
                                        icon={<FontAwesomeIcon icon={faUser} size="lg" cursor="pointer" />}  >Profile</MenuItem>
                                    <MenuItem onClick={handleLogOut} fontSize={{ md: "fs.12", xl: "fs.16" }} fontWeight="fw.500" icon={<FontAwesomeIcon icon={faArrowRightFromBracket} size="lg" cursor="pointer" />}  >Sign Out</MenuItem>
                                </MenuList>
                            </Menu>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex >
            <CreateTaskDialog isOpen={isModalOpen} onClose={toggleIsClose} onOpen={toggleIsOpen} isBreadcrumb={false} isAttachButton={false} />
            {isIncoming && <CallNotification isIncoming={isIncoming} onReject={onReject} onReceive={handleVideoCall} />}
        </>
    );
}