import {
  Button, ButtonGroup, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Spinner, Text,
} from '@chakra-ui/react';
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import reject from "../../../../src/assets/icon/reject.png";
import { GET_PROJECTS } from '../../../apis/constants.api';
import { archiveProject } from '../../../apis/project.api';

export default function ArchiveDialog(props) {
  const { project, openArchiveModal, onCloseArchiveModal } = props;
  const queryClient = useQueryClient();
  const { reset, handleSubmit } = useForm();

  const { mutate, isLoading } = useMutation(archiveProject, {
    onSuccess: () => {
      queryClient.invalidateQueries([GET_PROJECTS]);
      reset();
      onCloseArchiveModal();
    }
  });

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  const onReject = async () => {
    try {
      await mutate(project._id);
    } catch (error) {
      console.error(' !!! ERROR ', error);
    }
  };

  return (
    <Modal closeOnOverlayClick={false} onClose={onCloseArchiveModal} size="lg" isOpen={openArchiveModal} isCentered >
      <form onSubmit={handleSubmit(onReject)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Image alt='reject' src={reject} />
          </ModalHeader>
          <ModalCloseButton border="1px solid #868B944D" />
          <ModalBody>
            <Text fontSize="fs.18" fontWeight="fw.600">{`Archive ${project?.name} Request`}</Text>
            <Text fontSize="fs.14" fontWeight="fw.400" color="#475467" mt="5px" >Are you sure you want to archive this project?</Text>

            <ButtonGroup variant='outline' spacing='5' width="100%" my="18px">
              <Button {...actionButtonChakraProps} onClick={onCloseArchiveModal}>Cancel</Button>
              <Button  {...actionButtonChakraProps} type='submit' background={'red.500'} color="white" _hover={{ background: "red.600" }}>
                Archive
                {isLoading && <Spinner size="sm" ml={2} />}
              </Button>
            </ButtonGroup>
          </ModalBody>
        </ModalContent>
      </form>
    </Modal>
  );
}

const actionButtonChakraProps = {
  fontWeight: "fw.600",
  width: "100%",
  borderRadius: "8px",
  height: "44px",
  fontSize: "fs.16",
};